import ListExpense from './components/ListExpense.vue'
import FormExpense from './components/FormExpense.vue'
import ListRevenue from './components/ListRevenue.vue'
import FormRevenue from './components/FormRevenue.vue'

export default [
    {
        path: '/entry/expense',
        component: ListExpense,
        name: 'entry.expense.index',
        meta: {permission: 'search.entry.expense'},
        props: true
    },
    {
        path: '/entry/expense/store',
        component: FormExpense,
        name: 'entry.expense.store',
        meta: {permission: 'store.entry.expense'},
        props: true
    },
    {
        path: '/entry/revenue',
        component: ListRevenue,
        name: 'entry.revenue.index',
        meta: {permission: 'search.entry.revenue'},
        props: true
    },
    {
        path: '/entry/revenue/store',
        component: FormRevenue,
        name: 'entry.revenue.store',
        meta: {permission: 'store.entry.revenue'},
        props: true
    }
]
