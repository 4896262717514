<script>
import GripoDialog from '../common/Dialog'
import vue2Dropzone from 'vue2-dropzone'
import draggable from 'vuedraggable'

import * as restfulService from '../../../app/services/restfulService'

import baseConfig from '../../../config'
import {get} from '@utils/storage'

import {clone} from 'lodash'
import {warningDialog} from '@utils/flash'

export default {
  name: 'form-dropzone-upload',
  components: {GripoDialog, vue2Dropzone, draggable},
  props: {
    list: {
      default: () => {
        return []
      }
    },
    ownerId: {default: 0, required: true},
    ownerType: {default: ''},
    title: {default: 'Lista de Imagens'},
    btnAction: {default: 'Inserir Imagem'}
  },
  data() {
    return {
      images: [],
      image: {
        json: {
          main_sponsor: false,
          white_logo: false,
          name: '',
          link: ''
        }
      },
      isModalUploaderOpen: false,
      isModalCaptionOpen: false,
      dropzoneOptions: {
        url: baseConfig.fullAPIPath + 'image',
        thumbnailWidth: 150,
        maxFilesize: 5, // 5MB
        autoProcessQueue: false,
        addRemoveLinks: true,
        resizeWidth: 600,
        dictFileTooBig: 'Tamanho máximo de arquivo ultrapassado: {{filesize}} mb, o tamanho máximo é: {{maxFilesize}} mb',
        dictDefaultMessage: 'Arraste os arquivos para este local para fazer o upload',
        headers: {
          Authorization: '',
          Session: ''
        }
      }
    }
  },
  watch: {
    'list'(value) {
      this.images = [...value]
    }
  },
  async mounted() {
    const token = await get('token')
    const session = await get('session')
    this.dropzoneOptions.headers.Authorization = 'Bearer ' + token
    this.dropzoneOptions.headers.Session = session
  },
  methods: {
    handleModalUploader() {
      this.isModalUploaderOpen = !this.isModalUploaderOpen
    },
    handleModalCaption(image) {
      if (image.json === null) {
        image.json = {...this.image.json}
      }
      this.image = {...this.image, ...image}
      this.isModalCaptionOpen = !this.isModalCaptionOpen
    },
    onDropzoneSending(file, xhr, formData) {
      formData.append('owner_id', this.ownerId)
      formData.append('owner_type', this.ownerType)
      formData.append('has_images', this.images.length)
    },
    onSendImages() {
      this.$refs.dropzone.processQueue()
    },
    onDropzoneUploadComplete(file) {
      if (file && file.xhr) {
        if (file.xhr.status === 200) {
          let response = JSON.parse(file.xhr.responseText)
          this.images.push(response)
          this.$notify.success({
            title: 'Sucesso!',
            message: 'Arquivo gravado',
            offset: 50
          })
        }
        if (file.xhr.status === 500) {
          let response = JSON.parse(file.xhr.responseText)
          this.$notify.error({
            title: 'Atenção',
            message: response.message,
            offset: 50
          })
          console.log('Erro ao enviar o arquivo ', response.message)
        }
      }
    },
    onSortEnd(images) {
      this.onUpdateOrder(images)
    },
    onUpdateCaption(image) {
      this.isSubmitted = true
      const data = {}
      data.json = image.json
      restfulService.put('image', null, image.id, data)
          .then(() => {
            this.image = {}
            this.isModalCaptionOpen = !this.isModalCaptionOpen
            this.isSubmitted = false
          })
          .catch(e => {
            this.isSubmitted = false
            console.log(e)
          })
    },
    onUpdateOrder(images) {
      this.isSubmitted = true
      const data = clone(images)
      restfulService.post('image', 'update-order', null, data)
          .then(() => {
            this.isSubmitted = false
          })
          .catch(e => {
            this.isSubmitted = false
            console.log(e)
          })
    },
    onDropzoneFileUploadError(file, message) {
      if (message !== 'Upload canceled.') {
        warningDialog({
          title: '',
          text: 'Ocorreu um erro ao enviar o arquivo: ' + file.name,
          icon: 'warning'
        })
        console.log('Erro ao enviar o arquivo ', message)
      }
    },
    onDeleteFile(file) {
      const id = file.id
      restfulService.destroy('image', null, id)
          .then(() => {
            this.images = [...this.images.filter((item) => item.id !== id)]
          })
          .catch(e => {
            warningDialog({
              title: '',
              text: 'Ocorreu um erro ao remover a imagem, tente novamente',
              icon: 'warning'
            })
            console.log(e)
          })
    }
  }
}
</script>

<template>
  <el-card class="el-card__save_form box-card dropzone-box">
    <div slot="header">
      <span>{{ title }}</span>
      <el-button size="small"
                 type="primary"
                 @click="handleModalUploader">
        <i class="fas fa-plus"></i>
        <span>{{ btnAction }}</span>
      </el-button>
    </div>

    <draggable v-model="images"
               :options="{handle: '.move'}"
               class="images-list"
               @end="onSortEnd(images)">
      <div class="item" v-for="image in images">
        <div class="actions">
          <a class="move">
            <span class="icon"><i class="fas fa-arrows-alt"></i></span>
          </a>
          <a class="edit"
             @click="handleModalCaption(image)">
            <span class="icon"><i class="fas fa-pencil-alt"></i></span>
          </a>
          <a class="remove"
             @click="onDeleteFile(image)">
            <span class="icon"><i class="fas fa-times"></i></span>
          </a>
        </div>
        <div :class="['image', {'-white-logo': image.json && image.json.white_logo}]"
             :style="{ backgroundImage: 'url(' + image.file + ')' }"></div>
        <div class="caption" v-if="image.json && image.json.caption">{{ image.json.caption }}</div>
      </div>
    </draggable>

    <gripo-dialog :model.sync="isModalUploaderOpen"
                  width="60vw">
      <template v-slot:header>{{ btnAction }}</template>
      <template v-slot:content>
        <vue-2-dropzone ref="dropzone"
                        id="dropzone"
                        :options="dropzoneOptions"
                        @vdropzone-sending="onDropzoneSending"
                        @vdropzone-complete="onDropzoneUploadComplete"
                        @vdropzone-error="onDropzoneFileUploadError"></vue-2-dropzone>
      </template>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <el-button type="primary" @click="onSendImages">Enviar Arquivos</el-button>
      </template>
    </gripo-dialog>

    <gripo-dialog :model.sync="isModalCaptionOpen"
                  width="60vw">
      <template v-slot:header>Editar Dados</template>
      <template v-slot:content>
        <el-form label-position="top"
                 @submit.prevent="onUpdateCaption(image)">
          <el-form-item label="Patrocinador Master">
            <el-switch v-model="image.json.main_sponsor"
                       active-text="Sim"
                       :active-value="true"
                       inactive-text="Não"
                       :inactive-value="false">
            </el-switch>
          </el-form-item>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="Nome">
                <el-input v-model="image.json.name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Link">
                <el-input v-model="image.json.link"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="Logo Branco">
            <el-switch v-model="image.json.white_logo"
                       active-text="Sim"
                       :active-value="true"
                       inactive-text="Não"
                       :inactive-value="false">
            </el-switch>
          </el-form-item>
          <el-form-item label="Imagem">
            <figure :class="['preview-image', {'-white-logo': image.json.white_logo}]">
              <img :src="image.file" alt="">
            </figure>
          </el-form-item>
          <el-form-item>
            <el-button type="primary"
                       @click="onUpdateCaption(image)">Salvar Dados
            </el-button>
          </el-form-item>
        </el-form>
      </template>
    </gripo-dialog>
  </el-card>
</template>

<style lang="scss">
.dropzone-box {
  margin-top: 15px;
}

#dropzone {
  margin-top: 15px;
  border: 3px dashed #CCC;
  padding: 35px 15px;
  border-radius: 8px;
  background-color: #FFF;
  font-size: 16px;
}

.images-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -6px;

  > .item {
    width: 23%;
    margin: 0 6px 15px;
    border: 1px solid #d1dbe5;
    border-radius: 4px;
    background-color: #fff;
    overflow: hidden;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .12), 0 0 6px 0 rgba(0, 0, 0, .04);
    position: relative;

    .actions {
      position: absolute;
      top: 5px;
      right: 5px;
      background-color: rgba(0, 0, 0, .4);
      padding: 3px 5px;
      border-radius: 6px;

      a {
        display: inline-block;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        text-decoration: none;
        text-align: center;
        color: #FFF;
        cursor: pointer;

        .icon {
          font-size: 12px;
          position: relative;
          top: 1px;
        }

        &.move {
          background-color: #5c5c5c;
          cursor: move;
        }

        &.edit {
          background-color: #3074cc;
        }

        &.remove {
          background-color: #cc0e00;
        }
      }
    }

    > .image {
      height: 120px;
      background-size: contain;
      background-position: center center;

      &.-white-logo {
        background-color: #4e4e4e;
      }
    }

    > .caption {
      padding: 10px;
      font-size: 12px;
    }
  }
}

.preview-image {
  padding: 10px;

  &.-white-logo {
    background-color: #4e4e4e;
  }

  img {
    display: block;
    max-width: 400px;
  }
}
</style>
