<script>
import FormSaveButton from '@components/form/SaveButton'

import * as notifyService from '@app/services/notifyService'
import {
  SETTINGS_TYPES,
  defaultTenantRankingCobrapa,
  getTenantSettings,
  saveTenantSettings
} from '../../../service'

import {mapGetters} from 'vuex'
import {required} from 'vuelidate/lib/validators'

const SETTINGS_TYPE = SETTINGS_TYPES.tenant_ranking_cobrapa

export default {
  components: {FormSaveButton},
  name: 'ranking-cobrapa',
  data() {
    return {
      isComponentLoading: true,
      isFirstLoad: true,
      isSubmitted: false,
      showSecretToken: false,
      form: {...defaultTenantRankingCobrapa}
    }
  },
  validations: {
    form: {
      secret_token: {required}
    }
  },
  computed: {
    ...mapGetters(['tenant'])
  },
  async mounted() {
    this.isComponentLoading = true
    this.getSettings()
  },
  methods: {
    async getSettings() {
      const result = await getTenantSettings(SETTINGS_TYPE)
      this.form = {...this.form, ...result.data}
      setTimeout(() => {
        this.isFirstLoad = false
        this.isComponentLoading = false
      }, 200)
    },
    onSaveSettings() {
      this.isComponentLoading = true
      saveTenantSettings({type: SETTINGS_TYPE, settings: {...this.form}}, true)
          .then((settings) => {
            notifyService.success()
            this.form = {...this.form, ...settings.data}
            this.isComponentLoading = false
          })
          .catch(e => {
            this.isComponentLoading = false
            console.log(e)
          })
    },
  }
}
</script>

<template>
  <el-card class="el-card__save_form box-car el-loading-mask-light"
           :class="{'tenant-card-settings-loading' : isComponentLoading}"
           v-loading="isComponentLoading"
           element-loading-text="Carregando dados...">
    <div slot="header">
      <span>Ranking COBRAPA (Padel)</span>
      <el-switch v-model="form.enabled"
                 :active-value="true"
                 :inactive-value="false"
                 active-text="Habilitado"
                 inactive-text="">
      </el-switch>
    </div>
    <div v-if="form.enabled">
      <el-form-item label="Token do Clube"
                    class="is-required"
                    :class="{ 'el-form-item--error': $v.form.secret_token.$error }">
        <el-input v-model="form.secret_token"
                  :type="showSecretToken ? 'text' : 'password'"
                  @input="$v.form.secret_token.$touch()"
                  autocomplete="off">
          <div class="el-input--suffix-box" slot="suffix"
               @click="showSecretToken = !showSecretToken">
            <span v-if="showSecretToken" key="passwordVisibility"><i class="fas fa-eye"></i></span>
            <span v-else key="passwordVisibilityOff"><i class="fas fa-eye-slash"></i></span>
          </div>
        </el-input>
      </el-form-item>
    </div>
    <form-save-button :event-click="onSaveSettings"
                      :is-disabled="isComponentLoading || $v.form.$invalid"
                      :is-submitted="isSubmitted"/>
  </el-card>
</template>
