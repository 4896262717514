<script>
import GripoDialog from '../../../../../../components/common/Dialog'
import PlayerClassItem from './PlayerClass'
import PlayerClassAmount from './PlayerClassAmount'

import * as restfulService from '../../../../../../services/restfulService'
import * as notifyService from '../../../../../../services/notifyService'
import DataService from '../../../../../../services/dataService'

import {currencyConfig} from '@utils/money'
import helpers from '@mixins/helpers'
import bus from '@utils/bus'

import {cloneDeep, orderBy, merge} from 'lodash'
import {confirmDialog} from '@utils/flash'
import {mapGetters} from "vuex";

const getAmountObject = (counter = 1) => {
  return {
    key: `registration${counter}_amount`,
    value: 0,
    plan_discounts: [],
    plans: [],
    no_cost: false,
  }
}

export default {
  mixins: [helpers],
  props: {
    stageId: {
      type: Number,
      required: true
    }
  },
  components: {GripoDialog, PlayerClassItem, PlayerClassAmount},
  data() {
    return {
      listData: [],
      dialogVisible: false,
      playerClassCheckbox: ['male', 'female', 'mixed', 'child', 'free'],
      playerClassFilterName: '',
      showOnlyCobrapa: false,
      dependencies: {
        players_class: [],
        plans: [],
      },
      globalConfig: {
        max_pairs: 0,
        percent_points: 100,
        amounts: [],
      },
      stage: {
        players_class: [],
      },
      updatePrice: false,
      isSubmitted: false
    }
  },
  computed: {
    ...mapGetters(['tenant', 'hasRankingCobrapaEnabled']),
    moneyMask() {
      return currencyConfig()
    },
    listDataFiltered() {
      let list = [...this.listData]
      if (this.playerClassCheckbox.length > 0) {
        list = list.filter(item => this.playerClassCheckbox.indexOf(item.sex) !== -1)
      }
      if (this.playerClassFilterName.length > 0) {
        list = list.filter(item => item.name_with_sex.toLowerCase().includes(this.playerClassFilterName.toLowerCase()))
      }
      if (this.showOnlyCobrapa) {
        list = list.filter(item => item.cobrapa)
      }
      return orderBy(list, ['active', 'sex', 'name_with_sex'], ['desc', 'asc', 'asc'])
    },
    planWithRegistrationRule() {
      return this.dependencies.plans.filter(item => item.rules.some(rule => rule.target === 'registration'))
    },
    selectedPlayersClassCount() {
      return this.listData.filter(item => item.checked).length
    }
  },
  mounted() {
    this.getDependencies()
  },
  methods: {
    async getDependencies() {
      const response = await DataService.get([
        {domain: 'plan', relations: ['rules']}
      ])
      this.dependencies = {...response}
    },
    getPlayerClassAmounts() {
      const amounts = []
      for (let i = 0; i < (this.stage.settings?.max_registration_per_player || 1); i++) {
        amounts.push({...getAmountObject(i + 1)})
      }
      return amounts
    },
    openDialog() {
      bus.$emit('show-loader')
      this.isSubmitted = false
      this.listData.splice(0)
      restfulService.get('manage/stage', 'players-class', this.stageId).then(response => {
        this.dependencies.players_class = [...response.dependencies.players_class]
        this.stage = {...cloneDeep(response)}
        const list = []
        if (response.settings?.player_class_global_config !== null) {
          this.globalConfig = {...merge(this.globalConfig, response.settings?.player_class_global_config)}
        }
        for (let i = 0; i < (this.stage.settings?.max_registration_per_player || 1); i++) {
          const amountObj = {...getAmountObject(i + 1)}
          if (!this.globalConfig.amounts.some(item => item.key === amountObj.key)) {
            this.globalConfig.amounts.push(amountObj)
          }
        }
        this.dependencies.players_class.forEach(playerClass => {
          const stagePlayerClass = response.players_class.find(item => item.player_class_id === playerClass.id)
          let amounts = [...this.getPlayerClassAmounts()]
          if (stagePlayerClass && stagePlayerClass.amounts !== null) {
            amounts = [...stagePlayerClass.amounts]
            this.getPlayerClassAmounts().map(item => {
              if (!amounts.some(amount => amount.key === item.key)) {
                amounts.push(item)
              }
            })
            amounts.map(item => {
              return {
                key: item.key,
                value: item.value,
                plan_discounts: item.plan_discounts || [],
                plans: item.plans || [],
                no_cost: false,
              }
            })
          }
          list.push({
            id: stagePlayerClass ? stagePlayerClass.id : null,
            visible: stagePlayerClass ? stagePlayerClass.visible : false,
            checked: !!stagePlayerClass,
            active: !!stagePlayerClass,
            name_with_sex: playerClass.name_with_sex,
            sex: playerClass.sex,
            player_class_id: playerClass.id,
            cobrapa: playerClass.cobrapa,
            stage_id: this.stageId,
            type: stagePlayerClass ? stagePlayerClass.type : 'groups',
            max_pair_per_group: '' + (stagePlayerClass ? stagePlayerClass.max_pair_per_group : 3),
            max_pairs: stagePlayerClass ? stagePlayerClass.max_pairs : 0,
            amount: stagePlayerClass ? this.currencyFormatter(stagePlayerClass.amount) : 0,
            percent_points: stagePlayerClass && stagePlayerClass.percent_points !== null ? stagePlayerClass.percent_points : response.percent_points,
            registration_start_date: stagePlayerClass ? stagePlayerClass.registration_start_date : null,
            registration_end_date: stagePlayerClass ? stagePlayerClass.registration_end_date : null,
            registration_closed: stagePlayerClass ? stagePlayerClass.registration_closed : response.registration_closed,
            amounts: [...amounts]
          })
        })
        this.listData = [...list]
        bus.$emit('hide-loader')
        this.dialogVisible = true
      }).catch(e => {
        bus.$emit('hide-loader')
        console.log(e)
      })
    },
    closeDialog() {
      bus.$emit('hide-loader')
    },
    save(forced) {
      forced = forced || false
      bus.$emit('show-loader')
      this.isSubmitted = true
      let data = {forced}
      data.players_class = [...this.listData.filter(item => item.active)]
      data.players_class.forEach(item => {
        item.amount = this.currencyParsed(item.amount)
        item.amounts.forEach(amount => {
          amount.value = this.currencyParsed(amount.value)
          if (Array.isArray(amount.plan_discounts)) {
            amount.plan_discounts.forEach(planDiscount => {
              planDiscount.value = this.currencyParsed(planDiscount.value)
            })
          } else {
            amount.plan_discounts = []
          }
        })
      })
      restfulService.put('stage', 'player_class', this.stageId, data)
          .then(async (response) => {
            if (response.error === undefined) {
              const stageData = {
                settings: {
                  player_class_global_config: {
                    ...this.globalConfig,
                    amounts: this.globalConfig.amounts.map(amount => {
                      amount.value = this.currencyParsed(amount.value)
                      return amount
                    })
                  }
                }
              }
              await restfulService.put('manage/stage', 'save-config', this.stageId, stageData)
              notifyService.success({hideLoader: true})
              this.isSubmitted = false
              this.closeDialog()
            } else {
              this.isSubmitted = false
              bus.$emit('hide-loader')
              const {value: confirm} = await confirmDialog({
                title: response.error,
                text: 'Ao confirmar essa ação, as duplas e inscrições dessas categorias serão canceladas',
              })
              if (confirm) {
                this.save(true)
              }
            }
          })
          .catch(e => {
            bus.$emit('hide-loader')
            this.isSubmitted = false
            console.log(e)
          })
    }
  }
}

</script>


<template>
  <div class="stage-card card-players-class">

    <div class="card-content">
      <div class="card-icon">
        <i class="fas fa-boxes"></i>
      </div>
      <div class="card-information">
        <div class="card-title">Configurações das Categorias</div>

        <div class="card-buttons">
          <div class="box">
            <div class="full">
              <el-button @click="openDialog" type="default" size="small">
                <i class="fas fa-cog"></i> Gerenciar
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <gripo-dialog :model.sync="dialogVisible"
                  width="98vw"
                  top="2vh"
                  :on-close="closeDialog">
      <template v-slot:header>
        <span>Gerenciar Categorias</span>
        <strong>({{selectedPlayersClassCount}}) Selecionadas</strong>
      </template>
      <template v-slot:content>
        <v-container fluid grid-list-md class="p-0">
          <v-layout wrap>
            <v-flex sm4>
              <div class="simple-card -highlight mb-2">
                <div class="content">
                  <el-form label-position="top">
                    <h4>Filtrar/Buscar Categorias:</h4>
                    <el-checkbox-group v-model="playerClassCheckbox">
                      <el-checkbox label="male">Masc.</el-checkbox>
                      <el-checkbox label="female">Fem.</el-checkbox>
                      <el-checkbox label="mixed">Mistas</el-checkbox>
                      <el-checkbox label="child">Infantil</el-checkbox>
                      <el-checkbox label="free">Livre</el-checkbox>
                    </el-checkbox-group>
                    <el-input class="mt-2"
                              v-model="playerClassFilterName"
                              placeholder="Filtrar por nome"/>
                    <el-form-item v-if="hasRankingCobrapaEnabled" label="Filtrar somente Categorias COBRAPA" class="pb-0 mb-0 mt-2">
                      <el-switch v-model="showOnlyCobrapa"
                                 active-text="SIM"
                                 inactive-text="Não">
                      </el-switch>
                    </el-form-item>
                  </el-form>
                </div>
              </div>
            </v-flex>
            <v-flex sm8>
              <div class="simple-card -highlight mb-3">
                <div class="content">
                  <h4>Configurações Globais:</h4>
                  <el-form>
                    <el-tabs :tab-position="$vuetify.breakpoint.xs ? 'top' : 'left'">
                      <el-tab-pane label="Configurações">
                        <v-container fluid grid-list-xs class="p-0">
                          <v-layout wrap>
                            <v-flex>
                              <el-form-item label="Nº Máx. de Duplas Inscritas por Categoria" class="-no-validation">
                                <el-input type="number" :min="0" :step="1" v-model="globalConfig.max_pairs"/>
                                <div class="el-form-item-info">0 = Sem limite</div>
                              </el-form-item>
                            </v-flex>
                            <v-flex>
                              <el-form-item label="% da Pontuação do Ranking" class="-no-validation">
                                <el-input type="number" :min="0" :step="1" v-model="globalConfig.percent_points"/>
                              </el-form-item>
                            </v-flex>
                          </v-layout>
                        </v-container>
                      </el-tab-pane>
                      <el-tab-pane :label="`Valores (${globalConfig.amounts.length})`">
                        <v-container fluid grid-list-xs class="p-0">
                          <v-layout wrap>
                            <v-flex>
                              <div v-for="(amount, index) in globalConfig.amounts">
                                <player-class-amount :amount.sync="amount.value"
                                                     :plan-discounts.sync="amount.plan_discounts"
                                                     :index="index"
                                                     :show-no-cost="false"
                                                     active
                                                     :plans="planWithRegistrationRule"/>
                              </div>
                            </v-flex>
                          </v-layout>
                        </v-container>
                      </el-tab-pane>
                    </el-tabs>
                  </el-form>
                </div>
              </div>
            </v-flex>
          </v-layout>
        </v-container>
        <el-form label-position="top" :inline="true" class="stage-players-class-list">
          <template v-for="(playerClass, index) in listDataFiltered">
            <player-class-item :key="index"
                               :player-class="playerClass"
                               :update-price="updatePrice"
                               :global-config="globalConfig"
                               :stage="stage"
                               :plans="planWithRegistrationRule"/>
          </template>
        </el-form>
      </template>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <el-button type="primary"
                   @click="save"
                   :disabled="isSubmitted "
                   :loading="isSubmitted">
          {{ isSubmitted ? 'Salvando' : 'Salvar' }} Alterações
        </el-button>
      </template>
    </gripo-dialog>
  </div>
</template>

