import 'vuetify/dist/vuetify.css'
import 'element-ui/lib/theme-chalk/index.css'
import 'sweetalert2/src/sweetalert2.scss'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

import './assets/sass/element-variables.scss'

import Vue from 'vue'
import App from './app/components/main/Main'
import router from './router'
import store from './vuex'
import http from './http'
import i18n from './utils/i18n'
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/pt-br'
import VueSweetAlert from './utils/sweetalert'
import Vuelidate from 'vuelidate'
import VueMoney from 'v-money'
import VueTheMask from 'vue-the-mask'
import Vuetify from 'vuetify'
import VueAnalytics from 'vue-analytics'
import Echo from 'laravel-echo'
import Pusher from 'pusher-js'

import './app/filters'

Vue.config.productionTip = false

Vue.use(VueAnalytics, {
    id: 'UA-76871119-3',
    router,
    disabled: process.env.NODE_ENV === 'development'
})

Vue.use(http, {router})
Vue.use(ElementUI, {locale})
Vue.use(VueSweetAlert)
Vue.use(Vuelidate)
Vue.use(VueMoney)
Vue.use(VueTheMask)
Vue.use(Vuetify, {
    theme: {
        primary: '#4F4894',
        secondary: '#67c23a'
    },
    iconfont: 'fa'
})

// Global websocket config
const websocketHost = () => process.env.VUE_APP_WEBSOCKET_HOST
const websocketPort = () => process.env.VUE_APP_WEBSOCKET_PORT
const pusherKey = () => process.env.VUE_APP_PUSHER_KEY

window.Pusher = Pusher
window.Echo = new Echo({
    broadcaster: 'pusher',
    key: pusherKey(),
    wsHost: websocketHost(),
    wsPort: websocketPort(),
    wssPort: websocketPort(),
    forceTLS: false,
    encrypted: true,
    disableStats: true,
    enabledTransports: ['ws', 'wss'],
    cluster: 'mt1'
})

// Global locale config
window.localeCountryConfig = {
    locale: 'pt-BR',
    currency_code: 'BRL',
}

/* eslint-disable no-new */
new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')