<script>
import {getTenantData} from '@app/domains/system/tenant/service'

export default {
  name: 'finish-order-send-whatsapp',
  props: ['model', 'client', 'isPaid'],
  data() {
    return {
      sendToWhats: {
        enabled: false,
        message: ''
      },
      tenantPaymentConfig: {
        entry_portion_notify_custom_message: '',
        order_whatsapp_message: {
          unpaid: false,
          paid: false
        }
      },
    }
  },
  mounted() {
    this.sendToWhats = {...this.model}
    this.getTenantData()
  },
  watch: {
    sendToWhats: {
      deep: true,
      handler: function (value) {
        this.$emit('update:model', value)
      }
    },
    isPaid: function (value) {
      this.sendToWhats.enabled = false
      if (value && this.tenantPaymentConfig.order_whatsapp_message.paid && !this.sendToWhatsDisabled) {
        this.sendToWhats.enabled = true
      }
      if (!value && this.tenantPaymentConfig.order_whatsapp_message.unpaid && !this.sendToWhatsDisabled) {
        this.sendToWhats.enabled = true
      }
    }
  },
  computed: {
    clientPhone() {
      return this.client?.phone ?? this.client?.user?.phone
    },
    sendToWhatsDisabled() {
      return !this.clientPhone
    },
    sendToWhatsDisabledMessage() {
      let message = ''
      if (!this.clientPhone) {
        message += 'O cliente precisa de um telefone cadastrado para receber a comanda pelo whatsapp'
      }
      return message
    }
  },
  methods: {
    async getTenantData() {
      const result = await getTenantData()
      if (result) {
        this.tenantPaymentConfig = {...this.tenantPaymentConfig, ...result?.tenant_payment || {}}
        if (this.tenantPaymentConfig.entry_portion_notify_custom_message) {
          this.sendToWhats.message = this.tenantPaymentConfig.entry_portion_notify_custom_message
        }
        if (this.isPaid && this.tenantPaymentConfig.order_whatsapp_message.paid) {
          this.sendToWhats.enabled = true
        }
        if (!this.isPaid && this.tenantPaymentConfig.order_whatsapp_message.unpaid) {
          this.sendToWhats.enabled = true
        }
      }
    }
  }
}
</script>

<template>
  <div class="finish-order-send-whatsapp">
    <v-layout align-center justify-center>
      <v-flex class="icon">
        <i class="fab fa-whatsapp"></i>
      </v-flex>
      <v-flex class="fs-14">
        Enviar Comanda pelo Whatsapp
        <div v-if="clientPhone" class="font-weight-bold">( {{ clientPhone }} )</div>
      </v-flex>
      <v-flex>
        <el-switch v-model="sendToWhats.enabled"
                   :disabled="sendToWhatsDisabled"
                   :title="sendToWhatsDisabledMessage"
                   :active-value="true"
                   :inactive-value="false"
                   :active-text="sendToWhats ? 'SIM': 'NÃO'">
        </el-switch>
      </v-flex>
    </v-layout>
    <div class="mt-3" v-if="sendToWhats.enabled">
      <div class="mt-2 fs-14">Mensagem Customizada (Será incluída ao final da mensagem)</div>
      <el-input type="textarea"
                style="font-weight: normal"
                :rows="3"
                v-model="sendToWhats.message"/>
    </div>
  </div>
</template>
