<script>
export default {
  name: 'stock-type-label',
  props: ['type']
}
</script>

<template>
  <div>
    <el-tag type="success" v-if="type === 'input'">Entrada</el-tag>
    <el-tag type="danger" v-if="type === 'output'">Saída</el-tag>
  </div>
</template>

<style>
.el-tag {
  padding: 4px 10px;
  height: 30px;
  line-height: 21px;
}
</style>
