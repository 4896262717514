<script>
export default {
  name: 'invoice-type-label',
  props: ['type']
}
</script>

<template>
  <div class="d-inline-block">
    <el-tag size="mini" type="default" v-if="type === 'nfce'">Produto</el-tag>
    <el-tag size="mini" type="warning" v-if="type === 'nfse'">Serviço</el-tag>
  </div>
</template>
