<script>
import VueApexCharts from 'vue-apexcharts'
import * as restfulService from '../../../../services/restfulService'
import DatepickerPeriod from './DatepickerPeriod'

export default {
  name: 'dashboard-top-consumers',
  components: {
    apexchart: VueApexCharts,
    DatepickerPeriod,
  },
  props: ['tab', 'windowHeight', 'windowWidth'],
  data() {
    return {
      loading: false,
      period: 7,
      limit: 10,
      series: [{
        data: []
      }],
      chartOptions: {
        chart: {
          type: 'bar',
          toolbar: {
            show: false
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            distributed: true,
            barHeight: '100%',
          },
        },
        dataLabels: {
          enabled: true,
          formatter: (value) => value.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'}),
        },
        legend: {
          show: false
        },
        xaxis: {
          categories: [],
          labels: {
            formatter: (value) => value.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'}),
            style: {
              colors: [],
              fontSize: '0.7em',
              cssClass: 'apexcharts-xaxis-label',
            },
          }
        },
        yaxis: {
          labels: {
            formatter: (value) => value.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'}),
          },
        },
      },
    }
  },
  computed: {
    apexHeight() {
      if (this.windowWidth < 1024) {
        return 300
      }
      return (this.windowHeight - 360) / 2
    }
  },
  watch: {
    limit() {
      this.loadData()
    },
    period() {
      this.loadData()
    },
    tab(value) {
      if (value === 'consumers' && this.loading === false) {
        this.loadData()
      }
    }
  },
  methods: {
    async loadData() {
      this.loading = true
      const post = {limit: this.limit, initialDate: this.period[0], finalDate: this.period[1]}
      const data = await restfulService.post('dashboard', 'top-consumers', null, post)
      const {series, categories} = data

      this.series = [{name: 'Total', data: series}]
      this.chartOptions = {
        ...this.chartOptions, ...{
          xaxis: {
            categories: [...categories]
          }
        }
      }
      setTimeout(() => {
        this.loading = false
      }, 500)
    }
  }
}
</script>
<template>
  <div class="inner-content"
       v-loading="loading"
       element-loading-text="Carregando dados...">
    <div slot="header">
      <div class="flex-c -jc-fe">
        <datepicker-period :period.sync="period"></datepicker-period> &nbsp;
        <el-select size="mini" v-model="limit">
          <el-option label="Top 10" :value="10"/>
          <el-option label="Top 20" :value="20"/>
        </el-select>
      </div>
    </div>
    <div id="chart">
      <apexchart type="bar" :height="apexHeight" :options="chartOptions" :series="series"></apexchart>
    </div>
  </div>
</template>