<script>
export default {
  name: 'sender-status-label',
  props: ['status'],
  computed: {
    statusLabel() {
      if (this.status === 'not_connected' || this.status === 'paused') {
        return 'Desconectado'
      } else if (this.status === 'not_found') {
        return 'Carregando Informações...'
      } else {
        return 'Conectado'
      }
    },
    statusType() {
      if (this.status === 'not_connected' || this.status === 'paused') {
        return 'danger'
      } else if (this.status === 'not_found') {
        return 'warning'
      } else {
        return 'success'
      }
    }
  }
}
</script>

<template>
  <div>
    <el-tag :type="statusType">{{ statusLabel }}</el-tag>
  </div>
</template>
