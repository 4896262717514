<script>
import ListHeader from '../../../../components/list/Header'
import FormSaveButton from '../../../../components/form/SaveButton'
import FormCancelButton from '../../../../components/form/CancelButton'

import * as restfulService from '../../../../services/restfulService'
import * as notifyService from '../../../../services/notifyService'

import helpers from '@mixins/helpers'
import bus from '@utils/bus'

import {clone} from 'lodash'
import {required} from 'vuelidate/lib/validators'

export default {
  components: {ListHeader, FormSaveButton, FormCancelButton},
  mixins: [helpers],
  data() {
    return {
      isEdit: false,
      isSubmitted: false,
      isLoadingData: true,
      form: {
        name: '',
        max_players: 0
      }
    }
  },
  validations: {
    form: {
      name: {required},
      max_players: {required}
    }
  },
  async mounted() {
    const id = this.$route.params.id || null
    if (id) {
      this.isEdit = true
      restfulService.get('player_group', null, id)
          .then(response => {
            this.form = {...response}
            this.isLoadingData = false
            bus.$emit('hide-loader')
          })
          .catch(e => {
            console.log(e)
            this.isLoadingData = false
            bus.$emit('hide-loader')
          })
    } else {
      this.isLoadingData = false
      bus.$emit('hide-loader')
    }
  },
  methods: {
    onSend() {
      this.isSubmitted = true
      const data = clone(this.form)
      restfulService.save('player_group', null, null, data)
          .then(() => {
            notifyService.success({hideLoader: true})
            this.isSubmitted = false
            this.$router.push({name: 'player_group.index'})
          })
          .catch(e => {
            this.isSubmitted = false
            console.log(e)
          })
    }
  }
}
</script>

<template>
  <div>

    <ListHeader title="Circuito"/>

    <el-form ref="form"
             :model="form"
             label-position="top"
             @submit.prevent="onSend">

      <el-card class="el-card__save_form box-card">
        <div slot="header">
          <span>Dados do Grupo</span>
        </div>

        <el-form-item label="Nome"
                      class="is-required"
                      :class="{ 'el-form-item--error': $v.form.name.$error }">
          <el-input v-model="form.name"
                    @input="$v.form.name.$touch()"/>
          <div class="el-form-item__error" v-if="$v.form.name.$error">Esse campo é obrigatório</div>
        </el-form-item>
        <el-form-item label="Nº Máximo de Jogadores"
                      class="is-required"
                      :class="{'el-form-item--error': $v.form.max_players.$error }">
          <el-input type="number"
                    :min="0"
                    :step="1"
                    v-model="form.max_players"
                    @input="$v.form.max_players.$touch()"/>
          <div class="el-form-item__error" v-if="$v.form.max_players.$error">
            <span v-if="!$v.form.max_players.required">Esse campo é obrigatório</span>
          </div>
        </el-form-item>
      </el-card>
      <el-card class="el-card__btn_form">
        <form-save-button :event-click="onSend"
                          :is-disabled="isSubmitted || $v.form.$invalid"
                          :is-submitted="isSubmitted">
        </form-save-button>
        <form-cancel-button route-name="player_group.index"/>
      </el-card>
    </el-form>
  </div>
</template>
