<script>
import PlayerClassAmount from './PlayerClassAmount'

import {currencyConfig} from '@utils/money'

import {mapGetters} from 'vuex'

export default {
  name: 'player-class-item',
  components: {PlayerClassAmount},
  props: {
    playerClass: {
      type: Object
    },
    updatePrice: {
      type: Boolean,
      default: false
    },
    stage: {
      type: Object
    },
    globalConfig: {
      type: Object
    },
    plans: {
      type: Array
    }
  },
  data() {
    return {
      sameDateAsStage: true
    }
  },
  computed: {
    ...mapGetters(['tenant']),
    moneyMask() {
      return currencyConfig()
    },
    planWithRegistrationRule() {
      return this.plans.filter(item => item.rules.some(rule => rule.target === 'registration'))
    }
  },
  watch: {
    'playerClass.checked': function (value) {
      setTimeout(() => {
        this.playerClass.max_pairs = this.globalConfig.max_pairs
        this.playerClass.percent_points = this.globalConfig.percent_points
        this.playerClass.amounts = [...this.globalConfig.amounts]
        this.playerClass.active = value
      }, 200)
    },
    playerClass: {
      handler(value) {
        if (value && value.id) {
          this.sameDateAsStage = this.playerClass.registration_start_date === null && this.playerClass.registration_end_date === null
        }
      },
      deep: true,
      immediate: true
    },
    sameDateAsStage(value) {
      if (value) {
        this.playerClass.registration_start_date = null
        this.playerClass.registration_end_date = null
      }
    }
  }
}
</script>

<template>
  <v-container fluid grid-list-md
               :class="['simple-card stage-players-class-list-item', {'-active': playerClass.active}]">
    <v-layout wrap>
      <v-flex xs12>
        <div class="stage-players-class-list-item-container">
          <div class="player-class">
            <span class="mr-2">{{ playerClass.name_with_sex }}</span>
            <el-switch v-model="playerClass.checked"
                       v-if="!playerClass.active">
            </el-switch>
            <el-switch v-model="playerClass.checked"
                       v-else>
            </el-switch>
          </div>
          <div class="player-class-type" v-if="playerClass.active && stage.settings?.bracket_system !== 'super16'">
            <el-radio-group v-model="playerClass.type">
              <el-radio label="groups">Grupos</el-radio>
              <el-radio label="eliminatory">Eliminatórias</el-radio>
            </el-radio-group>
          </div>
          <el-button size="small" circle v-if="playerClass.active" @click="playerClass.visible = !playerClass.visible">
            <template v-if="playerClass.visible">
              <i class="fas fa-chevron-up"></i>
            </template>
            <template v-else>
              <i class="fas fa-chevron-down"></i>
            </template>
          </el-button>
        </div>
      </v-flex>
      <v-flex xs12 v-if="playerClass.active && playerClass.visible">
        <el-tabs tab-position="top">
          <el-tab-pane label="Configurações">
            <v-container fluid grid-list-xs class="p-0">
              <v-layout wrap>
                <v-flex>
                  <el-form-item label="Nº Máx. de Duplas Inscritas" class="-no-validation">
                    <el-input type="number" :min="0" :step="1" v-model="playerClass.max_pairs"/>
                    <div class="el-form-item-info">0 = Sem limite</div>
                  </el-form-item>
                </v-flex>
                <v-flex>
                  <el-form-item label="% da Pontuação do Ranking" class="-no-validation">
                    <el-input type="number" :min="0" :step="1" v-model="playerClass.percent_points"/>
                  </el-form-item>
                </v-flex>
                <v-flex>
                  <el-form-item label="Datas de Inscrição e Bloqueio:" class="-no-validation">
                    <el-switch v-model="playerClass.registration_closed"
                               :disabled="!playerClass.active"
                               class="mr-2"
                               active-color="#D3320F"
                               active-text="Bloquear Inscrições">
                    </el-switch>
                    <div class="mt-2">
                      <el-switch v-model="sameDateAsStage"
                                 active-text="Usar mesma data de inscrição do torneio"
                                 :disabled="!playerClass.active">
                      </el-switch>
                      <div v-if="!sameDateAsStage" class="mt-2">
                        <el-form-item label="Data de Início">
                          <el-date-picker v-model="playerClass.registration_start_date"
                                          :disabled="!playerClass.active"
                                          placeholder="Data de Inicio"
                                          type="datetime"
                                          format="dd/MM/yyyy HH:mm"
                                          size="small"
                                          value-format="yyyy-MM-dd HH:mm:ss">
                          </el-date-picker>
                        </el-form-item>
                        <el-form-item label="Data de Término">
                          <el-date-picker v-model="playerClass.registration_end_date"
                                          :disabled="!playerClass.active"
                                          placeholder="Data de Término"
                                          type="datetime"
                                          format="dd/MM/yyyy HH:mm"
                                          size="small"
                                          value-format="yyyy-MM-dd HH:mm:ss">
                          </el-date-picker>
                        </el-form-item>
                      </div>
                    </div>
                  </el-form-item>
                </v-flex>
              </v-layout>
            </v-container>
          </el-tab-pane>
          <el-tab-pane :label="`Valores (${playerClass.amounts.length})`">
            <v-container fluid grid-list-xs class="p-0">
              <v-layout wrap>
                <v-flex>
                  <div v-for="(amount, index) in playerClass.amounts">
                    <player-class-amount :amount.sync="amount.value"
                                         :plan-discounts.sync="amount.plan_discounts"
                                         :no-cost.sync="amount.no_cost"
                                         :index="index"
                                         :active="playerClass.active"
                                         :key="`${playerClass.id}-${index}`"
                                         :plans="planWithRegistrationRule"/>
                  </div>
                </v-flex>
              </v-layout>
            </v-container>
          </el-tab-pane>
        </el-tabs>
      </v-flex>
    </v-layout>
  </v-container>
</template>

