<script>
import FormSaveButton from '@components/form/SaveButton'
import TotemOrder from "./modules/Order.vue";
import TotemCheckin from "./modules/Checkin.vue";
import TotemFacialRegister from "./modules/FacialRegister.vue";

import * as notifyService from '@app/services/notifyService'
import {
  defaultTotem,
  eventOffReloadSettingsType,
  eventOnReloadSettingsType,
  getTenantSettings,
  saveTenantSettings,
  SETTINGS_TYPES,
  TOTEM_VALIDATION_OPTION_FACIAL,
  TOTEM_VALIDATION_OPTION_QRCODE
} from '../../../service'
import {warningDialog} from "@utils/flash";
import {mapGetters} from 'vuex'

const SETTINGS_TYPE = SETTINGS_TYPES.totem

export default {
  components: {TotemOrder, TotemCheckin, TotemFacialRegister, FormSaveButton},
  name: 'totem',
  data() {
    return {
      isComponentLoading: true,
      isFirstLoad: true,
      isSubmitted: false,
      form: {...defaultTotem, modules: []},
      validationOptions: [TOTEM_VALIDATION_OPTION_QRCODE, TOTEM_VALIDATION_OPTION_FACIAL]
    }
  },
  computed: {
    ...mapGetters(['tenant', 'hasProfessionalPlan', 'hasTotemModule', 'hasFaceReconModuleEnabled'])
  },
  async mounted() {
    eventOnReloadSettingsType(SETTINGS_TYPE, this.onLoad)
    this.onLoad()
  },
  beforeDestroy() {
    eventOffReloadSettingsType(SETTINGS_TYPE)
  },
  methods: {
    async onLoad() {
      this.isComponentLoading = true
      this.getSettings()
    },
    async getSettings() {
      const result = await getTenantSettings(SETTINGS_TYPE)
      this.form = {...this.form, ...result.data}
      const modules = [...defaultTotem.modules]
          .filter(item => {
            if (item.type === 'facial_register' && !this.hasFaceReconModuleEnabled) {
              return false
            }
            return true
          })
          .map(item => {
            if (item.type === 'facial_register' && !this.hasFaceReconModuleEnabled) {
              item.validation_options = [TOTEM_VALIDATION_OPTION_QRCODE]
            }
            const moduleEnabled = this.form.modules.find(module => module.type === item.type && module.enabled)
            if (moduleEnabled) {
              item.enabled = moduleEnabled.enabled
              item.settings = moduleEnabled.settings
              item.validation_options = moduleEnabled.validation_options
            }
            return item
          })
      if (!this.hasFaceReconModuleEnabled) {
        this.validationOptions = [TOTEM_VALIDATION_OPTION_QRCODE]
      }
      this.form.modules = [...modules]
      setTimeout(() => {
        this.isFirstLoad = false
        this.isComponentLoading = false
      }, 200)
    },
    onSaveSettings() {
      if (this.form.enabled) {
        if (this.form.modules.every(item => !item.enabled)) {
          warningDialog({text: 'Você precisa pelo menos 1 das opções habilitadas para habilitar o Totem'})
          return;
        }
        if (this.form.modules.some(item => item.enabled && item.validation_options.length === 0)) {
          warningDialog({text: 'Você precisa selecionar pelo menos 1 opção de validação para habilitar as opções'})
          return;
        }
      }
      this.isComponentLoading = true
      saveTenantSettings({type: SETTINGS_TYPE, settings: {...this.form}}, true)
          .then((settings) => {
            notifyService.success()
            this.form = {...this.form, ...settings.data}
            this.isComponentLoading = false
          })
          .catch(e => {
            this.isComponentLoading = false
            console.log(e)
          })
    },
  }
}
</script>

<template>
  <el-card class="el-card__save_form box-car el-loading-mask-light"
           :class="{'tenant-card-settings-loading' : isComponentLoading}"
           v-loading="isComponentLoading"
           element-loading-text="Carregando dados..."
           v-if="hasTotemModule">
    <div slot="header">
      <span>Totem de Atendimento</span>
      <el-switch v-model="form.enabled"
                 :active-value="true"
                 :inactive-value="false"
                 active-text="Habilitado"
                 inactive-text="">
      </el-switch>
    </div>
    <div>
      <template v-for="(module, index) in form.modules">
        <totem-order v-if="module.type === 'store_order'"
                     :key="index"
                     :enabled="form.enabled"
                     :validation-options="validationOptions"
                     :module="module"/>
        <totem-checkin v-if="module.type === 'checkin'"
                       :key="index"
                       :enabled="form.enabled"
                       :validation-options="validationOptions"
                       :module="module"/>
        <totem-facial-register v-if="module.type === 'facial_register'"
                               :key="index"
                               :enabled="form.enabled"
                               :validation-options="validationOptions"
                               :module="module"/>
      </template>
      <form-save-button :event-click="onSaveSettings"
                        :is-disabled="isComponentLoading"
                        :is-submitted="isSubmitted"/>
    </div>
  </el-card>
</template>
