export const currencyConfig = (config = {}) => {
    config = {...config, ...window?.localeCountryConfig || {}}
    const locale = config?.locale || 'pt-BR'
    const currency = config?.currency_code || 'BRL'
    const showSymbol = (config?.symbol || false) === true
    const formatterParts = (new Intl.NumberFormat(locale, {
        style: 'currency',
        currency
    })).formatToParts(1000000000).reverse()
    const decimalSeparator = formatterParts.find(item => item.type === 'decimal')
    const thousandsSeparator = formatterParts.find(item => item.type === 'group')
    const symbol = formatterParts.find(item => item.type === 'currency')
    return {
        locale,
        currency,
        decimal: decimalSeparator?.value || '.',
        thousands: thousandsSeparator?.value || '',
        prefix: showSymbol ? `${symbol.value} ` : '',
        precision: 2,
        masked: false,
        symbol: symbol.value
    }
}
