<script>
import * as authService from '../../../../services/authService'

import {default as helpers} from '@mixins/helpers'
import bus from '@utils/bus'
import {successDialog, warningDialog} from '@utils/flash'
import {getUserUniqueId} from '@utils/fingerprint'
import {loginRedirect} from '../service'

import {mapGetters} from 'vuex'
import {required} from 'vuelidate/lib/validators'
import {clone} from 'lodash'
import moment from 'moment'

export default {
  mixins: [helpers],
  data() {
    return {
      isSubmitted: false,
      isLoadingData: true,
      showPassword: false,
      form: {
        tenant_id: '',
        login: '',
        password: ''
      },
      clubs: []
    }
  },
  validations: {
    form: {
      login: {required},
      password: {required}
    }
  },
  computed: {
    ...mapGetters(['isLogged', 'hasBasicPlan', 'hasProfessionalPlan', 'tenant'])
  },
  mounted() {
    if (this.$route.params && this.$route.params.hash) {
      this.onLoginByHash(this.$route.params.hash)
    } else {
      setTimeout(() => {
        if (process.env.NODE_ENV === 'development') {
          this.form.password = (process.env.VUE_APP_GRIPO_TENANT_MASTER_PASSWORD || 'tenant') + moment().format('DD')
        }
        bus.$emit('hide-loader')
        this.$refs.login.focus()
        if (this.$route.query && this.$route.query.login) {
          this.form.login = this.$route.query.login
          this.$refs.password.focus()
        }
      }, 100)
    }
  },
  methods: {
    async onLogin() {
      bus.$emit('show-loader')
      this.isSubmitted = true
      const data = clone(this.form)
      data.device = await getUserUniqueId()
      this.clubs.splice(0)
      authService.login(data)
          .then(async (response) => {
            const {
              routeName,
              routeParams
            } = await loginRedirect(response, this.$router, this.hasBasicPlan, this.hasProfessionalPlan)
            this.isSubmitted = false
            if (routeName === false) {
              this.clubs = [...response.clubs]
              bus.$emit('hide-loader')
            } else {
              setTimeout(() => {
                this.$router.push({name: routeName, params: routeParams})
              }, 400)
            }
          })
          .catch(e => {
            bus.$emit('hide-loader')
            this.form.tenant_id = ''
            this.isSubmitted = false
            console.log(e)
          })
    },
    onRecoveryAccess() {
      if (!this.form.login) {
        warningDialog({
          title: 'Digite o seu login para solicitar a recuperação de senha',
        })
        return
      }
      bus.$emit('show-loader')
      this.isSubmitted = true
      const data = {login: this.form.login}
      authService.recoveryAccess(data)
          .then(response => {
            successDialog({
              title: response.title,
              text: response.text,
            })
            bus.$emit('hide-loader')
            this.isSubmitted = false
          })
          .catch(e => {
            bus.$emit('hide-loader')
            this.isSubmitted = false
            console.log(e)
          })
    },
    async onLoginByHash(hash) {
      this.isSubmitted = true
      const data = {
        hash,
        device: await getUserUniqueId()
      }
      authService.loginByHash(data)
          .then(() => {
            this.$router.push({name: 'client.index'})
            this.isSubmitted = false
          })
          .catch(() => {
            bus.$emit('hide-loader')
            this.isSubmitted = false
          })
    }
  }
}
</script>

<template>
  <div class="login-box" v-if="!isLogged">

    <div class="login-card-box">
      <div class="app-header">
        <h2>Área Restrita</h2>
      </div>

      <el-card class="el-card__save_form box-card">
        <el-form ref="form"
                 :model="form"
                 label-position="top"
                 @submit.prevent="onLogin">
          <el-form-item label="Login"
                        class="is-required"
                        :class="{ 'el-form-item--error': $v.form.login.$error }">
            <el-input v-model="form.login"
                      size="large"
                      ref="login"
                      @input="$v.form.login.$touch()"
                      @keydown.enter.native.prevent="onLogin"/>
            <div class="el-form-item__error" v-if="$v.form.login.$error">Esse campo é obrigatório</div>
          </el-form-item>
          <el-form-item label="Senha"
                        class="is-required"
                        :class="{ 'el-form-item--error': $v.form.password.$error }">
            <el-input v-model="form.password"
                      size="large"
                      ref="password"
                      :type="showPassword ? 'text' : 'password'"
                      @input="$v.form.password.$touch()"
                      @keydown.enter.native.prevent="onLogin">
              <div class="el-input--suffix-box" slot="suffix" @click="showPassword = !showPassword">
                <span v-if="showPassword" key="passwordVisibility"><i class="fas fa-eye"></i></span>
                <span v-else key="passwordVisibilityOff"><i class="fas fa-eye-slash"></i></span>
              </div>
            </el-input>
            <div class="el-form-item__error" v-if="$v.form.password.$error">Esse campo é obrigatório</div>
          </el-form-item>
          <el-form-item label="Selecione o Clube para Entrar" v-if="clubs.length > 0">
            <el-select v-model="form.tenant_id"
                       placeholder="Selecione o clube"
                       empty-text="Nenhum registro encontrado"
                       class="el-select-full">
              <el-option v-for="item in clubs"
                         :key="item.id"
                         :label="item.name"
                         :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary"
                       size="large"
                       @click="onLogin"
                       :loading="isSubmitted"
                       :disabled="isSubmitted || $v.form.$invalid">Acessar
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="text"
                       @click="onRecoveryAccess"
                       :loading="isSubmitted"
                       :disabled="isSubmitted">Esqueci minha senha
            </el-button>
          </el-form-item>
        </el-form>
      </el-card>
      <div class="login-logo">
        <img src="https://gripo.app/static/imgs/logo-light.png" alt="">
      </div>
    </div>
  </div>
</template>
