<script>
import * as restfulService from '@app/services/restfulService'
import * as notifyService from '@app/services/notifyService'

import bus from '@utils/bus'
import DataService from '@app/services/dataService'
import {confirmDialog} from '@utils/flash'

export default {
  name: 'registration-status',
  props: {
    size: {
      type: String,
      default: 'mini'
    },
    status: {
      type: String,
      default: ''
    },
    registration: {
      type: Object
    },
    showActions: {
      type: Boolean,
      default: true
    },
    playerNumber: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      localStatus: '',
      statusList: []
    }
  },
  computed: {
    statusLabel() {
      return this.statusList.find(item => item.id === this.localStatus)?.name ?? ''
    },
    tagType() {
      if (this.localStatus === 'approved') {
        return 'success'
      } else if (this.localStatus === 'canceled') {
        return 'danger'
      } else {
        return 'info'
      }
    }
  },
  watch: {
    status: function (value) {
      this.localStatus = value
      if (value && this.statusList.length === 0) {
        DataService.get([{domain: 'registration_status'}]).then((result) => {
          this.statusList = [...result.registration_status]
        })
      }
    }
  },
  mounted() {
    if (this.status) {
      this.localStatus = this.status
      if (this.statusList.length === 0) {
        DataService.get([{domain: 'registration_status'}]).then((result) => {
          this.statusList = [...result.registration_status]
        })
      }
    }
  },
  methods: {
    async approveTransaction() {
      const {value: confirm} = await confirmDialog({
        title: 'Deseja realmente confirmar essa inscrição?',
        icon: 'warning',
      })
      if (confirm) {
        bus.$emit('show-loader')
        const data = {}
        restfulService.put('registration', 'approve', this.registration.id, data)
            .then(response => {
              notifyService.success({hideLoader: true})
              this.localStatus = response.status
              this.$emit('updateRegistration')
              bus.$emit('hide-loader')
            })
            .catch(e => {
              console.log(e)
              bus.$emit('hide-loader')
            })
      }
    }
  }
}
</script>

<template>
  <div :class="['d-inline-block text-center']">
    <el-tag :size="size" class="el-tag--status" :type="tagType">
      {{ statusLabel }}
    </el-tag>
    <div v-if="localStatus === 'canceled' && !!registration.canceled_datetime" class="mt-1">
      <strong>Em: {{ registration.canceled_datetime | dateTimeEnToBr }}h
        ({{ registration.canceled_by_player ? 'App' : 'Gestão' }})</strong>
    </div>
    <div class="mt-1" v-if="showActions">
      <el-button type="success"
                 class="el-button--extra-small"
                 v-if="localStatus === 'created'"
                 @click="approveTransaction"
                 :size="size"
                 title="Confirmar Inscrição Manualmente">
        <span class="mr-1">Confirmar</span>
        <i class="fas fa-check"></i>
      </el-button>
    </div>
  </div>
</template>
