<script>
export default {
  name: 'device-order-payment-type-label',
  props: ['paymentMethodType'],
  computed: {
    label() {
      if (this.paymentMethodType === 'credit') {
        return 'Cartão de Crédito'
      }
      if (this.paymentMethodType === 'credit') {
        return 'Cartão de Débito'
      }
      return 'PIX'
    }
  }
}
</script>

<template>
  <el-tag size="mini" type="default">{{ label }}</el-tag>
</template>
