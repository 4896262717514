<script>
import GripoDialog from '../../../../components/common/Dialog'

import helpers from '@mixins/helpers'

import {debounce, cloneDeep, orderBy} from 'lodash'

export default {
  name: 'schedule-notify',
  components: {GripoDialog},
  mixins: [helpers],
  props: {
    notifyUser: {
      type: Object
    },
    show: {
      type: Boolean,
      default: false
    },
    scheduleFixed: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isSubmitted: false,
      isModalVisible: false,
      notify: false,
      playersList: [],
    }
  },
  watch: {
    show: debounce(function (value) {
      if (value) {
        this.playersList = [...orderBy(this.notifyUser.players.filter(item => item.client !== null), ['type'], ['asc'])]
        this.isModalVisible = true
      } else {
        this.notify = false
        this.playersList.splice(0)
        this.isModalVisible = false
      }
    }, 100),
  },
  methods: {
    toggleModalVisible() {
      this.$emit('update:show', !this.show)
    },
    confirmNotifySchedule() {
      const notifyUser = {...cloneDeep(this.notifyUser)}
      notifyUser.players = notifyUser.players.filter(item => item.notify)
      this.$emit('update:notifyUser', notifyUser)
      setTimeout(() => {
        if (this.notifyUser.type === 'remove' && this.scheduleFixed) {
          this.$emit('confirmRemoveScheduleFixed')
        } else if (this.notifyUser.type === 'remove') {
          this.$emit('confirmRemoveSchedule')
        } else if (this.notifyUser.type === 'save') {
          this.$emit('saveSchedule')
        } else if (this.notifyUser.type === 'queue_set') {
          this.$emit('setScheduleQueue')
        }
        this.toggleModalVisible()
      }, 200)
    },
    notifyAllUsers(value) {
      this.playersList.filter(item => item.client.phone).map(item => {
        item.notify = value
        return item
      })
    }
  }
}
</script>

<template>
  <div v-if="show">
    <gripo-dialog :model.sync="isModalVisible"
                  width="50vw"
                  :actions-close-btn="false"
                  :on-close="toggleModalVisible">
      <template slot="header">Notificar Usuários</template>
      <template slot="content">
        <el-form label-position="top">
          <el-row>
            <el-col class="schedule-notify-list">
              <p>
                Marcando a opção abaixo os atletas irão receber uma notificação por:
                <strong>app, email ou whatsapp</strong>
                conforme as configurações de privacidades que eles utilizam.
              </p>
              <div class="notify-list-all">
                <el-checkbox @change="notifyAllUsers" v-model="notify">
                  <span>Enviar notificação para todos</span>
                </el-checkbox>
              </div>
              <template v-if="playersList.length > 0">
                <v-divider/>
                <div v-for="(player, index) in playersList">
                  <template v-if="player.type === 'coach'">
                    <div :key="player.client.name" class="notify-list-coach" title="Professor">
                      <el-checkbox v-model="player.notify"
                                   :disabled="!player.client.phone">
                        <span>{{ player.client.name }} - <strong>Professor(a)</strong></span>
                        <span v-if="!player.client.phone"
                              class="font-weight-bold"> - Professor sem telefone cadastrado</span>
                      </el-checkbox>
                    </div>
                  </template>
                  <template v-if="player.type === 'player'">
                    <div :key="player.client.name" class="notify-list-player" title="Atleta">
                      <el-checkbox v-model="player.notify"
                                   :disabled="!player.client.phone">
                        <span>{{ player.client.name }}</span>
                        <span v-if="!player.client.phone"
                              class="font-weight-bold"> - Cliente sem telefone cadastrado</span>
                      </el-checkbox>
                    </div>
                  </template>
                  <v-divider v-if="index + 1 < playersList.length"
                             :key="index"></v-divider>
                </div>
              </template>
            </el-col>
          </el-row>
        </el-form>
      </template>
      <template slot="actions">
        <el-button type="success"
                   @click="confirmNotifySchedule"
                   :disabled="isSubmitted"
                   :loading="isSubmitted">
          <i class="fas fa-check"></i>
          <span>Confirmar</span>
        </el-button>
      </template>
    </gripo-dialog>
  </div>
</template>