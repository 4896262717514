<script>
export default {
  name: 'order-queue-status',
  props: ['status']
}
</script>

<template>
  <div class="d-inline-block">
    <el-tag size="small" class="el-tag--status" type="info" v-if="status === 'created'">
      Na fila
    </el-tag>
    <el-tag size="small" class="el-tag--status" type="default" v-if="status === 'cooking'">
      Em preparação
    </el-tag>
    <el-tag size="small" class="el-tag--status" type="warning" v-if="status === 'done'">
      Pronto
    </el-tag>
    <el-tag size="small" class="el-tag--status" type="success" v-if="status === 'delivered'">
      Entregue
    </el-tag>
  </div>
</template>
