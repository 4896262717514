<script>
import AppHeader from './Header'
import AppSidebar from './Sidebar'
import GripoDialog from '@components/common/Dialog'
import TellerControl from '@app/domains/financial/entry_teller/components/TellerControl.vue'

import * as notifyService from '@app/services/notifyService'
import * as restfulService from '@app/services/restfulService'

import helpers from '@mixins/helpers'
import bus from '@utils/bus'
import {getUserUniqueId} from '@utils/fingerprint'
import {mapGetters, mapActions} from 'vuex'

import {register} from 'register-service-worker'
import {isIOS} from "mobile-device-detect";

const isProduction = () => process.env.NODE_ENV === 'production'

export default {
  name: 'App',
  components: {AppHeader, AppSidebar, GripoDialog, TellerControl},
  mixins: [helpers],
  data() {
    return {
      showUpdateDialog: false,
      drawer: true,
      gaUserLoaded: false,
      firstPageLoad: true,
      timer: null,
      timerCurrentSeconds: 0,
      idle: false,
    }
  },
  computed: {
    ...mapGetters(['isLogged', 'isLoading', 'loadingMessage', 'showSidebar', 'currentUser', 'tenant', 'isAdmin', 'isFinancial']),
    wsGlobal() {
      const key = 'tenantMessage'
      return {
        channel: `${key}.${this.tenant?.id || ''}`,
        userChannel: `${key}.${this.tenant?.id || ''}.${this.currentUser?.id || ''}`,
        listen: `.${key}`
      }
    },
    isIOS() {
      return isIOS
    }
  },
  watch: {
    isLogged: function (value) {
      if (value) {
        this.startWebsocket()
        if (!this.gaUserLoaded) {
          this.$ga.set({
            tenantId: this.tenant.id,
            tenantName: this.tenant.name,
            userId: this.currentUser.id,
            userLogin: this.currentUser.login,
          })
          this.gaUserLoaded = true
        }
        if (this.firstPageLoad) {
          setTimeout(async () => {
            await this.getUserSession()
          }, 500)
          setTimeout(() => {
            this.firstPageLoad = false
          }, 800)
        }
      } else {
        this.closeWebsocket()
      }
    }
  },
  beforeMount() {
    document.querySelector('#global-loader').remove()
  },
  mounted() {
    this.registerServiceWorker()
    this.resetTimer()
    window.addEventListener('mousemove', this.resetTimer)
    window.addEventListener('mousedown', this.resetTimer)
    window.addEventListener('touchstart', this.resetTimer)
    window.addEventListener('click', this.resetTimer)
    window.addEventListener('keypress', this.resetTimer)
    bus.$on('show-loader', this.showLoader)
    bus.$on('hide-loader', this.hideLoader)
    bus.$on('reload-session-info', this.getUserSession)
    this.$refs.mainContainer.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    window.removeEventListener('mousemove', this.resetTimer)
    window.removeEventListener('mousedown', this.resetTimer)
    window.removeEventListener('touchstart', this.resetTimer)
    window.removeEventListener('click', this.resetTimer)
    window.removeEventListener('keypress', this.resetTimer)
    this.$refs.mainContainer.removeEventListener('scroll', this.handleScroll)
    bus.$off('reload-session-info', this.getUserSession)
    this.closeWebsocket()
  },
  methods: {
    ...mapActions(['showLoader', 'hideLoader', 'setUpAuth', 'setIdle', 'setLoadingSession']),
    async registerServiceWorker() {
      const vm = this
      if (isProduction()) {
        register(`${process.env.BASE_URL}service-worker.js`, {
          async updated() {
            vm.showUpdateDialog = true
          }
        })
      }
    },
    async forceUpdateApp() {
      this.showUpdateDialog = false
      window.location.reload()
    },
    handleScroll(event) {
      bus.$emit('main-container-scroll', event.target.scrollTop)
    },
    startWebsocket() {
      window.Echo.channel(this.wsGlobal.channel).listen(this.wsGlobal.listen, ({data}) => notifyService.common(data))
      window.Echo.channel(this.wsGlobal.userChannel).listen(this.wsGlobal.listen, ({data}) => notifyService.common(data))
    },
    closeWebsocket() {
      window.Echo.leaveAllChannels()
    },
    async getUserSession(callback) {
      if (!this.firstPageLoad) {
        this.setLoadingSession(true)
      }
      const data = {device: await getUserUniqueId()}
      const response = await restfulService.post('user', 'session-info', null, data)
      const {user, permissions, roles, session, token} = response
      await this.setUpAuth({tenant: user.tenant, user, permissions, roles, session, token})
      if (!this.firstPageLoad) {
        setTimeout(() => {
          this.setLoadingSession(false)
          if (typeof callback === 'function') {
            callback()
          }
        }, 300)
      } else {
        if (typeof callback === 'function') {
          callback()
        }
      }
    },
    startIdleTimer() {
      if (!this.idle && this.timerCurrentSeconds === 0) {
        this.setIdle({current: true, seconds: 0})
      }
      this.idle = true
      this.timerCurrentSeconds++
      this.setIdle({current: true, seconds: this.timerCurrentSeconds})
    },
    resetTimer() {
      clearInterval(this.timer)
      if (this.idle && this.timerCurrentSeconds > 0) {
        this.setIdle({current: false, seconds: this.timerCurrentSeconds})
        this.idle = false
      }
      this.timerCurrentSeconds = 0
      this.timer = setInterval(this.startIdleTimer, 1000)
    }
  }
}
</script>

<template>
  <v-app id="app" :class="[{'-ios': isIOS}, {'-loading': isLoading}]">
    <div class="app-loader" v-if="isLoading">
      <div class="loader-container">
        <div class="overlay-full"></div>
        <div class="overlay-loader">
          <div class="spinner"></div>
          <span v-html="loadingMessage"></span>
        </div>
      </div>
    </div>
    <template>
      <AppHeader v-if="isLogged"/>
      <AppSidebar v-if="isLogged"/>
      <v-content :class="['app-content', {'-no-auth': !isLogged}]">
        <el-alert v-if="tenant.status === 'active' && tenant?.whatsapp_credits < 1"
                  show-icon
                  class="mt-3 mb-3 ml-2"
                  style="width: 98%"
                  type="error"
                  effect="dark">
          <div slot="title" class="fs-16 pt-1">
            Você está sem créditos para enviar mensagens no whatsapp!
          </div>
        </el-alert>
        <v-container fluid fill-height ref="mainContainer">
          <v-layout>
            <v-flex xs12 shrink>
              <router-view></router-view>
            </v-flex>
          </v-layout>
        </v-container>
      </v-content>

      <TellerControl />

      <a href="https://api.whatsapp.com/send?phone=53991582039"
         class="support-wp-icon"
         target="_blank"
         title="Preciso de ajuda">
        <i class="fab fa-whatsapp"></i>
      </a>
      <gripo-dialog :model.sync="showUpdateDialog"
                    width="400px"
                    :persistent="true"
                    :actions-close-btn="false"
                    :show-top-close-btn="false">
        <div slot="content" class="update-modal">
          <div class="update-icon">
            <i class="fas fa-exclamation-circle"></i>
          </div>
          <div class="update-text">
            Existe uma atualização disponível, para prosseguir utilize
            o botão abaixo para atualizar o sistema
          </div>
        </div>
        <template v-slot:actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1 white--text" large @click="forceUpdateApp" class="mb-3">
            <i class="fas fa-sync-alt"></i>
            <span class="ml-2">Atualizar</span>
          </v-btn>
          <v-spacer></v-spacer>
        </template>
      </gripo-dialog>
    </template>
  </v-app>
</template>

<style src="../../../assets/sass/app.scss" lang="scss"></style>
<style src="../../../assets/sass/schedule.scss" lang="scss"></style>
<style src="../../../assets/sass/responsive.scss" lang="scss"></style>