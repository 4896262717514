<script>
import DrawMatch from './DrawMatch'

export default {
  name: 'draw-brackets-generator',
  components: {DrawMatch},
  props: {
    stageId: {
      type: Number
    },
    playerClassId: {
      type: Number
    },
    size: {
      type: Number,
      default: 16
    },
    matches: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data() {
    return {
      hideToPrint: false
    }
  },
  computed: {
    rounds() {
      return [...new Set(this.matches.slice().sort(item => item.round - item.round).map(item => item.round))]
    }
  },
  methods: {
    fixRound(round) {
      return round / 2
    },
    match(round, number) {
      const match = this.matches.find(match => {
        return round === match.round && number === match.number
      })
      if (!match) {
        return {
          id: null,
          datetime: ''
        }
      }
      return match
    }
  }
}
</script>

<template>
  <div>
    <div class="tournament-brackets" v-if="matches.length > 0">
      <div class="bracket">
        <template v-for="(round, index) in rounds">
          <div class="round" :class="['round-' + (round * 2)]" :key="index"
               :style="{'width: 200px;': hideToPrint}">
            <template v-for="(game, gameIndex) in fixRound(round)">
              <div class="match-overlay" :style="{top: (gameIndex * (100 / round) * 2) + '%'}">
                <div class="font-weight-bold" v-if="match(round, game).id">
                  {{ match(round, game).datetime | dateTimeEnToBr }}
                  <span v-if="match(round, game).court"> - {{ match(round, game).court.name }}</span>
                </div>
              </div>
              <div></div>
              <div class="match">
                <draw-match :key="round + '-' + game + '-pair1'"
                            :hide-to-print="hideToPrint"
                            :matches="matches"
                            :stage-id="stageId"
                            :player-class-id="playerClassId"
                            :round="round"
                            :game="game"
                            :pair-number="1"/>
              </div>
              <div class="match">
                <draw-match :key="round + '-' + game + '-pair2'"
                            :hide-to-print="hideToPrint"
                            :matches="matches"
                            :stage-id="stageId"
                            :player-class-id="playerClassId"
                            :round="round"
                            :game="game"
                            :pair-number="2"/>
              </div>
            </template>
          </div>
        </template>
        <div class="round round-1" :key="999999999">
          <div class="match">
            <div class="match-content"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.tournament-brackets {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;

  .bracket {
    display: flex;
    width: 100%;
  }

  .round {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    position: relative;
    min-width: 300px;

    &:first-child {
      .match {
        &::before {
          display: none !important;
        }

        > .match-content::before {
          display: none !important;
        }
      }
    }

    &:last-child {
      .match {
        &::after {
          display: none !important;
        }

        > .match-content::after {
          display: none !important;
        }
      }
    }

    .match-overlay {
      position: absolute;
      width: 100%;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .match {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 5px 10px;
      padding: 8px 0;
      flex-grow: 1;
      position: relative;

      &::before {
        content: "";
        display: block;
        height: 30px;
        border-left: 2px solid purple;
        position: absolute;
        left: -10px;
        top: 50%;
        margin-top: -15px;
        margin-left: -2px;
      }

      &:nth-child(odd)::after {
        content: "";
        display: block;
        border: 2px solid transparent;
        border-top-color: purple;
        border-right-color: purple;
        height: 50%;
        position: absolute;
        right: -10px;
        width: 10px;
        top: 50%;
      }

      &:nth-child(even)::after {
        content: "";
        display: block;
        border: 2px solid transparent;
        border-bottom-color: purple;
        border-right-color: purple;
        height: 50%;
        position: absolute;
        right: -10px;
        width: 10px;
        bottom: 50%;
      }

      > .match-content {
        border: 1px solid green;
        width: 100%;
        height: 40px;
        padding: 3px 6px;
        position: relative;
        text-align: center;
        font-size: 12px;
        line-height: 16px;

        &::before {
          content: "";
          display: block;
          width: 10px;
          border-bottom: 2px solid purple;
          margin-left: -2px;
          position: absolute;
          top: 50%;
          left: -10px;
        }

        .match-box {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;

          .draggable-component {
            width: 100%;
            min-height: 34px;
          }
        }
      }
    }

    &.round-4 {
      .match-overlay {
        height: 100%;
      }
    }

    &.round-8 {
      .match-overlay {
        height: 50%;
      }
    }

    &.round-16 {
      .match-overlay {
        height: 25%;
      }
    }

    &.round-32 {
      .match-overlay {
        height: 12.5%;
      }
    }

    &.round-64 {
      .match-overlay {
        height: 6.25%;
      }
    }
  }
}
</style>
