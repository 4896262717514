<script>
import {mapGetters} from 'vuex'

export const defaultChargingRules = {
  enabled: false,
  notify_at: '08:00',
  generate_transaction: false,
  days_notify: 5,
}

export default {
  name: 'charging-notify',
  props: {
    chargingRulesData: {
      type: Object
    }
  },
  data() {
    return {
      charging_rules: {...defaultChargingRules}
    }
  },
  computed: {
    ...mapGetters(['tenant']),
    paymentAccount() {
      return (this.tenant?.has_payment_account ?? false)
    }
  },
  watch: {
    charging_rules: {
      deep: true,
      handler: function (value) {
        if (value && value.notify_at.length === 5) {
          value.notify_at += ':00'
        }
        this.$emit('update:chargingRulesData', value)
      }
    }
  },
  mounted() {
    if (this.chargingRulesData !== null) {
      let notifyAt = this.chargingRulesData.notify_at
      if (notifyAt && notifyAt.length === 8) {
        notifyAt = notifyAt.slice(0, 5)
      }
      this.charging_rules = {
        ...this.charging_rules,
        ...this.chargingRulesData,
        notify_at: notifyAt
      }
    }
  }
}
</script>

<template>
  <el-form v-model="charging_rules">
    <el-row :gutter="20">
      <el-col :span="8">
        <el-form-item label="Enviar Cobrança Automaticamente?">
          <el-switch
              v-model="charging_rules.enabled"
              active-color="#13ce66"
              active-text="SIM"
              inactive-color="#ff4949"
              inactive-text="NÃO">
          </el-switch>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="Notificar X Dias Antes">
          <el-input-number v-model="charging_rules.days_notify"
                           :disabled="!charging_rules.enabled"
                           :step="1"
                           :max="30"
                           :min="0">
          </el-input-number>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="Horário da Notificação">
          <el-time-select
              :disabled="!charging_rules.enabled"
              v-model="charging_rules.notify_at"
              placeholder="Horário"
              value-format="HH:mm"
              :picker-options="{
                        start: '06:00',
                        step: '01:00',
                        end: '23:00'
                      }">
          </el-time-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="8">
        <el-alert type="warning"
                  class="pt-3 pb-3 mb-3"
                  show-icon
                  :closable="false"
                  v-if="!paymentAccount">
          <div slot="title" class="fs-16">
            Para habilitar/gerar o pagamento online cadastre uma conta de pagamentos
          </div>
          <div class="fs-14">
            Acesse o Menu: Sistema -> Meu Clube -> Configurações
            <div>
              ou
              <router-link
                  class="el-tag el-tag--mini el-tag--primary"
                  :to="{name: 'tenant_settings.show', params: {tab: 'config'}}">
                Clique aqui
              </router-link>
            </div>
          </div>
        </el-alert>
        <el-form-item label="Gerar Pagamento Online?">
          <el-switch
              v-model="charging_rules.generate_transaction"
              :disabled="!charging_rules.enabled || !paymentAccount"
              active-color="#13ce66"
              active-text="SIM"
              inactive-color="#ff4949"
              inactive-text="NÃO">
          </el-switch>
        </el-form-item>
      </el-col>
      <el-col :span="16" v-if="charging_rules.generate_transaction">
        <el-alert type="success"
                  class="pt-2 pb-2"
                  show-icon
                  :closable="false">
          <div slot="title" class="fs-14">
            Ao ativar essa opção, o sistema irá gerar automaticamente o PIX
            e enviará junto com a cobrança para o cliente
          </div>
        </el-alert>
      </el-col>
    </el-row>
  </el-form>
</template>
