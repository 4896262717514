<script>
export default {
  name: 'entry-teller-status',
  props: ['status'],
  computed: {
    statusLabel() {
      if (this.status === 'opened') {
        return 'Aberto'
      }
      return 'Fechado'
    },
    statusType() {
      if (this.status === 'opened') {
        return 'default'
      }
      return 'danger'
    },
  }
}
</script>

<template>
  <el-tag size="mini" :type="statusType">{{statusLabel}}</el-tag>
</template>
