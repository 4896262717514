import {get} from 'lodash'

/**
 * Função que processa a url para utilizar o padrão da API
 *
 * @param {String} domain
 * @param {Object} options
 * @returns string
 */
export const processUrl = (domain, options) => {
    let hasUrlParams = false
    let url = get(options, 'domain', domain)
    const route = get(options, 'route', null)
    const id = get(options, 'id', null)
    const columns = get(options, 'columns', null)
    const relations = get(options, 'relations', null)
    const page = get(options, 'page', 1)
    const perPage = get(options, 'perPage', 50)
    const searchRoute = get(options, 'searchRoute', false)
    const customSearchRoute = get(options, 'customSearchRoute', false)
    const isSearchRoute = (url + route).indexOf('search') !== -1 || searchRoute || customSearchRoute

    // Checa se a rota é de pesquisa
    if (searchRoute) {
        url = url + '/search'
    }

    if (id && id > 0) {
        url += '/' + id
    }
    url += (route ? '/' + route : '')
    url += '?q='

    // Inclui os dados da paginação na URL caso os parâmetros sejam passados
    if (isSearchRoute && page) {
        url += '&_page=' + page
        hasUrlParams = true
    }

    // Inclui os dados da paginação na URL caso os parâmetros sejam passados
    if (isSearchRoute && perPage) {
        url += '&_limit=' + perPage
        hasUrlParams = true
    }

    // Inclui as colunas que devem retornar na requisição
    if (!!columns && columns.length > 0) {
        columns.forEach(function (val) {
            if (val.length > 0) {
                url += '&_columns[]=' + val
            }
        })
        hasUrlParams = true
    }

    // Inclui os relacionamentos que devem retornar na requisição
    if (!!relations && relations.length > 0) {
        relations.forEach(function (val) {
            if (val.length > 0) {
                url += '&_with[]=' + val
            }
        })
        hasUrlParams = true
    }
    if (!hasUrlParams) {
        url = url.replace('?q=', '')
    }
    return url
}
