<script>
import GripoDialog from '../../../app/components/common/Dialog'
import ValidationMessage from '../../../app/components/form/ValidationMessage'
import ContactsLabel from './ContactsLabel'
import * as restfulService from '../../../app/services/restfulService'
import * as notifyService from '../../../app/services/notifyService'
import {required} from 'vuelidate/lib/validators'
import {clone} from 'lodash'

export default {
  components: {GripoDialog, ValidationMessage, ContactsLabel},
  name: 'form-contacts',
  props: {
    list: {
      default: function () {
        return []
      },
      type: Array
    },
    ownerId: {default: 0},
    ownerType: {default: ''}
  },
  data: function () {
    return {
      items: [],
      form: {
        type: 'phone',
        name: '',
        value: ''
      },
      isModalNewOpen: false,
      isSubmitted: false
    }
  },
  validations: {
    form: {
      name: {required},
      value: {required}
    }
  },
  created() {
    this.items = this.list
  },
  methods: {
    handleModalNew() {
      this.isModalNewOpen = !this.isModalNewOpen
    },
    addContact() {
      this.$v.form.$reset()
      this.form = {
        type: 'phone',
        name: '',
        value: ''
      }
      this.handleModalNew()
    },
    editContact(contact) {
      this.form = contact
      this.handleModalNew()
    },
    deleteContact(contact) {
      this.form = contact
    },
    onSave() {
      let data = clone(this.form)
      data.owner_id = this.ownerId
      data.owner_type = this.ownerType === 'client' ? 'Domain\\Tenant\\Common\\Client\\Client' : 'Domain\\Tenant\\Common\\Supplier\\Supplier'
      restfulService.save('contact', null, null, data)
          .then(response => {
            notifyService.success({hideLoader: true})
            this.isSubmitted = false
            this.items.push(response)
            this.handleModalNew()
          })
          .catch(e => {
            this.isSubmitted = false
            console.log(e)
          })
    }
  }
}
</script>

<template>
  <div>
    <el-card class="el-card__save_form box-card contact-box">
      <div slot="header">
        <span>Contatos Adicionais</span>
        <el-button size="small"
                   type="primary"
                   @click="addContact">
          <i class="fas fa-plus"></i>
          <span>Adicionar Contato</span>
        </el-button>
      </div>
      <table class="custom-table-list">
        <thead>
        <tr>
          <th>Tipo</th>
          <th>Nome</th>
          <th>Telefone/Email/Outro</th>
          <th class="btn-actions">Ações</th>
        </tr>
        </thead>
        <tbody v-if="items.length">
        <tr v-for="item in items">
          <td>
            <contacts-label :type="item.type"/>
          </td>
          <td>{{item.name}}</td>
          <td>{{item.value}}</td>
          <td>
            <div class="btn-actions">
              <el-button type="info"
                         size="small"
                         title="Editar contato"
                         @click="editContact(item)">
                <i class="fas fa-edit"></i>
              </el-button>
              <el-button type="danger"
                         size="small"
                         title="Remover contato"
                         @click="deleteContact(item)">
                <i class="fas fa-trash"></i>
              </el-button>
            </div>
          </td>
        </tr>
        </tbody>
        <tfoot v-if="!items.length">
        <tr>
          <th colspan="10">Nenhum contato encontrado</th>
        </tr>
        </tfoot>
      </table>
    </el-card>

    <gripo-dialog :model.sync="isModalNewOpen"
                  width="60vw">
      <template slot="header">Novo Contato</template>
      <template slot="content">
        <el-form ref="form"
                 :model="form"
                 label-position="top">
          <el-form-item label="Tipo">
            <el-radio-group v-model="form.type">
              <el-radio-button label="phone">Telefone</el-radio-button>
              <el-radio-button label="email">Email</el-radio-button>
              <el-radio-button label="other">Outro</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="Nome do Contato">
                <el-input v-model="form.name"/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                  :label="form.type === 'phone' ? 'Telefone': (form.type === 'email' ? 'Email': 'Outro')">
                <el-input v-model="form.value"
                          @input="$v.form.value.$touch()"/>
                <validation-message v-if="$v.form.value.$error"/>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </template>
      <template slot="actions">
        <v-spacer></v-spacer>
        <el-button type="primary"
                   @click="onSave"
                   :disabled="isSubmitted || $v.$invalid">Gravar Contato
        </el-button>
      </template>
    </gripo-dialog>

  </div>
</template>

<style lang="scss">
.contact-box {
  .el-card__header {
    padding: 14px 20px;

    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .custom-table-list {
    margin-bottom: 0;
  }
}
</style>
