<script>
import ListHeader from '../../../../components/list/Header'

import DataService from '../../../../services/dataService'
import * as restfulService from '../../../../services/restfulService'
import * as fileService from '../../../../services/fileService'

import helpers from '@mixins/helpers'
import bus from '@utils/bus'

import {mapGetters, mapActions} from 'vuex'
import StockTypeLabel from '../../stock/components/TypeLabel'

export default {
  components: {StockTypeLabel, ListHeader},
  mixins: [helpers],
  data() {
    return {
      isLoadingData: true,
      filters: {
        start_date: this.dateNow('en') + ' 00:00:00',
        end_date: '',
        group_products: false,
        category_id: null,
        product_id: null,
        classification_origin_id: null
      },
      list: {
        items: []
      },
      dependencies: {
        product_categories: [],
        products: [],
        classifications: []
      }
    }
  },
  mounted() {
    this.setListMode('single')
    this.getDependencies()
    bus.$emit('hide-loader')
  },
  computed: {
    ...mapGetters(['listStore'])
  },
  methods: {
    ...mapActions(['setListMode', 'setListSort']),
    getDependencies() {
      DataService.get([{domain: 'product_category'}, {
        domain: 'product',
        data: {active: true}
      }, {domain: 'classification_revenue'}]).then((result) => {
        this.dependencies = {...this.dependencies, ...result}
      })
    },
    getRequestData() {
      const data = {
        start_date: this.filters.start_date
      }
      if (this.filters.end_date) {
        data.end_date = this.filters.end_date
      }
      if (this.filters.category_id) {
        data.category_id = this.filters.category_id
      }
      if (this.filters.product_id) {
        data.product_id = this.filters.product_id
      }
      if (this.filters.type) {
        data.type = this.filters.type
      }
      if (this.filters.classification_origin_id) {
        data.classification_origin_id = this.filters.classification_origin_id
      }
      if (this.filters.group_products !== undefined) {
        data.group_products = this.filters.group_products
      }
      return data
    },
    onFilter() {
      bus.$emit('show-loader')
      this.isSubmitted = true
      const data = this.getRequestData()
      data.orderBy = [...this.listStore.listSort]
      restfulService.post('report', 'stock', null, data)
          .then(response => {
            this.list = {...response}
            this.isSubmitted = false
            bus.$emit('hide-loader')
          })
          .catch(e => {
            this.isSubmitted = false
            console.log(e)
            bus.$emit('hide-loader')
          })
    },
    generateCsv() {
      bus.$emit('show-loader')
      const data = this.getRequestData()
      data.export = 'csv'
      fileService.get('report', 'stock', null, 'csv', data, false, 'Relatório de Estoque')
          .finally(() => {
            bus.$emit('hide-loader')
          })
    },
    generatePDF() {
      const searchData = {}
      console.log(searchData)
    },
    onFilterClean() {
      this.filters = {...{group_products: false}}
    }
  }
}
</script>

<template>
  <div>

    <ListHeader title="Relatório de Estoque"/>

    <el-card class="el-card__search_list box-card">
      <div slot="header">
        <span>Filtros</span>
        <div>
          <el-button type="primary" @click="onFilter" size="small" :disabled="!filters.start_date">
            <i class="fas fa-filter"></i>
            <span>Gerar Relatório</span>
          </el-button>
          <template v-if="false">
            <el-button type="success" @click="generateCsv" size="small">
              <i class="fas fa-file-csv"></i>
              <span>Gerar em Excel/CSV</span>
            </el-button>
            <el-button type="default" @click="onFilterClean" size="small">
              <i class="fas fa-times"></i>
              <span>Limpar</span>
            </el-button>
          </template>
        </div>
      </div>
      <el-form :inline="true" :model="filters">
        <el-form-item label="Data Inicial" class="is-required">
          <el-date-picker v-model="filters.start_date"
                          placeholder="Data Inicial"
                          type="datetime"
                          format="dd/MM/yyyy HH:mm"
                          value-format="yyyy-MM-dd HH:mm:ss">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="Data Final">
          <el-date-picker v-model="filters.end_date"
                          placeholder="Data Final"
                          type="datetime"
                          format="dd/MM/yyyy HH:mm"
                          value-format="yyyy-MM-dd HH:mm:ss">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="Produto">
          <el-select v-model="filters.product_id"
                     filterable
                     clearable
                     placeholder="Selecione um Produto"
                     empty-text="Nenhum registro encontrado"
                     class="el-select-full"
                     @keydown.enter.native.prevent="onFilter">
            <el-option
                v-for="item in dependencies.products"
                :key="item.id"
                :label="item.description"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Categoria dos Produtos">
          <el-select v-model="filters.category_id"
                     filterable
                     clearable
                     placeholder="Selecione uma Categoria dos Produtos"
                     empty-text="Nenhum registro encontrado"
                     class="el-select-full"
                     @keydown.enter.native.prevent="onFilter">
            <el-option
                v-for="item in dependencies.product_categories"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Tipo">
          <el-select v-model="filters.type"
                     clearable
                     placeholder="Filtrar por Tipo"
                     empty-text="Nenhum registro encontrado">
            <el-option label="Entrada" value="input"/>
            <el-option label="Saída" value="output"/>
          </el-select>
        </el-form-item>
        <el-form-item label="Origem">
          <el-select v-model="filters.classification_origin_id"
                     filterable
                     clearable
                     placeholder="Selecione uma Classificação"
                     empty-text="Nenhum registro encontrado"
                     class="el-select-full"
                     @keydown.enter.native.prevent="onFilter">
            <el-option
                v-for="item in dependencies.classifications"
                :key="item.id"
                :label="item.description"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </el-card>

    <div class="main-list responsive-list space-top">
      <div class="el-card el-card__search_list box-card">
        <div class="el-card__header">
          <div>
            <div class="flex-c list-header"><span>Listagem</span></div>
          </div>
        </div>
        <div class="el-card__body">
          <div class="table-list">
            <div>
              <table class="custom-table-list">
                <thead>
                <tr>
                  <th>
                    Data
                  </th>
                  <th>Produto</th>
                  <th align="center">
                    Tipo
                  </th>
                  <th align="center">
                    Quantidade
                  </th>
                  <th align="center">
                    Origem
                  </th>
                  <th>
                    Usuário
                  </th>
                  <th>
                    Observações
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in list.items">
                  <td>{{ item.date | dateTimeEnToBr }}</td>
                  <td>{{ item.product }}</td>
                  <td align="center">
                    <stock-type-label :type="item.type"/>
                  </td>
                  <td align="center">{{ item.quantity }}</td>
                  <td align="center">{{ item.origin }}</td>
                  <td>{{ item.user }}</td>
                  <td>{{ item.notes }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
