<script>
import DataService from '@app/services/dataService'

export default {
  name: 'plan-rule-target-label',
  props: {
    size: {default: 'small', type: String},
    target: {default: '', type: String}
  },
  data() {
    return {
      list: []
    }
  },
  computed: {
    label() {
      return this.list.find(item => item.id === this.target)?.name ?? ''
    }
  },
  mounted() {
    if (this.target) {
      DataService.get([{domain: 'plan_rule_target'}]).then((result) => {
        this.list = [...result.plan_rule_targets]
      })
    }
  }
}
</script>

<template>
  <el-tag :size="size" class="el-tag--status" type="info">{{ label }}</el-tag>
</template>
