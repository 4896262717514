<script>
import VueApexCharts from 'vue-apexcharts'
import * as restfulService from '../../../../services/restfulService'
import helpers from '@mixins/helpers'
import DatepickerPeriod from './DatepickerPeriod'

export default {
  name: 'dashboard-classification',
  components: {
    apexchart: VueApexCharts,
    DatepickerPeriod,
  },
  mixins: [helpers],
  props: ['windowHeight', 'windowWidth'],
  data() {
    return {
      loading: true,
      period: [],
      series: [],
      chartOptions: {
        chart: {
          type: 'bar',
          toolbar: {
            show: false
          },
        },
        plotOptions: {
          bar: {
            columnWidth: '50%',
            distributed: true,
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false
        },
        yaxis: {
          labels: {
            formatter: (value) => value.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'}),
          },
        },
        xaxis: {
          categories: [],
          labels: {
            style: {
              fontSize: '12px'
            }
          }
        }
      },
    }
  },
  computed: {
    apexHeight() {
      if (this.windowWidth < 1024) {
        return 300
      }
      return (this.windowHeight - 350) / 2
    }
  },
  watch: {
    period() {
      this.loadData()
    }
  },
  methods: {
    async loadData() {
      this.loading = true
      const post = {initialDate: this.period[0], finalDate: this.period[1]}
      const data = await restfulService.post('dashboard', 'entries-by-classification', null, post)
      const dataSortedByCategory = this.sortListBy(data, 'category')
      const series = dataSortedByCategory.map(item => item.serie)
      const categories = dataSortedByCategory.map(item => [item.category])

      this.series = [{name: 'Total', data: series}]
      this.chartOptions = {
        ...this.chartOptions, ...{
          xaxis: {
            categories: [...categories]
          }
        }
      }
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
  },
}
</script>
<template>
  <el-card class="dashboard-card" v-loading="loading"
           element-loading-text="Carregando dados...">
    <template slot="header">
      <div class="header-title">Faturamento por Classificação</div>
      <datepicker-period :period.sync="period"></datepicker-period>
    </template>
    <div id="chart">
      <apexchart type="bar" :height="apexHeight" :options="chartOptions" :series="series"></apexchart>
    </div>
  </el-card>
</template>