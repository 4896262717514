<script>
import moment from 'moment'

export default {
  name: 'match-available-type',
  props: {
    currentDate: {
      type: String,
    },
    match: {
      type: Object,
    },
    selectedMatch1: {
      type: Object,
    },
    selectedMatch2: {
      type: Object,
    },
    interval: {
      type: Number,
      default: 40
    },
    impediments: {
      type: Array
    },
    courts: {
      type: Array
    }
  },
  computed: {
    type() {
      const result1 = this.checkMatchExists(this.match, this.selectedMatch1, 'not')
      if (result1 !== false) {
        return 'not'
      }

      if (this.match.hour) {
        const hasImpediment = this.impediments.some(item => {
          const hourMoment = moment(this.currentDate + ' ' + item, 'YYYY-MM-DD HH:mm:ss')
          const startHour = hourMoment.clone().subtract(this.interval - 10, 'minutes')
          const endHour = hourMoment.clone().add(this.interval - 10, 'minutes')
          return this.match.hour + ':00' >= startHour.format('HH:mm:ss') && this.match.hour + ':00' <= endHour.format('HH:mm:ss')
        })
        for (const i in this.courts) {
          const court = this.courts[i]
          let courtResult = false
          const matchHourIndex = court.items.findIndex(item => item.hour === this.match.hour)
          const currentMatch = court.items[matchHourIndex] || null
          if (currentMatch) {
            const currentMatchResult = this.checkMatchExists(currentMatch, this.selectedMatch1, 'not')
            if (currentMatchResult !== false) {
              courtResult = currentMatchResult
            }
          }
          const beforeMatch = court.items[matchHourIndex - 1] || null
          if (courtResult === false && beforeMatch) {
            const beforeMatchResult = this.checkMatchExists(beforeMatch, this.selectedMatch1)
            if (beforeMatchResult !== false) {
              courtResult = beforeMatchResult
            }
          }
          const afterMatch = court.items[matchHourIndex + 1] || null
          if (courtResult === false && afterMatch) {
            const afterMatchResult = this.checkMatchExists(afterMatch, this.selectedMatch1)
            if (afterMatchResult !== false) {
              courtResult = afterMatchResult
            }
          }
          if (courtResult !== false) {
            return courtResult
          }
        }
        if (hasImpediment) {
          return 'low'
        }
      }
      return 'high'
    },
    message() {
      if (this.type === 'medium') {
        return 'Jogos antes ou depois do horário'
      } else if (this.type === 'low') {
        return 'Possui impedimentos'
      } else if (this.type === 'not') {
        return 'Já existe um jogo no mesmo horário'
      } else if (this.type === '') {
        return ''
      }
      return 'Horário ideal'
    }
  },
  methods: {
    checkMatchExists(match, selectedMatch, type = 'medium') {
      const matchPlayers = [...new Set([
        match.pair1_player1_id,
        match.pair1_player2_id,
        match.pair2_player1_id,
        match.pair2_player2_id
      ])]
      const selectedMatchPlayers = new Set([
        selectedMatch.pair1_player1_id,
        selectedMatch.pair1_player2_id,
        selectedMatch.pair2_player1_id,
        selectedMatch.pair2_player2_id
      ])
      if (matchPlayers.some(item => selectedMatchPlayers.has(item))) {
        if (match.hour === selectedMatch.hour) {
          return ''
        }
        return type
      }
      return false
    }
  }
}
</script>

<template>
  <span class="hour-available" v-if="message">
    <el-tooltip effect="dark"
                :content="message"
                placement="right">
      <span class="-high" v-if="type === 'high'">
        <i class="fas fa-battery-full"></i>
      </span>
      <span class="-medium" v-if="type === 'medium'">
        <i class="fas fa-battery-half"></i>
      </span>
      <span class="-low" v-if="type === 'low'">
        <i class="fas fa-battery-quarter"></i>
      </span>
      <span v-if="type === 'not'">
        <span class="-not">
          <i class="fas fa-battery-empty"></i>
        </span>
        <span class="-not-slash"/>
      </span>
    </el-tooltip>
  </span>
</template>
