<script>
export default {
  name: 'datepicker-period',
  data() {
    return {
      period: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        },
        shortcuts: [{
          text: 'Últimos 7 dias',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 6)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: 'Últimos 30 dias',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: 'Últimos 3 meses',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: 'Últimos 6 meses',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 180)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: 'Último Ano',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 360)
            picker.$emit('pick', [start, end])
          }
        },]
      },
    }
  },
  mounted() {
    this.setDefaultDates()
  },
  watch: {
    period(newValue) {
      if (newValue) {
        this.$emit('update:period', newValue)
      }
    }
  },
  methods: {
    setDefaultDates() {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 6)
      this.period = [start, end]
    }
  }
}
</script>
<template>
  <el-date-picker
      v-model="period"
      type="daterange"
      range-separator="a"
      start-placeholder="Data Inicial"
      end-placeholder="Data Final"
      :picker-options="pickerOptions"
      size="mini"
      format="dd/MM/yyyy"
      value-format="yyyy-MM-dd"
      popper-class="dashboard-period">
  </el-date-picker>
</template>
<style lang="scss">
.dashboard-period {
  .el-picker-panel__sidebar {
    width: 130px !important;
  }

  .el-picker-panel__body {
    margin-left: 130px !important;
  }
}
</style>