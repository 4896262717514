const defaultConfig = {
    tolerance: 90,
    automaticDetection: true,
    timeToWait: null,
    liveness: true,
}

export const newInstance = (config) => {
    if ((window.CID || document.CID)) {
        return new (window.CID || document.CID).FaceRecon({
            ...defaultConfig,
            ...config
        })
    }
    return null
}

export const insertScript = () => {
    const faceReconScript = document.createElement('script')
    faceReconScript.setAttribute('src', 'https://dev-front.apifacialid.com.br/facerecon/facerecon.js?v=1.1.1')
    document.head.appendChild(faceReconScript)
}