<script>
import * as authService from '../../../../services/authService'
import * as notifyService from '../../../../services/notifyService'

import {default as helpers} from '@mixins/helpers'
import bus from '@utils/bus'

import {required, minLength, sameAs} from 'vuelidate/lib/validators'

export default {
  mixins: [helpers],
  data() {
    return {
      isSubmitted: false,
      isLoadingData: true,
      showPassword: false,
      form: {
        uuid: '',
        password: '',
        password_confirmation: ''
      },
      clubs: []
    }
  },
  validations: {
    form: {
      uuid: {required},
      password: {required, minLength: minLength(6)},
      password_confirmation: {required, sameAsPassword: sameAs('password'), minLength: minLength(6)}
    }
  },
  mounted() {
    setTimeout(() => {
      bus.$emit('hide-loader')
      this.$refs.password.focus()
      if (this.$route.query && this.$route.query.uuid) {
        this.form.uuid = this.$route.query.uuid
      }
    }, 100)
  },
  methods: {
    onRecoveryAccess() {
      bus.$emit('show-loader')
      this.isSubmitted = true
      const data = {...this.form}
      authService.updatePassword(data)
          .then(({message, login}) => {
            notifyService.success({message})
            this.$router.push({name: 'auth.login', params: {login}})
            this.isSubmitted = false
          })
          .catch(e => {
            bus.$emit('hide-loader')
            this.isSubmitted = false
            console.log(e)
          })
    }
  }
}
</script>

<template>
  <div class="login-box">

    <div class="login-card-box">
      <div class="app-header">
        <h2>Recuperação de Acesso</h2>
      </div>

      <el-card class="el-card__save_form box-card">
        <el-form ref="form"
                 :model="form"
                 label-position="top"
                 @submit.prevent="onRecoveryAccess">
          <el-form-item label="Nova Senha"
                        class="is-required"
                        :class="{ 'el-form-item--error': $v.form.password.$error }">
            <el-input v-model="form.password"
                      size="large"
                      ref="password"
                      :type="showPassword ? 'text' : 'password'"
                      @input="$v.form.password.$touch()"
                      @keydown.enter.native.prevent="onRecoveryAccess">
              <div class="el-input--suffix-box" slot="suffix" @click="showPassword = !showPassword">
                <span v-if="showPassword" key="passwordVisibility"><i class="fas fa-eye"></i></span>
                <span v-else key="passwordVisibilityOff"><i class="fas fa-eye-slash"></i></span>
              </div>
            </el-input>
            <template v-if="$v.form.password.$error">
              <div class="el-form-item__error" v-if="!$v.form.password.required">Esse campo é obrigatório</div>
              <div class="el-form-item__error" v-if="!$v.form.password.minLength">
                Esse campo precisa possuir no mínimo 6 caractéres
              </div>
            </template>
          </el-form-item>
          <el-form-item label="Confirmação a Nova Senha"
                        class="is-required"
                        :class="{ 'el-form-item--error': $v.form.password_confirmation.$error }">
            <el-input v-model="form.password_confirmation"
                      size="large"
                      :type="showPassword ? 'text' : 'password'"
                      @input="$v.form.password_confirmation.$touch()"
                      @keydown.enter.native.prevent="onRecoveryAccess">
              <div class="el-input--suffix-box" slot="suffix" @click="showPassword = !showPassword">
                <span v-if="showPassword" key="passwordVisibility"><i class="fas fa-eye"></i></span>
                <span v-else key="passwordVisibilityOff"><i class="fas fa-eye-slash"></i></span>
              </div>
            </el-input>
            <template v-if="$v.form.password_confirmation.$error">
              <div class="el-form-item__error" v-if="!$v.form.password_confirmation.required">Esse campo é obrigatório
              </div>
              <div class="el-form-item__error" v-if="!$v.form.password_confirmation.minLength">
                Esse campo precisa possuir no mínimo 6 caractéres
              </div>
              <div class="el-form-item__error" v-if="!$v.form.password_confirmation.sameAsPassword">
                Esse campo deve ser igual ao campo Nova Senha
              </div>
            </template>
          </el-form-item>
          <el-form-item>
            <el-button type="primary"
                       size="large"
                       @click="onRecoveryAccess"
                       :loading="isSubmitted"
                       :disabled="isSubmitted || $v.form.$invalid">Criar nova senha
            </el-button>
          </el-form-item>
        </el-form>
      </el-card>
      <div class="login-logo">
        <img src="https://www.gripo.com.br/site/images/logo/logo.png" alt="">
      </div>
    </div>
  </div>
</template>
