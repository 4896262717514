<script>
import helpers from '@mixins/helpers'

export default {
  name: 'order-kitchen-list',
  props: ['list'],
  mixins: [helpers],
  methods: {
    getOrderClientName(item) {
      if (item.order_product.order.client) {
        return item.order_product.order.client.name
      } else if (item.order_product.order.label) {
        return item.order_product.order.label
      }
      return ''
    }
  }
}
</script>

<template>
  <v-list two-line>
    <template v-for="(item, index) in list">
      <v-list-tile :key="item.id" avatar>
        <v-list-tile-avatar>
          <div class="flex-header">
            <div><span class="display-1">{{ item.position }}</span></div>
            <div><span class="body-2">{{ dateTimeFormatTime(item.updated_at, true) }}h</span></div>
          </div>
        </v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-title style="font-size: 18px;">
            {{ item.order_product.product.description }}
            ({{ getOrderClientName(item) }})
          </v-list-tile-title>
          <v-list-tile-sub-title v-if="item.notes">{{ item.notes }}</v-list-tile-sub-title>
        </v-list-tile-content>
        <v-list-tile-action>
          <slot name="action" :slot-scope="item"/>
        </v-list-tile-action>
      </v-list-tile>
      <v-divider :key="index + 1" v-if="(index + 1) < list.length"/>
    </template>
  </v-list>
</template>
