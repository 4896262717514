<script>
export default {
  name: 'entry-status-label',
  props: {
    status: {
      type: String,
      default: 'created'
    },
    size: {
      type: String,
      default: 'small'
    }
  }
}
</script>

<template>
  <div class="d-inline-block">
    <el-tag :size="size" type="default" v-if="status === 'created'">Em Aberto</el-tag>
    <el-tag :size="size" type="success" v-if="status === 'settled'">Pago</el-tag>
  </div>
</template>
