<script>
export default {
  name: 'schedule-payment-status-label',
  props: {
    status: String,
    paid: Boolean
  },
  computed: {
    statusText() {
      if (this.status === 'created') {
        return 'Aguardando pgto.'
      }
      return 'Confirmado/Pago'
    }
  }
}
</script>

<template>
  <v-chip :value="true"
          :color="paid ? 'success' : 'info'"
          small
          outlined>
    <span class="white--text">{{ statusText }}</span>
  </v-chip>
</template>
