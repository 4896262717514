<script>
import DrawBracketsGenerator from './DrawBracketsGenerator'

import * as restfulService from '../../../../../../services/restfulService'

import bus from '@utils/bus'

export default {
  name: 'groups-eliminatory-edit',
  components: {DrawBracketsGenerator},
  props: ['stageId', 'playerClassId'],
  data() {
    return {
      isLoadingData: false,
      drawSize: 16,
      matches: []
    }
  },
  mounted() {
    bus.$emit('show-loader')
    setTimeout(() => {
      this.loadMatches()
    }, 600)
  },
  methods: {
    loadMatches() {
      bus.$emit('show-loader')
      this.isLoadingData = true
      const data = {player_class_id: this.playerClassId}
      restfulService.post('stage', 'eliminatory', this.stageId, data)
          .then((response) => {
            this.matches = [...response.matches]
            this.drawSize = response.draw_size
            this.isLoadingData = false
            bus.$emit('hide-loader')
          })
          .catch(e => {
            console.log(e)
            this.isLoadingData = false
            bus.$emit('hide-loader')
          })
    }
  }
}

</script>

<template>
  <div id="tournament-brackets" v-if="!isLoadingData">
    <draw-brackets-generator :size="drawSize"
                             :matches="matches"
                             :stage-id="stageId"
                             :player-class-id="playerClassId"/>
  </div>
</template>
