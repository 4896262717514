import List from './components/List.vue'
import Form from './components/Form.vue'
import Show from './components/Show.vue'

export default [
    {path: '/plan', component: List, name: 'plan.index'},
    {path: '/plan/store', component: Form, name: 'plan.store'},
    {path: '/plan/:id', component: Form, name: 'plan.update'},
    {path: '/plan/:id/show', component: Show, name: 'plan.show'}
]
