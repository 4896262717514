<script>
export default {
  name: 'stage-status',
  props: ['status']
}
</script>

<template>
  <div class="d-inline-block">
    <el-tag size="small" class="el-tag--status" type="default" v-if="status === 'active'">
      Ativa
    </el-tag>
    <el-tag size="small" class="el-tag--status" type="danger" v-if="status === 'inactive'">
      Inativa
    </el-tag>
    <el-tag size="small" class="el-tag--status" type="success" v-if="status === 'finished'">
      Finalizada
    </el-tag>
    <el-tag size="small" class="el-tag--status" type="info" v-if="status === 'discarded'">
      Descartada
    </el-tag>
  </div>
</template>
