<script>
import * as restfulService from '../../../../../../services/restfulService'
import DataService from '../../../../../../services/dataService'

import helpers from '@mixins/helpers'
import bus from '@utils/bus'

import {required} from 'vuelidate/lib/validators'
import {clone} from 'lodash'
import {successDialog, warningDialog} from '@utils/flash'

export default {
  mixins: [helpers],
  props: ['stageId'],
  data() {
    return {
      isEdit: false,
      isSubmitted: false,
      isLoadingData: true,
      resetFile: false,
      form: {
        stage_id: this.stageId,
        file: {
          type: '',
          size: '',
          filename: '',
          base64: ''
        }
      },
      dependencies: {
        stages: [],
        players_class: []
      }
    }
  },
  validations: {
    form: {
      stage_id: {required},
      file: {base64: {required}}
    }
  },
  mounted() {
    this.getDependencies()
    bus.$emit('hide-loader')
  },
  methods: {
    getDependencies() {
      DataService.get([{domain: 'player_class'}]).then((result) => {
        this.dependencies = {...result}
      })
    },
    selectFile(event) {
      const file = event.target.files[0]
      this.form.file.filename = file.name
      this.form.file.size = file.size
      this.form.file.type = file.type
      if (file.type !== 'text/csv') {
        warningDialog({
          title: 'Formato de arquivo inválido',
          text: 'Selecione um arquivo com extensão .csv',
        })
        return
      }
      let reader = new window.FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.form.file.base64 = reader.result
      }
    },
    importCSV() {
      bus.$emit('show-loader')
      this.isSubmitted = true
      const data = clone(this.form)
      restfulService.post('registration', 'import', null, data)
          .then(response => {
            successDialog({
              title: 'Inscrições importadas com sucesso!',
              text: 'Foram importadas ' + response.total + ' duplas',
            })
            this.$emit('onImport')
            this.isSubmitted = false
          })
          .catch(e => {
            this.resetFile = true
            bus.$emit('hide-loader')
            setTimeout(() => {
              this.resetFile = false
            }, 200)
            this.isSubmitted = false
            console.log(e)
          })
    }
  }
}
</script>

<template>
  <div>
    <el-card class="el-card__save_form box-card">
      <h3>Formato da Tabela de Importação</h3>
      <div class="table-responsive">
        <table class="custom-table-list">
          <thead>
          <tr>
            <th>
              Código da Categoria
            </th>
            <th>Nome Atleta 1</th>
            <th>CPF Atleta 1 (Opcional)</th>
            <th>E-mail Atleta 1 (Opcional)</th>
            <th>Telefone Atleta 1 (Opcional)</th>
            <th>Nome Atleta 2</th>
            <th>CPF Atleta 2 (Opcional)</th>
            <th>E-mail Atleta 2 (Opcional)</th>
            <th>Telefone Atleta 2 (Opcional)</th>
            <th>Pontuação</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>1</td>
            <td>Pablo Lima</td>
            <td>11529743869</td>
            <td>pablo.lima@gmail.com</td>
            <td>55999995555</td>
            <td>Fernando Belasteguín</td>
            <td>10913870285</td>
            <td>fernando.bela@gmail.com</td>
            <td>55999997777</td>
            <td>120</td>
          </tr>
          <tr>
            <td>1</td>
            <td>Paquito Navarro</td>
            <td>46454754465</td>
            <td>paquito.paquitooo@gmail.com</td>
            <td>55999994444</td>
            <td>Lebron Chincoa</td>
            <td>64835874474</td>
            <td>lebron.el.lobo@gmail.com</td>
            <td>55999998888</td>
            <td>90</td>
          </tr>
          </tbody>
        </table>
      </div>
    </el-card>
    <el-card class="el-card__save_form box-card">
      <el-row :gutter="20">
        <el-col :span="12">
          <h3>Importação das Inscrições</h3>
          <v-alert class="empty-content-alert mb-3"
                   :value="true"
                   type="info"
                   outline>
            <slot name="content">
              <div>
                O formato do arquivo para ser importado deve ser <strong>Excel/CSV</strong>
              </div>
              <div>
                O sistema aceita importação com separadores por <strong>, (vírgula)</strong> ou <strong>; (ponto e
                vírgula)</strong>
              </div>
              <div>
                Não é necessário incluir o cabeçalho da tabela no arquivo
              </div>
            </slot>
          </v-alert>
          <el-form ref="form"
                   :model="form"
                   label-position="top">
            <el-form-item label="Selecionar o Arquivo"
                          class="is-required">
              <input v-if="!resetFile"
                     type="file"
                     @change="selectFile($event)">
            </el-form-item>
            <div class="el-item-no-label">
              <el-button type="success"
                         @click="importCSV"
                         :disabled="$v.form.$invalid">Importar Inscrições
              </el-button>
            </div>
          </el-form>
        </el-col>
        <el-col :span="12">
          <h3>Tabela de Códigos das Categorias</h3>
          <div class="scrollable" style="max-height: 260px;">
            <table class="custom-table-list">
              <thead>
              <tr>
                <th>
                  Código da Categoria
                </th>
                <th>Categoria</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="playerClass in dependencies.players_class">
                <td>{{ playerClass.id }}</td>
                <td>{{ playerClass.name_with_sex }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>
