<script>
export default {
  name: 'invoice-env-label',
  props: ['env']
}
</script>

<template>
  <div class="d-inline-block">
    <el-tag size="mini" type="success" v-if="env === 'production'">Produção</el-tag>
    <el-tag size="mini" type="info" v-if="env === 'staging'">Homologação</el-tag>
  </div>
</template>
