<script>
import ListMain from '../../../../components/list/Main'
import ListHeader from '../../../../components/list/Header'
import ScheduleTypeLabel from './labels/ScheduleType'
import ShowPdf from '../../../../components/common/ShowPdf'
import ScheduleAmountPaid from './labels/ScheduleAmountPaid.vue'

import DataService from '../../../../services/dataService'
import * as restfulService from '../../../../services/restfulService'
import * as fileService from '../../../../services/fileService'
import * as notifyService from '@app/services/notifyService'
import {
  releaseScheduleFixed,
  revertReleaseScheduleFixed,
  revertScheduleFixedCanceled
} from '@app/domains/management/schedule/service'

import helpers from '@mixins/helpers'
import bus from '@utils/bus'
import {mapGetters} from 'vuex'

import moment from 'moment'
import {confirmDialog} from '@utils/flash'

const defaultSort = {orderBy: [{column: 'start_datetime', direction: 'DESC'}]}

const defaultFilterDates = [helpers.methods.todaySubDays(false, 2), helpers.methods.todayAddDays(false, 1)]

export default {
  components: {ListMain, ListHeader, ScheduleTypeLabel, ShowPdf, ScheduleAmountPaid},
  mixins: [helpers],
  data() {
    return {
      isLoadingData: true,
      isSubmitted: false,
      selectedTab: 0,
      listDuplicated: [],
      isLoadingFixedData: true,
      listFixed: [],
      filters: {
        dates: [...defaultFilterDates],
        client: '',
        court_ids: [],
        coach_ids: [],
        type: [],
        status: 'scheduled',
        include_players: false,
        payment: 'all'
      },
      dependencies: {
        courts: [],
        coaches: [],
        schedule_types: []
      },
      pdfBase64String: '',
      isFixedReleasesModalVisible: false,
      revertFixedReleaseList: []
    }
  },
  mounted() {
    this.getDependencies()
    this.getListFixed()
    bus.$emit('list-init', {
      domain: 'schedule/list',
      searchRoute: false,
      customSearchRoute: true,
      data: {...this.prepareSearchData()}
    }, () => {
      bus.$emit('hide-loader')
      this.isLoadingData = false
    })
  },
  computed: {
    ...mapGetters(['listStore', 'roles', 'tenant', 'isCoach']),
    dependenciesScheduleTypesFiltered() {
      return this.dependencies.schedule_types
    },
    dependenciesScheduleStatusFiltered() {
      return this.dependencies.schedule_status
    },
    totalAmount() {
      return this.listStore.listData.reduce((total, item) => total + item.price, 0)
    },
    totalAmountPaid() {
      return (this.listStore.listData.reduce((total, item) => {
        if (item.paid) {
          if (!item.transaction) {
            const playersAmount = item.players
                .filter(player => player.paid)
                .reduce((playerTotal, player) => playerTotal += player.amount, 0)
            if (playersAmount > 0) {
              return total + playersAmount
            }
          }
          return total + item.price
        }
        if (item.transaction && item.transaction.status === 'paid') {
          const amount = (item.transaction.amount - item.transaction.discount)
          if (amount > 0) {
            return total + amount
          }
        }
        return total
      }, 0)) || 0
    },
    hasTypeLessonSelected() {
      return this.filters.type.some(item => ['lesson', 'lesson-fixed'].includes(item))
    },
    hasFixedTypeSelected() {
      return this.filters.type.some(item => ['schedule-fixed', 'lesson-fixed'].includes(item))
    },
    hasStatusCreatedSelected() {
      return this.filters.status === 'created'
    }
  },
  methods: {
    getDependencies() {
      return new Promise(resolve => {
        DataService.get([{domain: 'court'}, {domain: 'schedule_type'}, {domain: 'schedule_status'}]).then((result) => {
          this.dependencies = {...this.dependencies, ...result}
          this.filters.type = [...this.dependencies.schedule_types.map(item => item.id)]
          this.getCoaches()
          resolve()
        })
      })
    },
    async getCoaches() {
      const data = {is_coach: true, query: '', only_clients: true}
      const result = await restfulService.post('client', 'autocomplete', null, data)
      this.dependencies.coaches = [...result]
    },
    async getListFixed() {
      return new Promise(async (resolve) => {
        this.listFixed.splice(0)
        if (this.hasFixedTypeSelected && this.hasStatusCreatedSelected) {
          try {
            const data = this.prepareSearchData()
            this.listFixed = [...await restfulService.post('schedule', 'list-fixed', null, data)]
            this.isLoadingFixedData = false
            resolve()
          } catch (e) {
            this.isLoadingFixedData = false
          }
        } else {
          resolve()
          this.isLoadingFixedData = false
        }
      })
    },
    prepareSearchData() {
      const searchData = {...defaultSort}
      if (this.filters.client) {
        searchData.client = this.filters.client
      }
      if (this.filters.dates && this.filters.dates.length > 0) {
        searchData.start_date = this.filters.dates[0]
        searchData.end_date = this.filters.dates[1]
      }
      searchData.court_ids = this.filters.court_ids
      searchData.type = this.filters.type
      searchData.status = this.filters.status
      searchData.coach_ids = this.filters.coach_ids
      searchData.include_players = this.filters.include_players
      searchData.show_payment_method = this.filters.show_payment_method
      searchData.payment = this.filters.payment
      return searchData
    },
    onFilter() {
      const data = this.prepareSearchData()
      this.getListFixed()
      bus.$emit('list-filter', data)
    },
    onFilterClean() {
      this.filters = {
        dates: [...defaultFilterDates],
        court_ids: [],
        coach_ids: [],
        type: 'scheduled',
        payment: 'all'
      }
      const data = this.prepareSearchData()
      this.getListFixed()
      bus.$emit('list-filter-clean', data)
    },
    exportCommand(command) {
      if (command === 'pdf') {
        this.onExportPDF()
      } else if (command === 'csv') {
        this.onExportCSV()
      }
    },
    onExportPDF() {
      bus.$emit('show-loader')
      const data = this.prepareSearchData()
      data.export = 'pdf'
      restfulService.post('report', 'schedule', null, data)
          .then(response => {
            this.pdfBase64String = response.file
            bus.$emit('hide-loader')
          })
          .catch(e => {
            bus.$emit('hide-loader')
            console.log(e)
          })
    },
    onExportCSV() {
      bus.$emit('show-loader')
      const data = this.prepareSearchData()
      data.export = 'csv'
      fileService.get('report', 'schedule', null, 'csv', data, false, 'Relatório de Reservas')
          .finally(() => {
            bus.$emit('hide-loader')
          })
    },
    async cancelFixed(schedule) {
      const {value: confirm} = await confirmDialog({
        title: 'Deseja realmente cancelar essa reserva fixa?',
      })
      if (confirm) {
        bus.$emit('show-loader')
        restfulService.put('schedule', 'cancel-fixed', schedule.id, {})
            .then(async () => {
              notifyService.success({message: 'Cancelamento de horário fixo realizado com sucesso.'})
              await this.getListFixed()
              bus.$emit('hide-loader')
            })
            .catch(e => {
              bus.$emit('hide-loader')
              console.log(e)
            })
      }
    },
    async revertFixed(schedule) {
      const {value: confirm} = await confirmDialog({
        title: 'Deseja realmente reverter o cancelamento desse horário?',
      })
      if (confirm) {
        bus.$emit('show-loader')
        revertScheduleFixedCanceled(schedule.id)
            .then(() => {
              notifyService.success({message: 'Cancelamento de horário fixo revertido com sucesso.'})
              this.onFilter()
            })
            .catch(e => {
              bus.$emit('hide-loader')
              console.log(e)
            })
      }
    },
    async revertFixedRelease({fixed_release_id}) {
      const {value: confirm} = await confirmDialog({
        title: 'Deseja realmente reverter a liberação desse horário?',
      })
      if (confirm) {
        bus.$emit('show-loader')
        const data = {fixed_release_id}
        revertReleaseScheduleFixed(data)
            .then(() => {
              notifyService.success({message: 'Liberação de horário fixo revertido com sucesso.'})
              this.onFilter()
            })
            .catch(e => {
              bus.$emit('hide-loader')
              console.log(e)
            })
      }
    },
    async releaseScheduleFixed(schedule) {
      const {value: confirm} = await confirmDialog({
        title: 'Deseja realmente liberar esse horário fixo?',
        icon: 'warning',
      })
      if (confirm) {
        bus.$emit('show-loader')
        const data = {
          id: schedule?.id || '',
          court_id: schedule.court.id,
          date: schedule.date,
          start_hour: schedule.start_hour,
          end_hour: schedule.end_hour,
          is_multiple: false,
          selection: []
        }
        releaseScheduleFixed(data)
            .then(() => {
              notifyService.success({message: 'Horário fixo liberado com sucesso.'})
              this.onFilter()
            })
            .catch(e => {
              bus.$emit('hide-loader')
              console.log(e)
            })
      }
    },
    canceledAtRevertEnabled(startDatetime, canceledAt) {
      return moment(startDatetime).format('YYYY-MM-DD') === moment(canceledAt).format('YYYY-MM-DD')
    },
    canceledAtDate(startDatetime, canceledAt) {
      return moment(startDatetime).format('YYYY-MM-DD') >= moment(canceledAt).format('YYYY-MM-DD')
    }
  }
}
</script>

<template>
  <div>

    <show-pdf :base64="pdfBase64String"/>

    <ListHeader title="Reservas"/>

    <ListMain :show-header="false" :show-pagination="selectedTab ===0">

      <div slot="filters">

        <el-card class="el-card__search_list box-card">
          <div slot="header">
            <span>Filtros</span>
            <div>
              <el-button type="primary" @click="onFilter" size="small">
                <i class="fas fa-filter"></i>
                <span>Aplicar Filtros</span>
              </el-button>
              <el-button type="default" @click="onFilterClean" size="small">
                <i class="fas fa-times"></i>
                <span>Limpar</span>
              </el-button>
              <el-dropdown class="ml-2" @command="exportCommand">
                <el-button type="info" size="small">
                  <span>Exportar</span><i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="pdf">
                    <i class="fas fa-file-pdf"></i>
                    <span class="ml-2">Para PDF</span>
                  </el-dropdown-item>
                  <el-dropdown-item command="csv">
                    <i class="fas fa-file-csv"></i>
                    <span class="ml-2">Para Excel/CSV</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
          <el-form :inline="true" :model="filters">
            <el-form-item label="Cliente" style="max-width: 250px;">
              <el-input v-model="filters.client"
                        placeholder="Filtrar por cliente"
                        @keydown.enter.native.prevent="onFilter"/>
              <el-form-item label="Incluir Jogadores Confirmados?">
                <el-switch v-model="filters.include_players"
                           :active-value="true"
                           :inactive-value="false"
                           active-text="SIM"
                           inactive-text="NÃO">
                </el-switch>
              </el-form-item>
            </el-form-item>
            <el-form-item label="Período">
              <el-date-picker
                  v-model="filters.dates"
                  type="daterange"
                  format="dd/MM/yyyy"
                  value-format="yyyy-MM-dd"
                  placeholder="Filtrar por data da reserva">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="Quadras">
              <el-select v-model="filters.court_ids"
                         filterable
                         clearable
                         multiple
                         placeholder="Selecione uma quadra"
                         empty-text="Nenhum registro encontrado"
                         class="el-select-full"
                         @keydown.enter.native.prevent="onFilter">
                <el-option
                    v-for="item in dependencies.courts"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Tipo da Reserva" style="min-width: 320px;">
              <el-select v-model="filters.type"
                         multiple
                         class="el-select-full"
                         placeholder="Filtrar por tipo de reserva"
                         empty-text="Nenhum registro encontrado">
                <el-option
                    v-for="item in dependenciesScheduleTypesFiltered"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Status da Reserva" style="min-width: 220px;">
              <el-select v-model="filters.status"
                         class="el-select-full"
                         placeholder="Filtrar por Status da reserva"
                         empty-text="Nenhum registro encontrado">
                <el-option
                    v-for="item in dependenciesScheduleStatusFiltered"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Status do Pagamento">
              <el-select v-model="filters.payment"
                         placeholder="Filtrar por Pagamento"
                         empty-text="Nenhum registro encontrado">
                <el-option label="Não filtrar" value="all"/>
                <el-option label="Parcialmente Pago" value="partial"/>
                <el-option label="Pago" value="paid"/>
              </el-select>
            </el-form-item>
            <el-form-item label="Exibir Forma de Pagamento" v-if="['partial', 'paid'].includes(filters.payment)">
              <el-switch v-model="filters.show_payment_method"
                         :active-value="true"
                         :inactive-value="false"
                         active-text="SIM"
                         inactive-text="NÃO">
              </el-switch>
            </el-form-item>
            <el-form-item label="Professor" v-if="!isCoach && hasTypeLessonSelected">
              <el-select v-model="filters.coach_ids"
                         filterable
                         clearable
                         multiple
                         placeholder="Selecione um professor"
                         empty-text="Nenhum registro encontrado"
                         class="el-select-full"
                         @keydown.enter.native.prevent="onFilter">
                <el-option
                    v-for="item in dependencies.coaches"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-card>

      </div>

      <div slot="beforeMainList">
        <v-tabs
            fixed-tabs
            slider-color="secondary"
            v-model="selectedTab">
          <v-tab key="normal">Listagem</v-tab>
          <v-tab key="fixed"
                 :disabled="isLoadingFixedData"
                 v-if="hasFixedTypeSelected && hasStatusCreatedSelected">
            <span class="mr-1" v-if="isLoadingFixedData">
              <i class="fas fa-spinner"></i>
            </span>
            <span>Reservas Fixas</span>
          </v-tab>
        </v-tabs>
      </div>

      <div slot="list">
        <template v-if="selectedTab === 0">

          <table class="custom-table-list" v-if="!isLoadingData && listStore.listData.length > 0">
            <thead>
            <tr>
              <th width="120" class="text-center">Tipo de Reserva</th>
              <th>Quadra</th>
              <th>Data/Hora</th>
              <th>Dia da Semana</th>
              <th>Professor/Cliente</th>
              <th class="text-right">Valor</th>
              <th>Atualizações</th>
              <th>Ações</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in listStore.listData" :key="index">
              <td class="text-center">
                <schedule-type-label :type="item.type"/>
              </td>
              <td>{{ item.court ? item.court.name : '' }}</td>
              <td>
                {{ item.start_datetime | dateTimeEnToBr }}h até {{ item.end_datetime | timeEnToBr }}h
                <div v-if="item.status === 'canceled' || item.canceled_at">
                  <strong>Data/Hora Final:</strong> {{ item.canceled_at | dateTimeEnToBr }}h
                </div>
              </td>
              <td>{{ weekdayString(item.weekday) }}</td>
              <td>
                <template v-if="item.coach_id">
                  <div><strong>Professor:</strong> {{ item.coach ? item.coach.name : '' }}</div>
                </template>
                <template v-else>
                  <strong>Cliente:</strong> {{ item.client ? item.client.name : '' }}
                </template>
                <template v-if="(filters.include_players || filters.show_payment_method) && item.players.length">
                  <h4 class="mt-1 mb-1">Atletas:</h4>
                  <ul>
                    <li v-for="player in item.players">
                      {{ player.name }}
                      <span v-if="filters.show_payment_method && player.payment_method" class="ml-1">
                        ({{ player.payment_method }} | {{ player.amount | currencyFormatter }})
                      </span>
                    </li>
                  </ul>
                </template>
              </td>
              <td class="text-right" style="width: 150px;">
                <el-tag type="success" size="mini" class="mr-1" v-if="item.paid">Pago</el-tag>
                <schedule-amount-paid :schedule="item"/>
              </td>
              <td>
                <template v-if="item.canceled_at">
                  <el-tooltip effect="dark"
                              :content="`Usuário: ${item.canceled_by?.name ?? ''}`"
                              :disabled="!item.canceled_by"
                              placement="top">
                    <div v-if="item.canceled_by.created_at">
                      <div>
                        <strong>Cancelado em:</strong>
                      </div>
                      <el-tag size="mini" class="el-tag--status" type="danger">
                        {{ item.canceled_by.created_at | dateTimeEnToBr }}h
                      </el-tag>
                    </div>
                  </el-tooltip>
                </template>
                <template v-if="item.fixed_release_id">
                  <el-tooltip effect="dark"
                              :content="`Usuário: ${item.released_by?.name ?? ''}`"
                              :disabled="!item.released_by"
                              placement="top">
                    <div v-if="item.released_by">
                      <div>
                        <strong>Liberado em:</strong>
                      </div>
                      <el-tag size="mini" class="el-tag--status" type="warning">
                        {{ item.released_by?.created_at ?? '' | dateTimeEnToBr }}h
                      </el-tag>
                    </div>
                  </el-tooltip>
                </template>
              </td>
              <td class="list-table-nowrap">
                <div class="btn-actions">
                  <el-button type="warning"
                             v-if="item.fixed && item.fixed_release_id"
                             @click="revertFixedRelease(item)"
                             key="revertFixedRelease"
                             title="Reverter Liberação">
                    <i class="fas fa-clock"></i>
                  </el-button>
                  <el-button type="danger"
                             v-if="item.canceled_at && item.fixed"
                             @click="revertFixed(item)"
                             key="revertFixed"
                             title="Reverter Cancelamento">
                    <i class="fas fa-recycle"></i>
                  </el-button>
                </div>
              </td>
            </tr>
            </tbody>
            <tfoot>
            <tr>
              <th colspan="5" align="right">Valor Total:</th>
              <th align="right">{{ totalAmount | currencyFormatter }}</th>
              <th colspan="2"></th>
            </tr>
            <tr class="row-success">
              <th colspan="5" align="right">Valor Total Pago:</th>
              <th align="right">{{ totalAmountPaid | currencyFormatter }}</th>
              <th colspan="2"></th>
            </tr>
            <tr class="row-danger">
              <th colspan="5" align="right">Valor Pendente:</th>
              <th align="right">{{ totalAmount - totalAmountPaid | currencyFormatter }}</th>
              <th colspan="2"></th>
            </tr>
            </tfoot>
          </table>

        </template>
        <template v-if="selectedTab === 1">
          <el-card>
            <table class="custom-table-list">
              <thead>
              <tr>
                <th width="120" class="text-center">Tipo de Reserva</th>
                <th>Quadra</th>
                <th class="text-center">Dia da Semana</th>
                <th>Data/Hora</th>
                <th>Professor/Cliente</th>
                <th>Valor</th>
                <th>Datas/Cancelamento</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, index) in listFixed" :key="index">
                <td class="text-center">
                  <schedule-type-label :type="item.type"/>
                </td>
                <td>{{ item.court.name }}</td>
                <td class="text-center">
                  {{ item.weekday_label }}
                  <div v-if="item.overlaps">
                    <el-tag size="mini" class="el-tag--status font-weight-bold" type="danger">
                      Horário em Conflito
                    </el-tag>
                  </div>
                </td>
                <td>
                  {{ item.start_datetime | dateTimeEnToBr }}h até {{ item.end_datetime | timeEnToBr }}h
                  <div v-if="item.status === 'canceled' || item.canceled_at">
                    <strong>Data/Hora Final:</strong> {{ item.canceled_at | dateTimeEnToBr }}h
                  </div>
                </td>
                <td>{{ item?.client?.name || '' }}</td>
                <td class="text-right">
                  {{ item.price | currencyFormatter }}
                </td>
                <td>
                  <strong>Criado em:</strong> {{ item.created_at | dateTimeEnToBr }}h
                  <el-tooltip effect="dark"
                              v-if="!!item.canceled_at"
                              :content="`Usuário: ${item.canceled_by?.name ?? ''}`"
                              :disabled="!item.canceled_by"
                              placement="top">
                    <div v-if="item.canceled_by.created_at">
                      <div>
                        <strong>Cancelado em:</strong>
                      </div>
                      <el-tag size="mini" class="el-tag--status" type="danger">
                        {{ item.canceled_by.created_at | dateTimeEnToBr }}h
                      </el-tag>
                    </div>
                  </el-tooltip>
                </td>
                <td>
                  <el-button type="danger"
                             size="mini"
                             @click="cancelFixed(item)">
                    <i class="fas fa-times"></i>
                    <span class="ml-1">Cancelar</span>
                  </el-button>
                </td>
              </tr>
              </tbody>
            </table>
          </el-card>
        </template>
      </div>

    </ListMain>
  </div>
</template>
