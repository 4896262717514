<script>
import ListHeader from '../../../../components/list/Header'
import DreResultAmount from './labels/DreResultAmount.vue'

import * as restfulService from '../../../../services/restfulService'

import bus from '@utils/bus'

export default {
  components: {ListHeader, DreResultAmount},
  data() {
    return {
      items: []
    }
  },
  mounted() {
    this.getData()
  },
  computed: {
    getExpenses() {
      return this.items.filter(item => item.type === 'expense')
    },
    getRevenues() {
      return this.items.filter(item => item.type === 'revenue')
    },
  },
  methods: {
    getData() {
      restfulService.post('report', 'financial-operation')
        .then((result) => {
          this.items = result.classifications
          bus.$emit('hide-loader')
        }
      );
    },
    getRevenuesTotalOfPeriod(period) {
      return this.getRevenues.reduce((total, item) => total + parseFloat(item[period].total), 0)
    },
    getExpensesTotalOfPeriod(period) {
      return this.getExpenses.reduce((total, item) => total + parseFloat(item[period].total), 0)
    },
    getExpenseTableHeaderLabels() {
      return [
        this.getExpenses[0].entriesOfLastYear.label,
        this.getExpenses[0].entriesOfYear.label,
        this.getExpenses[0].entriesOfLastMonth.label,
        this.getExpenses[0].entriesOfMonth.label,
        this.getExpenses[0].entriesOfNextMonth.label,
      ]
    },
    getRevenueTableHeaderLabels() {
      return [
        this.getRevenues[0].entriesOfLastYear.label,
        this.getRevenues[0].entriesOfYear.label,
        this.getRevenues[0].entriesOfLastMonth.label,
        this.getRevenues[0].entriesOfMonth.label,
        this.getRevenues[0].entriesOfNextMonth.label,
      ]
    },
    getResultsTableHeaderLabels() {
      return [
        this.getRevenues[0].entriesOfLastYear.label,
        this.getRevenues[0].entriesOfYear.label,
        this.getRevenues[0].entriesOfLastMonth.label,
        this.getRevenues[0].entriesOfMonth.label,
        this.getRevenues[0].entriesOfNextMonth.label,
      ]
    },
  },
}
</script>
<template>
  <div>
    <ListHeader></ListHeader>
    <el-card>
      <div class="table-list" v-if="getExpenses.length">
        <table class="custom-table-list">
          <thead>
            <tr>
              <th colspan="6" align="center" class="-soft-red header-big">Despesas</th>
            </tr>
            <tr class="font-big">
              <th width="25%" align="right">Classificação</th>
              <th width="15%" align="right" v-for="label in getExpenseTableHeaderLabels()" :key="label">{{ label }}</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="classification in getExpenses" :key="classification.description">
              <td width="25%" align="right">{{ classification.description }}</td>
              <td width="15%" align="right">{{ classification.entriesOfLastYear.total | currencyFormatter }}</td>
              <td width="15%" align="right">{{ classification.entriesOfYear.total | currencyFormatter }}</td>
              <td width="15%" align="right">{{ classification.entriesOfLastMonth.total | currencyFormatter }}</td>
              <td width="15%" align="right">{{ classification.entriesOfMonth.total | currencyFormatter }}</td>
              <td width="15%" align="right">{{ classification.entriesOfNextMonth.total | currencyFormatter }}</td>
            </tr>
            <tr class="font-big">
              <td width="25%" align="right"><strong>Totais</strong></td>
              <td width="15%" align="right" class="font-weight-bold">{{ getExpensesTotalOfPeriod('entriesOfLastYear') | currencyFormatter }}</td>
              <td width="15%" align="right" class="font-weight-bold">{{ getExpensesTotalOfPeriod('entriesOfYear') | currencyFormatter }}</td>
              <td width="15%" align="right" class="font-weight-bold">{{ getExpensesTotalOfPeriod('entriesOfLastMonth') | currencyFormatter }}</td>
              <td width="15%" align="right" class="font-weight-bold">{{ getExpensesTotalOfPeriod('entriesOfMonth') | currencyFormatter }}</td>
              <td width="15%" align="right" class="font-weight-bold">{{ getExpensesTotalOfPeriod('entriesOfNextMonth') | currencyFormatter }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="table-list" v-if="getRevenues.length">
        <table class="custom-table-list">
          <thead>
            <tr>
              <th colspan="6" align="center" class="-soft-green header-big">Receitas</th>
            </tr>
            <tr class="font-big">
              <th width="25%" align="right">Classificação</th>
              <th width="15%" align="right" v-for="label in getRevenueTableHeaderLabels()" :key="label">{{ label }}</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="classification in getRevenues" :key="classification.description">
              <td width="25%" align="right">{{ classification.description }}</td>
              <td width="15%" align="right">{{ classification.entriesOfLastYear.total | currencyFormatter}}</td>
              <td width="15%" align="right">{{ classification.entriesOfYear.total | currencyFormatter}}</td>
              <td width="15%" align="right">{{ classification.entriesOfLastMonth.total | currencyFormatter}}</td>
              <td width="15%" align="right">{{ classification.entriesOfMonth.total | currencyFormatter }}</td>
              <td width="15%" align="right">{{ classification.entriesOfNextMonth.total | currencyFormatter }}</td>
            </tr>
            <tr class="font-big">
              <td width="25%" align="right"><strong>Totais</strong></td>
              <td width="15%" align="right" class="font-weight-bold">{{ getRevenuesTotalOfPeriod('entriesOfLastYear') | currencyFormatter}}</td>
              <td width="15%" align="right" class="font-weight-bold">{{ getRevenuesTotalOfPeriod('entriesOfYear') | currencyFormatter}}</td>
              <td width="15%" align="right" class="font-weight-bold">{{ getRevenuesTotalOfPeriod('entriesOfLastMonth') | currencyFormatter}}</td>
              <td width="15%" align="right" class="font-weight-bold">{{ getRevenuesTotalOfPeriod('entriesOfMonth') | currencyFormatter}}</td>
              <td width="15%" align="right" class="font-weight-bold">{{ getRevenuesTotalOfPeriod('entriesOfNextMonth') | currencyFormatter }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="table-list" v-if="getExpenses.length && getRevenues.length">
        <table class="custom-table-list">
          <thead>
            <tr class="font-big">
              <th colspan="6" align="center" class="-soft-blue header-big">Resultados</th>
            </tr>
            <tr class="font-big">
              <th width="25%" align="right"></th>
              <th width="15%" align="right" v-for="label in getResultsTableHeaderLabels()" :key="label">{{ label }}</th>
            </tr>
          </thead>

          <tbody>
            <tr class="font-big">
              <td width="25%" align="right"><strong>Totais</strong></td>
              <td width="15%" align="right">
                <dre-result-amount :amount="(getRevenuesTotalOfPeriod('entriesOfLastYear') - getExpensesTotalOfPeriod('entriesOfLastYear'))" />
              </td>
              <td width="15%" align="right">
                <dre-result-amount :amount="(getRevenuesTotalOfPeriod('entriesOfYear') - getExpensesTotalOfPeriod('entriesOfYear'))" />
              </td>
              <td width="15%" align="right">
                <dre-result-amount :amount="(getRevenuesTotalOfPeriod('entriesOfLastMonth') - getExpensesTotalOfPeriod('entriesOfLastMonth'))" />
              </td>
              <td width="15%" align="right">
                <dre-result-amount :amount="(getRevenuesTotalOfPeriod('entriesOfMonth') - getExpensesTotalOfPeriod('entriesOfMonth'))" />
              </td>
              <td width="15%" align="right">
                <dre-result-amount :amount="(getRevenuesTotalOfPeriod('entriesOfNextMonth') - getExpensesTotalOfPeriod('entriesOfNextMonth'))" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>


    </el-card>
  </div>
</template>
