import store from '../vuex'

import bus from '@utils/bus'
import {get} from '@utils/storage'
import {hasModulePermission, hasRoutePermission} from '@mixins/helpers'

import {warningDialog} from '@utils/flash'
import {hasPermissionByName} from "../mixins/helpers";

export default async (to, from, next) => {
    // Position the scroll on top
    window.scrollTo(0, 0)
    document.querySelector('body').scrollTo(0, 0)

    // Show global loader
    bus.$emit('show-loader')

    // Check if route needs auth
    if (to.meta.auth === false) {
        return next()
    }

    // Get token from storage
    const token = await get('token')

    // If token doesn't exists, redirect to to auth
    if (!token) {
        return next({name: 'auth.login'})
    }

    // Get blocked info
    const blocked = await get('blocked')
    if (blocked === true && to.name !== 'tenant_settings.show') {
        return next({name: 'tenant_settings.show', params: {tab: 'payment'}})
    }

    // Check if vuex is already loaded with persistent data
    if (!store.getters.appLoaded) {
        // Get auth data from storage
        const user = await get('user')
        const permissions = await get('permissions')
        const roles = await get('roles')
        const tenant = await get('tenant')
        const session = await get('session')

        // Store in vuex user data
        const data = {
            token: token,
            user: user,
            permissions: permissions,
            roles: roles,
            tenant: tenant,
            session: session
        }
        await store.dispatch('setUpAuth', data)
        await store.dispatch('setAppLoaded', true)
    }

    // Check module permissions
    if (hasModulePermission(to.name) === false) {
        warningDialog({
            title: 'Seu plano não tem acesso a essa funcionalidade',
            html: 'Contrate um plano para começar a utilizar esse módulo',
            icon: 'warning'
        })
        console.log('Acesso negado', to.name)
        bus.$emit('hide-loader')
        return next({name: 'tenant_settings.show'})
    }

    // Check route permissions
    if (hasRoutePermission(to) === false) {
        warningDialog({
            title: 'Atenção',
            html: 'Acesso negado a esse recurso',
            icon: 'warning'
        })
        console.log('Acesso negado', to.name)
        bus.$emit('hide-loader')
        if (hasPermissionByName('stage.index')) {
            return next({name: 'stage.index'})
        } else {
            if (hasPermissionByName('client.index')) {
                return next({name: 'client.index'})
            } else if (hasPermissionByName('schedule_daily.index')) {
                return next({name: 'schedule_daily.index'})
            } else {
                return next()
            }
        }
    }

    if (store.getters.listStore.listData.length) {
        store.dispatch('setListData', [])
    }

    return next()
}
