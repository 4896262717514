<script>
import {mapGetters} from 'vuex'

export default {
  name: 'schedule-show-type',
  props: {
    schedule: {
      type: Object
    }
  },
  computed: {
    ...mapGetters(['tenant']),
    type() {
      return this.schedule.type + (this.schedule.type === 'lesson' && (this.schedule.fixed || this.schedule.schedule_fixed) ? '-fixed' : '')
    },
    lessonType() {
      return ['lesson', 'lesson-fixed'].includes(this.type)
    },
    convertLabel() {
      if (this.lessonType) {
        if (this.schedule.fixed || this.schedule.schedule_fixed) {
          return 'Horário Fixo'
        } else {
          return 'Horário Avulso'
        }
      } else {
        if (this.schedule.fixed || this.schedule.schedule_fixed) {
          return 'Aula Fixa'
        } else {
          return 'Aula Avulsa'
        }
      }
    },
    title() {
      if (this.schedule.coach_id) {
        if (this.schedule.fixed || this.schedule.schedule_fixed) {
          return 'Aula Fixa'
        } else {
          return 'Aula Avulsa'
        }
      } else {
        if (this.schedule.local === 'app') {
          return 'Reserva Online'
        } else {
          if (this.schedule.fixed || this.schedule.schedule_fixed) {
            return 'Horário Fixo'
          } else {
            return 'Horário Avulso'
          }
        }
      }
    },
    fixedConfirmed() {
      if (this.schedule.coach_id) {
        return 'Aula Fixa Confirmada'
      } else {
        return 'Horário Fixo Confirmado'
      }
    }
  }
}
</script>

<template>
  <div :class="['schedule-info-box text-center -fill-height', `-${type}`]">
    <div>
      <h2 class="mb-0 white--text pb-1">
        <span>{{ title }}</span>
        <span class="ml-2 fs-14" v-if="schedule.fixed && schedule.schedule_fixed" :title="fixedConfirmed">
          <i class="fas fa-check-circle"></i>
        </span>
      </h2>
      <slot></slot>
      <el-button size="mini" v-if="!lessonType"
                 @click="$emit('convertToLesson', type === 'fixed' ? 'lesson-fixed' : 'lesson')">
        <i class="fas fa-random"></i>
        <span class="ml-2">Converter para {{ convertLabel }}</span>
      </el-button>
    </div>
  </div>
</template>