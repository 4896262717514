<script>
import * as restfulService from '../../../../../services/restfulService'

import helpers from '@mixins/helpers'
import bus from '@utils/bus'
import {currencyConfig} from '@utils/money'

import {cloneDeep} from 'lodash'
import {mapGetters} from "vuex";

export default {
  mixins: [helpers],
  name: 'split-product',
  props: {
    orderProduct: {
      type: Object
    },
    orders: {
      type: Array
    },
    propListOrderProductsSplitted: {
      type: Array
    }
  },
  data() {
    return {
      isSubmitted: false,
      localOrders: [],
      localOrderProduct: {},
      listOrdersSplit: [],
      listOrderProductsSplitted: [],
      currentSplitOrderProductId: null
    }
  },
  computed: {
    ...mapGetters(['tenant']),
    moneyMask() {
      return currencyConfig()
    },
    listOrdersSplitLength() {
      return this.listOrdersSplit.filter(item => item.checked).length
    },
    canSplitProduct() {
      return this.listOrdersSplitLength > 1 || this.listOrdersSplitLength === 0
    }
  },
  watch: {
    orderProduct: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value.id && value.id !== this.localOrderProduct.id) {
          this.localOrderProduct = {...cloneDeep(value)}
        }
      }
    },
    orders: {
      immediate: true,
      deep: true,
      handler(value) {
        if (this.localOrders.length === 0) {
          this.generateClientsOrdersList(value)
        }
      }
    }
  },
  methods: {
    updateProductsSplit(value, orderProduct) {
      this.$emit('update-products-split', value, orderProduct)
    },
    generateClientsOrdersList(orders) {
      this.listOrdersSplit.splice(0)
      const newList = [...cloneDeep(orders)].map(item => {
        item.checked = false
        item.percent = 0
        item.amount = '0,00'
        let productOrder = this.localOrderProduct.orders.find(order => order.order_id === item.id)
        if (productOrder) {
          item.checked = true
          item.percent = productOrder.percent
          item.amount = productOrder.amount
        }
        return item
      })
      setTimeout(() => {
        this.localOrders = [...newList]
        this.listOrdersSplit = [...newList]
      }, 50)
    },
    updateQuantitySplitOrderProduct() {
      this.addClientToSplitOrder()
      setTimeout(() => {
        this.saveSplitOrderProduct(this.localOrderProduct)
      }, 100)
    },
    addClientToSplitOrder() {
      this.localOrderProduct.orders = [...this.listOrdersSplit]
      this.localOrderProduct.orders = [...cloneDeep(this.splitOrderProductValues(this.localOrderProduct))]
      this.localOrderProduct.orders.map(order => {
        let itemIndex = this.listOrdersSplit.findIndex(splitOrder => splitOrder.id === order.id)
        if (itemIndex !== -1) {
          this.listOrdersSplit.splice(itemIndex, 1, order)
        }
      })
      const list = [...cloneDeep(this.listOrdersSplit)]
      this.listOrdersSplit.splice(0)
      setTimeout(() => {
        this.listOrdersSplit = [...cloneDeep(list)]
      }, 50)
    },
    splitOrderProductValues(orderProduct) {
      const ordersLength = orderProduct.orders.filter(item => item.checked).length
      let percentResultDiff = 0
      let percentResult = parseFloat((100 / ordersLength).toFixed(2))
      if (percentResult * ordersLength < 100) {
        percentResultDiff = parseFloat((100 - (percentResult * ordersLength)).toFixed(2))
      }

      const amountTotal = orderProduct.quantity * orderProduct.price
      let amountResultDiff = 0
      let amountResult = parseFloat((amountTotal / ordersLength).toFixed(2))
      if (amountResult * ordersLength < amountTotal) {
        amountResultDiff = parseFloat((amountTotal - (amountResult * ordersLength)).toFixed(2))
      }

      const newOrders = [...cloneDeep(orderProduct.orders)].map((item, index) => {
        if (item.checked) {
          item.percent = percentResult
          if (percentResultDiff > 0 && index === 0) {
            item.percent += percentResultDiff
          }
          item.amount = amountResult
          if (amountResultDiff > 0 && index === 0) {
            item.amount += amountResultDiff
          }
        } else {
          item.amount = 0
        }
        item.amount = this.currencyFormatter(item.amount)
        return item
      })
      return newOrders
    },
    async saveSplitOrderProduct() {
      bus.$emit('show-loader')
      this.isSubmitted = true
      const data = {
        order_product_id: this.localOrderProduct.id,
        quantity: this.localOrderProduct.quantity,
        split: this.listOrdersSplit.some(item => item.checked),
        orders: [...cloneDeep(this.listOrdersSplit)].filter(item => item.checked)
      }
      const orders = []
      data.orders.forEach(item => {
        orders.push({
          amount: this.currencyParsed(item.amount),
          order_id: item.id
        })
      })
      data.orders = orders
      await this.saveSplitOrderProductRequest(data)
    },
    async saveSplitOrderProductRequest(data) {
      return new Promise((resolve, reject) => {
        bus.$emit('show-loader')
        if (data.orders.length === 0) {
          this.localOrderProduct.split = false
          this.$emit('update-products-split', false, {...this.localOrderProduct})
          this.listOrdersSplit = [...cloneDeep(this.orders)]
        }
        this.isSubmitted = true
        restfulService.post('order', 'products-orders', null, data)
            .then((response) => {
              this.isSubmitted = false
              this.$emit('update-orders', response)
              bus.$emit('hide-loader')
              resolve()
            })
            .catch(e => {
              console.log(e)
              this.isSubmitted = false
              bus.$emit('hide-loader')
              reject()
            })
      })
    }
  }
}
</script>

<template>
  <div class="product-item -splitted mb-3">
    <el-row :gutter="30">
      <el-col :span="6" v-if="localOrderProduct.product">
        <strong class="product-category-name">
          {{ localOrderProduct.product.description }} /
          {{ (localOrderProduct.quantity * localOrderProduct.price) | currencyFormatter }}
        </strong>
        <el-form-item class="mb-1">
          <el-input-number v-model="localOrderProduct.quantity"
                           class="-bigger"
                           :precision="localOrderProduct.product.unit === 'UN' ? 0 : 3"
                           :step="localOrderProduct.product.unit === 'UN' ? 1 : 0.001"
                           :min="0.001"
                           :disabled="listOrdersSplitLength < 2"
                           @change="updateQuantitySplitOrderProduct">
          </el-input-number>
        </el-form-item>
        <el-form-item label="Dividir produto?">
          <el-switch v-model="localOrderProduct.split"
                     @change="updateProductsSplit($event, localOrderProduct)"
                     active-text="Sim"
                     inactive-text="Não">
          </el-switch>
        </el-form-item>
      </el-col>
      <el-col :span="18" style="text-align: left;">
        <el-row :gutter="30" class="pt-2 pb-3">
          <el-col :span="12">
            <span class="pr-2">Selecionar comandas?</span>
            <el-switch v-model="localOrderProduct.select_clients"
                       active-text="Sim"
                       inactive-text="Não">
            </el-switch>
          </el-col>
          <el-col :span="12" v-if="false">
            Dividir igualmente?
            <el-switch v-model="localOrderProduct.equal_split"
                       active-text="Sim"
                       inactive-text="Não">
            </el-switch>
          </el-col>
        </el-row>
        <el-divider class="mt-0 mb-1"/>
        <template v-if="!localOrderProduct.select_clients">
          <div class="select-products kitchen-products">
            <div class="product-item"
                 v-for="orderClient in localOrderProduct.orders"
                 :key="orderClient.order_id">
              {{ orderClient.client ? orderClient.client.name : 'Comanda removida' }} /
              <strong>{{ orderClient.amount }}</strong>
            </div>
          </div>
        </template>
        <template v-else>
          <el-row :gutter="10" class="mb-2">
            <el-col :span="10"><strong>Cliente</strong></el-col>
            <el-col :span="6" v-if="false">
              <strong>Porcentagem %</strong>
            </el-col>
            <el-col :span="8">
              <strong>Valor a Pagar</strong>
            </el-col>
          </el-row>
          <el-divider class="mt-2 mb-2"/>
          <template v-if="listOrdersSplit.length > 0">
            <div v-for="order in listOrdersSplit" :key="order.id">
              <el-row :gutter="10">
                <el-col :span="10" class="pt-1">
                  <el-checkbox v-model="order.checked"
                               @change="addClientToSplitOrder">
                    {{ order.client ? (order.client.name || (order.label || '')) : 'Comanda removida' }}
                  </el-checkbox>
                </el-col>
                <el-col :span="6" v-if="false">
                  <el-input-number :min="0"
                                   :max="100"
                                   :class="{'disabled': localOrderProduct.equal_split}"
                                   v-model="order.percent"
                                   size="small"></el-input-number>
                </el-col>
                <el-col :span="8">
                  <el-input size="small"
                            v-money="moneyMask"
                            v-model="order.amount"></el-input>
                </el-col>
              </el-row>
              <el-divider class="mt-2 mb-2"/>
            </div>
            <el-button type="primary"
                       @click="saveSplitOrderProduct"
                       :disabled="isSubmitted || !canSplitProduct"
                       :loading="isSubmitted">
              <i class="fas fa-check"></i>
              <span>Salvar Divisão</span>
            </el-button>
          </template>
        </template>
      </el-col>
    </el-row>
  </div>
</template>
