<script>
import List from './List'

export default {
  components: {List},
  name: 'entry-list-expense'
}
</script>

<template>
  <div>
    <list entry-type="expense"
          title="Contas a Pagar"
          store-title="Criar Lançamento"
          store-simple-title="Criar Lançamento Simples"/>
  </div>
</template>