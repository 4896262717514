<script>
import ListHeader from '../../../../components/list/Header'
import FormSaveButton from '../../../../components/form/SaveButton'
import FormCancelButton from '../../../../components/form/CancelButton'
import ValidationMessage from '../../../../components/form/ValidationMessage'

import * as restfulService from '../../../../services/restfulService'
import * as notifyService from '../../../../services/notifyService'
import DataService from '../../../../services/dataService'

import {currencyConfig} from '@utils/money'
import helpers from '@mixins/helpers'
import bus from '@utils/bus'

import {required} from 'vuelidate/lib/validators'
import {clone} from 'lodash'
import {warningDialog} from '@utils/flash'
import {mapGetters} from "vuex";

export default {
  components: {ListHeader, FormSaveButton, FormCancelButton, ValidationMessage},
  mixins: [helpers],
  data() {
    return {
      isEdit: false,
      isSubmitted: false,
      isLoadingData: true,
      isSearchingProduct: false,
      form: {
        supplier_id: '',
        created_at: '',
        notes: '',
        products: []
      },
      product: {
        product_id: ''
      },
      dependencies: {
        products: [],
        suppliers: []
      },
    }
  },
  validations: {
    form: {
      supplier_id: {required},
      created_at: {required},
      products: {required}
    }
  },
  computed: {
    ...mapGetters(['tenant']),
    moneyMask() {
      return currencyConfig()
    },
    amountTotal: function () {
      let total = 0
      if (this.form.products.length) {
        this.form.products.forEach((item) => {
          total += item.quantity * this.currencyParsed(item.price)
        })
      }
      return this.currencyFormatter(total)
    }
  },
  mounted() {
    this.getDependencies()
    const id = this.$route.params.id || null
    if (id) {
      this.isEdit = true
      restfulService.get('purchase', null, id, null, ['products.product'])
          .then(response => {
            this.form = {...response}
            this.isLoadingData = false
            bus.$emit('hide-loader')
          })
          .catch(e => {
            console.log(e)
            this.isLoadingData = false
            bus.$emit('hide-loader')
          })
    } else {
      this.isLoadingData = false
      bus.$emit('hide-loader')
    }
  },
  methods: {
    getDependencies() {
      DataService.get([{domain: 'supplier'}]).then((result) => {
        this.dependencies = {...this.dependencies, ...result}
      })
    },
    onSearchProduct(query) {
      this.dependencies.products = []
      this.isSearchingProduct = true
      let data = {
        code: {or: {code: {like: '%' + query + '%'}}},
        description: {or: {description: {like: '%' + query + '%'}}}
      }
      restfulService.search('product', null, data, null, null, 1, 25)
          .then((response) => {
            this.dependencies.products = [...this.dependencies.products, ...response.data]
            this.isSearchingProduct = false
          })
          .catch(e => {
            console.log(e)
          })
    },
    onSelectProduct() {
      let cantAddProduct = this.form.products.some((item) => {
        return item.id === this.product.product_id
      })
      if (cantAddProduct) {
        warningDialog({
          text: 'Esse produto já foi adicionado',
        })
        this.product.product_id = ''
      } else {
        let product = this.dependencies.products.filter((item) => {
          return item.id === this.product.product_id
        })
        if (product.length) {
          this.form.products.push({
            id: product[0].id,
            product_id: product[0].id,
            code: product[0].code,
            description: product[0].description,
            purchase_price: 0,
            price: 0,
            quantity: 1
          })
          this.product.product_id = ''
          this.dependencies.products = []
        }
      }
    },
    addProduct() {
      this.form.products.push({
        product_id: '',
        code: '',
        description: '',
        purchase_price: 0,
        price: 0,
        quantity: 1
      })
    },
    onRemoveProduct(product) {
      this.form.products = this.form.products.filter((item) => {
        return item.product_id !== product.product_id
      })
    },
    onSend() {
      this.isSubmitted = true
      const data = clone(this.form)
      data.products.forEach((item) => {
        item.purchase_price = this.currencyParsed(item.purchase_price)
        item.price = this.currencyParsed(item.price)
      })
      data.amount = this.currencyParsed(this.amountTotal)
      restfulService.save('purchase', null, null, data)
          .then(response => {
            notifyService.success({hideLoader: true})
            this.isSubmitted = false
            if (!this.isEdit) {
              this.$router.push({name: 'entry.expense.store', params: {purchase: response}})
            } else {
              this.$router.push({name: 'purchase.index'})
            }
          })
          .catch(e => {
            this.isSubmitted = false
            console.log(e)
          })
    }
  }
}
</script>

<template>
  <div>
    <ListHeader title="Compras"/>
    <el-form ref="form"
             :model="form"
             label-position="top">
      <el-row :gutter="20">
        <el-col :span="8">
          <el-card class="el-card__save_form box-card">
            <div slot="header">
              <span>Dados da Compra</span>
            </div>
            <el-form-item label="Fornecedor"
                          class="is-required"
                          :class="{ 'el-form-item--error': $v.form.supplier_id.$error }">
              <el-select v-model="form.supplier_id"
                         filterable
                         clearable
                         placeholder="Selecione um fornecedor"
                         empty-text="Nenhum registro encontrado"
                         class="el-select-full"
                         @input="$v.form.supplier_id.$touch()">
                <el-option
                    v-for="item in dependencies.suppliers"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
              <validation-message v-if="$v.form.supplier_id.$error"/>
            </el-form-item>
            <el-form-item label="Data da Compra"
                          class="is-required"
                          :class="{ 'el-form-item--error': $v.form.created_at.$error }">
              <el-date-picker
                  v-model="form.created_at"
                  type="date"
                  format="dd/MM/yyyy"
                  value-format="yyyy-MM-dd"
                  v-mask="'##/##/####'"
                  placeholder="Data da Compra"
                  @input="$v.form.created_at.$touch()">
              </el-date-picker>
              <validation-message v-if="$v.form.created_at.$error"/>
            </el-form-item>
            <el-form-item label="Observações">
              <el-input type="textarea"
                        :rows="4"
                        v-model="form.description"/>
            </el-form-item>
          </el-card>
        </el-col>
        <el-col :span="16">
          <el-card class="el-card__save_form box-card">
            <div slot="header">
              <span>Produtos</span>
              <el-button size="small"
                         v-if="!isEdit"
                         type="primary"
                         @click="addProduct">
                <i class="fas fa-plus"></i>
                <span>Novo Produto</span>
              </el-button>
            </div>
            <el-form ref="formProduct"
                     :model="product"
                     v-if="!isEdit"
                     label-position="top">
              <el-form-item label="Produto">
                <el-select v-model="product.product_id"
                           filterable
                           clearable
                           remote
                           :remote-method="onSearchProduct"
                           :loading="isSearchingProduct"
                           placeholder="Digite o Código ou Descrição do produto"
                           empty-text="Nenhum registro encontrado"
                           class="el-select-full"
                           @change="onSelectProduct">
                  <el-option
                      v-for="item in dependencies.products"
                      :key="item.id"
                      :label="(item.code+ ' - ' +item.description)"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <h3 v-if="!isEdit">Lista de Produtos</h3>
            <table class="custom-table-list" v-if="!isEdit">
              <thead>
              <tr>
                <th>Código</th>
                <th>Descrição</th>
                <th>Preço Compra</th>
                <th>Preço Venda</th>
                <th>Quantidade</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in form.products">
                <td>
                  <span v-if="item.id || isEdit">{{ item.code }}</span>
                  <el-input v-if="!item.id" v-model="item.code"/>
                </td>
                <td>
                  <span v-if="item.id || isEdit">{{ item.description }}</span>
                  <el-input v-if="!item.id" v-model="item.description"/>
                </td>
                <td>
                  <el-input v-model.lazy="item.purchase_price"
                            :disabled="isEdit"
                            v-money="moneyMask"/>
                </td>
                <td>
                  <el-input v-model.lazy="item.price"
                            :disabled="isEdit"
                            v-money="moneyMask"/>
                </td>
                <td>
                  <el-input-number v-model="item.quantity"
                                   :disabled="isEdit"
                                   :min="1"
                                   :step="1"/>
                </td>
                <td width="60">
                  <el-button type="danger"
                             size="small"
                             v-if="!isEdit"
                             title="Remover Produto"
                             @click="onRemoveProduct(item)">
                    <i class="fas fa-trash"></i>
                  </el-button>
                </td>
              </tr>
              </tbody>
            </table>
            <table class="custom-table-list" v-if="isEdit">
              <thead>
              <tr>
                <th>Código</th>
                <th>Descrição</th>
                <th>Quantidade</th>
                <th>Preço de Compra</th>
                <th>Total</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in form.products">
                <td>{{ item.product.code }}</td>
                <td>{{ item.product.description }}</td>
                <td>{{ item.quantity }}</td>
                <td>{{ item.price | currencyFormatter }}</td>
                <td>{{ (item.price * item.quantity) | currencyFormatter }}</td>
              </tr>
              </tbody>
            </table>
            <h3>Total: {{ amountTotal }}</h3>
          </el-card>
        </el-col>
      </el-row>

      <el-card class="el-card__save_form box-card el-card__save_form-submit">
        <form-save-button :event-click="onSend"
                          :is-disabled="isSubmitted || $v.$invalid"
                          :is-submitted="isSubmitted">
        </form-save-button>
        <form-cancel-button route-name="purchase.index"/>
      </el-card>
    </el-form>
  </div>
</template>
