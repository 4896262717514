<script>
import DataService from '@app/services/dataService'

export default {
  name: 'stage-bracket-system',
  props: ['bracketSystem'],
  data() {
    return {
      localBracketSystem: '',
      bracketSystemList: []
    }
  },
  computed: {
    statusLabel() {
      return this.bracketSystemList.find(item => item.id === this.localBracketSystem)?.name ?? ''
    }
  },
  mounted() {
    if (this.bracketSystem) {
      this.localBracketSystem = this.bracketSystem
      DataService.get([{domain: 'stage_bracket_system'}]).then((result) => {
        this.bracketSystemList = [...result.stage_bracket_system]
      })
    }
  }
}
</script>

<template>
  <div class="d-inline-block">
    <el-tag size="small" class="font-weight-bold el-tag--status" type="info" v-if="localBracketSystem === 'common'">
      {{ statusLabel }}
    </el-tag>
    <el-tag size="small" class="font-weight-bold el-tag--status" type="default"
            v-else-if="localBracketSystem === 'cobrapa'">
      {{ statusLabel }}
    </el-tag>
    <el-tag size="small" class="font-weight-bold el-tag--status" type="warning"
            v-else-if="localBracketSystem === 'super16'">
      {{ statusLabel }}
    </el-tag>
  </div>
</template>
