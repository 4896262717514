import baseConfig from '../config'

export const defaultLoadingMessage = 'Carregando...'

export default {
    appLoaded: false,
    idle: {
        current: false,
        seconds: 0
    },
    // Base API path
    config: {
        appName: baseConfig.appName,
        apiPath: baseConfig.basePath
    },
    // Auth
    tenant: {
        id: null,
        name: '',
        status: 'active',
        whatsapp_credits: 1,
    },
    session: '',
    token: '',
    user: {
        id: null,
        name: '',
        status: ''
    },
    permissions: [],
    roles: [],
    // Loader
    isLoadingSession: false,
    isLoading: true,
    loadingMessage: defaultLoadingMessage,
    // Sidebar
    showSidebar: false,
    // Notification
    notification: {
        title: '',
        message: ''
    },
    // Main list component
    listStore: {
        domain: '',
        options: {},
        listData: [],
        listSort: [],
        // Old main list component
        init: {},
        listOriginalData: [],
        listTotal: 0,
        listPerPage: 20,
        listCurrentPage: 1,
        listFilters: {},
        listMode: 'multiple'
    },
    // Service Worker
    serviceWorker: {
        updated: {
            active: false
        }
    },
    //
    globalDependencies: [
        {
            name: 'countries',
            loaded: false,
            list: []
        }
    ]
}
