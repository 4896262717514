<script>
import * as restfulService from '@app/services/restfulService'

import {hasModulePermission, hasRoutePermission, hasPermissionByName} from '@mixins/helpers'
import {get, set} from '@utils/storage'
import bus from '@utils/bus'
import {getUserUniqueId} from '@utils/fingerprint'

import {mapActions, mapGetters} from 'vuex'
import moment from 'moment'

import menu from '../../menu'

export default {
  name: 'app-sidebar',
  data() {
    return {
      firstLoad: true,
      isSubmitted: false,
      activeIndex: '',
      modulesOpened: [],
      windowWidth: document.documentElement.clientWidth,
      tenantBlocked: false
    }
  },
  computed: {
    ...mapGetters([
      'isLogged', 'isLoadingSession', 'currentUser', 'permissions', 'roles', 'tenant', 'showSidebar',
      'hasCommercialReceiptModule', 'hasCommercialInvoiceModule',
      'hasBasicPlan', 'hasProfessionalPlan', 'isCoach',
    ]),
    drawer: {
      get: function () {
        return this.showSidebar
      },
      set: function (value) {
        this.toggleSidebar(value)
      }
    },
    menu() {
      return menu
    },
    userClubs() {
      return this.currentUser.clubs
    },
    hasMultipleClubs() {
      return this.userClubs.length > 1
    },
    paymentAccount() {
      return (this.tenant?.has_payment_account ?? false)
    },
    trialTime() {
      return this.tenant && this.tenant.status === 'trial_time'
    },
    trialTimeMissing() {
      return this.trialTime
          ? 30 - moment().diff(moment(this.tenant.created_at), 'days')
          : 0
    },
    tellerControlEnabled() {
      return (!!this.tenant?.teller_control || false) && (hasPermissionByName('open.entry_teller') || hasPermissionByName('close.entry_teller'))
    },
    userHasTellerOpened() {
      return (!this.tellerControlEnabled || !!(this.tenant?.teller_control?.teller_opened?.id) || false)
    },
  },
  watch: {
    '$route': function (val) {
      if (val.name) {
        const routeSplit = val.name.split('.')
        const module = menu.find(item => item.children.some(childItem => childItem.route.name.includes(routeSplit[0])))
        if (module && this.firstLoad) {
          this.handleModuleSelection(module)
        }
      }
    }
  },
  async mounted() {
    this.tenantBlocked = await get('blocked')
    let tenantBlockedTab = 'payment'
    if (this.trialTimeMissing < 0) {
      await set('blocked', true)
      tenantBlockedTab = 'data'
      this.tenantBlocked = true
    }
    if (this.tenantBlocked) {
      this.$router.push({name: 'tenant_settings.show', params: {tab: tenantBlockedTab}})
    }
    window.addEventListener('resize', this.handleResize)
    setTimeout(() => {
      if (this.$vuetify.breakpoint.mdAndUp) {
        this.toggleSidebar(true)
      }
      this.firstLoad = false
    }, 500)
  },
  methods: {
    ...mapActions(['toggleSidebar', 'setToken']),
    handleResize() {
      this.windowWidth = document.documentElement.clientWidth
    },
    hasMenuPermissions(menu) {
      return menu.children.some(item => this.hasPermission(item.route)) && this.specialPermission(menu)
    },
    hasPermission(route) {
      const routeData = this.$router.resolve({name: route.name})
      return hasRoutePermission(routeData.route) && this.specialPermission(route)
    },
    hasModulePermission(routeName) {
      return hasModulePermission(routeName)
    },
    specialPermission(route) {
      if (['transaction.search', 'transaction.index'].some(item => item === route.name) && !this.paymentAccount) {
        return false
      } else if (['invoice.search', 'invoice.index'].some(item => item === route.name) && (!this.hasCommercialReceiptModule && !this.hasCommercialInvoiceModule)) {
        return false
      } else if (['entry.revenue.index'].some(item => item === route.name) && this.isCoach && !this.currentUser?.coach_config?.entries_by_coach) {
        return false
      } else if (['invoice_order.index'].some(item => item === route.name) && !this.hasProfessionalPlan) {
        return false
      } else if (['device_order.index'].some(item => item === route.name)) {
        return false
      } else if (['entry_teller.list'].some(item => item === route.name) && !this.tellerControlEnabled) {
        return false
      }
      return true
    },
    handleModuleSelection(module) {
      setTimeout(() => {
        if (this.modulesOpened.some(item => item === module.index)) {
          this.modulesOpened = this.modulesOpened.filter(item => item !== module.index)
        } else {
          this.modulesOpened.push(module.index)
        }
      }, 200)
    },
    getItemCssClass(item) {
      let itemRouteName = '0'
      let itemRouteSplit = []
      let currentRouteName = '1'
      let currentRouteSplit = []
      if (this.$route.name) {
        itemRouteName = item.route.name
        itemRouteSplit = item.route.name.split('.')
        if (itemRouteSplit.length === 3) {
          itemRouteName = [itemRouteSplit[0], itemRouteSplit[1]].join('.')
        }
        currentRouteName = this.$route.name
        currentRouteSplit = this.$route.name.split('.')
        if (currentRouteSplit.length === 3) {
          currentRouteName = [currentRouteSplit[0], currentRouteSplit[1]].join('.')
        }
      }
      return [
        item.cssClass,
        {'active': (itemRouteName === currentRouteName || (itemRouteSplit.length < 3 && currentRouteSplit[0] === itemRouteSplit[0]))}
      ]
    },
    isCurrentTenant(club) {
      return this.tenant.id === club.tenant_id
    },
    async selectClub(club) {
      bus.$emit('show-loader')
      this.isSubmitted = true
      const data = {
        tenant_id: club.tenant_id,
        device: await getUserUniqueId()
      }
      restfulService.post('tenant', 'switch', null, data)
          .then(async (response) => {
            this.isSubmitted = false
            await this.setToken(response.token)
            bus.$emit('reload-session-info', () => {
              if (!hasRoutePermission(this.$route)) {
                return this.$router.push({name: 'client.index'})
              } else {
                return this.$router.go()
              }
            })
          })
          .catch(e => {
            this.isSubmitted = false
            bus.$emit('hide-loader')
            console.log(e)
          })
    },
    handleTellerControl() {
      bus.$emit('teller-control', this.userHasTellerOpened)
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  }
}
</script>

<template>
  <v-navigation-drawer v-model="drawer"
                       :class="['app-navigation-drawer', {'-trial-time': trialTime}]"
                       app
                       fixed
                       dark
                       :hide-overlay="true"
                       :touchless="false"
                       :width="230">
    <div class="navigation-logo">
      <router-link :is="tenant ? 'span' : 'router-link'" :to="{name: 'dashboard.index'}">
        <span class="text"><strong>Gripo</strong> - Gestão de Clubes</span>
      </router-link>
    </div>
    <div class="loader" v-if="isLoadingSession">
      <div class="block">
        <div class="animated-item" v-for="item in [...Array(10)]" :key="item"/>
      </div>
      <div class="block">
        <div class="animated-item"></div>
        <div class="animated-item"></div>
      </div>
    </div>
    <template v-else>
      <div class="navigation-drawer-box">
        <v-list dense class="navigation-drawer-sidebar" ref="navigationDrawerSidebar" v-if="!tenantBlocked">
          <template v-for="module in menu">
            <v-list-group sub-group
                          no-action
                          :key="module.index"
                          :value="modulesOpened.includes(module.index)"
                          v-if="hasMenuPermissions(module)">
              <template v-slot:prependIcon>
                <i class="fas fa-caret-down"></i>
              </template>
              <template v-slot:activator>
                <v-list-tile @click="handleModuleSelection(module)">
                  <v-list-tile-title>
                    <span class="mr-2"><i :class="module.icon"></i></span>
                    <span>{{ module.text }}</span>
                  </v-list-tile-title>
                </v-list-tile>
              </template>
              <template v-for="(item, itemIndex) in module.children">
                <template v-if="module.index === '2' && itemIndex === 0 && tellerControlEnabled">
                  <v-list-tile :class="['-teller', {'-close': userHasTellerOpened}]"
                               @click="handleTellerControl"
                               :key="`handleTellerControl-${itemIndex}`">
                    <v-list-tile-action>
                      <div class="sidebar-icon"><i class="fas fa-cash-register"></i></div>
                    </v-list-tile-action>
                    <v-list-tile-content>
                      <v-list-tile-title>
                        {{ userHasTellerOpened ? 'Gerenciar' : 'Abrir' }} Caixa
                      </v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </template>
                <v-list-tile :key="`${item.route.name}_${itemIndex}`"
                             v-if="hasPermission(item.route)"
                             :exact="true"
                             :to="{name: item.route.name}"
                             :class="getItemCssClass(item)">
                  <v-list-tile-action>
                    <div class="sidebar-icon"><i :class="item.icon"></i></div>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title>
                      {{ item.menuText || item.text }}
                      <el-tooltip :content="hasBasicPlan ? 'Contrate o plano profissional' : 'Contrate um plano'"
                                  placement="right"
                                  v-if="!hasModulePermission(item.route.name)">
                        <el-tag type="danger"
                                size="mini">
                          <i class="fas fa-unlock"></i>
                        </el-tag>
                      </el-tooltip>
                    </v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
              </template>
            </v-list-group>
          </template>
        </v-list>
      </div>
      <div class="tenant-name" ref="tenantName">
        <a href="https://ajuda.gripo.com.br" target="_blank" class="support-link">
          <i class="fas fa-question-circle"></i>
          <span>Central de Ajuda</span>
        </a>
        <div class="tenant-data">
          <template v-if="trialTime">
            <div class="trial-time">
              <span v-if="trialTimeMissing >= 0">Teste por mais {{ trialTimeMissing }} dias</span>
              <span v-else>Acabou o seu período de testes</span>
            </div>
            <div class="mt-1 mb-1">
              <el-button class="el-button-activate-tenant"
                         size="mini"
                         type="success"
                         @click="$router.push({name: 'tenant_settings.show', query: {activate: 'y'}})">
                Ativar meu Clube
              </el-button>
            </div>
          </template>
          <div class="flex-c -jc-ce gap-3">
            <strong :class="[hasMultipleClubs ? 'text-left' : 'text-center']">{{ currentUser.tenant.name }}</strong>
            <el-dropdown @command="selectClub" v-if="hasMultipleClubs">
              <el-button type="info"
                         size="mini"
                         circle
                         class="el-button--extra-small-circle"
                         title="Alterar Cliente">
                <i class="fas fa-retweet"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="club in userClubs"
                                  :command="club"
                                  :disabled="isCurrentTenant(club) || isSubmitted"
                                  :key="club.id">
                  {{ club.tenant_name }}
                  <el-tag type="success" size="mini" v-if="isCurrentTenant(club)">Ativo</el-tag>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
    </template>
  </v-navigation-drawer>
</template>
