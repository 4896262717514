<script>
import ListHeader from '../../../../components/list/Header'
import FormSaveButton from '../../../../components/form/SaveButton'
import FormCancelButton from '../../../../components/form/CancelButton'

import * as restfulService from '../../../../services/restfulService'
import * as notifyService from '../../../../services/notifyService'

import bus from '@utils/bus'

import {required} from 'vuelidate/lib/validators'
import {clone} from 'lodash'

export default {
  components: {ListHeader, FormSaveButton, FormCancelButton},
  data() {
    return {
      isEdit: false,
      isSubmitted: false,
      isLoadingData: true,
      showPassword: false,
      form: {
        name: ''
      },
      permissionsGroup: [],
      selectedPermissions: []
    }
  },
  validations: {
    form: {
      name: {required}
    }
  },
  async mounted() {
    await this.getPermissions()
    const id = this.$route.params.id || null
    if (id) {
      this.isEdit = true
      restfulService.get('role', null, id, null, ['permissions'])
          .then(response => {
            this.form = {...response}
            this.selectedPermissions = response.permissions.map((item) => {
              return item.id
            })
            this.isLoadingData = false
            bus.$emit('hide-loader')
          })
          .catch(e => {
            console.log(e)
            this.isLoadingData = false
            bus.$emit('hide-loader')
          })
    } else {
      this.isLoadingData = false
      this.permissionsGroup.map(item => {
        this.selectedPermissions = [...this.selectedPermissions, ...item.permissions.map((item) => {
          return item.id
        })]
      })
      bus.$emit('hide-loader')
    }
  },
  methods: {
    getPermissions() {
      return new Promise((resolve, reject) => {
        this.permissionsGroup.splice(0)
        restfulService.get('permissions')
            .then(response => {
              this.permissionsGroup = [...response]
              resolve()
            })
            .catch(e => {
              reject(e)
              console.log(e)
            })
      })
    },
    groupPermissionChecked(group) {
      return group.permissions.every(permission => this.selectedPermissions.some(item => item === permission.id))
    },
    permissionChecked(permission) {
      return this.selectedPermissions.some(item => item === permission.id)
    },
    togglePermission(permission) {
      if (this.selectedPermissions.some(item => item === permission.id)) {
        this.selectedPermissions = this.selectedPermissions.filter(item => item !== permission.id)
      } else {
        this.selectedPermissions.push(permission.id)
      }
    },
    toggleGroupPermission(value, group) {
      group.permissions.map(permission => {
        this.selectedPermissions = this.selectedPermissions.filter(item => item !== permission.id)
        if (value) {
          this.togglePermission(permission)
        }
      })
    },
    onSend() {
      this.isSubmitted = true
      bus.$emit('show-loader')
      const data = clone(this.form)
      data.permissions = this.selectedPermissions
      restfulService.save('role', null, null, data)
          .then(() => {
            notifyService.success({hideLoader: true})
            this.isSubmitted = false
            this.$router.push({name: 'role.index'})
          })
          .catch(e => {
            bus.$emit('hide-loader')
            this.isSubmitted = false
            console.log(e)
          })
    }
  }
}
</script>

<template>
  <div>

    <ListHeader title="Perfis de Usuário"/>

    <el-card class="el-card__save_form box-card">
      <div slot="header">
        <span>Dados do Perfil</span>
      </div>
      <el-form ref="form"
               :model="form"
               label-position="top"
               @submit.prevent="onSend">
        <el-form-item label="Nome">
          <el-input v-model="form.name"
                    :readonly="form.blocked"
                    @input="$v.form.name.$touch()"/>
        </el-form-item>
        <h3>Permissões do Perfil</h3>
        <v-container fluid grid-list-lg class="p-0">
          <v-layout row wrap>
            <v-flex xs4 v-for="(group, index) in permissionsGroup" :key="index">
              <v-card :elevation="1">
                <v-card-title class="subheading grey lighten-3 permissions-card">
                  <v-checkbox :value="groupPermissionChecked(group)"
                              @change="toggleGroupPermission($event, group)"
                              hide-details
                              color="primary"
                              :label="group.name"/>
                </v-card-title>
                <v-list dense>
                  <template v-for="(permission, index) in group.permissions">
                    <v-list-tile :key="permission.name">
                      <v-list-tile-content>
                        <v-checkbox :value="permissionChecked(permission)"
                                    @change="togglePermission(permission)"
                                    color="primary"
                                    :label="permission.readable_name"/>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-divider v-if="group.permissions.length -1 > index"
                               :key="`${index}-permission.name`"></v-divider>
                  </template>
                </v-list>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
        <el-form-item class="mt-4">
          <form-save-button :event-click="onSend"
                            :is-disabled="isSubmitted || $v.form.$invalid"
                            :is-submitted="isSubmitted"/>
          <form-cancel-button route-name="role.index"/>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>
