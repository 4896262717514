<script>

export default {
  name: 'schedule-amount-paid',
  props: {
    schedule: {
      type: Object,
    }
  },
  computed: {
    amountPaid() {
      if (this.schedule.paid && !this.schedule.transaction) {
        if (!this.schedule.transaction) {
          const playersAmount = this.schedule.players
              .filter(player => player.paid)
              .reduce((total, player) => total += player.amount, 0)
          if (playersAmount > 0) {
            return playersAmount
          }
        }
      }
      if (this.schedule.transaction) {
        if (this.schedule.transaction.status === 'paid') {
          const amount = (this.schedule.transaction.amount - this.schedule.transaction.discount)
          if (amount > 0) {
            return amount
          }
        }
      }
      return this.schedule.price
    }
  }
}
</script>

<template>
  <span>
    {{ amountPaid | currencyFormatter }}
  </span>
</template>
