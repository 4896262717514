<script>
export default {
  name: 'status-label',
  props: ['status'],
  computed: {
    statusLabel() {
      if (this.status === 'queued') {
        return 'Na fila'
      } else if (this.status === 'sent') {
        return 'Enviada'
      } else if (this.status === 'delivered') {
        return 'Entregue'
      } else if (this.status === 'read') {
        return 'Lida'
      } else if (this.status === 'undelivered' || this.status === 'failed' || this.status === 'no_credits') {
        return 'Falhou'
      } else {
        return 'Arquivada'
      }
    },
    statusType() {
      if (this.status === 'queued') {
        return 'warning'
      } else if (['sent', 'delivered', 'read'].includes(this.status)) {
        return 'success'
      } else if (['undelivered', 'failed', 'no_credits'].includes(this.status)) {
        return 'danger'
      } else {
        return 'info'
      }
    }
  }
}
</script>

<template>
  <div>
    <el-tag size="mini" :type="statusType">{{ statusLabel }}</el-tag>
  </div>
</template>
