<script>
import * as restfulService from '../../../../services/restfulService'
import * as notifyService from '../../../../services/notifyService'

import helpers from '@mixins/helpers'
import bus from '@utils/bus'
import {mapGetters} from 'vuex'
import {getUserUniqueId} from '@utils/fingerprint'

const vapidPublicKey = () => process.env.VUE_APP_VAPID_PUBLIC_KEY

export default {
  mixins: [helpers],
  name: 'enable-push-notifications',
  props: {
    disabled: {
      default: false,
      type: Boolean
    },
    model: {
      default: false,
      type: Boolean
    },
    data: {
      default: () => ({
        endpoint: '',
        key: '',
        token: ''
      }),
      type: Object
    },
    userNotGrantedPermission: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      notificationModel: false,
      notificationsSupported: false,
      notificationsEnabled: false,
      buttonDisabled: false,
      serviceWorkerRegistration: null,
      subscription: null
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    subscriptionData() {
      if (this.subscription) {
        const key = this.subscription.getKey('p256dh')
        const token = this.subscription.getKey('auth')
        return {
          endpoint: this.subscription.endpoint,
          public_key: key ? btoa(String.fromCharCode.apply(null, new Uint8Array(key))) : null,
          auth_token: token ? btoa(String.fromCharCode.apply(null, new Uint8Array(token))) : null
        }
      }
      return {}
    }
  },
  created() {
    if ('Notification' in window && 'serviceWorker' in navigator) {
      this.notificationsSupported = true
    }
  },
  mounted() {
    this.notificationModel = this.model
    if (navigator.serviceWorker) {
      this.findSubscription()
          .then(sub => {
            if (sub === null) {
              this.buttonDisabled = false
              this.notificationsEnabled = false
              this.notificationModel = false
            } else {
              this.buttonDisabled = false
              this.notificationsEnabled = true
              this.subscription = sub
              this.$emit('update:data', {...this.subscriptionData})
            }
          })
    }
  },
  methods: {
    toggleSubscription() {
      this.$emit('update:model', this.notificationModel)
      if (this.notificationsSupported) {
        this.buttonDisabled = true
        if (this.notificationModel) {
          //console.log('notificationsEnabled')
          Notification.requestPermission()
              .then(result => {
                if (result === 'granted') {
                  //console.log('granted')
                  this.$emit('update:userNotGrantedPermission', false)
                  this.createSubscription()
                      .then(sub => {
                        //console.log('createSubscription')
                        this.buttonDisabled = false
                        this.notificationsEnabled = true
                        this.$emit('update:model', true)
                        this.subscription = sub
                        this.$emit('update:data', {...this.subscriptionData})
                        this.updateUserSubscription(this.subscriptionData)
                      })
                } else {
                  this.buttonDisabled = false
                  this.notificationModel = false
                  //console.info('User did not granted permission')
                  this.$emit('update:model', false)
                  this.$emit('update:userNotGrantedPermission', true)
                }
              })
        } else {
          //console.log('disabled notificationsEnabled')
          if (this.subscription !== null) {
            //console.log('subscription !== null')
            this.findSubscription()
                .then(sub => {
                  sub.unsubscribe()
                      .then(() => {
                        //console.log('unsubscribe')
                        this.notificationsEnabled = false
                        this.$emit('update:model', false)
                        this.subscription = null
                        this.buttonDisabled = false
                        this.updateUserSubscription()
                      })
                      .catch(e => {
                        console.log('Unsubscription error: ', e)
                      })
                      .finally(() => {
                        //console.log('unsubscribe finally')
                        this.notificationsEnabled = false
                        this.$emit('update:model', false)
                        this.subscription = null
                        this.buttonDisabled = false
                      })
                })
          }
        }
      } else {
        //console.log('notifications not supported')
      }
    },
    createSubscription() {
      if (this.serviceWorkerRegistration === null) {
        return navigator.serviceWorker.ready
            .then(swReg => {
              this.serviceWorkerRegistration = swReg
              return this.subscribe(this.serviceWorkerRegistration)
            })
      } else {
        return this.subscribe(this.serviceWorkerRegistration)
      }
    },
    getSubscription(swReg) {
      return swReg.pushManager.getSubscription()
    },
    subscribe(swReg) {
      const convertedVapidPublicKey = this.urlBase64ToUint8Array(vapidPublicKey())
      return swReg.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: convertedVapidPublicKey
      })
    },
    findSubscription() {
      return navigator.serviceWorker.ready
          .then(swReg => {
            this.serviceWorkerRegistration = swReg
            return this.getSubscription(this.serviceWorkerRegistration)
          })
    },
    async updateUserSubscription(subData) {
      bus.$emit('show-loader')
      subData = subData || null
      let data = {}
      if (subData && subData.endpoint && subData.public_key && subData.auth_token) {
        data.endpoint = subData.endpoint
        data.public_key = subData.public_key
        data.auth_token = subData.auth_token
      }
      if (!this.currentUser.device) {
        data.device = await getUserUniqueId()
      }
      restfulService.post('user', 'update-push-subscription', null, data)
          .then(() => {
            notifyService.success({message: 'Permissões de notificações atualizadas'})
            bus.$emit('hide-loader')
          })
          .catch(e => {
            bus.$emit('hide-loader')
            console.log(e)
          })
    }
  }
}
</script>

<template>
  <v-switch v-model="notificationModel"
            class="mt-0"
            @change="toggleSubscription"
            color="secondary"
            :disabled="disabled"
            hide-details></v-switch>
</template>