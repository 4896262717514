<script>
export default {
  name: 'plan-rule-value-label',
  props: {
    type: {default: '', type: String},
    value: {default: 0},
    showInputSelection: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      localValue: 0
    }
  },
  watch: {
    localValue: function (value) {
      this.$emit('update:value', value)
    }
  },
  mounted() {
    this.localValue = this.value
  }
}
</script>

<template>
  <div>
    <template v-if="type === 'percent'">{{ value }}%</template>
    <template v-else-if="type === 'amount'">{{ value | currencyFormatter }}</template>
    <template v-else-if="showInputSelection">
      <el-input-number size="mini" v-model="localValue"/>
    </template>
    <template v-else>{{ value }}</template>
  </div>
</template>
