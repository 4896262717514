import { render, staticRenderFns } from "./SenderStatusLabel.vue?vue&type=template&id=030c549c"
import script from "./SenderStatusLabel.vue?vue&type=script&lang=js"
export * from "./SenderStatusLabel.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports