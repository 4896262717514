<script>
export default {
  name: 'entry-status-label',
  props: {
    status: {
      type: String
    },
    item: {
      type: Object
    },
    showDot: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'mini'
    },
    isPortion: {
      type: Boolean,
      default: true
    },
    portionsSettled: {
      type: Boolean,
      default: false
    },
    deleted: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    dotClass() {
      if (this.deleted) {
        return '-deleted'
      }
      if (this.isPortion) {
        if (this.item.overdue) {
          return '-overdue'
        } else if (this.status === 'settled' && !this.item.received_late) {
          return '-paid'
        } else if (this.status === 'settled' && this.item.received_late) {
          return '-paid-late'
        } else {
          return '-created'
        }
      }
      if (this.status === 'settled' || this.portionsSettled) {
        return '-paid'
      } else {
        return '-created'
      }
    },
    statusTitle() {
      if (this.deleted) {
        return 'Deletado'
      }
      if (this.isPortion) {
        if (this.item.overdue) {
          return 'Em atraso'
        } else if (this.status === 'settled' && !this.item.received_late) {
          return 'Quitado'
        } else if (this.status === 'settled' && this.item.received_late) {
          return 'Quitado com atraso'
        } else if (this.status === 'created' && this.item.partial_settled) {
          return 'Quitado parcialmente'
        } else {
          return 'Aberto'
        }
      }
      if (this.status === 'settled' || this.portionsSettled) {
        return 'Quitado'
      } else {
        return 'Aberto'
      }
    },
    statusKey() {
      if (this.deleted) {
        return 'deleted'
      }
      if (!this.isPortion && (this.status === 'settled' || this.portionsSettled)) {
        return 'settled'
      }
      return this.status
    }
  }
}
</script>

<template>
  <div :class="['entry-status', {'mt-1' : showDot }]" :title="statusTitle">
    <el-badge is-dot :class="['entry-status-dot', dotClass]" :hidden="!showDot">
      <el-tag :size="size" type="default" v-if="statusKey === 'created'">Aberto</el-tag>
      <el-tag :size="size" type="success" v-if="statusKey === 'settled'">Quitado</el-tag>
      <el-tag :size="size" type="danger" v-if="statusKey === 'deleted'">Deletado</el-tag>
    </el-badge>
  </div>
</template>
