<script>
import GripoDialog from '@components/common/Dialog'
import MessagePackTypeLabel from '../labels/MessagePackTypeLabel'
import MessagePackSenderTypeLabel from '../labels/MessagePackSenderTypeLabel'
import MessagePackStatusLabel from '../labels/MessagePackStatusLabel'
import MessagePackList from './List'
import WhatsappSettings from '../config/whatsapp/Index.vue'

import * as restfulService from '@app/services/restfulService'
import * as notifyService from '@app/services/notifyService'

import bus from '@utils/bus'
import {mapGetters} from 'vuex'

import QRCode from 'qrcode-svg'
import {confirmDialog} from '@utils/flash'

export default {
  components: {
    GripoDialog,
    MessagePackTypeLabel,
    MessagePackSenderTypeLabel,
    MessagePackStatusLabel,
    MessagePackList,
    WhatsappSettings
  },
  name: 'whatsapp',
  props: ['entriesOverdueCount', 'active'],
  data() {
    return {
      isSubmitted: false,
      packs: {
        filters: {
          dates: [],
          status: 'active'
        },
        data: [],
        credits: 0,
        pendingMessages: 0,
      },
      instanceInterval: null,
      packsHidden: true,
      dependencies: {
        quantities: [200, 500, 1000, 5000]
      },
      showAddPack: false,
      loadNotifications: this.active,
      newPack: {
        client_id: '',
        status: 'created',
        type: 'message',
        target_id: '',
        target_type: '',
        amount: 0,
        quantity: 1000,
        custom_quantity: 10000,
        expires_at: '',
      },
      payment: {
        amount: 0,
        boleto: {
          showModal: false,
          base64Pdf: '',
          urlPdf: '',
          generateTries: 0
        },
        pix: {
          showModal: false,
          base64Image: '',
          qrcode: ''
        }
      },
    }
  },
  computed: {
    ...mapGetters(['tenant']),
    showCustomQuantity() {
      return this.newPack.quantity === '0'
    },
    packDiscount() {
      let quantity = this.newPack.quantity
      if (this.showCustomQuantity) {
        quantity = this.newPack.custom_quantity
      }
      if (quantity >= 200 && quantity < 500) {
        return 0.05
      } else if (quantity >= 500 && quantity < 1000) {
        return 0.08
      } else if (quantity >= 1000 && quantity < 5000) {
        return 0.1
      } else if (quantity >= 5000) {
        return 0.15
      } else {
        return 0
      }
    },
    packAmount() {
      let amount = this.newPack.quantity * this.messageCost
      if (this.showCustomQuantity) {
        amount = this.newPack.custom_quantity * this.messageCost
      }
      if (this.packDiscount > 0) {
        amount = amount - (amount * this.packDiscount)
      }
      return amount
    },
    tenantSettingsSenderType() {
      return this.tenant.settings?.whatsapp_sender?.type || 'gripo'
    },
    messageCost() {
      return this.tenantSettingsSenderType === 'gripo' ? 0.2 : 0.1
    }
  },
  async mounted() {
    this.loadMessagePacks()
  },
  methods: {
    loadMessagePacks() {
      return new Promise((resolve, reject) => {
        bus.$emit('show-loader')
        const data = {...this.packs.filters}
        restfulService.post('tenant', 'message-packs', null, data)
            .then(async (response) => {
              this.packs.data = [...response.packs]
              this.packs.credits = response.credits
              this.packs.pendingMessages = response.pending_messages
              bus.$emit('hide-loader')
              resolve()
            })
            .catch(e => {
              bus.$emit('hide-loader')
              console.log(e)
              reject()
            })
      })
    },
    async tenantPayment(pack) {
      this.isSubmitted = true
      bus.$emit('show-loader', 'Aguarde...<br>Estamos gerando seu PIX')
      const data = {
        entry_portion_id: pack.entry_portion_id,
        payment_method: 'pix'
      }
      try {
        const response = await restfulService.post('tenant', 'entry-payment', null, data)
        this.handleTransaction(response)
      } catch (e) {
        this.isSubmitted = false
        bus.$emit('hide-loader')
        console.log(e)
      }
    },
    handleTransaction(transaction) {
      this.payment.amount = transaction.amount
      this.payment.pix.base64Image = transaction.base64_image
      this.payment.pix.qrcode = transaction.pix_qr_code
      this.payment.pix.showModal = true
      setTimeout(() => {
        const svg = (new QRCode({
          content: transaction.pix_qr_code,
          padding: 0,
          width: 256,
          height: 256,
          color: '#000000',
          background: '#ffffff',
          ecl: 'M',
        })).svg()
        this.$refs['pixContainer'].innerHTML = svg
        this.isSubmitted = false
        bus.$emit('hide-loader')
      }, 200)
    },
    resetPayment() {
      this.payment.amount = 0
      this.payment.pix.base64Image = ''
      this.payment.pix.qrcode = ''
    },
    resetPaymentPix() {
      this.resetPayment('pix')
    },
    copyText(ref) {
      const codeToCopy = this.$refs[ref]
      navigator.clipboard.writeText(codeToCopy.value)
      this.$notify.success({
        title: 'Sucesso!',
        message: 'Código QR Code copiado!',
        duration: 3000,
      })
    },
    onAddPack() {
      this.newPack.quantity = 500
      this.showAddPack = !this.showAddPack
    },
    async purchaseMessagePack() {
      const {value: confirm} = await confirmDialog({
        title: 'Deseja realmente comprar esse pacote?',
        icon: 'warning',
      })
      if (confirm) {
        bus.$emit('show-loader')
        const data = {...this.newPack}
        if (this.showCustomQuantity) {
          data.quantity = data.custom_quantity
          delete data.custom_quantity
        }
        restfulService.post('tenant', 'purchase-message-pack', null, data)
            .then((response) => {
              notifyService.success({message: 'O registro de compra do pacote for registrado, realize o pagamento para ativar o pacote'})
              this.loadMessagePacks()
              this.onAddPack()
              this.handleTransaction(response)
            })
            .catch(e => {
              console.log(e)
            })
            .finally(() => {
              bus.$emit('hide-loader')
            })
      }
    }
  }
}
</script>

<template>
  <div>
    <whatsapp-settings />
    <el-card :class="['el-card__save_form', {'box-card-collapse': packsHidden}]">
      <div slot="header">
        <div @click="packsHidden = !packsHidden">
          <el-button size="mini" type="text" class="mr-1">
            <template v-if="packsHidden">
              <i class="fas fa-chevron-down"></i>
            </template>
            <template v-else>
              <i class="fas fa-chevron-up"></i>
            </template>
          </el-button>
          <span>Pacotes de Mensagens ({{ packs.data.length }})</span>
        </div>
        <div>
          <el-button size="mini" type="success" @click="onAddPack">
            <i class="fas fa-plus"></i>
            <span>Comprar Novo Pacote</span>
          </el-button>
        </div>
      </div>
      <template v-if="!packsHidden">
        <el-form :inline="true" :model="packs.filters">
          <h4 class="mb-1">Filtrar por:</h4>
          <el-form-item label="Status">
            <el-select v-model="packs.filters.status"
                       @change="loadMessagePacks"
                       placeholder="Filtrar por Status"
                       empty-text="Nenhum registro encontrado"
                       class="el-select-full">
              <el-option label="Ativo" value="active"/>
              <el-option label="Utilizado/Expirado" value="expired"/>
              <el-option label="Aguardando Pagamento" value="created"/>
            </el-select>
          </el-form-item>
          <el-form-item label="Data de Criação" v-if="packs.filters.status === 'expired'">
            <el-date-picker
                v-model="packs.filters.dates"
                @change="loadMessagePacks"
                type="monthrange"
                format="MMM/yyyy"
                value-format="yyyy-MM-dd"
                placeholder="Filtrar por Data de Criação">
            </el-date-picker>
          </el-form-item>
        </el-form>
        <table class="custom-table-list responsive-data-table" v-if="packs.data.length">
          <thead>
          <tr>
            <th class="text-center">Tipo</th>
            <th class="text-center">Tipo de Envio</th>
            <th class="text-center">Status</th>
            <th class="text-center">Quantidade / Utilizado</th>
            <th align="right">Valor</th>
            <th class="text-center">Data de Criação</th>
            <th class="text-center">Data de Ativação</th>
            <th class="text-center">Expira em</th>
            <th class="btn-actions">Ações</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in packs.data" :class="{'overdue': item.overdue}">
            <template v-if="!$vuetify.breakpoint.xs">
              <td class="text-center">
                <message-pack-type-label :type="item.settings.group"/>
                <div v-if="item.settings.group === 'stage_free'" class="pt-1">
                  {{ item.stage }}
                </div>
              </td>
              <td class="text-center">
                <message-pack-sender-type-label :sender-type="item.settings.sender_type"/>
              </td>
              <td class="text-center">
                <message-pack-status-label :status="item.status"/>
              </td>
              <td class="text-center">{{ item.quantity }} / {{ item.quantity_used }}</td>
              <td align="right">
                <template v-if="item.settings.group === 'purchase'">
                  {{ item.amount | currencyFormatter }}
                </template>
                <template v-else>
                  Sem custo
                </template>
              </td>
              <td class="text-center">{{ item.stored_at | dateTimeEnToBr }}h</td>
              <td class="text-center">
                <template v-if="item.activated_at">
                  {{ item.activated_at | dateTimeEnToBr }}h
                </template>
              </td>
              <td class="text-center">
                <el-tag type="danger" size="mini">
                  <template v-if="item.settings.group === 'monthly_free'">
                    {{ item.expires_at | dateTimeEnToBr }}h
                  </template>
                  <template v-else>Não expira</template>
                </el-tag>
              </td>
              <td class="list-table-nowrap">
                <template v-if="item.settings.group === 'purchase' && item.status === 'created'">
                  <el-button type="success"
                             size="small"
                             @click="tenantPayment(item, 'pix')"
                             :disabled="isSubmitted"
                             title="Pagar com  PIX">
                    <i class="fas fa-qrcode"></i>
                    <span>Pagar com PIX</span>
                  </el-button>
                </template>
              </td>
            </template>
            <template v-else>
              <td>
                <ul class="flex-content">
                  <li class="flex-item" data-label="Tipo">
                    <message-pack-type-label :type="item.settings.group"/>
                    <div v-if="item.settings.group === 'stage_free'" class="pt-1">
                      {{ item.stage }}
                    </div>
                  </li>
                  <li class="flex-item" data-label="Status">
                    <message-pack-status-label :type="item.status"/>
                  </li>
                  <li class="flex-item" data-label="Utilização">
                    {{ item.quantity_used }} de {{ item.quantity }}
                  </li>
                  <li class="flex-item" data-label="Valor" v-if="item.settings.group === 'purchase'">
                    {{ item.amount | currencyFormatter }}
                  </li>
                  <li class="flex-item" data-label="Data de Ativação">
                <span v-if="item.activated_at">
                  {{ item.activated_at | dateTimeEnToBr }}h
                </span>
                  </li>
                  <li class="flex-item" data-label="Expira em">
                    <el-tag type="danger" size="mini">
                      <template v-if="item.settings.group === 'monthly_free'">
                        {{ item.expires_at | dateTimeEnToBr }}h
                      </template>
                      <template v-else>Não expira</template>
                    </el-tag>
                  </li>
                  <li class="flex-item" data-label="Ações" v-if="item.status === 'created'">
                    <el-button type="success"
                               size="small"
                               @click="tenantPayment(item, 'pix')"
                               :disabled="isSubmitted"
                               title="Pagar com PIX">
                      <i class="fas fa-qrcode"></i>
                      <span>Pagar com PIX</span>
                    </el-button>
                  </li>
                </ul>
              </td>
            </template>
          </tr>
          </tbody>
        </table>
        <div v-else class="text-center">
          <el-divider/>
          Nenhuma pacote foi encontrado!
        </div>
      </template>
    </el-card>
    <message-pack-list v-if="loadNotifications"
                       class="mt-4"
                       :credits="packs.credits"
                       :pending-messages="packs.pendingMessages"
                       @onFilter="loadMessagePacks"/>

    <gripo-dialog :model.sync="showAddPack"
                  width="70vw">
      <template v-slot:header>
        Comprar novo pacote
      </template>
      <template v-slot:content>
        <el-form :model="newPack" ref="form" label-position="top">
          <el-row :gutter="20" class="mb-4">
            <el-col :span="12">
              <el-alert type="warning"
                        show-icon
                        :closable="false">
                <div class="fs-16">
                  Seu clube está atualmente configurado para
                  enviar mensagens utilizando o número do
                  <strong>{{ tenantSettingsSenderType === 'gripo' ? 'Gripo' : 'Clube' }}</strong>
                </div>
                <div class="fs-16">
                  Valor por mensagem: <strong>{{ messageCost | currencyFormatter }}</strong>
                </div>
              </el-alert>
            </el-col>
            <el-col :span="12">
              <el-alert type="info"
                        show-icon
                        :closable="false">
                <div class="fs-16">
                  Pacotes de mensagens comprados não expiram, aproveite para comprar um pacote grande e não ficar sem
                  receber/enviar mensagens!
                </div>
              </el-alert>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="Quantidade de Mensagens"
                            class="is-required">
                <el-radio-group v-model="newPack.quantity">
                  <template v-for="quantity in dependencies.quantities">
                    <el-radio-button :key="quantity"
                                     :label="quantity">
                      {{ quantity }}
                    </el-radio-button>
                  </template>
                  <el-radio-button label="0">Customizado</el-radio-button>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12" class="text-center">
              <div class="fs-16 mb-1" v-if="packDiscount > 0">
                <span class="font-weight-normal">Desconto:</span> <strong>{{ 100 * packDiscount }}%</strong>
              </div>
              <div class="fs-18">
                <span class="font-weight-normal">
                  Valor Total:</span> <strong>{{ packAmount | currencyFormatter }}
              </strong>
              </div>
              <el-alert type="success"
                        show-icon
                        class="mt-4"
                        :closable="false">
                <div class="fs-16">
                  Após a confirmação da compra do pacote, será gerado um PIX para que o pagamento seja efetuado!
                </div>
              </el-alert>
            </el-col>
          </el-row>
          <el-form-item label="Quantidade Customizada"
                        style="max-width: 300px;"
                        v-if="showCustomQuantity"
                        class="is-required">
            <el-input-number v-model="newPack.custom_quantity" :min="1" :step="1"/>
          </el-form-item>
        </el-form>
      </template>
      <template v-slot:actions>
        <v-spacer/>
        <el-button type="success"
                   :disabled="isSubmitted"
                   @click="purchaseMessagePack">
          <i class="fas fa-check"></i>
          <span class="ml-1">Confirmar Compra do Pacote</span>
        </el-button>
      </template>
    </gripo-dialog>

    <gripo-dialog :model.sync="payment.pix.showModal"
                  max-width="450"
                  :actions-close-btn="false"
                  :persistent="false"
                  :on-close="resetPaymentPix">
      <template v-slot:header>Pagar via PIX</template>
      <template v-slot:content>
        <h2 class="text-center mb-5">
          Valor: {{ payment.amount | currencyFormatter }}
        </h2>
        <div ref="pixContainer" class="text-center">
          Gerando QR Code...
        </div>
        <div class="mt-4">
          <h4>Código PIX</h4>
          <p>{{ payment.pix.qrcode }}</p>
        </div>
        <input type="text" :value="payment.pix.qrcode" ref="pixCopyCodeText"
               style="opacity: 0;position: absolute;">
        <v-btn color="blue" block class="white--text" @click="copyText('pixCopyCodeText')">
          Copiar Código PIX
        </v-btn>
      </template>
    </gripo-dialog>
  </div>
</template>
