import * as TYPES from './types'
import {clear, set} from '../utils/storage'
import {defaultLoadingMessage} from './state'

const setGlobalCurrencyConfig = (config) => {
    config.locale = config.locale.replace('_', '-')
    window.localeCountryConfig = {
        ...window.localeCountryConfig,
        ...config
    }
}

export default {
    setAppLoaded: (context, payload) => {
        context.commit(TYPES.APP_LOADED, payload)
    },
    setIdle: (context, payload) => {
        context.commit(TYPES.IDLE, payload)
    },
    setLoadingSession: (context, payload) => {
        context.commit(TYPES.LOADING_SESSION, payload)
    },
    showLoader: (context, payload) => {
        context.commit(TYPES.LOADER_SHOW, true)
        if (payload) {
            context.commit(TYPES.LOADER_MESSAGE, payload)
        }
    },
    hideLoader: (context) => {
        setTimeout(() => {
            context.commit(TYPES.LOADER_SHOW, false)
            setTimeout(() => {
                context.commit(TYPES.LOADER_MESSAGE, defaultLoadingMessage)
            }, 100)
        }, 300)
    },
    toggleSidebar: (context, payload) => {
        context.commit(TYPES.TOGGLE_SIDEBAR, payload)
    },
    showNotification: (context, payload) => {
        context.commit(TYPES.NOTIFICATION, payload)
    },
    setListOptions: (context, payload) => {
        context.commit(TYPES.LIST_OPTIONS, payload)
    },
    setListData: (context, payload) => {
        context.commit(TYPES.LIST_DATA, payload)
    },
    setListSort(context, payload) {
        context.commit(TYPES.LIST_SORT, payload)
    },
    setListFilters(context, payload) {
        context.commit(TYPES.LIST_FILTERS, payload)
    },
    setCurrentPage(context, payload) {
        context.commit(TYPES.LIST_CURRENT_PAGE, payload)
    },
    setListMode(context, payload) {
        context.commit(TYPES.LIST_MODE, payload)
    },
    async setSortField({commit, getters}, payload) {
        return new Promise(async (resolve) => {
            let field = null
            if (payload) {
                field = getters.listStore.listSort.find(item => item.column === payload)
                const sortList = getters.listStore.listSort.filter(item => item.column !== payload)
                if (field) {
                    if (field.direction === 'asc') {
                        field.direction = 'desc'
                    } else {
                        field = null
                    }
                } else {
                    field = {
                        column: payload,
                        direction: 'asc'
                    }
                }
                if (field) {
                    if (getters.listStore.listMode === 'single') {
                        sortList.splice(0)
                    }
                    sortList.push(field)
                }
                await commit(TYPES.LIST_SORT, sortList)
            }
            resolve()
        })
    },
    login: ({dispatch}, payload) => {
        return new Promise((resolve) => {
            const tenant = payload.user.tenant
            const session = payload.session + '--' + tenant.id
            const data = {
                token: payload.token,
                user: payload.user,
                permissions: payload.permissions,
                roles: payload.roles,
                tenant: tenant,
                session: session
            }
            dispatch('setUpAuth', data).then(() => resolve())
        })
    },
    logout: (context) => {
        return new Promise(async (resolve) => {
            await clear()
            context.commit(TYPES.AUTH_TENANT, {})
            context.commit(TYPES.AUTH_SESSION, '')
            context.commit(TYPES.AUTH_USER, {})
            context.commit(TYPES.AUTH_PERMISSIONS, [])
            context.commit(TYPES.AUTH_ROLES, [])
            context.commit(TYPES.AUTH_TOKEN, '')
            resolve()
        })
    },
    setUser: (context, payload) => {
        return new Promise(async (resolve) => {
            await set('user', payload)
            context.commit(TYPES.AUTH_USER, payload)
            resolve()
        })
    },
    setUpAuth: (context, payload) => {
        return new Promise(async (resolve) => {
            if (payload.tenant?.country) {
                setGlobalCurrencyConfig(payload.tenant?.country)
            }
            await set('tenant', payload.tenant)
            if (payload.session) {
                await set('session', payload.session)
            }
            await set('user', payload.user)
            await set('permissions', payload.permissions)
            await set('roles', payload.roles)
            if (payload.token) {
                await set('token', payload.token)
            }
            context.commit(TYPES.AUTH_TENANT, payload.tenant)
            if (payload.session) {
                context.commit(TYPES.AUTH_SESSION, payload.session)
            }
            context.commit(TYPES.AUTH_USER, payload.user)
            context.commit(TYPES.AUTH_PERMISSIONS, payload.permissions)
            context.commit(TYPES.AUTH_ROLES, payload.roles)
            if (payload.token) {
                await context.dispatch('setToken', payload.token)
            }
            resolve()
        })
    },
    setToken: (context, payload) => {
        return new Promise(async (resolve) => {
            await set('token', payload)
            context.commit(TYPES.AUTH_TOKEN, payload)
            resolve()
        })
    },
    setTenant: (context, payload) => {
        return new Promise(async (resolve) => {
            if (payload?.country) {
                setGlobalCurrencyConfig(payload?.country)
            }
            await set('tenant', payload)
            context.commit(TYPES.AUTH_TENANT, payload)
            resolve()
        })
    },
    swUpdateAvailable(context, payload) {
        context.commit(TYPES.SW_UPDATE_AVAILABLE, payload)
    },
    setGlobalDependencies(context, payload) {
        context.commit(TYPES.GLOBAL_DEPENDENCIES, payload)
    }
}
