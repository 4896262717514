<script>
import ClientName from '../../../../../components/common/ClientName'

export default {
  components: {ClientName},
  name: 'schedule-players',
  props: {
    players: {
      type: Array,
    },
    client: {
      type: Object,
    },
    coach: {
      type: Object,
    },
    gridInterval: {
      type: Number,
      default: 50
    },
    fixed: {
      type: Boolean,
      default: false
    },
    showPayment: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    playersList() {
      if (this.players && this.players.length > 0) {
        return this.players
      }
      return this.client ? [{client: this.client}] : []
    },
    playersCount() {
      const total = this.playersList.length
      if (!this.isCoach) {
        return total
      }
      return total + 1
    },
    isCoach() {
      return !!(this.coach && this.coach.id)
    }
  }
}
</script>

<template>
  <div class="schedule-players-components">
    <div :class="['box -coach', {'-fluid' : playersCount > 4}]" v-if="isCoach" title="Professor">
      <span>{{ coach.name }}</span>
    </div>
    <template v-for="player in playersList">
      <div :class="['box', {'-full': playersCount < 3}, {'-fluid' : playersCount > 4}]">
        <client-name :client="player.client"
                     :full-name-limit="14"
                     :paid="showPayment && player.paid"/>
      </div>
    </template>
  </div>
</template>
