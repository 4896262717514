import { render, staticRenderFns } from "./PairStatus.vue?vue&type=template&id=158e3be8"
import script from "./PairStatus.vue?vue&type=script&lang=js"
export * from "./PairStatus.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports