<script>
import GripoDialog from '../common/Dialog'

import * as restfulService from '../../services/restfulService'
import {get as getCep} from '@app/services/cepService'

import * as MESSAGES from '../../messages'
import helpers from '@mixins/helpers'
import bus from '@utils/bus'
import {mapGetters, mapActions} from 'vuex'

import {required, email} from 'vuelidate/lib/validators'
import {cloneDeep, debounce} from 'lodash'

const defaultFormData = {
  country_id: '',
  city_id: '',
  external_reference: '',
  name: '',
  nickname: '',
  cpf_cnpj: '',
  email: '',
  phone: '',
  birth_date: '',
  notes: '',
  contacts: [],
  address: {
    cep: '',
    street: '',
    number: '',
    neighborhood: '',
    complement: '',
    codigo_ibge: '',
    city_id: '',
    city: {
      id: '',
      state_id: '',
      name: '',
      name_with_state: '',
      state: {
        id: '',
        name: '',
        initials: '',
      }
    },
  }
}

export default {
  name: 'client-component',
  components: {GripoDialog},
  mixins: [helpers],
  props: {
    clientId: {
      default: ''
    },
    outline: {
      type: Boolean,
      default: true
    },
    validate: {
      type: Object,
      default: null
    },
    enableCreate: {
      type: Boolean,
      default: true
    },
    createWithEmptyData: {
      type: Boolean,
      default: false
    },
    onlyClients: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hideSearch: {
      type: Boolean,
      default: false
    },
    hideLoaderAfterSelect: {
      type: Boolean,
      default: true
    },
    searchData: {
      default: null
    },
    placeholder: {
      type: String,
      default: 'Pesquisar clientes...'
    },
    emptyListMessage: {
      type: String,
      default: 'Clientes não encontrados'
    },
    emptyTitle: {
      type: String,
      default: 'Busque pelo <strong>Nome/Celular/Email/CPF</strong>'
    },
    createNewClientText: {
      type: String,
      default: ' (CRIAR NOVO CLIENTE)'
    },
    selectFirstClientAfterSearch: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialogVisible: false,
      isSubmitted: false,
      isSearchingCity: false,
      isSearchingClient: false,
      isLoadingClient: false,
      clearAutocomplete: false,
      searchClientModel: null,
      searchClient: null,
      searchCity: '',
      phonePrefix: '+55',
      formEnabled: false,
      form: cloneDeep(defaultFormData),
      dependencies: {
        cities: [],
        clients: [],
        countries: []
      }
    }
  },
  validations: {
    form: {
      country_id: {required},
      name: {required},
      email: {email}
    }
  },
  computed: {
    ...mapGetters(['globalDependencies', 'tenant', 'hasCommercialReceiptModule', 'hasCommercialInvoiceModule']),
    validateModel() {
      if (this.validate && this.validate.$dirty && this.validate.$invalid) {
        return [MESSAGES.VALIDATION_REQUIRED]
      }
      return []
    },
    phoneMask() {
      const country = this.dependencies.countries.find(item => item.id === this.form.country_id)
      if (country) {
        switch (country.code) {
          case 'URY':
            return '########'

          case 'ARG':
            return '##########'

          case 'PRY':
            return '#########'
        }
      }
      return '## #####-####'
    }
  },
  watch: {
    searchClient: debounce(function (value) {
      if (!this.isLoadingClient && value && value !== this.searchClientModel && (this.searchClientModel === null || this.searchClientModel === undefined)) {
        this.dependencies.clients.splice(0)
        this.onSearchClient(value)
      }
    }, 300),
    searchClientModel: function (value) {
      if (this.validate) {
        this.validate.$touch()
      }
      if (!value) {
        this.updateClientRef(value)
      }
    },
    searchCity: debounce(function (value) {
      value && value !== this.form.city_id && this.onSearchCities(value)
    }, 300),
    async 'form.address.cep'(value, oldValue) {
      const cep = this.onlyNumbers(value)
      const cepOldValue = this.onlyNumbers(oldValue)
      if (value && cepOldValue.length === 7) {
        if (cep.length === 8) {
          const cepFound = await getCep(cep)
          if (cepFound) {
            this.$refs.formAddressNumber.focus()
            this.form.address.street = cepFound.logradouro
            this.form.address.neighborhood = cepFound.bairro
            this.form.address.complement = cepFound.complemento
            this.form.address.codigo_ibge = cepFound.ibge
          }
        } else {
          this.form.address.street = ''
          this.form.address.neighborhood = ''
          this.form.address.number = ''
          this.form.address.complement = ''
          this.form.address.codigo_ibge = ''
        }
      }
    },
  },
  async mounted() {
    await this.getDependencies()
  },
  methods: {
    ...mapActions(['setGlobalDependencies']),
    async loadClientDialog(client) {
      bus.$emit('show-loader')
      this.form = {...this.form, ...cloneDeep(client)}
      this.setDefaultCountry()
      bus.$emit('hide-loader')
      setTimeout(() => {
        this.dialogVisible = true
        this.formEnabled = true
      }, 100)
    },
    openDialog(clientName) {
      this.form = {...cloneDeep(defaultFormData)}
      if (clientName) {
        this.form.name = clientName
      }
      this.setDefaultCountry()
      this.formEnabled = true
      this.dialogVisible = true
    },
    setDefaultCountry() {
      if (!this.form.country_id) {
        const country = this.dependencies.countries.find(item => item.code === 'BRA')
        this.form.country_id = country.id
      }
    },
    closeDialog() {
      this.dialogVisible = false
    },
    getDependencies() {
      return new Promise(async (resolve) => {
        // eslint-disable-next-line no-case-declarations
        const dependencies = [...this.globalDependencies]
        // eslint-disable-next-line no-case-declarations
        let countryDependency = dependencies.find(item => item.name === 'countries')
        // eslint-disable-next-line no-case-declarations
        let countryDependencyIndex = dependencies.findIndex(item => item.name === 'countries')
        if (!countryDependency.loaded) {
          countryDependency.list = [...(await restfulService.search('country', null, null, ['*'], [], 1, 2000)).data]
          countryDependency.loaded = true
          dependencies.splice(countryDependencyIndex, 1, countryDependency)
          await this.setGlobalDependencies(dependencies)
        }
        this.dependencies.countries = [...countryDependency.list]
        resolve()
      })
    },
    async loadClient(client) {
      return new Promise(resolve => {
        this.isLoadingClient = true
        this.searchClientModel = client.id
        this.dependencies.clients.splice(0)
        const clientData = {...client}
        this.dependencies.clients.push(clientData)
        setTimeout(() => {
          this.isLoadingClient = false
          resolve()
        }, 500)
      })
    },
    reset() {
      this.resetClientAutocomplete()
      if (this.validate && typeof this.validate.$reset === 'function') {
        this.validate.$reset()
      }
      this.$v.form.$reset()
      this.$emit('update:validate', this.$v.form)
    },
    onSearchClient(query) {
      this.isSearchingClient = true
      let data = {query}
      if (this.onlyClients) {
        data.only_clients = true
      }
      if (this.searchData) {
        data = {
          ...data,
          ...this.searchData
        }
      }
      restfulService.post('client', 'autocomplete', null, data)
          .then(async (response) => {
            if (response !== undefined && response !== null && response.length > 0) {
              this.dependencies.clients = [...response]
            }
            if (this.enableCreate) {
              this.dependencies.clients.push({id: 'new_client', name: query, avatar: null})
            }
            if (response.length > 0 && this.selectFirstClientAfterSearch) {
              await this.updateClientRef(response[0].id)
            }
            this.isSearchingClient = false
          })
          .catch(e => {
            console.log(e)
          })
    },
    onSearchCities(query) {
      this.dependencies.cities.splice(0)
      if (query !== '') {
        this.isSearchingCity = true
        const country = this.dependencies.countries.find(item => item.id === this.form.country_id)
        const data = {country_code: country.code, query: query}
        restfulService.post('city', 'autocomplete', null, data)
            .then((response) => {
              this.dependencies.cities = [...response]
              this.isSearchingCity = false
            })
            .catch(e => {
              console.log(e)
            })
      }
    },
    changeCountry(id) {
      const country = this.dependencies.countries.find(item => item.id === id)
      this.phonePrefix = '+' + country.phone_prefix
    },
    async updateRenderAutocomplete() {
      return new Promise(resolve => {
        this.clearAutocomplete = true
        setTimeout(() => {
          this.clearAutocomplete = false
        }, 50)
        resolve()
      })
    },
    resetDataValues() {
      this.searchClientModel = null
      this.searchClient = null
      this.dependencies.clients.splice(0)
    },
    async resetClientAutocomplete() {
      return new Promise(resolve => {
        this.resetDataValues()
        this.updateRenderAutocomplete()
        resolve()
      })
    },
    async onSelectClient() {
      if (this.searchClient) {
        if (this.searchClientModel === 'new_client' && this.enableCreate) {
          if (!this.createWithEmptyData) {
            this.form.name = this.searchClient
            this.openDialog(this.searchClient)
          } else {
            bus.$emit('show-loader')
            this.$emit('selectClient', this.searchClient)
            bus.$emit('hide-loader')
            this.updateRenderAutocomplete()
          }
        } else {
          bus.$emit('show-loader')
          const client = this.dependencies.clients.find(item => item.id === this.searchClientModel)
          if (client.type === 'client') {
            await this.updateClientRef(client.id)
            if (this.hideLoaderAfterSelect) {
              bus.$emit('hide-loader')
            }
          } else if (client.type === 'user') {
            this.form.name = client.name
            this.form.cpf_cnpj = client.cpf
            this.form.phone = client.phone
            this.form.email = client.email
            this.form.country_id = client.country_id
            this.form.city_id = client.city_id
            await this.createClient()
          }
        }
      }
    },
    async storeClient() {
      return new Promise((resolve, reject) => {
        bus.$emit('show-loader')
        this.isSubmitted = true
        const data = cloneDeep(this.form)
        if (data.phone) {
          data.phone = this.onlyNumbers(data.phone)
        }
        if (data.cpf_cnpj) {
          data.cpf_cnpj = this.onlyNumbers(data.cpf_cnpj)
        }
        restfulService.post('client', 'save', null, data)
            .then(response => {
              this.isSubmitted = false
              resolve(response)
            })
            .catch(e => {
              bus.$emit('hide-loader')
              this.isSubmitted = false
              console.log(e)
              reject(e)
            })
      })
    },
    async createClient() {
      const newClient = await this.storeClient()
      if (newClient) {
        await this.loadClient(newClient)
        this.updateClientRef(newClient.id)
        if (this.hideLoaderAfterSelect) {
          bus.$emit('hide-loader')
        }
        this.closeDialog()
      }
    },
    async updateClientRef(clientId) {
      this.$emit('update:clientId', clientId)
      if (clientId && clientId > 0) {
        const client = await restfulService.get('client', null, clientId, null, [
          'address.city.state', 'city', 'country', 'players_class', 'user.country', 'user.city'
        ])
        this.$emit('selectClient', client)
        this.updateRenderAutocomplete()
      }
    }
  },
  beforeDestroy() {
    this.$off('selectClient')
  }
}
</script>

<template>
  <div>
    <div style="min-height: 45px;" v-show="!hideSearch">
      <template v-if="!clearAutocomplete">
        <v-autocomplete v-model="searchClientModel"
                        :search-input.sync="searchClient"
                        :items="dependencies.clients"
                        :error-messages="validateModel"
                        :placeholder="placeholder"
                        :no-data-text="emptyListMessage"
                        @focus="$emit('focus')"
                        @blur="$emit('blur')"
                        @change="onSelectClient"
                        @click:clear="resetDataValues"
                        color="blue-grey lighten-2"
                        item-text="name"
                        item-value="id"
                        :disabled="disabled"
                        :loading="isSearchingClient"
                        :outline="outline"
                        :hide-details="!validate || hideDetails"
                        hide-selected
                        clearable
                        single-line
                        no-filter>
          <template v-slot:no-data>
            <v-list-tile>
              <v-list-tile-title v-html="emptyTitle"/>
            </v-list-tile>
          </template>
          <template v-slot:item="{ item }">
            <v-list-tile-content>
              <v-list-tile-title>
                <div class="flex-c -jc-fs gap-5">
                  <div v-if="item.type === 'user'" class="mr-2" title="Importar Usuário">
                    <i class="fas fa-user-plus"></i>
                  </div>
                  <div>
                    <span>{{ item.name }}</span>
                    <span v-if="!!item.external_reference"> ({{ item.external_reference }})</span >
                  </div>
                  <div v-if="item.cpf"> - {{ item.cpf | cpfCnpj }}</div>
                  <div v-if="item.id === 'new_client'">{{ createNewClientText }}</div>
                </div>
              </v-list-tile-title>
              <v-list-tile-sub-title v-if="item.email || item.phone">
                <div class="flex-c -jc-fs gap-5">
                  <div v-if="item.phone">{{ item.phone | phone }}</div>
                  <div v-if="item.phone && item.email"> -</div>
                  <div v-if="item.email">{{ item.email }}</div>
                </div>
              </v-list-tile-sub-title>
            </v-list-tile-content>
          </template>
        </v-autocomplete>
      </template>
    </div>
    <gripo-dialog :model.sync="dialogVisible"
                  width="85vw"
                  top="3vh"
                  :on-close="closeDialog">
      <template v-slot:header>{{ form.id ? 'Alterar dados do cliente' : 'Criar novo cliente' }}</template>
      <template v-slot:content>
        <el-form ref="form"
                 :model="form"
                 label-position="top">
          <el-row :gutter="10">
            <el-col :span="hasCommercialInvoiceModule ? 12 : 24">
              <el-card class="el-card__save_form box-card">
                <div slot="header">
                  <span>Dados</span>
                </div>
                <el-form-item label="País"
                              class="is-required"
                              style="margin-bottom: 0;"
                              :class="{ 'el-form-item--error': $v.form.country_id.$error }">
                  <v-select v-model="form.country_id"
                            :items="dependencies.countries"
                            item-value="id"
                            item-text="name"
                            outline
                            single-line
                            :error-messages="validationMessageField($v.form.country_id)"
                            @input="$v.form.country_id.$touch()"
                            @blur="$v.form.country_id.$touch()"
                            :disabled="!formEnabled"
                            @change="changeCountry">
                    <template v-slot:item="data">
                      <v-list-tile-avatar>
                        <img :src="`/static/flags/${data.item.flag_icon}`" alt="">
                      </v-list-tile-avatar>
                      <v-list-tile-content>
                        <v-list-tile-title>
                          {{ data.item.name }}
                        </v-list-tile-title>
                      </v-list-tile-content>
                    </template>
                    <template v-slot:selection="{ item }">
                      <v-list-tile-avatar>
                        <img :src="`/static/flags/${item.flag_icon}`" alt="">
                      </v-list-tile-avatar>
                      <v-list-tile-content>
                        <v-list-tile-title>
                          {{ item.name }}
                        </v-list-tile-title>
                      </v-list-tile-content>
                    </template>
                  </v-select>
                  <div class="el-form-item__error" v-if="$v.form.country_id.$error">
                    Campo obrigatório
                  </div>
                </el-form-item>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="Nome Completo"
                                  class="is-required"
                                  :class="{ 'el-form-item--error': $v.form.name.$error }">
                      <el-input v-model="form.name"
                                :disabled="!formEnabled"/>
                    </el-form-item>
                    <div class="el-form-item__error" v-if="$v.form.name.$error">
                      Campo obrigatório
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="Apelido">
                      <el-input v-model="form.nickname"
                                :disabled="!formEnabled"/>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="Celular">
                      <el-input v-model="form.phone"
                                type="tel"
                                v-mask="phoneMask"
                                :disabled="!formEnabled">
                        <template v-slot:prepend>{{ phonePrefix }}</template>
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="CPF/CNPJ/CI/DNI">
                      <el-input v-model="form.cpf_cnpj"
                                :disabled="!formEnabled"/>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="Email"
                                  :class="{ 'el-form-item--error': $v.form.email.$error }">
                      <el-input v-model="form.email"
                                type="email"
                                :disabled="!formEnabled"
                                @input="$v.form.email.$touch()"/>
                      <div class="el-form-item__error" v-if="!$v.form.email">
                        Esse e-mail é inválido
                      </div>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="Data de Nascimento">
                      <el-date-picker
                          v-model="form.birth_date"
                          type="date"
                          format="dd/MM/yyyy"
                          value-format="yyyy-MM-dd"
                          v-mask="'##/##/####'"
                          :disabled="!formEnabled"
                          placeholder="Data de Nascimento">
                      </el-date-picker>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="Cidade">
                      <el-select v-model="form.city_id"
                                 clearable
                                 filterable
                                 remote
                                 :remote-method="onSearchCities"
                                 :loading="isSearchingCity"
                                 placeholder="Digite para buscar a cidade..."
                                 empty-text="Nenhum registro encontrado"
                                 class="el-select-full"
                                 :disabled="!formEnabled">
                        <el-option v-for="item in dependencies.cities"
                                   :key="item.id"
                                   :label="(item.name + (item.state ? ' - '+item.state.initials : '') + (item.country ? ' / '+item.country.code : ''))"
                                   :value="item.id">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="Código de Referência">
                      <el-input v-model="form.external_reference"/>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-form-item label="Observações">
                  <el-input type="textarea"
                            :rows="4"
                            :disabled="!formEnabled"
                            v-model="form.notes"/>
                </el-form-item>
              </el-card>
            </el-col>
            <el-col :span="12" v-if="hasCommercialInvoiceModule">
              <el-card class="el-card__save_form box-card">
                <div slot="header">
                  <span>Endereço (Obrigatório para geração de NFSe)</span>
                </div>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="CEP">
                      <el-input v-model="form.address.cep"
                                v-mask="'#####-###'"/>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="Endereço">
                      <el-input v-model="form.address.street"/>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="Número">
                      <el-input v-model="form.address.number" ref="formAddressNumber"/>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="Bairro">
                      <el-input v-model="form.address.neighborhood"/>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="Complemento">
                      <el-input v-model="form.address.complement"/>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="Código IBGE">
                      <el-input v-model="form.address.codigo_ibge"/>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-card>
            </el-col>
          </el-row>
        </el-form>
      </template>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <el-button type="success" @click="createClient()">
          <i class="fas fa-check"></i>
          <span>Salvar</span>
        </el-button>
      </template>
    </gripo-dialog>
  </div>
</template>