<script>
import List from './List'

export default {
  components: {List},
  name: 'entry-list-revenue',
  props: {
    filtersData: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>

<template>
  <div>
    <list entry-type="revenue" :filters-data="filtersData"/>
  </div>
</template>
