<script>
export default {
  name: 'plan-interval-label',
  props: {
    interval: {default: 1, type: Number},
    intervalType: {default: '', type: String}
  },
  computed: {
    text() {
      switch (this.intervalType) {
        case 'days':
          return `A cada ${this.interval} dia${this.interval > 1 ? ' (s)' : ''}`
        case 'months':
          return `A cada ${this.interval} mês${this.interval > 1 ? ' (s)' : ''}`
        case 'years':
          return `A cada ${this.interval} ano${this.interval > 1 ? ' (s)' : ''}`
        default:
          return ''
      }
    }
  },
}
</script>
<template>
  <el-tag size="small" class="el-tag--status" type="default">{{ text }}</el-tag>
</template>
