<script>
import ListMain from '../../../../components/list/Main'
import ListHeader from '../../../../components/list/Header'
import ListEditButton from '../../../../components/list/EditButton'
import ShowPdf from '../../../../components/common/ShowPdf'
import GripoDialog from '../../../../components/common/Dialog'
import ValidationMessage from '../../../../components/form/ValidationMessage'
import ClientName from '../../../../components/common/ClientName'

import * as restfulService from '../../../../services/restfulService'
import * as notifyService from '../../../../services/notifyService'
import DataService from '@app/services/dataService'

import {currencyConfig} from '@utils/money'
import helpers from '@mixins/helpers'
import bus from '@utils/bus'

import {mapGetters, mapActions} from 'vuex'
import {required} from 'vuelidate/lib/validators'
import {clone} from 'lodash'

export default {
  components: {ListMain, ListHeader, ListEditButton, ShowPdf, GripoDialog, ValidationMessage, ClientName},
  mixins: [helpers],
  data() {
    return {
      isLoadingData: true,
      isSubmitted: false,
      pdfBase64String: '',
      filters: {
        status: 'active',
        financial: 'all',
        name: '',
        birth_date: '',
        plan_id: '',
        is_coach: 'all',
      },
      isModalUpdateCreditVisible: false,
      updateCredit: {
        client_id: null,
        client_name: '',
        amount: 0,
        notes: ''
      },
      dependencies: {
        plans: []
      }
    }
  },
  validations: {
    updateCredit: {
      client_id: {required},
      amount: {required}
    }
  },
  async mounted() {
    await this.getDependencies()
    bus.$emit('list-init', {
      domain: 'client/custom-search',
      searchRoute: false
    }, () => {
      bus.$emit('hide-loader')
      this.isLoadingData = false
    })
  },
  computed: {
    ...mapGetters(['listStore', 'roles', 'permissions', 'tenant', 'isCoach']),
    moneyMask() {
      return currencyConfig()
    },
    canDestroy() {
      return this.permissions.some((item) => item.name === this.domain + '.destroy')
    },
  },
  methods: {
    ...mapActions(['setListSort']),
    getDependencies() {
      return new Promise(resolve => {
        DataService.get([
          {domain: 'plan'},
        ]).then((result) => {
          this.dependencies = {...this.dependencies, ...result}
          resolve()
        })
      })
    },
    getFilterData() {
      const searchData = {}
      if (this.filters.name) {
        searchData.name = this.filters.name
      }
      if (this.filters.birth_month) {
        searchData.birth_month = this.filters.birth_month
      }
      if (this.filters.financial !== 'all') {
        searchData.financial = this.filters.financial
      }
      if (this.filters.status) {
        searchData.status = this.filters.status
      }
      if (this.filters.plan_id) {
        searchData.plan_id = this.filters.plan_id
      }
      if (this.filters.is_coach !== 'all') {
        searchData.is_coach = this.filters.is_coach === 'yes'
      }
      return searchData
    },
    onFilter() {
      const searchData = this.getFilterData()
      bus.$emit('list-filter', searchData)
    },
    onFilterClean() {
      this.filters = {
        status: 'active',
        financial: 'all',
        plan_id: '',
        is_coach: 'all',
      }
      this.setListSort({})
      bus.$emit('list-filter-clean', this.filters)
    },
    onDestroy(item) {
      restfulService.destroy('client', 'tenant', item.id)
          .then(() => {
            this.onFilter()
          })
          .catch(e => {
            bus.$emit('hide-loader')
            console.log(e)
          })
    },
    onExportPDF() {
      bus.$emit('show-loader')
      const searchData = this.getFilterData()
      restfulService.post('report/client', 'export-search', null, searchData)
          .then(response => {
            this.pdfBase64String = response.file
            bus.$emit('hide-loader')
          })
          .catch(e => {
            bus.$emit('hide-loader')
            console.log(e)
          })
    },
    showContacts(contacts) {
      return contacts ? contacts.filter(item => item.type === 'phone').map(item => item.value).join(' - ') : []
    },
    dateEnToBr(date) {
      return this.dateFormat(date, 'br')
    },
    toggleUpdateCredit() {
      this.isModalUpdateCreditVisible = !this.isModalUpdateCreditVisible
    },
    openUpdateCredit(item) {
      this.updateCredit.client_id = item.id
      this.updateCredit.client_name = item.name
      this.updateCredit.notes = ''
      this.toggleUpdateCredit()
    },
    onSaveUpdateCredit() {
      bus.$emit('show-loader')
      this.isSubmitted = true
      const data = clone(this.updateCredit)
      data.amount = this.currencyParsed(data.amount)
      restfulService.put('client', 'update-credit', data.client_id, data)
          .then(() => {
            notifyService.success({hideLoader: true})
            this.isSubmitted = false
            this.onFilter()
            this.toggleUpdateCredit()
          })
          .catch(e => {
            this.isSubmitted = false
            bus.$emit('hide-loader')
            console.log(e)
          })
    }
  }
}
</script>

<template>
  <div>

    <show-pdf :base64="pdfBase64String"/>

    <ListHeader title="Clientes" route-name="client.store">
      <router-link :to="{ name: 'client.merge'}"
                   v-if="!isCoach"
                   class="el-button el-button--warning">
        <i class="fas fa-object-ungroup"></i>
        <span>Mesclar Cadastros</span>
      </router-link>
    </ListHeader>

    <ListMain>

      <div slot="filters">

        <el-card class="el-card__search_list box-card">
          <div slot="header">
            <span>Filtros</span>
            <div class="responsive-buttons -list-filter">
              <el-button type="primary" @click="onFilter" size="small">
                <i class="fas fa-filter"></i>
                <span>Aplicar Filtros</span>
              </el-button>
              <el-button type="default" @click="onFilterClean" size="small">
                <i class="fas fa-times"></i>
                <span>Limpar</span>
              </el-button>
              <el-button type="info" @click="onExportPDF" size="small" v-if="!isCoach">
                <i class="fas fa-file-pdf"></i>
                <span>Exportar para PDF</span>
              </el-button>
            </div>
          </div>
          <el-form :inline="true" :model="filters">
            <el-form-item label="Nome/CPF/Email/Telefone">
              <el-input v-model="filters.name"
                        placeholder="Filtrar por Nome/CPF/Email/Telefone"
                        @keydown.enter.native.prevent="onFilter"/>
            </el-form-item>
            <el-form-item label="Mês de Nascimento/Abertura">
              <el-date-picker
                  v-model="filters.birth_month"
                  type="month"
                  format="MMMM"
                  value-format="MM"
                  v-mask="'##'"
                  placeholder="Mês"
                  @keydown.enter.native.prevent="onFilter">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="Status Financeiro">
              <el-select v-model="filters.financial"
                         filterable
                         placeholder="Filtrar por Status Financeiro"
                         empty-text="Nenhum registro encontrado">
                <el-option value="all" label="Não filtrar"></el-option>
                <el-option value="credit" label="Com Saldo"></el-option>
                <el-option value="debit" label="Com Débitos"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Plano/Pacote">
              <el-select v-model="filters.plan_id"
                         clearable
                         filterable
                         placeholder="Selecione um plano/pacote"
                         empty-text="Nenhum registro encontrado"
                         class="el-select-full">
                <el-option v-for="item in dependencies.plans"
                           :key="item.id"
                           :label="item.name"
                           :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Cadastro Ativo">
              <el-switch v-model="filters.status"
                         active-value="active"
                         inactive-value="inactive"
                         active-text="SIM"
                         inactive-text="NÃO">
              </el-switch>
            </el-form-item>
            <el-form-item label="Professor">
              <el-select v-model="filters.is_coach"
                         filterable
                         placeholder="Filtrar por Professor"
                         empty-text="Nenhum registro encontrado">
                <el-option value="all" label="Não filtrar"></el-option>
                <el-option value="yes" label="Sim"></el-option>
                <el-option value="no" label="Não"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-card>

      </div>

      <div slot="list" v-if="!isLoadingData">

        <table class="custom-table-list">
          <thead>
          <tr>
            <th class="list-table-nowrap">ID</th>
            <th>Nome</th>
            <th>CPF/CNPJ</th>
            <th>Telefone</th>
            <th>E-mail</th>
            <th>Data de Nascimento/Abertura</th>
            <th>Saldo</th>
            <th>Débitos</th>
            <th class="btn-actions">Ações</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in listStore.listData" :key="index">
            <td>{{ item.id }}</td>
            <td>
              <client-name :client="item" :show-nickname="false"/>
              <span v-if="item.nickname"> ({{ item.nickname }})</span>
            </td>
            <td>{{ item.cpf_cnpj || '' }}</td>
            <td>{{ item.phone }}</td>
            <td>{{ item.email }}</td>
            <td>{{ item.birth_date ? dateEnToBr(item.birth_date) : '' }}</td>
            <td>
              <span :class="['currency-amount', {'-balance': item.balance > 0}]">
                {{ item.balance | currencyFormatter }}
              </span>
            </td>
            <td>
              <span :class="['currency-amount', {'-debit': item.debit > 0}]">
                {{ item.debit | currencyFormatter }}
              </span>
            </td>
            <td class="list-table-nowrap">
              <div class="btn-actions">
                <el-button type="warning"
                           @click="openUpdateCredit(item)"
                           v-if="!isCoach"
                           title="Atualizar Saldo">
                  <i class="fas fa-money-bill"></i>
                </el-button>
                <ListEditButton route-name="client.update"
                                :item="item"/>
                <el-button type="danger"
                           v-if="canDestroy"
                           @click="onDestroy(item)"
                           title="Remover Item">
                  <i class="fas fa-trash"></i>
                </el-button>
              </div>
            </td>
          </tr>
          </tbody>
        </table>

      </div>

    </ListMain>

    <gripo-dialog :model.sync="isModalUpdateCreditVisible"
                  width="60vw">
      <template slot="header">
        Atualizar Saldo - Client: {{ updateCredit.client_name }}
      </template>
      <template slot="content">
        <el-form ref="form"
                 :model="updateCredit"
                 label-position="top">
          <el-form-item label="Valor a ser removido"
                        class="is-required"
                        :class="{ 'el-form-item--error': $v.updateCredit.amount.$error }">
            <el-input v-model.lazy="updateCredit.amount"
                      v-money="moneyMask"
                      @input="$v.updateCredit.amount.$touch()"/>
            <validation-message v-if="$v.updateCredit.amount.$error"/>
          </el-form-item>
          <el-form-item label="Observações">
            <el-input type="textarea"
                      :rows="3"
                      v-model="updateCredit.notes"/>
          </el-form-item>
        </el-form>
      </template>
      <template slot="actions">
        <v-spacer></v-spacer>
        <el-button type="primary"
                   @click="onSaveUpdateCredit"
                   :disabled="isSubmitted || $v.updateCredit.$invalid">Salvar Alterações
        </el-button>
      </template>
    </gripo-dialog>
  </div>
</template>
