import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const i18n = new VueI18n(
    {
        locale: 'pt_BR',
        fallbackLocale: 'pt_BR',
        numberFormats: {
            'pt-BR': {
                currency: {
                    style: 'currency',
                    currency: 'BRL'
                }
            },
            'es-AR': {
                currency: {
                    style: 'currency',
                    currency: 'ARS'
                }
            },
            'es-UY': {
                currency: {
                    style: 'currency',
                    currency: 'UYU'
                }
            },
            'es-PY': {
                currency: {
                    style: 'currency',
                    currency: 'PYG'
                }
            }
        }
    }
)

export default i18n
