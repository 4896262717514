<script>
import FormSaveButton from '@components/form/SaveButton'
import ValidationMessage from '@components/form/ValidationMessage'
import RegistrationStatus from './components/Status'
import RegistrationPayment from './components/Payment'

import * as restfulService from '../../../../../../services/restfulService'
import * as notifyService from '../../../../../../services/notifyService'
import DataService from '../../../../../../services/dataService'

import helpers from '@mixins/helpers'
import bus from '@utils/bus'

import {required} from 'vuelidate/lib/validators'
import {debounce} from 'lodash'
import {currencyConfig} from '@utils/money'
import {mapGetters} from "vuex";
import ListHeader from "../../../../../../components/list/Header.vue";

export default {
  components: {ListHeader, FormSaveButton, ValidationMessage, RegistrationStatus, RegistrationPayment},
  mixins: [helpers],
  props: ['registrationId', 'isFullPage'],
  data() {
    return {
      isSubmitted: false,
      isLoadingData: true,
      isLoadingItem: false,
      isSearchingCity: false,
      searchCities: '',
      updateInputMoney: false,
      form: {
        stage: {
          name_with_circuit: '',
          settings: {
            registration_with_payment: false,
            payment_unique: false,
          }
        },
        player_class: {
          name_with_sex: ''
        },
        stage_id: '',
        city_id: '',
        player_class_id: '',
        player_id: '',
        player: {
          name: ''
        },
        status: '',
        points: 0,
        amount: 0,
        data: {
          name: null,
          shirt_type: null,
          shirt_size: null,
          city_id: null,
          cpf: null,
          email: null,
          phone: null,
          sex: null,
          player_number: 1
        },
        transaction: {}
      },
      dependencies: {
        cities: [],
        players_class: [],
        clients: [],
        shirt_type: [],
        shirt_size: [],
        registration_status: []
      }
    }
  },
  validations: {
    form: {
      points: {required},
      status: {required}
    }
  },
  computed: {
    ...mapGetters(['tenant']),
    moneyMask() {
      return currencyConfig()
    }
  },
  watch: {
    searchCities: debounce(function (value) {
      value && value !== this.form.city_id && this.onSearchCity(value)
    }, 300),
  },
  async mounted() {
    await this.getDependencies()
    this.isLoadingItem = true
    this.updateInputMoney = true
    const id = this.registrationId || this.$route.params.id
    restfulService.get('registration', null, id, null, ['stage.players_class.player_class', 'player.user', 'player_class', 'city', 'transaction'])
        .then(response => {
          this.dependencies.players_class = [...response.stage.players_class]
          if (response.data === null) {
            response.data = {...this.form.data, ...{shirt_type: '', shirt_size: ''}}
          }
          if (!response.data.name) {
            response.data.name = response.player.name
          }
          if (response.city) {
            this.dependencies.cities.push(response.city)
          }
          if (response.amount > 0) {
            response.amount = this.currencyFormatter(response.amount)
          }
          this.form = {...this.form, ...response}
          this.isLoadingData = false
          this.updateInputMoney = false
          setTimeout(() => {
            this.isLoadingItem = false
          }, 600)
          bus.$emit('hide-loader')
        })
        .catch(e => {
          console.log(e)
          this.isLoadingData = false
          bus.$emit('hide-loader')
        })
  },
  methods: {
    getDependencies() {
      return new Promise(resolve => {
        DataService.get([{domain: 'shirt_type'}, {domain: 'shirt_size'}, {domain: 'registration_status'}]).then((result) => {
          this.dependencies = {...this.dependencies, ...result}
          resolve()
        })
      })
    },
    onSearchCity(query) {
      return new Promise((resolve, reject) => {
        this.dependencies.cities.splice(0)
        if (query !== '') {
          this.isSearchingCity = true
          let data = {query}
          restfulService.post('city', 'autocomplete', null, data)
              .then((response) => {
                this.dependencies.cities.splice(0)
                this.dependencies.cities = [...this.dependencies.cities, ...response]
                this.isSearchingCity = false
                resolve()
              })
              .catch(e => {
                console.log(e)
                reject()
              })
        }
      })
    },
    onSend() {
      bus.$emit('show-loader')
      this.isSubmitted = true
      const data = {
        id: this.form.id,
        city_id: this.form.city_id,
        points: this.form.points,
        amount: this.form.amount,
        data: this.form.data
      }
      data.amount = this.currencyParsed(data.amount)
      restfulService.save('registration', null, null, data)
          .then(() => {
            notifyService.success()
            this.isSubmitted = false
            if(!this.isFullPage) {
              this.$emit('on-close')
              this.$emit('save-registration')
            } else {
              this.$router.push({name: 'stage.show', params: {id: this.form.stage_id}})
            }
          })
          .catch(e => {
            this.isSubmitted = false
            bus.$emit('hide-loader')
            console.log(e)
          })
    }
  }
}
</script>

<template>
  <div>
    <ListHeader title="Dados da Inscrição" v-if="isFullPage"/>
    <div :class="[isFullPage ? 'el-card el-card__save_form box-card is-always-shadow' : '']">
      <div :class="[isFullPage ? 'el-card__body' : '']">

        <el-form ref="form"
                 :model="form"
                 label-position="top">
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="Torneio">
                <div class="mt-2"><strong>{{ form.stage.name_with_circuit }}</strong></div>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="Categoria">
                <div class="mt-2"><strong>{{ form.player_class.name_with_sex }}</strong></div>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="Status">
                <registration-status size="small"
                                     :show-actions="false"
                                     :status="form.status"
                                     :registration="form"/>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="Pagamento"
                            v-if="!!form.transaction">
                <registration-payment size="small"
                                      :show-actions="false"
                                      :transaction="form.transaction"
                                      :registration="form"
                                      :player-number="form.data?.player_number"
                                      :payment-enabled="form.stage.settings.registration_with_payment"
                                      :payment-unique="form.stage.settings.payment_unique"/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="Nome">
                <el-input v-model="form.data.name"/>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="Cidade">
                <v-autocomplete v-model="form.city_id"
                                :items="dependencies.cities"
                                :loading="isSearchingCity"
                                :search-input.sync="searchCities"
                                ref="form.city_id"
                                no-data-text="Nenhum registro encontrado"
                                color="blue-grey lighten-2"
                                label="Selecione uma cidade"
                                item-text="name_with_state"
                                item-value="id"
                                single-line
                                no-filter
                                clearable
                                outline
                                hide-details
                                hide-selected
                                class="v-autocomplete-custom">
                  <template slot="item" slot-scope="data">
                    <v-list-tile-content>
                      <v-list-tile-title>
                        {{ data.item.name_with_state }}
                      </v-list-tile-title>
                    </v-list-tile-content>
                  </template>
                  <template slot="selection" slot-scope="data">
                    <v-list-tile-content>
                      <v-list-tile-title>
                        {{ data.item.name_with_state }}
                      </v-list-tile-title>
                    </v-list-tile-content>
                  </template>
                </v-autocomplete>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="Valor">
                <el-input v-model.lazy="form.amount"
                          v-if="!updateInputMoney"
                          v-money="moneyMask"/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="CPF">
                <el-input v-model="form.data.cpf"/>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="Email">
                <el-input v-model="form.data.email"/>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="Telefone">
                <el-input v-model="form.data.phone"/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="Tipo de Camiseta">
                <el-select v-model="form.data.shirt_type"
                           placeholder="Selecione uma opção"
                           empty-text="Nenhum registro encontrado"
                           class="el-select-full">
                  <el-option
                      v-for="item in dependencies.shirt_type"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="Tamanho da Camiseta">
                <el-select v-model="form.data.shirt_size"
                           placeholder="Selecione uma opção"
                           empty-text="Nenhum registro encontrado"
                           class="el-select-full">
                  <el-option
                      v-for="item in dependencies.shirt_size"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="Pontos"
                            class="is-required"
                            :class="{ 'el-form-item--error': $v.form.points.$error }">
                <el-input v-model="form.points"
                          @input="$v.form.points.$touch()"/>
                <validation-message v-if="$v.form.points.$error"/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item class="mb-0">
            <form-save-button :event-click="onSend"
                              :is-disabled="isSubmitted || $v.form.$invalid"
                              :is-submitted="isSubmitted">
            </form-save-button>
            <el-button type="default" @click="$emit('on-close')">
              <i class="fas fa-arrow-left"></i>
              <span>Voltar</span>
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
