import {routes as productCategory} from './product_category'
import {routes as entryClassification} from './entry_classification'
import {routes as entryPaymentMethod} from './entry_payment_method'
import {routes as product} from './product'
import {routes as stock} from './stock'
import {routes as supplier} from './supplier'
import {routes as purchase} from './purchase'
import {routes as entry} from './entry'
import {routes as report} from './report'
import {routes as invoice} from './invoice'
import {routes as transaction} from './transaction'
import {routes as bankAccount} from './bank_account'
import {routes as deviceOrder} from './device_order'

export default [
    ...productCategory,
    ...entryClassification,
    ...entryPaymentMethod,
    ...product,
    ...stock,
    ...supplier,
    ...purchase,
    ...entry,
    ...report,
    ...invoice,
    ...transaction,
    ...bankAccount,
    ...deviceOrder,
]
