<script>
export default {
  name: 'gripo-dialog',
  props: {
    model: null,
    title: {
      type: String,
      default: ''
    },
    top: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: '90vw'
    },
    maxWidth: {
      default: null
    },
    hideHeader: {
      type: Boolean,
      default: false
    },
    showTopCloseBtn: {
      type: Boolean,
      default: true
    },
    actionsCloseBtn: {
      type: Boolean,
      default: true
    },
    persistent: {
      type: Boolean,
      default: true
    },
    big: {
      type: Boolean,
      default: false
    },
    customDialogClass: {
      type: String,
      default: ''
    },
    contentClass: {
      type: Array,
      default: () => {
        return []
      }
    },
    contentCardClass: {
      type: String,
      default: ''
    },
    onClose: {
      type: Function,
      required: false
    }
  },
  data() {
    return {
      showDialog: false,
      modelDialog: false
    }
  },
  computed: {
    getContentClass() {
      const defaultClass = ['gripo-dialog']
      if (this.top && !this.$vuetify.breakpoint.smAndDown) {
        defaultClass.push('-dialog-top')
      }
      if (!this.model) {
        defaultClass.push('-is-hidden')
      }
      if (this.big) {
        defaultClass.push('v-dialog--big')
      }
      if (this.customDialogClass) {
        defaultClass.push(this.customDialogClass)
      }
      return [...defaultClass, ...this.contentClass].join(' ')
    },
    getStyle() {
      if (this.top && this.$vuetify.breakpoint.smAndUp) {
        return {
          top: `${this.top} !important`
        }
      }
      return {}
    },
    hasActionsSlot() {
      return (!!this.$slots['actions']) || this.actionsCloseBtn
    }
  },
  watch: {
    model: {
      immediate: true,
      handler: function (value) {
        if (this.showDialog) {
          this.showDialog = false
        }
        this.modelDialog = value
        setTimeout(() => {
          this.showDialog = value
        }, 150)
      }
    }
  },
  methods: {
    handleModalVisible(clicked) {
      if (clicked) {
        this.modelDialog = !this.modelDialog
      }
      this.showDialog = this.modelDialog
      this.$emit('update:model', this.modelDialog)
      if (this.onClose && typeof this.onClose === 'function' && !this.modelDialog) {
        this.onClose()
      }
    }
  }
}
</script>

<template>
  <v-dialog v-model="modelDialog"
            attach="#app"
            :persistent="persistent"
            @input="v => v || handleModalVisible()"
            @keydown.esc="handleModalVisible(true)"
            :content-class="getContentClass"
            :fullscreen="$vuetify.breakpoint.smAndDown"
            :style="getStyle"
            :width="width"
            :max-width="maxWidth">
    <v-card :class="contentCardClass">
      <v-card-title class="subheading grey lighten-2" v-if="!hideHeader">
        <div class="flex-c">
          <slot name="header">
            <span>{{ title }}</span>
          </slot>
          <v-btn title="Fechar" icon dark color="grey darken-1"
                 v-if="showTopCloseBtn"
                 @click="handleModalVisible(true)">
            <i class="fas fa-times"></i>
          </v-btn>
        </div>
      </v-card-title>
      <v-card-text>
        <div v-if="showDialog">
          <slot name="content"></slot>
        </div>
      </v-card-text>
      <div class="dialog-actions" v-if="hasActionsSlot">
        <v-divider></v-divider>
        <v-card-actions>
          <el-button v-if="actionsCloseBtn"
                     @click="handleModalVisible(true)">
            <i class="fas fa-times"></i>
            <span>Fechar Janela</span>
          </el-button>
          <slot name="actions"></slot>
        </v-card-actions>
      </div>
    </v-card>
  </v-dialog>
</template>

<style lang="scss">
.gripo-dialog {
  position: absolute;
  top: 2vh;
  margin: 0 !important;

  &.v-dialog--big {
    top: 1vh !important;
    width: 99vw !important;
    height: 98vh !important;
    max-height: none !important;
  }

  &.v-dialog--fullscreen {
    top: 0 !important;
  }

  > .v-card {
    > .v-card__title {
      padding: 0 16px;
      box-shadow: 0 1px 5px 1px rgba(0, 0, 0, .1);
    }

    > .v-card__text {
      min-height: 100px;
    }

    > .v-card__title,
    > .dialog-actions {
      position: sticky;
      top: 0;
      z-index: 999;

      .v-card__actions {
        gap: 10px;
        flex-wrap: wrap;
      }
    }

    > .dialog-actions {
      bottom: 0;
      background-color: #FFF;
    }
  }

}
</style>