import Vue from 'vue'
import bus from '@utils/bus'

import menu from '../app/menu'

const getMenuInfo = (route) => {
    let menuItem = null
    menu.map(module => {
        let menuFound = module.children.find(item => {
            return item.route.name.replace('.index', '').includes(route.name.replace('.index', ''))
        })
        if (menuFound) {
            menuItem = menuFound
        } else {
            menuFound = module.children.find(item => {
                const [childrenRoundName] = item.route.name.split('.')
                const [roundName] = route.name.split('.')
                return childrenRoundName === roundName
            })
            if (menuFound) {
                menuItem = menuFound
            }
        }
    })
    return menuItem
}

export default (to, from) => {
    if (to.name === from.name) {
        bus.$emit('hide-loader')
    }
    Vue.nextTick(() => {
        if (typeof document !== 'undefined') {
            const menuItem = getMenuInfo(to)
            if (menuItem) {
                document.title = `Gripo - ${menuItem.text}`
            } else {
                document.title = 'Gripo'
            }
        }
    })
}
