<script>
export default {
  name: 'schedule-invite-status',
  props: ['status']
}
</script>

<template>
  <div class="d-inline-block">
    <el-tag size="small" class="el-tag--status" type="default" v-if="status === 'created'">
      Enviado
    </el-tag>
    <el-tag size="small" class="el-tag--status" type="success" v-if="status === 'accepted'">
      Aceito
    </el-tag>
    <el-tag size="small" class="el-tag--status" type="success" v-if="status === 'confirmed'">
      Confirmado
    </el-tag>
    <el-tag size="small" class="el-tag--status" type="danger" v-if="status === 'denied'">
      Negado
    </el-tag>
    <el-tag size="small" class="el-tag--status" type="danger" v-if="status === 'canceled'">
      Cancelado
    </el-tag>
  </div>
</template>
