<script>
export default {
  name: 'entry-status-circle',
  props: ['item']
}
</script>

<template>
  <div>
    <div class="legend-entry"
         v-if="item.status === 'created' && !item.overdue"
         title="Aberta">
      <span class="label label-default">&nbsp;</span>
    </div>
    <div class="legend-entry"
         v-if="item.overdue"
         title="Em Atraso">
      <span class="label label-danger">&nbsp;</span>
    </div>
    <div class="legend-entry"
         v-if="item.status === 'settled' && !item.received_late"
         title="Quitada">
      <span class="label label-success">&nbsp;</span>
    </div>
    <div class="legend-entry"
         v-if="item.status === 'settled' && item.received_late"
         title="Quitada com Atraso">
      <span class="label label-warning">&nbsp;</span>
    </div>
  </div>
</template>
