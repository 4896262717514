<script>
import ListHeader from '../../../../components/list/Header'
import FormSaveButton from '../../../../components/form/SaveButton'
import FormCancelButton from '../../../../components/form/CancelButton'
import ValidationMessage from '../../../../components/form/ValidationMessage'
import ClientComponent from '../../../../components/common/ClientComponent'
import ChargingNotify from './ChargingNotify'

import * as restfulService from '../../../../services/restfulService'
import * as notifyService from '../../../../services/notifyService'
import DataService from '../../../../services/dataService'

import {currencyConfig} from '@utils/money'
import helpers, {currencyNotZero} from '@mixins/helpers'
import bus from '@utils/bus'

import {required, requiredIf} from 'vuelidate/lib/validators'
import {cloneDeep} from 'lodash'
import moment from 'moment'
import {mapGetters} from 'vuex'

import {defaultChargingRules} from './ChargingNotify.vue'

const defaultInvoiceData = {
  nfce: false,
  nfce_include_cpf: false,
  nfce_send_notification: false,
  nfse: false,
  nfse_include_cpf: false,
  nfse_send_notification: false,
}

export default {
  name: 'form-entry',
  components: {ListHeader, FormSaveButton, FormCancelButton, ValidationMessage, ClientComponent, ChargingNotify},
  mixins: [helpers],
  props: {
    entryType: {
      type: String,
      default: 'revenue'
    }
  },
  data() {
    return {
      isEdit: false,
      pageLoaded: false,
      isSubmitted: false,
      isLoadingData: true,
      isSearchingProduct: false,
      isResettingAmount: false,
      isResettingPortions: false,
      hasPortions: false,
      addProducts: false,
      addProductId: '',
      selectedProducts: [],
      form: {
        type: 'revenue',
        classification_id: '',
        payment_method_id: '',
        bank_account_id: '',
        client_id: '',
        supplier_id: '',
        status: 'created',
        portions_number: 1,
        portions: [],
        amount: 0,
        discount: 0,
        due_date: this.dateNow('en'),
        interval: 1,
        interval_type: 'months',
        recurrent: false,
        generate_balance: false,
        is_paid: false,
        pay_date: '',
        charging_rules: {...defaultChargingRules}
      },
      dependencies: {
        classifications: [],
        payment_methods: [],
        clients: [],
        suppliers: [],
        products: [],
        bank_accounts: [],
        portions: []
      },
      generateInvoiceData: {...defaultInvoiceData}
    }
  },
  validations: {
    form: {
      classification_id: {required},
      client_id: {
        required: requiredIf(function () {
          return this.isRevenue
        })
      },
      supplier_id: {
        required: requiredIf(function () {
          return !this.isRevenue
        })
      },
      amount: {required, currencyNotZero},
      due_date: {required},
      pay_date: {
        required: requiredIf(function () {
          return this.form.is_paid
        })
      },
      payment_method_id: {
        required: requiredIf(function () {
          return this.form.is_paid
        })
      }
    }
  },
  async mounted() {
    await this.getDependencies()
    this.form.type = this.entryType

    this.generateInvoiceData.nfce = this.canGenerateReceipt
    this.generateInvoiceData.nfse = this.canGenerateInvoice

    const id = this.$route.params.id || null
    if (id) {
      this.isEdit = true
      restfulService.get(`entry/${this.entryType}`, null, id, null, ['client', 'supplier', 'portions'])
          .then(response => {
            if (this.isRevenue) {
              this.$refs.clientComponentClient.loadClient(response.client)
            }
            this.form = {...response}
            bus.$emit('hide-loader')
            this.isLoadingData = false
            this.pageLoaded = true
          })
          .catch(e => {
            console.log(e)
            bus.$emit('hide-loader')
            this.isLoadingData = false
            this.pageLoaded = true
          })
    } else {
      bus.$emit('hide-loader')
      this.isLoadingData = false
      this.pageLoaded = true
    }
  },
  computed: {
    ...mapGetters(['tenant', 'hasCommercialReceiptModule', 'hasCommercialInvoiceModule']),
    moneyMask() {
      return currencyConfig()
    },
    checkPortions() {
      return [this.form.portions_number, this.form.due_date, this.form.amount, this.form.discount, Date.now()].join()
    },
    isRevenue() {
      return this.entryType === 'revenue'
    },
    canGenerateReceipt() {
      return this.hasCommercialReceiptModule
          && this.selectedProducts.some(item => item.type === 'product' && item.nf_enabled)
    },
    canGenerateInvoice() {
      return this.hasCommercialInvoiceModule
          && (this.selectedProducts.some(item => item.type === 'service' && item.nf_enabled) || this.form.description)
    },
    dependenciesClassificationsFiltered() {
      return this.dependencies.classifications
    },
    dependenciesPaymentMethodsFiltered() {
      return this.dependencies.payment_methods.filter(item => item.key !== 'saldo' && item.key !== 'cupom')
    },
    classificationCredit() {
      if (this.form.classification_id) {
        const classification = this.dependencies.classifications.find(item => item.id === this.form.classification_id)
        return classification?.key === 'credito'
      }
      return false
    }
  },
  watch: {
    checkPortions() {
      this.calculatePortions()
    },
    hasPortions(value) {
      this.form.portions_number = value ? 2 : 1
    },
    'form.recurrent': function (value) {
      if (value) {
        this.form.is_paid = false
        this.form.portions_number = 1
        this.hasPortions = false
      }
    },
    'form.classification_id': function () {
      this.form.generate_balance = this.classificationCredit
    },
    'form.is_paid': function (newValue) {
      if (!newValue) {
        this.form.generate_balance = this.classificationCredit
      } else {
        this.form.recurrent = false
      }
      if (newValue && this.form.pay_date.length === 0) {
        this.form.pay_date = this.dateNow('en')
      } else {
        this.form.pay_date = ''
      }
    },
    addProducts: function (value) {
      if (!value) {
        this.selectedProducts.splice(0)
      }
    },
    selectedProducts: {
      handler: function (value) {
        const total = value.reduce((total, item) => total += (item.quantity * item.price) - this.currencyParsed(item?.discount || 0), 0)
        this.isResettingAmount = true
        this.form.amount = this.currencyFormatter(total)
        setTimeout(() => {
          this.isResettingAmount = false
        }, 50)
      },
      deep: true
    }
  },
  methods: {
    getDependencies() {
      return new Promise(resolve => {
        const dependencies = [{domain: 'portion'}, {domain: 'payment_method'}, {domain: `portion/${this.entryType}`}, {domain: 'interval_type'}, {domain: 'bank_account'}, {
          domain: 'product',
          data: {active: true}
        }]
        if (this.isRevenue) {
          dependencies.push({domain: 'classification_revenue'})
        } else {
          dependencies.push({domain: 'supplier'})
          dependencies.push({domain: 'classification_expense'})
        }
        DataService.get(dependencies).then((result) => {
          this.dependencies = {...this.dependencies, ...result}
          resolve()
        })
      })
    },
    calculatePortions() {
      let dueDate = this.form.due_date
      let amount = this.currencyParsed(this.form.amount) - this.currencyParsed(this.form.discount)
      let portionsNumber = this.form.portions_number
      if (dueDate.length > 0 && amount > 0 && portionsNumber > 0) {
        let amountPortion = this.roundLower(amount / portionsNumber, 2)
        let amountFirstPortion = (Math.round(((amount - (amountPortion * portionsNumber)) * 100)) / 100) + amountPortion
        let intervalType = this.form.interval_type
        let intervalValue = this.form.interval
        let dateMoment = moment(dueDate, 'YYYY-MM-DD').subtract(intervalValue, intervalType)
        this.resetPortions()
        this.form.portions.splice(0)
        for (let i = 1; i <= portionsNumber; i++) {
          this.form.portions.push({
            portion: i,
            description: portionsNumber > 1 ? 'Parcela ' + i + ' de ' + portionsNumber : null,
            amount: this.currencyFormatter(i === 1 ? amountFirstPortion : amountPortion),
            due_date: dateMoment.add(intervalValue, intervalType).format('YYYY-MM-DD')
          })
        }
      }
    },
    resetPortions() {
      this.isResettingPortions = true
      this.form.portions = []
      setTimeout(() => {
        this.isResettingPortions = false
      }, 100)
    },
    onSelectProduct(value) {
      if (value) {
        const productList = this.dependencies.products.find(item => item.id === value)
        const product = {
          quantity: 1,
          ...productList
        }
        if (!this.selectedProducts.some(item => item.id === product.id)) {
          this.selectedProducts.push(product)
        }
        this.addProductId = ''
      }
    },
    onRemoveProduct(product) {
      this.selectedProducts = this.selectedProducts.filter(item => item.id !== product.id)
    },
    onSend() {
      bus.$emit('show-loader')
      this.isSubmitted = true
      const data = cloneDeep(this.form)
      if (!this.hasPortions) {
        data.portions_number = 1
      }
      data.amount = this.currencyParsed(data.amount)
      data.discount = this.currencyParsed(data.discount)
      data.portions.forEach((item) => {
        item.amount = this.currencyParsed(item.amount)
      })
      if (this.isRevenue) {
        delete data.supplier_id
      } else {
        delete data.client_id
        if(!(typeof data.supplier_id === 'number')) {
          data.supplier_name = data.supplier_id
          data.supplier_id = null
        }
      }
      data.products = [...this.selectedProducts.map(item => {
        return {
          id: item.id,
          quantity: item.quantity,
          price: item.price,
          discount: this.currencyParsed(item.discount)
        }
      })]
      data.generate_invoice = {...this.generateInvoiceData}
      if (!data.is_paid) {
        data.generate_invoice.nfce = false
        data.generate_invoice.nfse = false
      }
      if (!data.charging_rules.enabled) {
        data.charging_rules = null
      }
      restfulService.save(`entry/${this.entryType}`, null, null, data)
          .then(() => {
            notifyService.success({hideLoader: true})
            bus.$emit('hide-loader')
            this.isSubmitted = false
            this.$router.push({name: `entry.${this.entryType}.index`})
          })
          .catch(e => {
            bus.$emit('hide-loader')
            this.isSubmitted = false
            console.log(e)
          })
    },
    selectPaymentMethod(paymentMethod) {
      this.form.bank_account_id = ''
      if (paymentMethod) {
        const bankAccount = this.dependencies.bank_accounts.find(item => item.default_payment_method_id === paymentMethod)
        if (bankAccount) {
          this.form.bank_account_id = bankAccount.id
        }
      }
    },
    productFinalPrice(product) {
      return ((product.price * product.quantity) - this.currencyParsed(product.discount))
    }
  }
}
</script>

<template>
  <div>
    <ListHeader title="Lançamentos"/>
    <el-form ref="form"
             :model="form"
             label-position="top">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-card class="el-card__save_form box-card">
            <div slot="header">
              <span>Dados do Lançamento</span>
            </div>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="Classificação"
                              class="is-required mb-0"
                              :class="{ 'el-form-item--error': $v.form.classification_id.$error }">
                  <el-select v-model="form.classification_id"
                             filterable
                             clearable
                             placeholder="Selecione uma classificação"
                             empty-text="Nenhum registro encontrado"
                             class="el-select-full"
                             @input="$v.form.classification_id.$touch()">
                    <el-option
                        v-for="item in dependenciesClassificationsFiltered"
                        :key="item.id"
                        :label="item.description"
                        :value="item.id">
                    </el-option>
                  </el-select>
                  <validation-message v-if="$v.form.classification_id.$error"/>
                </el-form-item>
              </el-col>
              <el-col :span="12" v-if="isRevenue">
                <el-form-item label="Cliente"
                              class="is-required mb-0"
                              :class="{ 'el-form-item--error': $v.form.client_id.$error }">
                  <client-component :client-id.sync="form.client_id"
                                    ref="clientComponentClient"
                                    :validate="$v.form.client_id"/>
                </el-form-item>
              </el-col>
              <el-col :span="12" v-else>
                <el-form-item label="Fornecedor"
                              class="is-required"
                              :class="{ 'el-form-item--error': $v.form.supplier_id.$error }">
                  <el-select v-model="form.supplier_id"
                             filterable
                             clearable
                             allow-create
                             placeholder="Selecione um Fornecedor"
                             empty-text="Nenhum registro encontrado"
                             class="el-select-full"
                             @input="$v.form.supplier_id.$touch()">
                    <el-option
                        v-for="item in dependencies.suppliers"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                  </el-select>
                  <validation-message v-if="$v.form.supplier_id.$error"/>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="8">
                <el-form-item label="Vencimento"
                              class="is-required"
                              :class="{ 'el-form-item--error': $v.form.due_date.$error }">
                  <el-date-picker
                      v-model="form.due_date"
                      type="date"
                      format="dd/MM/yyyy"
                      value-format="yyyy-MM-dd"
                      v-mask="'##/##/####'"
                      placeholder="Vencimento"
                      @input="$v.form.due_date.$touch()">
                  </el-date-picker>
                  <validation-message v-if="$v.form.due_date.$error"/>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Valor"
                              class="is-required"
                              :class="{ 'el-form-item--error': $v.form.amount.$error && pageLoaded }">
                  <el-input v-model.lazy="form.amount"
                            v-money="moneyMask"
                            :readonly="addProducts"
                            v-if="!isResettingAmount"
                            @input="$v.form.amount.$touch()"/>
                  <validation-message v-if="$v.form.amount.$error"/>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Desconto">
                  <el-input v-model.lazy="form.discount"
                            v-money="moneyMask"
                            :readonly="addProducts"
                            v-if="!isResettingAmount"/>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="6">
                <el-form-item label="Recorrente">
                  <el-switch
                      v-model="form.recurrent"
                      :disabled="form.is_paid"
                      active-color="#13ce66"
                      active-text="SIM"
                      inactive-color="#ff4949"
                      inactive-text="NÃO">
                  </el-switch>
                </el-form-item>
              </el-col>
              <el-col :span="8" :class="{'disabled': !form.recurrent}">
                <el-form-item label="Intervalo"
                              class="is-required">
                  <el-input-number v-model="form.interval"
                                   :disabled="!form.recurrent"
                                   :step="1"
                                   :min="1">
                  </el-input-number>
                </el-form-item>
              </el-col>
              <el-col :span="10" :class="{'disabled': !form.recurrent}">
                <el-form-item label="Tipo"
                              class="is-required">
                  <el-select v-model="form.interval_type"
                             filterable
                             :disabled="!form.recurrent"
                             empty-text="Nenhum registro encontrado"
                             class="el-select-full">
                    <el-option
                        v-for="item in dependencies.interval_types"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <template v-if="form.recurrent && isRevenue">
              <charging-notify :charging-rules-data.sync="form.charging_rules"/>
            </template>
            <el-row :gutter="20" v-else>
              <el-col :span="8">
                <el-form-item label="Pago ?">
                  <el-switch
                      v-model="form.is_paid"
                      active-color="#13ce66"
                      active-text="SIM"
                      inactive-color="#ff4949"
                      inactive-text="NÃO">
                  </el-switch>
                </el-form-item>
              </el-col>
              <el-col :span="8" v-if="isRevenue && form.is_paid">
                <el-form-item label="Gerar Saldo para o Cliente">
                  <el-switch
                      v-model="form.generate_balance"
                      :disabled="selectedProducts.length > 0"
                      active-color="#13ce66"
                      active-text="SIM"
                      inactive-color="#ff4949"
                      inactive-text="NÃO">
                  </el-switch>
                </el-form-item>
              </el-col>
              <el-col :span="8" v-if="form.is_paid">
                <el-form-item label="Data de Pagamento"
                              :class="{ 'el-form-item--error': $v.form.pay_date.$error, 'is-required': form.is_paid }">
                  <el-date-picker
                      v-model="form.pay_date"
                      type="date"
                      format="dd/MM/yyyy"
                      value-format="yyyy-MM-dd"
                      v-mask="'##/##/####'"
                      placeholder="Data de Pagamento"
                      :disabled="!form.is_paid"
                      @input="$v.form.pay_date.$touch()">
                  </el-date-picker>
                  <validation-message v-if="$v.form.pay_date.$error"/>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20" v-if="form.is_paid">
              <el-col :span="12">
                <el-form-item label="Forma de Pagamento"
                              class="is-required"
                              :class="{ 'el-form-item--error': $v.form.payment_method_id.$error }">
                  <el-select v-model="form.payment_method_id"
                             filterable
                             clearable
                             placeholder="Selecione uma forma de pagamento"
                             empty-text="Nenhum registro encontrado"
                             class="el-select-full"
                             @change="selectPaymentMethod"
                             @input="$v.form.payment_method_id.$touch()">
                    <el-option
                        v-for="item in dependenciesPaymentMethodsFiltered"
                        :key="item.id"
                        :label="item.description"
                        :value="item.id">
                    </el-option>
                  </el-select>
                  <validation-message v-if="$v.form.payment_method_id.$error"/>
                </el-form-item>
              </el-col>
              <el-col :span="12" v-if="isRevenue">
                <el-form-item label="Conta Bancária">
                  <el-select v-model="form.bank_account_id"
                             filterable
                             clearable
                             placeholder="Selecione uma conta bancária"
                             empty-text="Nenhum registro encontrado"
                             class="el-select-full">
                    <el-option
                        v-for="item in dependencies.bank_accounts"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item label="Descrição">
              <el-input type="textarea"
                        :rows="4"
                        v-model="form.description"/>
            </el-form-item>
          </el-card>
        </el-col>
        <el-col :span="12">
          <el-card class="el-card__save_form box-card" v-if="isRevenue">
            <div slot="header">
              <span>Adicionar Produtos</span>
              <el-switch v-model="addProducts"
                         :active-value="true"
                         :inactive-value="false"
                         active-text="SIM"
                         inactive-text="NÃO">
              </el-switch>
            </div>
            <template v-if="addProducts">
              <el-form-item>
                <el-select v-model="addProductId"
                           @change="onSelectProduct"
                           filterable
                           placeholder="Selecione um produto para adicionar"
                           empty-text="Nenhum registro encontrado"
                           class="el-select-full">
                  <el-option v-for="item in dependencies.products"
                             :key="item.id"
                             :value="item.id">
                    <span>{{ item.price | currencyFormatter }} | {{ item.description }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
              <h4>Produtos Selecionados</h4>
              <table class="custom-table-list">
                <thead>
                <tr>
                  <th>Descrição</th>
                  <th width="130">Quantidade</th>
                  <th>Desconto</th>
                  <th width="90" align="right">Valor Unit.</th>
                  <th width="90" align="right">Valor Total</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(product, index) in selectedProducts" :key="index">
                  <td>
                    <el-button type="danger"
                               size="mini"
                               title="Remover produto"
                               circle
                               class="el-button--ultra-small"
                               @click="onRemoveProduct(product)">
                      <i class="fas fa-times"></i>
                    </el-button>
                    {{ product.description }}
                  </td>
                  <td>
                    <el-input-number v-model="product.quantity"
                                     size="mini"
                                     :step="1"
                                     :min="1">
                    </el-input-number>
                  </td>
                  <td>
                    <el-input size="mini" v-model.lazy="product.discount"
                              v-money="moneyMask"/>
                  </td>
                  <td align="right">{{ product.price | currencyFormatter }}</td>
                  <td align="right">{{ productFinalPrice(product) | currencyFormatter }}</td>
                </tr>
                </tbody>
              </table>
            </template>
          </el-card>
          <el-card class="el-card__save_form box-card">
            <div slot="header">
              <span>Parcelar Lançamento</span>
              <el-switch v-model="hasPortions"
                         :active-value="true"
                         :inactive-value="false"
                         :disabled="form.recurrent"
                         active-text="SIM"
                         inactive-text="NÃO">
              </el-switch>
            </div>
            <template v-if="hasPortions">
              <el-form-item label="Selecione o nº de parcelas">
                <el-select v-model="form.portions_number"
                           filterable
                           empty-text="Nenhum registro encontrado"
                           class="el-select-full"
                           :disabled="form.recurrent">
                  <el-option
                      v-for="item in dependencies.portions"
                      :key="item.id"
                      :label="item.value"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <table class="custom-table-list" v-if="!isResettingPortions">
                <thead>
                <tr>
                  <th width="70">Parcela</th>
                  <th>Descrição</th>
                  <th>Valor</th>
                  <th width="140">Data de Vencimento</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in form.portions" :key="index">
                  <td>
                    <el-input v-model="item.portion" :readonly="true" size="small"/>
                  </td>
                  <td>
                    <el-input v-model="item.description" :maxlength="200" size="small"/>
                  </td>
                  <td>
                    <el-input v-model.lazy="item.amount"
                              size="small"
                              v-money="moneyMask"/>
                  </td>
                  <td>
                    <el-date-picker
                        v-model="item.due_date"
                        type="date"
                        size="small"
                        format="dd/MM/yyyy"
                        value-format="yyyy-MM-dd"
                        v-mask="'##/##/####'"
                        placeholder="Vencimento"/>
                  </td>
                </tr>
                </tbody>
              </table>
            </template>
          </el-card>
          <el-card class="el-card__save_form box-card" v-if="isRevenue && form.is_paid">
            <div slot="header">
              <span>Gerar Nota Fiscal</span>
            </div>
            <template>
              <el-row :gutter="20">
                <el-col :span="12">
                  <div :class="{'disabled': !canGenerateReceipt}">
                    <h3 class="mb-1">Nota Fiscal de Produto (NFCe)</h3>
                    <el-switch v-model="generateInvoiceData.nfce"
                               :active-value="true"
                               :inactive-value="false"
                               active-text="SIM"
                               inactive-text="NÃO"/>
                    <h3 class="mt-4 mb-1">Incluir CPF/CNPJ</h3>
                    <el-switch v-model="generateInvoiceData.nfce_include_cpf"
                               :active-value="true"
                               :inactive-value="false"
                               active-text="SIM"
                               inactive-text="NÃO"/>
                    <h3 class="mt-4 mb-1">Enviar Nota por Email/Whatsapp</h3>
                    <el-switch v-model="generateInvoiceData.nfce_send_notification"
                               :active-value="true"
                               :inactive-value="false"
                               active-text="SIM"
                               inactive-text="NÃO"/>
                  </div>
                  <el-alert type="warning" class="mt-3 small-alert text-center"
                            :closable="false"
                            v-if="!canGenerateReceipt">
                    <div class="fs-14">
                      <strong>Geração de NFCe desabilitada</strong>, verifique se seu clube possui esse módulo ativo
                      ou se existem produtos habilitados para geração incluídos nesse lançamento
                    </div>
                  </el-alert>
                </el-col>
                <el-col :span="12">
                  <div :class="{'disabled': !canGenerateInvoice}">
                    <h3 class="mb-1">Nota Fiscal de Serviço (NFSe)</h3>
                    <el-switch v-model="generateInvoiceData.nfse"
                               :active-value="true"
                               :inactive-value="false"
                               active-text="SIM"
                               inactive-text="NÃO"/>
                    <h3 class="mt-4 mb-1">Incluir CPF/CNPJ</h3>
                    <el-switch v-model="generateInvoiceData.nfse_include_cpf"
                               :active-value="true"
                               :inactive-value="false"
                               active-text="SIM"
                               inactive-text="NÃO"/>
                    <h3 class="mt-4 mb-1">Enviar Nota por Email/Whatsapp</h3>
                    <el-switch v-model="generateInvoiceData.nfse_send_notification"
                               :active-value="true"
                               :inactive-value="false"
                               active-text="SIM"
                               inactive-text="NÃO"/>
                  </div>
                  <el-alert type="warning" class="mt-3 small-alert text-center"
                            :closable="false"
                            v-if="!canGenerateInvoice">
                    <div class="fs-14">
                      <strong>Geração de NFSe desabilitada</strong>, verifique se seu clube possui esse módulo ativo
                      ou se existem serviços habilitados para geração incluídos nesse lançamento
                    </div>
                  </el-alert>
                </el-col>
              </el-row>
            </template>
          </el-card>
        </el-col>
      </el-row>

      <el-card class="el-card__save_form box-card el-card__save_form-submit">
        <form-save-button :event-click="onSend"
                          :is-disabled="isSubmitted || $v.$invalid"
                          :is-submitted="isSubmitted">
        </form-save-button>
        <form-cancel-button :route-name="`entry.${entryType}.index`"/>
      </el-card>
    </el-form>
  </div>
</template>
