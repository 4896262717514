<script>
export default {
  name: 'form-save-button',
  props: {
    type: {
      type: String,
      default: 'success'
    },
    size: {
      type: String,
      default: 'default'
    },
    icon: {
      type: String,
      default: 'fas fa-check'
    },
    eventClick: {
      type: Function
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isSubmitted: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: ''
    },
    submittingText: {
      type: String,
      default: ''
    }
  },
  computed: {
    textLabel() {
      if (this.text && !this.isSubmitted) {
        return this.text
      }
      if (this.submittingText && this.isSubmitted) {
        return this.submittingText
      }
      return `${this.isSubmitted ? 'Salvando' : 'Salvar'} Alterações`
    }
  },
  methods: {
    onSend() {
      this.eventClick()
    }
  }
}
</script>

<template>
  <el-button :type="type"
             :size="size"
             @click="onSend"
             :disabled="isDisabled"
             :loading="isSubmitted">
    <i :class="icon"></i>
    <span>{{ textLabel }}</span>
  </el-button>
</template>
