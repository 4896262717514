<script>
import ListMain from '../../../../components/list/Main'
import ListHeader from '../../../../components/list/Header'
import ListEditButton from '../../../../components/list/EditButton'
import ListDeleteButton from '../../../../components/list/DeleteButton'

import helpers from '@mixins/helpers'
import bus from '@utils/bus'
import {mapGetters} from 'vuex'

import {isEmpty} from 'lodash'

export default {
  components: {ListMain, ListHeader, ListEditButton, ListDeleteButton},
  mixins: [helpers],
  data() {
    return {
      isLoadingData: true,
      filters: {},
      dependencies: {}
    }
  },
  mounted() {
    bus.$emit('list-init', {
      domain: 'purchase',
      relations: ['supplier'],
      processResponse: this.processListResponse
    }, () => {
      bus.$emit('hide-loader')
      this.isLoadingData = false
    })
  },
  computed: {
    ...mapGetters(['listStore'])
  },
  methods: {
    processListResponse(list) {
      list.forEach((item) => {
        item.amount = this.currencyFormatter(item.amount)
      })
      return list
    },
    onFilter() {
      const searchData = {}
      if (!isEmpty(this.filters.name)) {
        searchData.name = {
          'like': '%' + this.filters.name + '%'
        }
      }
      bus.$emit('list-filter', searchData)
    },
    onFilterClean() {
      this.filters = {}
      bus.$emit('list-filter-clean', this.filters)
    },
    onDestroy(item) {
      bus.$emit('list-destroy-item', item)
    }
  }
}
</script>

<template>
  <div>

    <ListHeader title="Compras" route-name="purchase.store"/>

    <ListMain>

      <div slot="filters">

        <el-card class="el-card__search_list box-card">
          <div slot="header">
            <span>Filtros</span>
            <div>
              <el-button type="primary" @click="onFilter" size="small">
                <i class="fas fa-filter"></i>
                <span>Aplicar Filtros</span>
              </el-button>
              <el-button type="default" @click="onFilterClean" size="small">
                <i class="fas fa-times"></i>
                <span>Limpar</span>
              </el-button>
            </div>
          </div>
          <el-form :inline="true" :model="filters">
            <el-form-item label="Nome">
              <el-input v-model="filters.name"
                        placeholder="Filtrar por nome"
                        @keydown.enter.native.prevent="onFilter"/>
            </el-form-item>
          </el-form>
        </el-card>

      </div>

      <div slot="list" v-if="!isLoadingData">

        <table class="custom-table-list">
          <thead>
          <tr>
            <th class="list-table-nowrap">ID</th>
            <th>Fornecedor</th>
            <th>Data da Compra</th>
            <th>Valor Total</th>
            <th class="btn-actions">Ações</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in listStore.listData" :key="index">
            <td>{{ item.id }}</td>
            <td>{{ item.supplier.name }}</td>
            <td>{{ item.created_at | dateEnToBr }}</td>
            <td>{{ item.amount }}</td>
            <td class="list-table-nowrap">
              <div class="btn-actions">
                <ListEditButton route-name="purchase.update" :item="item"/>
                <ListDeleteButton v-if="false" :item="item" domain="purchase"/>
              </div>
            </td>
          </tr>
          </tbody>
        </table>

      </div>

    </ListMain>
  </div>
</template>
