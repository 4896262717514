<script>
import SplitProduct from './Product'

import * as restfulService from '../../../../../services/restfulService'

import bus from '@utils/bus'
import helpers from '@mixins/helpers'

export default {
  components: {SplitProduct},
  mixins: [helpers],
  name: 'order-products-list',
  props: {
    orders: {
      type: Array
    },
    order: {
      type: Object
    }
  },
  data() {
    return {
      isSubmitted: false,
      isChangingQuantity: false,
      localOrderId: null,
      listOrderProducts: [],
      listOrderProductsSplitted: [],
      currentSplitOrderProductId: null
    }
  },
  watch: {
    'order.id': {
      immediate: true,
      deep: true,
      handler: function (value) {
        if (!this.localOrderId && value) {
          this.localOrderId = value
          this.listOrderProducts.splice(0)
          this.listOrderProductsSplitted.splice(0)
          setTimeout(() => {
            this.listOrderProducts = [...this.order.products.filter(item => !item.split && !item.payment)]
            const splittedProducts = [...this.order.products.filter(item => item.split && !item.payment)]
            this.listOrderProductsSplitted = [...splittedProducts]
          }, 50)
        }
      }
    }
  },
  methods: {
    updateProductsSplit(value, orderProduct) {
      bus.$emit('show-loader')
      let listOrderProducts = [...this.listOrderProducts]
      let listOrderProductsSplitted = [...this.listOrderProductsSplitted]
      orderProduct.select_clients = true
      if (value) {
        listOrderProductsSplitted.push(orderProduct)
        listOrderProducts = listOrderProducts.filter(item => item.id !== orderProduct.id)
        bus.$emit('hide-loader')
      } else {
        listOrderProducts.push(orderProduct)
        listOrderProductsSplitted = listOrderProductsSplitted.filter(item => item.id !== orderProduct.id)
        const data = {
          order_product_id: orderProduct.id,
          split: false,
          orders: []
        }
        restfulService.post('order', 'products-orders', null, data)
            .then((response) => {
              this.$emit('update-orders', response)
              bus.$emit('hide-loader')
            })
            .catch(e => {
              console.log(e)
              bus.$emit('hide-loader')
            })
      }
      setTimeout(() => {
        this.listOrderProducts = [...listOrderProducts]
        this.listOrderProductsSplitted = [...listOrderProductsSplitted]
      }, 50)
    },
    saveChangeQuantity(value, product) {
      this.isChangingQuantity = true
      bus.$emit('show-loader')
      let data = {
        id: product.order_id,
        products: [{
          product_id: product.product_id,
          quantity: value,
        }]
      }
      restfulService.post('order', 'products', null, data)
          .then((response) => {
            this.$emit('update-orders', response)
            bus.$emit('hide-loader')
            this.isChangingQuantity = false
          })
          .catch(e => {
            bus.$emit('hide-loader')
            console.log(e)
          })
    },
    updateOrders(orders) {
      this.$emit('update-orders', orders)
    }
  }
}
</script>

<template>
  <div>
    <template v-if="listOrderProducts.length > 0">
      <h3>Produtos Simples</h3>
      <el-row :gutter="10">
        <el-col v-for="(product, index) in listOrderProducts"
                :span="6"
                :xs="24"
                :key="index">
          <div class="product-item" v-if="product.product">
            <strong class="product-category-name">
              {{ product.product.description }} /
              {{ (product.quantity * product.price) | currencyFormatter }}
            </strong>
            <div :title="product.schedule.description"
                 class="gripo-tag mb-1"
                 v-if="product.schedule">
              {{ product.schedule.description }}
            </div>
            <el-form-item class="mb-1">
              <el-input-number v-model="product.quantity"
                               class="-bigger"
                               :precision="product.product.unit === 'UN' ? 0 : 3"
                               :step="product.product.unit === 'UN' ? 1 : 0.001"
                               :min="0.001"
                               :disabled="isChangingQuantity"
                               @change="saveChangeQuantity($event, product)">
              </el-input-number>
            </el-form-item>
            <el-form-item v-if="!product.schedule" label="Dividir produto?">
              <el-switch v-model="product.split"
                         @change="updateProductsSplit($event, product)"
                         active-text="Sim"
                         inactive-text="Não">
              </el-switch>
            </el-form-item>
          </div>
        </el-col>
      </el-row>
    </template>
    <el-divider v-if="listOrderProducts.length > 0"/>
    <template v-if="listOrderProductsSplitted.length > 0">
      <h3>Produtos Dividos</h3>
      <el-row :gutter="10">
        <el-col v-for="(orderProduct, index) in listOrderProductsSplitted" :span="24"
                :key="index">
          <split-product :order-product="{...orderProduct}"
                         :orders="[...orders]"
                         @update-products-split="updateProductsSplit"
                         @update-orders="updateOrders"/>
        </el-col>
      </el-row>
    </template>
  </div>
</template>
