import {routes as common} from './domains/common'
import {routes as financial} from './domains/financial'
import {routes as management} from './domains/management'
import {routes as system} from './domains/system'
import {routes as tournament} from './domains/tournament'

export default [
    ...common,
    ...financial,
    ...management,
    ...system,
    ...tournament
]
