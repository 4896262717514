<script>
import VueApexCharts from 'vue-apexcharts'
import * as restfulService from '../../../../services/restfulService'
import helpers from '@mixins/helpers'
import ptBr from 'apexcharts/dist/locales/pt-br.json'
import DatepickerPeriod from './DatepickerPeriod.vue'

export default {
  name: 'dashboard-payment-method',
  components: {
    apexchart: VueApexCharts,
    DatepickerPeriod
  },
  mixins: [helpers],
  props: ['windowHeight', 'windowWidth'],
  data() {
    return {
      loading: true,
      period: [],
      series: [],
      chartOptions: {
        chart: {
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          },
          type: 'area',
          defaultLocale: 'pt-br',
          locales: [ptBr],
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          type: 'datetime',
          categories: []
        },
        yaxis: {
          labels: {
            formatter: (value) => value.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'}),
          },
        },
        tooltip: {
          x: {
            format: 'dd/MM/yyyy'
          },
        },
      },
    }
  },
  computed: {
    apexHeight() {
      if (this.windowWidth < 1024) {
        return 300
      }
      return (this.windowHeight - 280) / 2
    }
  },
  watch: {
    period() {
      this.loadData()
    }
  },
  methods: {
    async loadData() {
      this.loading = true
      const post = {initialDate: this.period[0], finalDate: this.period[1]}
      const data = await restfulService.post('dashboard', 'entries-by-payment-method', null, post)
      const {categories, series} = data

      this.series = series
      this.chartOptions = {
        ...this.chartOptions, ...{
          xaxis: {
            categories: [...categories]
          }
        }
      }
      setTimeout(() => {
        this.loading = false
      }, 500)
    }
  }
}
</script>
<template>
  <el-card class="dashboard-card" v-loading="loading"
           element-loading-text="Carregando dados...">
    <template slot="header">
      <div class="header-title">Faturamento por Forma de Pagamento</div>
      <datepicker-period :period.sync="period"></datepicker-period>
    </template>
    <div id="chart">
      <apexchart type="area" :height="apexHeight" :options="chartOptions" :series="series"></apexchart>
    </div>
  </el-card>
</template>
