<script>
import SchedulePaymentStatusLabel from './labels/SchedulePaymentStatus'
import TransactionStatusLabel from './labels/TransactionStatus'

import * as restfulService from '../../../../services/restfulService'
import * as notifyService from '../../../../services/notifyService'

import helpers from '@mixins/helpers'
import bus from '@utils/bus'
import {confirmDialog} from '@utils/flash'

export default {
  name: 'schedule-payment',
  components: {TransactionStatusLabel, SchedulePaymentStatusLabel},
  mixins: [helpers],
  props: ['schedule'],
  data() {
    return {
      isSubmitted: false
    }
  },
  methods: {
    async approvePayment() {
      const {value: confirm} = await confirmDialog({
        title: 'Deseja realmente aprovar o pagamento?',
      })
      if (confirm) {
        bus.$emit('show-loader')
        this.isSubmitted = true
        restfulService.put('schedule', 'approve-payment', this.schedule.id, {})
            .then(() => {
              notifyService.success({message: 'Pagamento aprovado com sucesso.'})
              this.$emit('update-schedule')
              this.isSubmitted = false
            })
            .catch(e => {
              bus.$emit('hide-loader')
              this.isSubmitted = false
              console.log(e)
            })
      }
    }
  }
}
</script>

<template>
  <div class="schedule-box-content mb-3">
    <h4 class="mb-1">
      <div class="flex-c">
        <div>
          <i class="fas fa-dollar-sign"></i>
          <span class="ml-2">Pagamento Online</span>
        </div>
        <schedule-payment-status-label :status="schedule.status" :paid="schedule.paid"/>
      </div>
    </h4>
    <v-divider class="mb-3"/>
    <h4 class="mb-1">Histórico de Transações</h4>
    <table class="schedule-payment-transactions">
      <thead>
      <tr>
        <th>Status</th>
        <th>Valor</th>
        <th>Criado em</th>
        <th>Pago em</th>
      </tr>
      </thead>
      <tbody>
      <template v-for="(item) in schedule.transactions">
        <tr :key="item.id">
          <td align="center">
            <div>
              <transaction-status-label :status="item.status"/>
            </div>
            <div>
              <strong v-if="item.manual_approval" :title="`${item.user.name} - ${item.user.login}`">
                Aprovação Manual
              </strong>
            </div>
          </td>
          <td>{{item.amount | currencyFormatter}}</td>
          <td>{{item.created_at | dateTimeEnToBrFull}}h</td>
          <td>
                        <span v-if="item.pay_date">
                            {{item.pay_date | dateTimeEnToBrFull}}h
                        </span>
          </td>
        </tr>
      </template>
      </tbody>
    </table>
    <v-alert
        v-if="schedule.status === 'created'"
        :value="true"
        color="primary"
        outline
        class="mt-2">
      <div class="flex-c">
        <div class="flex-c -jc-fs">
          <div class="fs-18 mr-2">
            <i class="fas fa-exclamation-circle"></i>
          </div>
          <div class="text-center">
            <div class="subheading">
              Aprovar manualmente o pagamento
            </div>
          </div>
        </div>
        <div>
          <el-button type="primary"
                     @click="approvePayment"
                     :disabled="isSubmitted"
                     :loading="isSubmitted">
            <i class="fas fa-check"></i>
            <span>Aprovar Pagamento</span>
          </el-button>
        </div>
      </div>
    </v-alert>
  </div>
</template>

<style lang="scss">
.schedule-payment-transactions {
  border-collapse: collapse;
  width: 100%;

  th, td {
    padding: 2px 4px;
  }

  th {
    font-weight: bold;
    font-size: 11px;
  }

  td {
    font-size: 13px;
    padding: 4px;
    border-top: 2px solid #DDD;
  }
}
</style>