<script>
export default {
  name: 'form-cancel-button',
  props: ['routeName']
}
</script>

<template>
  <router-link :to="{name: routeName}"
               class="el-button el-button--default">
    <i class="fas fa-arrow-left"></i>
    <span>Voltar</span>
  </router-link>
</template>
