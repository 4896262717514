<script>
export default {
  name: 'validation-message',
  props: {
    message: {default: 'Esse campo é obrigatório', type: String},
    type: {default: 'required'}
  },
  computed: {
    validationMessage() {
      switch (this.type) {
        case 'required':
          return 'Esse campo é obrigatório'
        case 'email':
          return 'Esse email é inválido'

        default:
          return this.message
      }
    }
  }
}
</script>

<template>
  <div class="el-form-item__error">{{validationMessage}}</div>
</template>
