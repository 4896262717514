<script>
import * as authService from '../../../../services/authService'

import {default as helpers} from '@mixins/helpers'
import bus from '@utils/bus'
import {getUserUniqueId} from '@utils/fingerprint'
import {mapGetters} from 'vuex'

import {required} from 'vuelidate/lib/validators'
import {clone} from 'lodash'
import {loginRedirect} from '../service'

export default {
  mixins: [helpers],
  data() {
    return {
      isSubmitted: false,
      showPassword: false,
      form: {
        hash: '',
        email: '',
        name: '',
        password: '',
        password_confirmation: '',
      }
    }
  },
  validations: {
    form: {
      email: {required},
      name: {required},
      password: {required},
      password_confirmation: {required},
    }
  },
  computed: {
    ...mapGetters(['isLogged', 'hasBasicPlan', 'hasProfessionalPlan'])
  },
  async mounted() {
    this.form.email = this.$route.params.email
    this.form.hash = this.$route.params.hash
    setTimeout(() => {
      bus.$emit('hide-loader')
      this.$refs.name.focus()
    }, 100)
  },
  methods: {
    async onRegister() {
      bus.$emit('show-loader')
      this.isSubmitted = true
      const data = clone(this.form)
      data.device = await getUserUniqueId()
      authService.register(data)
          .then(async (response) => {
            const {
              routeName,
              routeParams
            } = await loginRedirect(response, this.$router, this.hasBasicPlan, this.hasProfessionalPlan)
            this.isSubmitted = false
            setTimeout(() => {
              this.$router.push({name: routeName, params: routeParams})
            }, 400)
          })
          .catch(e => {
            bus.$emit('hide-loader')
            this.form.tenant_id = ''
            this.isSubmitted = false
            console.log(e)
          })
    }
  }
}
</script>

<template>
  <div class="login-box" v-if="!isLogged">

    <div class="login-card-box">
      <div class="app-header">
        <h2>Complete seu Cadastro</h2>
      </div>

      <el-card class="el-card__save_form box-card">
        <el-form ref="form"
                 :model="form"
                 label-position="top"
                 @submit.prevent="onRegister">
          <el-form-item label="Email">
            <el-input v-model="form.email"
                      readonly
                      size="large"/>
          </el-form-item>
          <el-form-item label="Nome"
                        class="is-required"
                        :class="{ 'el-form-item--error': $v.form.name.$error }">
            <el-input v-model="form.name"
                      size="large"
                      ref="name"
                      @input="$v.form.name.$touch()"
                      @keydown.enter.native.prevent="onRegister"/>
            <div class="el-form-item__error" v-if="$v.form.name.$error">Esse campo é obrigatório</div>
          </el-form-item>
          <el-form-item label="Senha">
            <el-input v-model="form.password"
                      ref="password"
                      :type="showPassword ? 'text' : 'password'"
                      autocomplete="off"
                      @keydown.enter.native.prevent="onRegister">
              <div class="el-input--suffix-box" slot="suffix" @click="showPassword = !showPassword">
                <span v-if="showPassword" key="passwordVisibility"><i class="fas fa-eye"></i></span>
                <span v-else key="passwordVisibilityOff"><i class="fas fa-eye-slash"></i></span>
              </div>
            </el-input>
          </el-form-item>
          <el-form-item label="Confirmação de Senha">
            <el-input v-model="form.password_confirmation"
                      ref="password"
                      :type="showPassword ? 'text' : 'password'"
                      autocomplete="off"
                      @keydown.enter.native.prevent="onRegister">
              <div class="el-input--suffix-box" slot="suffix" @click="showPassword = !showPassword">
                <span v-if="showPassword" key="passwordVisibility"><i class="fas fa-eye"></i></span>
                <span v-else key="passwordVisibilityOff"><i class="fas fa-eye-slash"></i></span>
              </div>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary"
                       size="large"
                       @click="onRegister"
                       :loading="isSubmitted"
                       :disabled="isSubmitted || $v.form.$invalid">Finalizar
            </el-button>
          </el-form-item>
        </el-form>
      </el-card>
      <div class="login-logo">
        <img src="https://gripo.app/static/imgs/logo-light.png" alt="">
      </div>
    </div>
  </div>
</template>
