<script>
import * as authService from '../../../../services/authService'
import {loginRedirect} from '../service'

import {default as helpers} from '@mixins/helpers'
import bus from '@utils/bus'
import {getUserUniqueId} from '@utils/fingerprint'

import {clone} from 'lodash'
import {mapGetters} from "vuex";

export default {
  mixins: [helpers],
  data() {
    return {
      form: {
        hash: '',
        email: '',
      }
    }
  },
  computed: {
    ...mapGetters(['isLogged', 'hasBasicPlan', 'hasProfessionalPlan'])
  },
  async mounted() {
    this.form.email = this.$route.params.email
    this.form.hash = this.$route.params.hash
    setTimeout(() => {
      this.onVerify()
    }, 100)
  },
  methods: {
    async onVerify() {
      const data = clone(this.form)
      data.device = await getUserUniqueId()
      authService.verify(data)
          .then(async (response) => {
            const {
              routeName,
              routeParams
            } = await loginRedirect(response, this.$router, this.hasBasicPlan, this.hasProfessionalPlan)
            setTimeout(() => {
              this.$router.push({name: routeName, params: routeParams})
            }, 400)
          })
          .catch(e => {
            bus.$emit('hide-loader')
            console.log(e)
          })
    }
  }
}
</script>

<template>
  <div class="login-box">
    <div class="login-card-box">
      <div class="app-header">
        <h2>Confirmando seu email...</h2>
      </div>
      <div class="login-logo">
        <img src="https://gripo.app/static/imgs/logo-light.png" alt="">
      </div>
    </div>
  </div>
</template>
