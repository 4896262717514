<script>
export default {
  name: 'pair-status',
  props: ['status']
}
</script>

<template>
  <div class="text-center">
    <v-chip outline small v-if="status === 'created' || status === 'restricted'">
      Pendente
    </v-chip>
    <v-chip outline small color="green" v-if="status === 'approved'">
      Aprovada
    </v-chip>
    <v-chip outline small color="orange" v-if="status === 'queued'">
      Fila de Espera
    </v-chip>
    <v-chip outline small color="red" v-if="status === 'canceled'">
      Cancelada
    </v-chip>
  </div>
</template>
