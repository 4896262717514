<script>
export default {
  name: 'match-list-result',
  props: ['sets', 'pairId', 'hasWinner'],
  computed: {
    pairSet() {
      return this.sets.find(item => item.id === this.pairId)
    },
    pairWinner() {
      return this.pairSet.winner === true
    },
    pairResults() {
      return this.pairSet.result_grid
    }
  }
}
</script>

<template>
  <td v-if="pairSet"
      class="pair1_games"
      :class="{'winner': pairWinner && hasWinner}">
    <div v-for="result in pairResults">
      {{result}}
    </div>
  </td>
</template>
