import * as restfulService from '@app/services/restfulService'
import bus from '@utils/bus'

export const SETTINGS_TYPES = {
    face_recon: 'face_recon',
    tenant_nf: 'tenant_nf',
    tenant_payment: 'tenant_payment',
    tenant_ranking_cobrapa: 'tenant_ranking_cobrapa',
    tenant_stone_payment: 'tenant_stone_payment',
    totem: 'totem',
}

export const defaultTenantNf = {
    focus_nfe_id: '',
    focus_nfe_token_homologacao: '',
    focus_nfe_token_producao: '',
    nfse_city_enabled: true,
    certificate: {
        exists: false,
        created_at: '',
        expire_datetime: '',
        expired: false,
        file: '',
        base64: '',
        password: '',
        status: ''
    },
    nfce: {
        status: 'inactive',
        enabled: false,
        staging: false,
        inscricao_estadual: '',
        regime_tributario: '',
        id_token_nfce: '1',
        serie_nfce: '1',
        token_csc: '',
        proximo_numero: 1
    },
    nfce_staging: {
        enabled: false,
        id_token_nfce: '1',
        serie_nfce: '1',
        token_csc: '',
        proximo_numero: 1
    },
    nfse: {
        city_config: {},
        status: 'inactive',
        enabled: false,
        staging: false,
        inscricao_municipal: '',
        regime_especial_tributacao: '',
        proximo_numero: '1',
        serie_nfse: '1',
        login: '',
        password: '',
        codigo_cnae: '',
        item_lista_servico: '',
        codigo_tributario_municipio: '',
        iss_retido: false,
        aliquota: 0,
    }
}

export const defaultTenantPayment = {
    teller_control_enabled: false,
    entry_portion_notify_custom_message: '',
    order_online_payment: {
        enabled: false,
        generate_transaction_on_sent_order: false,
        minutes_to_cancel: 0,
    },
    order_whatsapp_message: {
        unpaid: false,
        paid: false
    }
}

export const defaultTenantStonePayment = {
    active: false,
    requested: false,
    stoneCode: '',
    enabled: false,
    auto_generate_invoice: false,
    devices: []
}

export const TOTEM_VALIDATION_OPTION_QRCODE = 'qrcode'
export const TOTEM_VALIDATION_OPTION_FACIAL = 'facial'
export const TOTEM_VALIDATION_OPTION_LABELS = {
    qrcode: 'QR Code',
    facial: 'Reconhecimento Facial',
}

export const defaultTotem = {
    enabled: false,
    modules: [
        {
            type: 'store_order',
            name: 'Pedidos',
            enabled: false,
            validation_options: [TOTEM_VALIDATION_OPTION_QRCODE, TOTEM_VALIDATION_OPTION_FACIAL],
            settings: {
                max_product_quantity: 10,
            }
        },
        {
            type: 'facial_register',
            name: 'Cadastro do Rosto',
            enabled: false,
            validation_options: [TOTEM_VALIDATION_OPTION_FACIAL],
            settings: {}
        },
        /*
        {
            type: 'stage_checkin',
            name: 'Check-in',
            enabled: false,
            validation_options: [TOTEM_VALIDATION_OPTION_QRCODE, TOTEM_VALIDATION_OPTION_FACIAL],
            settings: {}
        },
         */
    ],
}

export const defaultFaceRecon = {
    enabled: false,
}

export const defaultTenantRankingCobrapa = {
    enabled: false,
    secret_token: ''
}

export const defaultConfigData = {
    id: '',
    country_id: '',
    city_id: '',
    cpf_cnpj: '',
    name: '',
    legal_name: '',
    club_name: '',
    modules: {
        basic: false,
        professional: false,
        tournament: false,
    },
    contact_email: '',
    contact_phone: '',
    financial_email: '',
    financial_phone: '',
    address: {
        cep: '',
        street: '',
        number: '',
        neighborhood: '',
        complement: '',
        codigo_ibge: '',
        city_id: '',
        city: {
            id: '',
            state_id: '',
            name: '',
            name_with_state: '',
            state: {
                id: '',
                name: '',
                initials: '',
            }
        },
    },
}
defaultConfigData[SETTINGS_TYPES.tenant_nf] = {...defaultTenantNf}
defaultConfigData[SETTINGS_TYPES.tenant_payment] = {...defaultTenantPayment}
defaultConfigData[SETTINGS_TYPES.tenant_stone_payment] = {...defaultTenantStonePayment}
defaultConfigData[SETTINGS_TYPES.totem] = {...defaultTotem}
defaultConfigData[SETTINGS_TYPES.face_recon] = {...defaultFaceRecon}
defaultConfigData[SETTINGS_TYPES.tenant_ranking_cobrapa] = {...defaultTenantRankingCobrapa}

export const eventOnReloadSettingsType = async (type, callback) => {
    bus.$on(`reload-${type}`, () => {
        if (typeof callback === 'function') {
            callback()
        }
    })
}

export const eventOffReloadSettingsType = async (type) => {
    bus.$off(`reload-${type}`)
}

export const emitReloadSettingsType = async (type) => {
    bus.$emit(`reload-${type}`)
}

export const getTenantData = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            const data = await restfulService.get('tenant', 'data', null, null, null)
            resolve(data)
        } catch (e) {
            reject(e)
        }
    })
}

export const getTenantSettings = async (type) => {
    return restfulService.get('tenant', `settings${type ? `/${type}` : ''}`)
}

export const saveTenantSettings = async (data, reloadSession) => {
    reloadSession = reloadSession || false
    return new Promise(async (resolve, reject) => {
        try {
            const result = await restfulService.post('tenant', 'settings', null, data)
            if (reloadSession) {
                bus.$emit('reload-session-info', () => {
                    resolve(result)
                })
            } else {
                resolve(result)
            }
        } catch (e) {
            reject(e)
        }
    })
}