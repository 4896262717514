<script>
export default {
  name: 'plan-rule-type-label',
  props: {
    size: {default: 'small', type: String},
    type: {default: '', type: String},
    tag: {default: true, type: Boolean},
    showValue: {default: false, type: Boolean},
    value: {default: 0},
  },
  computed: {
    targetLabel() {
      return this.list.find(item => item.id === this.target)?.name ?? ''
    },
    label() {
      let label = ''
      switch (this.type) {
        case 'percent':
          label = 'Desconto' + (this.showValue ? ` de (${this.value}%)` : ' %')
          break
        case 'amount':
          label = 'Desconto' + (this.showValue ? ` de (${this.value})` : '')
          break
        case 'credit':
          label = 'Créditos' + (this.showValue ? ` (${this.value})` : '')
          break
      }
      return label
    }
  }
}
</script>

<template>
  <el-tag :size="size" type="default" v-if="tag">{{ label }}</el-tag>
  <span v-else>{{ label }}</span>
</template>
