<script>
import Draggable from 'vuedraggable'

import GripoDialog from '../../../../../../components/common/Dialog'
import * as restfulService from '../../../../../../services/restfulService'

import bus from '@utils/bus'
import helpers from '@mixins/helpers'
import {mapGetters} from 'vuex'

import {required} from 'vuelidate/lib/validators'
import {cloneDeep, groupBy, debounce} from 'lodash'
import {warningDialog} from '@utils/flash'

const validGames = (value) => {
  return parseInt(value) >= 0 && value !== ''
}

export default {
  name: 'match-edit',
  props: {
    stageId: {
      type: Number,
      required: true
    },
    stageTitle: {
      type: String,
      default: ''
    },
    scoreboardKey: {
      type: String,
      default: ''
    },
    scoreboardConfig: {
      type: Object || null,
      default: null
    },
    match: {
      type: Object,
      required: true
    },
    switchSelected: {
      type: Boolean,
      default: false
    }
  },
  components: {GripoDialog, Draggable},
  mixins: [helpers],
  data() {
    return {
      dialogVisible: false,
      isSubmitted: false,
      isSetsValid: [],
      list: [],
      matchData: {
        id: null,
        date: '',
        court_id: '',
        hour: '',
        sets: [],
        metadata: {
          scoreboard_config: null
        }
      },
      scoreboard: {
        visible: false,
        config: {
          visible: false,
          golden_point: true,
          number_sets: 1,
          number_games: 9,
          tiebreak_last_set: true
        },
        data: {
          stageDescription: '',
          pair1: {
            names: '',
            service: false,
            points: 0,
            sets: []
          },
          pair2: {
            names: '',
            service: false,
            points: 0,
            sets: []
          }
        },
        interval: false,
        count: 0
      },
      setNumberCount: 1,
      formLabelWidth: '120px',
      dependencies: {
        stage_dates: [],
        courts: [],
        hours: []
      },
      showPairsDrawDialog: false,
      pairsDraw: {
        force: false,
        type: 'system',
        pairs: []
      }
    }
  },
  validations: {
    matchData: {
      date: {required},
      court_id: {required},
      hour: {required}
    }
  },
  computed: {
    ...mapGetters(['roles']),
    isAllSetsValid() {
      return this.isSetsValid.some(item => item.valid)
    },
    scoreboardLink() {
      return `http://gripo-scoreboard.s3-website-us-east-1.amazonaws.com/?key=${this.scoreboardKey}`
    },
    tiebreakWinPoints() {
      const pair1ActiveSet = this.scoreboard.data.pair1.sets.find(item => item.active)
      if (pair1ActiveSet && pair1ActiveSet.number === 3) {
        return 10
      }
      return 7
    },
    isTiebreak() {
      const pair1ActiveSet = this.scoreboard.data.pair1.sets.find(item => item.active)
      const pair2ActiveSet = this.scoreboard.data.pair2.sets.find(item => item.active)
      if (pair1ActiveSet && pair2ActiveSet) {
        const numberGames = this.scoreboard.config.number_games
        const gamesToTiebreak = numberGames - 1
        if (numberGames !== 6) {
          return (pair1ActiveSet.value === gamesToTiebreak && pair2ActiveSet.value === gamesToTiebreak)
        }
        return (pair1ActiveSet.value === numberGames && pair2ActiveSet.value === numberGames)
      }
      return false
    },
    activeSetEnded() {
      const pair1ActiveSet = this.scoreboard.data.pair1.sets.find(item => item.active)
      const pair2ActiveSet = this.scoreboard.data.pair2.sets.find(item => item.active)
      if (pair1ActiveSet && pair2ActiveSet) {
        const numberGames = this.scoreboard.config.number_games
        return (pair1ActiveSet.value >= numberGames && pair1ActiveSet.value > pair2ActiveSet.value)
            || (pair2ActiveSet.value >= numberGames && pair2ActiveSet.value > pair1ActiveSet.value)
      }
      return false
    },
    scoreboardTitle() {
      if (this.stageTitle) {
        return `${this.stageTitle} - ${this.match.player_class_label}`
      }
      return ''
    }
  },
  watch: {
    'matchData.sets': {
      deep: true,
      handler: function (value) {
        this.isSetsValid.splice(0)
        let validObj = {
          valid: true,
          games: true,
          tiebreak: true
        }
        value.map((item, index) => {
          validObj.games = !((validGames(item.pair1_games) && !validGames(item.pair2_games)) || (validGames(item.pair2_games) && !validGames(item.pair1_games)) || (validGames(item.pair1_games) && validGames(item.pair2_games) && item.pair1_games === item.pair2_games))
          if (validGames(item.pair1_games) && validGames(item.pair2_games) && item.pair1_games === item.pair2_games) {
            validObj.games = true
            validObj.tiebreak = !(!validGames(item.pair1_tiebreak) || !validGames(item.pair2_tiebreak))
          }
          validObj.valid = validObj.games && validObj.tiebreak
          this.isSetsValid[index] = {...validObj}
        })
      }
    },
    'scoreboard.config': {
      deep: true,
      handler: function () {
        this.updateScoreboardConfig()
      }
    },
    'scoreboard.visible': function (value) {
      bus.$emit('show-loader')
      if (value) {
        const [pair1Player1, pair1Player2] = this.matchData.pair1_names.split(' /<br> ')
        const [pair2Player1, pair2Player2] = this.matchData.pair2_names.split(' /<br> ')
        const pair1Player1Names = pair1Player1.split(' ')
        const pair1Player2Names = pair1Player2.split(' ')
        const pair2Player1Names = pair2Player1.split(' ')
        const pair2Player2Names = pair2Player2.split(' ')
        this.scoreboard.data.stageDescription = this.scoreboardTitle
        this.scoreboard.data.pair1.names = pair1Player1Names[pair1Player1Names.length - 1] + '/' + pair1Player2Names[pair1Player2Names.length - 1]
        this.scoreboard.data.pair2.names = pair2Player1Names[pair2Player1Names.length - 1] + '/' + pair2Player2Names[pair2Player2Names.length - 1]
      }
      this.updateScoreboard()
    }
  },
  methods: {
    async openDialog() {
      this.isSubmitted = false
      this.matchData = {...this.matchData, ...cloneDeep(this.match)}
      if (this.scoreboardConfig) {
        this.scoreboard.config = {...this.scoreboard.config, ...this.scoreboardConfig}
        this.scoreboard.config.visible = false
      }
      const pair1 = this.matchData.sets.find(item => item.id === this.matchData.pair1_id)
      const pair2 = this.matchData.sets.find(item => item.id === this.matchData.pair2_id)
      if (pair1 && pair2) {
        const sets = []
        pair1.result.forEach((item, index) => {
          sets.push({
            pair1_games: Array.isArray(pair1.result) ? pair1.result[index] : '',
            pair1_tiebreak: Array.isArray(pair1.tiebreak) ? pair1.tiebreak[index] : '',
            pair1_result_grid: pair1.result_grid[`${index + 1}`] || null,
            pair2_games: Array.isArray(pair2.result) ? pair2.result[index] : '',
            pair2_tiebreak: Array.isArray(pair2.tiebreak) ? pair2.tiebreak[index] : '',
            pair2_result_grid: pair2.result_grid[`${index + 1}`] || null,
            winner: pair1.winner ? this.matchData.pair1_id : this.matchData.pair2_id
          })
        })
        this.matchData.sets = [...sets]
      }
      if (this.matchData.sets.length) {
        this.matchData.sets.forEach((set, index) => {
          this.scoreboard.data.pair1.sets[index] = {
            active: index === 0,
            number: index + 1,
            value: set.pair1_result_grid || '0',
            tiebreak: set.pair1_tiebreak
          }
          this.scoreboard.data.pair2.sets[index] = {
            active: index === 0,
            number: index + 1,
            value: set.pair2_result_grid || '0',
            tiebreak: set.pair2_tiebreak
          }
        })
      } else {
        this.addSet()
        this.scoreboard.data.pair1.sets.splice(0)
        this.scoreboard.data.pair2.sets.splice(0)
        for (let i = 1; i <= this.scoreboard.config.number_sets; i++) {
          this.scoreboardAddSet(this.scoreboard.data.pair1.sets, i)
          this.scoreboardAddSet(this.scoreboard.data.pair2.sets, i)
        }
      }
      setTimeout(() => {
        this.dialogVisible = true
        setTimeout(() => {
          this.$refs.pair1Games1[0].focus()
        }, 200)
      }, 200)
    },
    closeDialog() {
      this.matchData.date = ''
      this.matchData.court_id = ''
      this.matchData.hour = ''
      this.matchData.sets.splice(0)
      this.dialogVisible = false
      this.showPairsDrawDialog = false
    },
    setsValidation(index) {
      return this.isSetsValid[index] ?? {valid: true, games: true, tiebreak: true}
    },
    closePairsDrawDialog() {
      this.pairsDraw.force = false
      this.pairsDraw.type = 'system'
      this.pairsDraw.pairs.splice(0)
    },
    addSet() {
      this.matchData.sets.push({
        pair1_games: '',
        pair1_tiebreak: '',
        pair2_games: '',
        pair2_tiebreak: '',
        number: this.matchData.sets.length + 1,
        winner: ''
      })
    },
    disableResult(item) {
      if (item.pair1_games === '' || item.pair2_games === '') {
        item.pair1_tiebreak = ''
        item.pair2_tiebreak = ''
        return true
      }
      return item.pair1_games !== item.pair2_games
    },
    save(setWinner) {
      this.isSubmitted = true
      bus.$emit('show-loader')
      let data = {set_winner: setWinner}
      data.pairs_draw = {...this.pairsDraw}
      data.stage_id = this.stageId
      data.id = this.matchData.id
      data.pair1_id = this.matchData.pair1_id
      data.pair2_id = this.matchData.pair2_id
      if (!data.pair1_id || !data.pair2_id) {
        warningDialog({
          title: 'O jogo precisa estar com as duas duplas setadas para receber um resultado',
        })
        bus.$emit('hide-loader')
        this.isSubmitted = false
        return
      }
      data.sets = []
      this.matchData.sets.forEach((set, index) => {
        if (set.pair1_games !== '') {
          data.sets.push({
            match_id: data.id,
            pair_id: data.pair1_id,
            type: 'normal',
            number: index + 1,
            games: parseInt(set.pair1_games)
          })
        }
        if (set.pair2_games !== '') {
          data.sets.push({
            match_id: data.id,
            pair_id: data.pair2_id,
            type: 'normal',
            number: index + 1,
            games: parseInt(set.pair2_games)
          })
        }
        if (set.pair1_tiebreak !== '') {
          data.sets.push({
            match_id: data.id,
            pair_id: data.pair1_id,
            type: 'tiebreak',
            number: index + 1,
            games: parseInt(set.pair1_tiebreak)
          })
        }
        if (set.pair2_tiebreak !== '') {
          data.sets.push({
            match_id: data.id,
            pair_id: data.pair2_id,
            type: 'tiebreak',
            number: index + 1,
            games: parseInt(set.pair2_tiebreak)
          })
        }
      })
      let hasInvalidResult = false
      Object.values(groupBy(data.sets, 'number')).forEach(sets => {
        // Set normal, sem tie break
        if (sets.length === 2 && sets[0] && sets[0].games === 0 && sets[1] && sets[1].games === 0) {
          hasInvalidResult = true
        } else if (sets.length === 4 && (sets[0] && sets[0].games === 0 && sets[1] && sets[1].games === 0) || (sets[2] && sets[2].games === 0 && sets[3] && sets[3].games === 0)) {
          // Set com tie break
          hasInvalidResult = true
        }
      })
      if (hasInvalidResult) {
        warningDialog({
          title: 'Algum dos sets está com resultado inválido',
          text: 'Verifique todos os sets para prosseguir',
        })
        bus.$emit('hide-loader')
        this.isSubmitted = false
        return
      }
      restfulService.put('match', 'update', data.id, data)
          .then(async (response) => {
            if (response.error !== undefined) {
              this.showPairsDrawDialog = true
              this.isSubmitted = false
              this.pairsDraw.force = true
              this.pairsDraw.pairs = [...response.pairs]
              bus.$emit('hide-loader')
            } else {
              if (data.set_winner && this.scoreboard.visible) {
                this.scoreboard.visible = false
                await this.updateScoreboard()
              }
              bus.$emit('match-data', response)
              bus.$emit('hide-loader')
              this.isSubmitted = false
              if (!data.set_winner && !this.scoreboard.visible) {
                this.closePairsDrawDialog()
                this.showPairsDrawDialog = false
                this.closeDialog()
              }
            }
          })
          .catch((e) => {
            console.log(e)
            bus.$emit('hide-loader')
            this.isSubmitted = false
          })
    },
    scoreboardAddSet(obj, number) {
      obj.push({
        active: obj.length === 0,
        number: number,
        value: 0,
        tiebreak: null
      })
    },
    handleClick(pair, type, key, obj) {
      this.scoreboard.count++
      if (this.scoreboard.count === 1) {
        this.scoreboard.interval = setTimeout(() => {
          this.scoreboard.count = 0
          if (type === 'points') {
            if (!this.scoreboard.data.pair1.service && !this.scoreboard.data.pair2.service) {
              this.scoreboard.data[pair].service = true
            }
            this.addPoint(pair, type, key, obj)
          } else if (type === 'games') {
            this.addGame(pair, type, key, obj)
          }
        }, 300)
      } else if (this.scoreboard.count === 2) {
        clearTimeout(this.scoreboard.interval)
        this.scoreboard.count = 0
        if (type === 'points') {
          this.removePoint(pair, type, key, obj)
        } else if (type === 'games') {
          this.removeGame(pair, type, key, obj)
        }
      }
    },
    updateScoreboardService(value, pair) {
      const pairChange = pair === 'pair1' ? 'pair2' : 'pair1'
      this.scoreboard.data[pairChange].service = !value
      this.updateScoreboard()
    },
    handleGamePoints(action, pair, value) {
      const isPair1 = pair === 'pair1'
      const oldValue = value
      let ended = false
      if (this.isTiebreak) {
        if (action === 'add') {
          let pair1Points = 0
          let pair2Points = 0
          if (isPair1) {
            pair1Points = this.scoreboard.data.pair1.points
            pair2Points = this.scoreboard.data.pair2.points
          } else {
            pair1Points = this.scoreboard.data.pair2.points
            pair2Points = this.scoreboard.data.pair1.points
          }
          const pair1FuturePoints = pair1Points + 1
          ended = this.tiebreakWinPoints === pair1FuturePoints && (pair1FuturePoints - pair2Points) >= 2
          if (ended) {
            const pair1ActiveSet = this.scoreboard.data.pair1.sets.find(item => item.active)
            const pair2ActiveSet = this.scoreboard.data.pair2.sets.find(item => item.active)
            pair1ActiveSet.tiebreak = pair1FuturePoints
            pair2ActiveSet.tiebreak = pair2Points
          }
          value++
        } else {
          value--
        }
      } else {
        if (action === 'add') {
          if (value === 0) {
            value = 15
          } else if (value === 15) {
            value = 30
          } else if (value === 30) {
            value = 40
          }
          ended = oldValue === 40
        } else {
          if (value === 15) {
            value = 0
          } else if (value === 30) {
            value = 15
          } else if (value === 40) {
            value = 30
          }
        }
      }
      return {
        value,
        ended
      }
    },
    addPoint(pair, type, key, obj) {
      const oldValue = obj[key]
      const {value, ended} = this.handleGamePoints('add', pair, oldValue)
      obj[key] = value
      if (ended) {
        this.addGame(pair)
        const otherPair = pair === 'pair1' ? 'pair2' : 'pair1'
        this.scoreboard.data[pair].service = !this.scoreboard.data[pair].service
        this.scoreboard.data[otherPair].service = !this.scoreboard.data[otherPair].service
        this.resetPoints()
      }
      this.updateScoreboard()
    },
    removePoint(pair, type, key, obj) {
      const {value} = this.handleGamePoints('remove', pair, obj[key])
      obj[key] = value
      this.updateScoreboard()
    },
    addGame(pair) {
      const activeSet = this.scoreboard.data[pair].sets.find(item => item.active)
      activeSet.value++
      if (this.activeSetEnded) {
        this.setNextActiveSet()
      }
      this.updateScoreboard()
    },
    removeGame(pair, type, key, obj) {
      this.setNextActiveSet(obj.number)
      obj.value--
      this.updateScoreboard()
    },
    setNextActiveSet(number) {
      const pair1ActiveSet = this.scoreboard.data.pair1.sets.find(item => item.active)
      number = number || pair1ActiveSet.number + 1
      const pair1NextSet = this.scoreboard.data.pair1.sets.find(item => item.number === number)
      const pair2ActiveSet = this.scoreboard.data.pair2.sets.find(item => item.active)
      const pair2NextSet = this.scoreboard.data.pair2.sets.find(item => item.number === number)
      pair1ActiveSet.active = false
      pair2ActiveSet.active = false
      if (pair1NextSet && pair2NextSet) {
        pair1NextSet.active = true
        pair2NextSet.active = true
      }
    },
    resetPoints() {
      this.scoreboard.data.pair1.points = 0
      this.scoreboard.data.pair2.points = 0
    },
    updateScoreboard: debounce(async function () {
      this.matchData.sets.splice(0)
      this.scoreboard.data.pair1.sets.forEach((set, index) => {
        let setData = {
          pair1_games: set.value + '',
          pair1_tiebreak: (set.tiebreak || '') + '',
          pair2_games: (this.scoreboard.data.pair2.sets[index]?.value || '') + '',
          pair2_tiebreak: (this.scoreboard.data.pair2.sets[index]?.tiebreak || '') + '',
          number: set.number,
          winner: ''
        }
        if (this.tiebreakWinPoints === 7
            && setData.pair1_games && setData.pair2_games
            && setData.pair1_games !== setData.pair2_games
            && setData.pair1_tiebreak && setData.pair2_tiebreak
            && setData.pair1_tiebreak !== setData.pair2_tiebreak) {
          if (setData.pair1_tiebreak > setData.pair2_tiebreak) {
            setData.pair1_games = (parseInt(setData.pair1_games) - 1) + ''
          } else if (setData.pair2_tiebreak > setData.pair1_tiebreak) {
            setData.pair2_games = (parseInt(setData.pair2_games) - 1) + ''
          }
        }
        const matchSetIndex = this.matchData.sets.findIndex(item => item.number === set.number)
        if (matchSetIndex !== -1) {
          this.matchData.sets.splice(matchSetIndex, 1, setData)
        } else {
          this.matchData.sets.push(setData)
        }
      })
      const data = {...this.scoreboard}
      await restfulService.put('match', 'scoreboard', this.match.id, data)
      bus.$emit('hide-loader')
    }, 1000),
    updateScoreboardConfig: debounce(async function () {
      const data = {...this.scoreboard.config, visible: false}
      await restfulService.post('stage', 'scoreboard-config', this.stageId, data)
      this.$emit('update:scoreboardConfig', data)
    }, 1000),
    copyText(ref) {
      const codeToCopy = this.$refs[ref]
      navigator.clipboard.writeText(codeToCopy.value)
      this.$notify({
        title: 'Link copiado!',
        type: 'success',
        duration: 1000,
        position: 'bottom-right'
      })
    }
  }
}

</script>


<template>
  <div class="match-results-button">

    <el-button @click="openDialog()"
               type="primary"
               size="mini"
               :disabled="!match.pair1_id || !match.pair2_id || switchSelected"
               title="Alterar resultado">
      <i class="fas fa-dice"></i>
    </el-button>

    <gripo-dialog :model.sync="dialogVisible"
                  width="98vw"
                  top="2vh"
                  :on-close="closeDialog">
      <template v-slot:header>Alterar resultado</template>
      <template v-slot:content>
        <el-form class="match-results pt-0"
                 v-if="matchData.pair1_id && matchData.pair2_id">
          <div class="pb-3 text-center">
            <div class="flex-c">
              <div/>
              <div>
                <span class="mr-2">
                  <i class="far fa-keyboard"></i>
                  <strong class="ml-2">Ativar marcador Ao Vivo</strong>
                </span>
                <el-switch v-model="scoreboard.visible"
                           active-text="SIM">
                </el-switch>
              </div>
              <div>
                <el-button size="mini"
                           @click="scoreboard.config.visible = !scoreboard.config.visible">
                  <i class="fas fa-cog"></i>
                  <span class="ml-2 mr-2">Configurações do Marcador</span>
                  <template v-if="scoreboard.config.visible">
                    <i class="fas fa-chevron-up"></i>
                  </template>
                  <template v-else>
                    <i class="fas fa-chevron-down"></i>
                  </template>
                </el-button>
              </div>
            </div>
          </div>
          <div v-if="scoreboard.config.visible" class="scoreboard-config">
            <el-form :inline="true">
              <el-form-item label="Link do Marcador p/ Tranmissão">
                <el-input v-model="scoreboardLink"
                          ref="scoreboardLink"
                          size="mini"
                          readonly>
                  <div slot="append"
                       @click="copyText('scoreboardLink')">
                    <i class="far fa-copy"></i>
                  </div>
                </el-input>
              </el-form-item>
              <el-form-item label="Número de Sets">
                <el-input-number v-model="scoreboard.config.number_sets"
                                 size="mini"
                                 :min="1"
                                 :step="1">
                </el-input-number>
              </el-form-item>
              <el-form-item label="Número de Games">
                <el-input-number v-model="scoreboard.config.number_games"
                                 size="mini"
                                 :min="1"
                                 :step="1">
                </el-input-number>
              </el-form-item>
              <el-form-item label="Ponto de Ouro">
                <el-switch v-model="scoreboard.config.golden_point"
                           active-text="SIM"
                           inactive-text="Não">
                </el-switch>
              </el-form-item>
              <el-form-item label="Último Set é Tiebreak">
                <el-switch v-model="scoreboard.config.tiebreak_last_set"
                           active-text="SIM"
                           inactive-text="Não">
                </el-switch>
              </el-form-item>
            </el-form>
          </div>
          <div v-if="scoreboard.visible" class="scoreboard">
            <div class="stage-title">
              <el-form-item label="Título/Descrição" class="mb-0">
                <el-input type="text"
                          v-model="scoreboard.data.stageDescription"
                          @input="updateScoreboard"/>
              </el-form-item>
            </div>
            <table class="scoreboard-table">
              <thead>
              <tr>
                <th></th>
                <th>Pontos</th>
                <template v-for="set in scoreboard.data.pair1.sets">
                  <th>Set {{ set.number }}</th>
                </template>
              </tr>
              </thead>
              <tr>
                <td class="names">
                  <input type="text"
                         v-model="scoreboard.data.pair1.names"
                         @input="updateScoreboard">
                  <el-switch v-model="scoreboard.data.pair1.service"
                             @change="updateScoreboardService($event, 'pair1')"
                             active-text="Serviço">
                  </el-switch>
                </td>
                <td class="points">
                  <button class="field"
                          type="button"
                          @click="handleClick('pair1', 'points', 'points', scoreboard.data.pair1)">
                    {{ scoreboard.data.pair1.points }}
                  </button>
                </td>
                <template v-for="set in scoreboard.data.pair1.sets">
                  <td class="set">
                    <button class="field"
                            type="button"
                            @click="handleClick('pair1', 'games', 'value', set)">
                      {{ set.value }}
                    </button>
                  </td>
                </template>
              </tr>
              <tr>
                <td class="names">
                  <input type="text"
                         v-model="scoreboard.data.pair2.names"
                         @input="updateScoreboard">
                  <el-switch v-model="scoreboard.data.pair2.service"
                             @change="updateScoreboardService($event, 'pair2')"
                             active-text="Serviço">
                  </el-switch>
                </td>
                <td class="points">
                  <button class="field"
                          type="button"
                          @click="handleClick('pair2', 'points', 'points', scoreboard.data.pair2)">
                    {{ scoreboard.data.pair2.points }}
                  </button>
                </td>
                <template v-for="set in scoreboard.data.pair2.sets">
                  <td class="set">
                    <button class="field"
                            type="button"
                            @click="handleClick('pair2', 'games', 'value', set)">
                      {{ set.value }}
                    </button>
                  </td>
                </template>
              </tr>
            </table>
          </div>
          <template v-else>
            <el-row :gutter="10">
              <el-col :span="12" class="first-pair text-center">
                <div class="pair-names">
                  <div v-if="matchData.winner_pair === matchData.pair1_id">
                    <i class="fas fa-trophy"></i>
                  </div>
                  <div class="names">
                    <span v-html="matchData.pair1_names"></span>
                  </div>
                </div>
              </el-col>
              <el-col :span="12" class="first-pair text-center">
                <div class="pair-names">
                  <div v-if="matchData.winner_pair === matchData.pair2_id">
                    <i class="fas fa-trophy"></i>
                  </div>
                  <div class="names">
                    <span v-html="matchData.pair2_names"></span>
                  </div>
                </div>
              </el-col>
            </el-row>
            <el-row v-for="(item,index) in matchData.sets" :key="index">
              <el-card class="box-card box-match-result">
                <el-row :gutter="10">
                  <el-col :span="4" class="set-label">Set {{ index + 1 }}</el-col>
                  <el-col :span="8" :xs="8">
                    <el-form-item
                        :class="{ 'el-form-item--error': !(setsValidation(index).games) }">
                      <el-input placeholder="Games"
                                v-mask="['###']"
                                v-model="item.pair1_games"
                                type="number"
                                :min="0"
                                :ref="`pair1Games${index + 1}`"/>
                    </el-form-item>
                  </el-col>
                  <el-col :span="1" class="versus"><span>X</span></el-col>
                  <el-col :span="6" :xs="8">
                    <el-form-item
                        :class="{ 'el-form-item--error': !(setsValidation(index).games) }">
                      <el-input placeholder="Games"
                                v-mask="['###']"
                                v-model="item.pair2_games"
                                type="number"
                                :min="0"/>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="10">
                  <el-col :span="4" class="set-label">Tiebreak</el-col>
                  <el-col :span="8" :xs="8">
                    <el-form-item
                        :class="{ 'el-form-item--error': !(setsValidation(index).tiebreak) }">
                      <el-input placeholder="Tiebreak"
                                v-mask="['###']"
                                v-model="item.pair1_tiebreak"
                                :disabled="disableResult(item)"
                                type="number"/>
                    </el-form-item>
                    <div class="el-form-item__error"
                         v-if="!isSetsValid">
                      <span>Campo Obrigatório</span>
                    </div>
                  </el-col>
                  <el-col :span="1" class="versus"><span>X</span></el-col>
                  <el-col :span="6" :xs="8">
                    <el-form-item
                        :class="{ 'el-form-item--error': !(setsValidation(index).tiebreak) }">
                      <el-input placeholder="Tiebreak"
                                v-mask="['###']"
                                v-model="item.pair2_tiebreak"
                                :disabled="disableResult(item)"
                                type="number"/>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-card>
            </el-row>
            <div class="app-header match-header">
              <h2></h2>
              <el-button type="warning"
                         size="medium"
                         @click="addSet">
                <i class="fas fa-plus"></i>
                <span>Adicionar Set</span>
              </el-button>
            </div>
          </template>
        </el-form>
      </template>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <el-button type="primary"
                   @click="save(false)"
                   :disabled="isSubmitted || !isAllSetsValid"
                   :loading="isSubmitted">
          <i class="fas fa-dice"></i>
          <span class="ml-2">{{ isSubmitted ? 'Salvando' : 'Salvar' }} Resultado Parcial</span>
        </el-button>
        <el-button type="success"
                   @click="save(true)"
                   :disabled="isSubmitted || !isAllSetsValid"
                   :loading="isSubmitted">
          <i class="fas fa-check"></i>
          <span class="ml-2">{{ isSubmitted ? 'Salvando' : 'Salvar e Gravar Vencedor' }}</span>
        </el-button>
      </template>
    </gripo-dialog>

    <gripo-dialog :model.sync="showPairsDrawDialog"
                  :width="!$vuetify.breakpoint.mobile ? '50%' : '90%'"
                  top="2vh"
                  :on-close="closePairsDrawDialog">
      <template v-slot:header>Chave Empatada - Sorteio</template>
      <template v-slot:content>
        <el-form>
          <el-radio-group v-model="pairsDraw.type">
            <el-radio-button label="system" border>Sorteio Automático (Sistema)</el-radio-button>
            <el-radio-button label="manual" border>Sorteio Manual</el-radio-button>
          </el-radio-group>
          <el-card class="el-card box-card draggable-list draggable-list-scrollable mt-3">
            <div slot="header">
              <span>Duplas Empatadas</span>
            </div>
            <Draggable :class="['draggable-component', {'disabled': pairsDraw.type === 'system'}]"
                       v-model="pairsDraw.pairs"
                       :disabled="pairsDraw.type === 'system'"
                       :options="{group:{name:'pairs'}, scroll:false, disabled: pairsDraw.type === 'system'}">
              <div v-for="(pair, index) in pairsDraw.pairs"
                   :key="pair.id">
                <div class="group-data">
                  <span v-if="pairsDraw.type === 'manual'">{{ index + 1 }} - </span><span
                    v-html="pair.names"></span>
                </div>
              </div>
            </Draggable>
          </el-card>
        </el-form>
      </template>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <el-button type="primary"
                   @click="save()"
                   :disabled="isSubmitted || (pairsDraw.type === 'manual' && pairsDraw.pairs.length === 0)"
                   :loading="isSubmitted">
          {{ isSubmitted ? 'Salvando' : 'Salvar' }} Resultado/Sorteio
        </el-button>
      </template>
    </gripo-dialog>
  </div>
</template>

