<script>
import helpers from '@mixins/helpers'
import menu from '../../menu'
import {mapGetters} from 'vuex'

export default {
  mixins: [helpers],
  name: 'list-header',
  props: {
    title: {
      type: String,
    },
    routeName: {
      type: String,
    },
    backRoute: {
      type: String,
    },
    routeEditName: {
      type: String,
    },
    item: {
      type: Object,
    },
    storeTitle: {
      type: String,
    },
    checkPermission: {
      type: Boolean,
      default: false
    },
    forceCheckPermission: {
      type: Boolean,
      default: false
    },
    showStoreButton: {
      type: Boolean,
      default: true
    },
  },
  computed: {
    ...mapGetters(['permissions']),
    canStore() {
      const routeResolved = this.$router.resolve({name: this.routeName})
      const permission = routeResolved.route?.meta?.permission || this.routeName
      return (this.checkPermission && this.permissions.some(item => item.name === permission)) || !this.checkPermission
    },
    routeData() {
      const [param1, param2, param3] = this.$route.name.split('.')
      let routeIndex = param1
      if (param3) {
        routeIndex += '.' + param2
      }
      const menuFlat = menu.flatMap(item => item.children)
      return menuFlat.find(item => item.route.index === routeIndex)
    }
  }
}
</script>

<template>
  <div class="app-header">
    <h2>
      <template v-if="routeData && !title">
        <span class="app-header-icon"><i :class="routeData.icon"></i></span>
        <span class="ml-2">{{ routeData.text }}</span>
      </template>
      <template v-else>{{ title }}</template>
    </h2>
    <div class="responsive-buttons">
      <slot></slot>
      <router-link :to="{ name: routeName }" v-if="showStoreButton && routeName && canStore"
                   class="el-button el-button--success">
        <i class="fas fa-plus-circle"></i>
        <span>{{ storeTitle ? storeTitle : 'Criar ' + title }}</span>
      </router-link>
      <router-link :to="{ name: routeEditName, params: { id: item.id } }"
                   v-if="routeEditName && item"
                   class="el-button el-button--info">
        <i class="fas fa-edit"></i>
        <span>Editar</span>
      </router-link>
      <router-link :to="{ name: backRoute }" v-if="backRoute" class="el-button el-button--default">
        <i class="fas fa-arrow-left"></i>
        <span>Voltar</span>
      </router-link>
    </div>
  </div>
</template>
