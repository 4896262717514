<script>
import GripoDialog from '../../../../components/common/Dialog'
import EnablePushNotifications from './EnablePushNotifications'
import UserInfoActions from './UserInfoActions'
import CoachData from '../../../common/client/components/CoachData'

import * as restfulService from '../../../../services/restfulService'
import * as notifyService from '../../../../services/notifyService'

import helpers from '@mixins/helpers'
import bus from '@utils/bus'
import {mapGetters, mapActions} from 'vuex'

import {required, requiredIf, sameAs, minLength, email} from 'vuelidate/lib/validators'
import {cloneDeep, merge} from 'lodash'
import {defaultCoachConfig} from '@app/domains/common/client/components/CoachData'
import DataService from '@app/services/dataService'

const defaultUserData = {
  country_id: '',
  name: '',
  login: '',
  email: '',
  phone: '',
  tenant_client_id: '',
  coach_config: {...defaultCoachConfig}
}

const defaultUserPassword = {
  current_password: '',
  password: '',
  password_confirmation: ''
}

const defaultActions = {
  schedule_store: {
    created: true,
    scheduled: true,
    canceled: true
  },
  product_minimum_stock: true,
  stage_pair_store: {
    created: true,
  }
}

const defaultUserNotifications = {
  enabled: false,
  push: {
    enabled: false,
    actions: {...cloneDeep(defaultActions)}
  },
  email: {
    enabled: false,
    actions: {...cloneDeep(defaultActions)}
  },
  whatsapp: {
    enabled: false,
    actions: {...cloneDeep(defaultActions)}
  }
}

export default {
  mixins: [helpers],
  components: {GripoDialog, EnablePushNotifications, UserInfoActions, CoachData},
  props: ['showModal'],
  data() {
    return {
      isFirstLoad: true,
      isSubmitted: false,
      isLoadingData: true,
      showUserInfoModal: false,
      user: {...defaultUserData},
      user_password: {...defaultUserPassword},
      user_notifications: {...defaultUserNotifications},
      userNotGrantedPermission: false,
      skipMultipleUpdate: false,
      roles: [],
      showNotificationsConfig: false,
      phonePrefix: '+55',
      dependencies: {
        countries: []
      },
      phoneVerify: {
        showModal: false,
        code: ''
      }
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'isCoach']),
    isAdmin() {
      return this.roles.includes('administrador')
    },
    isFinancial() {
      return this.roles.includes('financeiro')
    },
    isSchedules() {
      return this.roles.includes('reservas')
    },
    isTorneios() {
      return this.roles.includes('torneios')
    },
    phoneMask() {
      const country = this.dependencies.countries.find(item => item.id === this.user.country_id)
      return this.countryPhoneMask(country?.code)
    }
  },
  validations: {
    user: {
      country_id: {required},
      name: {required},
      login: {
        required: requiredIf(function () {
          return !this.user.email && !this.user.phone
        })
      },
      email: {email}
    },
    user_password: {
      current_password: {required},
      password: {required, minLength: minLength(6)},
      password_confirmation: {sameAsPassword: sameAs('password')}
    }
  },
  watch: {
    'user_notifications.enabled': function () {
      this.skipMultipleUpdate = true
      if (!this.isFirstLoad) {
        this.onUpdateNotifications()
      }
      setTimeout(() => {
        this.skipMultipleUpdate = false
      }, 500)
    },
    'user_notifications.push.enabled': function () {
      if (!this.isFirstLoad && !this.skipMultipleUpdate) {
        this.onUpdateNotifications()
      }
    },
    'currentUser.phone': function (value) {
      this.user_notifications.whatsapp.enabled = !!value
    },
    'currentUser.email': function (value) {
      this.user_notifications.email.enabled = !!value
    }
  },
  mounted() {
    bus.$on('open-modal-user', (value) => this.openModalUser(value))
  },
  beforeDestroy() {
    bus.$off('open-modal-user', (value) => this.openModalUser(value))
  },
  methods: {
    ...mapActions(['setUser']),
    getDependencies() {
      return new Promise(resolve => {
        DataService.get([{domain: 'role'}, {domain: 'country'}]).then((result) => {
          this.dependencies = {...this.dependencies, ...result}
          resolve()
        })
      })
    },
    changeCountry(id) {
      const country = this.dependencies.countries.find(item => item.id === id)
      this.phonePrefix = '+' + country.phone_prefix
    },
    async openModalUser(value) {
      this.resetModal()
      await this.getUserData()
      if (!this.showUserInfoModal) {
        this.showUserInfoModal = value
      }
      bus.$emit('hide-loader')
      setTimeout(() => {
        this.isFirstLoad = false
      }, 300)
    },
    async getUserData() {
      return new Promise(async (resolve) => {
        await this.getDependencies()
        const user = await restfulService.get('user', 'show-user-logged')
        this.roles = user.roles.map((item) => {
          return item.key
        })
        this.user = cloneDeep({...defaultUserData, ...user})
        if (!this.user.country_id) {
          const country = this.dependencies.countries.find(item => item.code === 'BRA')
          this.user.country_id = country.id
        }
        this.user_notifications = {...merge(defaultUserNotifications, user.notifications || {})}
        resolve()
      })
    },
    resetModal() {
      this.$v.user.$reset()
      this.$v.user_password.$reset()
      this.user = cloneDeep({...defaultUserData, ...this.currentUser})
      this.user_password = {...defaultUserPassword}
      this.user_notifications = {...defaultUserNotifications}
      this.isFirstLoad = true
    },
    onUpdateUserInfo() {
      bus.$emit('show-loader')
      this.isSubmitted = true
      const data = {...this.user}
      if (data.phone) {
        data.phone = this.onlyNumbers(data.phone)
      }
      let clearPhoneNotifications = false
      if (!data.phone && this.currentUser.phone) {
        clearPhoneNotifications = true
      }
      let clearEmailNotifications = false
      if (!data.email && this.currentUser.email) {
        clearEmailNotifications = true
      }
      if (Array.isArray(data.coach_config?.selected_grid) && data.coach_config.selected_grid.length) {
        delete data.coach_config.grid
        const selectedGrid = []
        data.coach_config.selected_grid.filter(item => typeof item === 'string').map(item => {
          const [weekday, court_id, start_hour, end_hour, interval] = item.split('-')
          selectedGrid.push({weekday, court_id, start_hour, end_hour, interval})
        })
        data.coach_config.selected_grid = [...selectedGrid]
        data.coach_config.tenant_client_id = this.currentUser.tenant_client_id
      }
      restfulService.post('user', 'update-user-info', null, data)
          .then(async (response) => {
            if (!response.action) {
              if (clearPhoneNotifications || clearEmailNotifications) {
                this.user_notifications.email.enabled = !clearPhoneNotifications
                this.user_notifications.whatsapp.enabled = !clearEmailNotifications
                await this.onUpdateNotifications(false)
              }
              setTimeout(() => {
                const currentUser = {...this.currentUser}
                currentUser.email = data.email
                currentUser.phone = data.phone
                this.setUser({...currentUser})
                notifyService.success({hideLoader: true})
                this.isSubmitted = false
                bus.$emit('hide-loader')
              }, 300)
            } else if (response.action === 'verify-phone') {
              notifyService.success({message: response.message})
              this.isSubmitted = false
              if (!this.phoneVerify.showModal) {
                this.phoneVerify.showModal = true
              }
              bus.$emit('hide-loader')
            }
          })
          .catch(e => {
            console.log(e)
            this.isSubmitted = false
            bus.$emit('hide-loader')
          })
    },
    onUpdatePassword() {
      bus.$emit('show-loader')
      this.isSubmitted = true
      const data = {...this.user_password}
      restfulService.post('user', 'update-password', null, data)
          .then(() => {
            notifyService.success({hideLoader: true})
            this.isSubmitted = false
            bus.$emit('hide-loader')
          })
          .catch(e => {
            console.log(e)
            this.isSubmitted = false
            bus.$emit('hide-loader')
          })
    },
    onUpdateNotifications(controlLoader) {
      controlLoader = controlLoader || true
      return new Promise((resolve, reject) => {
        if (controlLoader) {
          bus.$emit('show-loader')
          this.isSubmitted = true
        }
        const data = {...cloneDeep(this.user_notifications)}
        restfulService.post('user', 'update-notifications', null, data)
            .then((response) => {
              const currentUser = {...cloneDeep(this.currentUser)}
              currentUser.notifications = {...response.notifications}
              this.setUser({...currentUser})
              if (controlLoader) {
                notifyService.success({hideLoader: true})
                this.isSubmitted = false
                bus.$emit('hide-loader')
              }
              resolve()
            })
            .catch(e => {
              console.log(e)
              if (controlLoader) {
                this.isSubmitted = false
                bus.$emit('hide-loader')
              }
              reject(e)
            })
      })
    },
    tabSelected(tab) {
      this.showNotificationsConfig = false
      if (tab === 2) {
        setTimeout(() => {
          this.showNotificationsConfig = true
        }, 100)
      }
    },
    onVerifyPhone() {
      bus.$emit('show-loader')
      this.isSubmitted = true
      const data = {code: this.phoneVerify.code}
      restfulService.post('auth', 'verify', null, data)
          .then(({message, tenant_user}) => {
            setTimeout(() => {
              const currentUser = {...this.currentUser}
              currentUser.email = tenant_user.email
              currentUser.phone = tenant_user.phone
              this.setUser({...currentUser})
              notifyService.success({hideLoader: true, message})
              this.isSubmitted = false
              bus.$emit('hide-loader')
              this.phoneVerify.code = ''
              this.phoneVerify.showModal = false
            }, 300)
          })
          .catch(e => {
            console.log(e)
            this.isSubmitted = false
            bus.$emit('hide-loader')
          })
    },
  }
}
</script>

<template>
  <gripo-dialog :model.sync="showUserInfoModal"
                :actions-close-btn="false"
                persistent
                width="60vw">
    <template slot="header">Meus dados</template>
    <template slot="content">
      <v-tabs slider-color="secondary" @change="tabSelected">
        <v-tab key="userInfo">
          <i class="fas fa-user"></i>
          <span class="ml-2">Dados do usuário</span>
        </v-tab>
        <v-tab key="password">
          <i class="fas fa-lock"></i>
          <span class="ml-2">Alterar Senha</span>
        </v-tab>
        <v-tab key="notifications" v-if="!isCoach">
          <i class="fas fa-bell"></i>
          <span class="ml-2">Notificações</span>
        </v-tab>
        <v-tab key="coachConfig" v-if="isCoach">
          <i class="fas fa-user-cog"></i>
          <span class="ml-2">Professor</span>
        </v-tab>
        <v-tabs-items class="mt-2" :touchless="true">
          <v-tab-item key="userInfo">
            <v-card flat>
              <el-form ref="form" :model="user" label-position="top">
                <el-form-item label="País"
                              class="is-required"
                              style="margin-bottom: 0;"
                              :class="{ 'el-form-item--error': $v.user.country_id.$error }">
                  <v-select v-model="user.country_id"
                            :items="dependencies.countries"
                            item-value="id"
                            item-text="name"
                            outline
                            single-line
                            :error-messages="validationMessageField($v.user.country_id)"
                            @input="$v.user.country_id.$touch()"
                            @blur="$v.user.country_id.$touch()"
                            @change="changeCountry">
                    <template slot="item" slot-scope="data">
                      <v-list-tile-avatar>
                        <img :src="`/static/flags/${data.item.flag_icon}`" alt="">
                      </v-list-tile-avatar>
                      <v-list-tile-content>
                        <v-list-tile-title>
                          {{ data.item.name }}
                        </v-list-tile-title>
                      </v-list-tile-content>
                    </template>
                    <template v-slot:selection="{ item }">
                      <v-list-tile-avatar>
                        <img :src="`/static/flags/${item.flag_icon}`" alt="">
                      </v-list-tile-avatar>
                      <v-list-tile-content>
                        <v-list-tile-title>
                          {{ item.name }}
                        </v-list-tile-title>
                      </v-list-tile-content>
                    </template>
                  </v-select>
                  <div class="el-form-item__error" v-if="$v.user.country_id.$error">
                    Campo obrigatório
                  </div>
                </el-form-item>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="Nome"
                                  class="is-required"
                                  :class="{ 'el-form-item--error': $v.user.name.$error }">
                      <el-input type="text"
                                v-model="user.name"
                                @input="$v.user.name.$touch()"></el-input>
                      <div class="el-form-item__error" v-if="$v.user.name.$error">
                        Esse campo é obrigatório
                      </div>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="Login"
                                  class="is-required"
                                  :class="{ 'el-form-item--error': $v.user.login.$error }">
                      <el-input type="text"
                                v-model="user.login"
                                @input="$v.user.login.$touch()"></el-input>
                      <div class="el-form-item__error" v-if="$v.user.login.$error">
                        Esse campo é obrigatório
                      </div>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="Email"
                                  :class="{ 'el-form-item--error': $v.user.email.$error }">
                      <el-input type="text"
                                v-model="user.email"
                                @input="$v.user.email.$touch()"></el-input>
                      <div class="el-form-item__error" v-if="$v.user.email.$error">
                        Esse email é inválido
                      </div>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="Telefone">
                      <el-input type="tel"
                                v-model="user.phone"
                                v-mask="phoneMask">
                        <template slot="prepend">{{ phonePrefix }}</template>
                      </el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-button type="success"
                           @click="onUpdateUserInfo"
                           :disabled="isSubmitted || $v.user.$invalid"
                           :loading="isSubmitted">
                  <i class="fas fa-check"></i>
                  <span>Salvar dados</span>
                </el-button>
              </el-form>
            </v-card>
          </v-tab-item>
          <v-tab-item key="password">
            <v-card flat>
              <el-form ref="form" :model="user_password" label-position="top">
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="Senha atual"
                                  class="is-required"
                                  :class="{ 'el-form-item--error': $v.user_password.current_password.$error }">
                      <el-input type="password" v-model="user_password.current_password"
                                autocomplete="off"
                                @input="$v.user_password.current_password.$touch()"></el-input>
                      <div class="el-form-item__error"
                           v-if="$v.user_password.current_password.$error">Esse campo é
                        obrigatório
                      </div>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="Nova senha"
                                  class="is-required"
                                  :class="{ 'el-form-item--error': $v.user_password.password.$error }">
                      <el-input type="password" v-model="user_password.password"
                                autocomplete="off"
                                @input="$v.user_password.password.$touch()"></el-input>
                      <div class="el-form-item__error" v-if="$v.user_password.password.$error">
                        Esse campo é
                        obrigatório
                      </div>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="Confirmação da nova senha"
                                  class="is-required"
                                  :class="{ 'el-form-item--error': $v.user_password.password_confirmation.$error }">
                      <el-input type="password" v-model="user_password.password_confirmation"
                                autocomplete="off"
                                @input="$v.user_password.password_confirmation.$touch()"></el-input>
                      <template v-if="$v.user_password.password_confirmation.$error">
                        <div class="el-form-item__error"
                             v-if="!$v.user_password.password_confirmation.sameAsPassword">
                          A confirmação precisa ser igual a nova senha
                        </div>
                      </template>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-button type="success"
                           @click="onUpdatePassword"
                           :disabled="isSubmitted || $v.user_password.$invalid"
                           :loading="isSubmitted">
                  <i class="fas fa-check"></i>
                  <span>Alterar senha</span>
                </el-button>
              </el-form>
            </v-card>
          </v-tab-item>
          <v-tab-item key="notifications" v-if="!isCoach">
            <div style="min-height: 300px" v-if="!showNotificationsConfig"/>
            <template v-if="showNotificationsConfig">
              <div class="subheading mb-3">
                <i class="fas fa-bell"></i>
                <span class="ml-2">Habilitar notificações</span>
                <div class="d-inline-block ml-3">
                  <v-switch v-model="user_notifications.enabled"
                            class="mt-0"
                            color="secondary"
                            hide-details></v-switch>
                </div>
              </div>
              <el-alert type="warning"
                        class="mb-2"
                        show-icon
                        :closable="false"
                        v-if="userNotGrantedPermission">
                <div class="fs-14">Para ativar as notificações via navegador é necessário permitir/ativar as
                  notificações para esse site
                </div>
              </el-alert>
              <v-expansion-panel>
                <v-expansion-panel-content :disabled="!user_notifications.enabled">
                  <div slot="header" class="flex-c -jc-fs gap-10">
                    <i class="fas fa-window-restore"></i>
                    <div class="d-inline-block ml-3">
                      <enable-push-notifications :model.sync="user_notifications.push.enabled"
                                                 :user-not-granted-permission.sync="userNotGrantedPermission"
                                                 :disabled="!user_notifications.enabled"/>
                    </div>
                    <span class="ml-2">Navegador</span>
                  </div>
                  <v-card>
                    <v-card-text>
                      <user-info-actions
                          :is-admin="isAdmin"
                          :is-schedules="isSchedules"
                          :is-financial="isFinancial"
                          :is-torneios="isTorneios"
                          :enabled.sync="user_notifications.push.enabled"
                          :actions.sync="user_notifications.push.actions"
                      />
                    </v-card-text>
                  </v-card>
                </v-expansion-panel-content>
                <v-expansion-panel-content :disabled="!user_notifications.enabled || !currentUser.email">
                  <div slot="header" class="flex-c -jc-fs gap-10">
                    <i class="fas fa-envelope"></i>
                    <div class="d-inline-block">
                      <v-switch v-model="user_notifications.email.enabled"
                                :disabled="!user_notifications.enabled || !currentUser.email"
                                class="mt-0 ml-3"
                                color="secondary"
                                @change="onUpdateNotifications"
                                hide-details></v-switch>
                    </div>
                    <span class="ml-2">E-mail</span>
                    <el-tag type="warning" class="ml-1" size="mini" v-if="!currentUser.email">
                      Preencha seu e-mail nos dados do usuário
                    </el-tag>
                  </div>
                  <v-card>
                    <v-card-text>
                      <user-info-actions
                          :is-admin="isAdmin"
                          :is-schedules="isSchedules"
                          :is-financial="isFinancial"
                          :is-torneios="isTorneios"
                          :enabled.sync="user_notifications.email.enabled"
                          :actions.sync="user_notifications.email.actions"
                          @updateNotifications="onUpdateNotifications"
                      />
                    </v-card-text>
                  </v-card>
                </v-expansion-panel-content>
                <v-expansion-panel-content :disabled="!user_notifications.enabled || !currentUser.phone">
                  <div slot="header" class="flex-c -jc-fs gap-10">
                    <i class="fab fa-whatsapp"></i>
                    <div class="d-inline-block">
                      <v-switch v-model="user_notifications.whatsapp.enabled"
                                :disabled="!user_notifications.enabled || !currentUser.phone"
                                class="mt-0 ml-3"
                                color="secondary"
                                @change="onUpdateNotifications"
                                hide-details></v-switch>
                    </div>
                    <span class="ml-2">Whatsapp</span>
                    <el-tag type="warning" class="ml-1" size="mini" v-if="!currentUser.phone">
                      Preencha seu telefone nos dados do usuário
                    </el-tag>
                  </div>
                  <v-card>
                    <v-card-text>
                      <user-info-actions
                          :is-admin="isAdmin"
                          :is-schedules="isSchedules"
                          :is-financial="isFinancial"
                          :is-torneios="isTorneios"
                          :enabled.sync="user_notifications.whatsapp.enabled"
                          :actions.sync="user_notifications.whatsapp.actions"
                          @updateNotifications="onUpdateNotifications"
                      />
                    </v-card-text>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-card flat class="mt-3">
                <el-button type="success"
                           @click="onUpdateNotifications"
                           :disabled="isSubmitted"
                           :loading="isSubmitted">
                  <i class="fas fa-check"></i>
                  <span>Salvar preferências</span>
                </el-button>
              </v-card>
            </template>
          </v-tab-item>
          <v-tab-item key="coachConfig" v-if="isCoach">
            <v-card flat>
              <coach-data :client-id="user.tenant_client_id"
                          :enabled="true"
                          :coach-config.sync="user.coach_config"/>
              <el-button type="success"
                         @click="onUpdateUserInfo"
                         :disabled="isSubmitted || $v.user.$invalid"
                         :loading="isSubmitted">
                <i class="fas fa-check"></i>
                <span>Salvar dados</span>
              </el-button>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
      <gripo-dialog :model.sync="phoneVerify.showModal"
                    :actions-close-btn="false"
                    persistent
                    width="35vw">
        <template slot="header">Confirmar Telefone</template>
        <template slot="content">
          <el-input
              placeholder="Insira o Código de Confirmação"
              :maxlength="6"
              :minlength="6"
              v-model="phoneVerify.code">
          </el-input>
        </template>
        <template slot="actions">
          <el-button type="success"
                     @click="onVerifyPhone"
                     :disabled="isSubmitted || !phoneVerify.code"
                     :loading="isSubmitted">
            <i class="fas fa-check"></i>
            <span>Confirmar Telefone</span>
          </el-button>
          <v-spacer/>
          <el-button type="warning"
                     @click="onUpdateUserInfo"
                     :disabled="isSubmitted"
                     :loading="isSubmitted">
            <i class="fas fa-sync"></i>
            <span>Reenviar Código</span>
          </el-button>
        </template>
      </gripo-dialog>
    </template>
  </gripo-dialog>
</template>