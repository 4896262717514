<script>
import GripoDialog from '../../../../../components/common/Dialog'
import RegistrationList from './registration/List'
import RegistrationImport from './registration/Import'
import bus from '@utils/bus'

export default {
  props: {
    stageId: {
      type: Number,
      required: true
    },
  },
  components: {GripoDialog, RegistrationList, RegistrationImport},
  data() {
    return {
      dialogVisible: false,
      dialogVisibleImport: false,
    }
  },
  methods: {
    async openDialog() {
      bus.$emit('show-loader')
      this.dialogVisible = true
    },
    closeDialog() {
      bus.$emit('hide-loader')
    },
    importRegistrations() {
      bus.$emit('show-loader')
      this.dialogVisibleImport = true
      bus.$emit('hide-loader')
    },
    closeDialogImport() {
      this.dialogVisibleImport = false
      bus.$emit('hide-loader')
    }
  }
}

</script>


<template>
  <div class="stage-card card-impediments">

    <div class="card-content">
      <div class="card-icon">
        <i class="fas fa-user-plus"></i>
      </div>
      <div class="card-information">
        <div class="card-title">Inscrições</div>

        <div class="card-buttons">
          <div class="box">
            <div class="col">
              <el-button @click="openDialog()" size="small" type="default">
                <i class="fas fa-cog"></i> Gerenciar
              </el-button>
            </div>
            <div class="col">
              <el-button @click="importRegistrations" size="small" type="warning">
                <i class="fas fa-file-csv"></i> Importar
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <gripo-dialog :model.sync="dialogVisible"
                  :big="true"
                  :on-close="closeDialog">
      <template slot="header">Gerenciar Inscrições</template>
      <template slot="content">
        <RegistrationList :stage-id="stageId"/>
      </template>
    </gripo-dialog>

    <gripo-dialog :model.sync="dialogVisibleImport"
                  width="98vw"
                  top="2vh"
                  :on-close="closeDialogImport">
      <template slot="header">Importar Inscrições</template>
      <template slot="content">
        <RegistrationImport :stage-id="stageId" @onImport="closeDialogImport"/>
      </template>
    </gripo-dialog>
  </div>
</template>

