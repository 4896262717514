<script>
import ListMain from '../../../../components/list/Main'
import ListHeader from '../../../../components/list/Header'
import ListEditButton from '../../../../components/list/EditButton'
import ListDeleteButton from '../../../../components/list/DeleteButton'

import bus from '@utils/bus'
import {mapGetters} from 'vuex'

export default {
  components: {ListMain, ListHeader, ListEditButton, ListDeleteButton},
  data() {
    return {
      isLoadingData: true,
      filters: {},
      dependencies: {}
    }
  },
  mounted() {
    bus.$emit('list-init', {domain: 'bank_account', relations: ['bank', 'default_payment_method']}, () => {
      bus.$emit('hide-loader')
      this.isLoadingData = false
    })
  },
  computed: {
    ...mapGetters(['listStore'])
  },
  methods: {
    onFilter() {
      const searchData = {}
      if (this.filters.name) {
        searchData.name = {
          'like': '%' + this.filters.name + '%'
        }
      }
      bus.$emit('list-filter', searchData)
    },
    onFilterClean() {
      this.filters = {}
      bus.$emit('list-filter-clean', this.filters)
    },
    onDestroy(item) {
      bus.$emit('list-destroy-item', item)
    }
  }
}
</script>

<template>
  <div>

    <ListHeader title="Contas Bancárias" route-name="bank_account.store"/>

    <ListMain>

      <div slot="filters">

        <el-card class="el-card__search_list box-card">
          <div slot="header">
            <span>Filtros</span>
            <div>
              <el-button type="primary" @click="onFilter" size="small">
                <i class="fas fa-filter"></i>
                <span>Aplicar Filtros</span>
              </el-button>
              <el-button type="default" @click="onFilterClean" size="small">
                <i class="fas fa-times"></i>
                <span>Limpar</span>
              </el-button>
            </div>
          </div>
          <el-form :inline="true" :model="filters">
            <el-form-item label="Nome">
              <el-input v-model="filters.name"
                        placeholder="Filtrar por nome"
                        @keydown.enter.native.prevent="onFilter"/>
            </el-form-item>
          </el-form>
        </el-card>

      </div>

      <div slot="list" v-if="!isLoadingData">

        <table class="custom-table-list">
          <thead>
          <tr>
            <th class="list-table-nowrap">ID</th>
            <th>Banco</th>
            <th>Nome</th>
            <th>Forma de Pgto. Padrão</th>
            <th>CPF/CNPJ</th>
            <th>Responsável</th>
            <th>Dados</th>
            <th>Status</th>
            <th class="btn-actions">Ações</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in listStore.listData" :key="index">
            <td>{{item.id}}</td>
            <td>{{item.bank ? item.bank.name : ''}}</td>
            <td>{{item.name}}</td>
            <td>{{item.default_payment_method ? item.default_payment_method.description : ''}}</td>
            <td>{{item.cpf_cnpj}}</td>
            <td>{{item.responsible_name}}</td>
            <td>{{item.agency}} / {{item.account_number}}<span v-if="item.account_digit">-{{item.account_digit}}</span>
            </td>
            <td>{{item.status === 'active' ? 'ATIVA' : 'INATIVA'}}</td>
            <td class="list-table-nowrap">
              <div class="btn-actions">
                <ListEditButton route-name="bank_account.update" :item="item"/>
                <ListDeleteButton :item="item" domain="bank_account"/>
              </div>
            </td>
          </tr>
          </tbody>
        </table>

      </div>

    </ListMain>
  </div>
</template>
