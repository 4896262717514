<script>
export default {
  name: 'schedule-type-label',
  props: {
    type: String,
    selected: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<template>
  <span :class="['schedule-type-label', '-' + type, {'is-selected': selected}]">
    <span class="text" v-if="type === 'system'">Horário Avulso</span>
    <span class="text" v-if="type === 'fixed'">Horário Fixo</span>
    <span class="text" v-if="type === 'app'">Online</span>
    <span class="text" v-if="type === 'unavailable'">Indisponível</span>
    <span class="text" v-if="type === 'lesson'">Aula Avulsa</span>
    <span class="text" v-if="type === 'lesson-fixed'">Aula Fixa</span>
    <template v-if="type === 'app_not_confirmed'">
      <span class="icon"><i class="fas fa-exclamation-triangle"></i></span>
      <span class="text">Não Confirmada</span>
    </template>
  </span>
</template>
