<script>
import GripoDialog from '../../../../../components/common/Dialog'
import EmptyContent from '../../../../../components/common/EmptyContent'
import CourtForm from '../../../../common/court/components/Form'

import * as restfulService from '../../../../../services/restfulService'
import * as notifyService from '../../../../../services/notifyService'
import DataService from '../../../../../services/dataService'

import helpers from '@mixins/helpers'
import bus from '@utils/bus'

import {clone} from 'lodash'
import {required} from 'vuelidate/lib/validators'
import {confirmDialog, warningDialog} from '@utils/flash'

const defaultPeriod = {
  court_id: '',
  date: '',
  live: false,
  live_url: '',
  hourIndex: null,
  start_hour: '08:00',
  end_hour: '00:00',
  interval: 45,
  players_class: [0]
}

export default {
  props: {
    stageId: {
      type: Number,
      required: true
    }
  },
  components: {GripoDialog, EmptyContent, CourtForm},
  mixins: [helpers],
  data() {
    return {
      dialogVisible: false,
      courtModalVisible: false,
      isSubmitted: false,
      listData: [],
      listCourts: [],
      tabs: [],
      selectedCourts: [],
      currentCourtSelected: null,
      currentCourtDateSelected: null,
      showSavePeriod: false,
      dependencies: {
        courts: [],
        stage_dates: [],
        stage_players_class: []
      },
      period: clone(defaultPeriod)
    }
  },
  validations: {
    period: {
      start_hour: {required},
      end_hour: {required},
      interval: {required},
      players_class: {required}
    }
  },
  methods: {
    getDependencies() {
      return new Promise(resolve => {
        DataService.get([
          {domain: 'court', data: {orderBy: [{column: 'order'}, {column: 'name'}]}},
          {
            domain: 'stage_player_class',
            data: {stage_id: this.stageId},
            relations: ['player_class']
          },
          {
            domain: 'stage_date',
            data: {stage_id: this.stageId}
          }
        ]).then((result) => {
          result.stage_players_class = [...result.stage_players_class.map(item => item.player_class)]
          result.stage_players_class.unshift({
            id: 0,
            name: 'Todas',
            name_with_sex: 'Todas'
          })
          this.dependencies = {...this.dependencies, ...result}
          resolve()
        })
      })
    },
    async getScheduleGrid() {
      return new Promise((resolve, reject) => {
        restfulService.get('manage/stage', 'schedule_grid', this.stageId)
            .then(response => {
              this.listCourts = [...response]
              if (response.length > 0) {
                this.currentCourtSelected = response[0].court_id.toString()
                this.selectedCourts = response.map(item => item.court_id)
              }
              resolve()
            })
            .catch(e => {
              this.isSubmitted = false
              console.log(e)
              reject(e)
            })
      })
    },
    async openDialog() {
      bus.$emit('show-loader')
      await this.getDependencies()
      this.isSubmitted = false
      this.dialogVisible = true
      this.listCourts.splice(0)
      this.selectedCourts.splice(0)
      this.currentCourtSelected = null
      this.currentCourtDateSelected = null
      await this.getScheduleGrid()
      bus.$emit('hide-loader')
    },
    closeDialog() {
      this.listCourts.splice(0)
      this.selectedCourts.splice(0)
      this.currentCourtSelected = null
      this.currentCourtDateSelected = null
      bus.$emit('hide-loader')
    },
    handleAddCourt() {
      this.courtModalVisible = !this.courtModalVisible
    },
    onSaveCourt(court) {
      this.handleAddCourt()
      this.dependencies.courts.push(court)
    },
    selectCourt(value, court) {
      if (value) {
        const datesHours = []
        this.dependencies.stage_dates.forEach(item => {
          const hours = this.getDateHours(item.date)
          this.$set(hours, 'players_class', [0])
          datesHours.push({
            date: item.date,
            hours: [hours]
          })
        })
        this.listCourts.push({
          court_id: court.id,
          name: court.name,
          dates: datesHours
        })
      }
    },
    async removeCourt(courtId) {
      const {value: confirm} = await confirmDialog({
        title: 'Deseja realmente remover essa quadra?',
        text: 'Os horários dessa quadra serão apagados!',
      })
      if (confirm) {
        this.listCourts = [...this.listCourts.filter(item => item.court_id !== parseInt(courtId))]
        this.selectedCourts = [...this.selectedCourts.filter(item => item !== parseInt(courtId))]
      }
    },
    toggleShowPeriod() {
      this.showSavePeriod = !this.showSavePeriod
    },
    resetPeriod() {
      this.period = {...clone(defaultPeriod)}
    },
    addPeriod(courtId, date) {
      this.resetPeriod()
      this.period.court_id = courtId
      this.period.date = date
      this.toggleShowPeriod()
    },
    async removePeriod(hour, courtIndex, dateIndex) {
      const {value: confirm} = await confirmDialog({
        title: 'Deseja realmente remover esse período?',
      })
      if (confirm) {
        const hourIndex = this.listCourts[courtIndex].dates[dateIndex].hours.findIndex(item => item.start_hour === hour.start_hour && item.end_hour === hour.end_hour)
        this.listCourts[courtIndex].dates[dateIndex].hours.splice(hourIndex, 1)
      }
    },
    updatePlayersClass(value, hour) {
      if (value.some(item => item === 0)) {
        hour.players_class = [...this.dependencies.stage_players_class]
      }
    },
    savePeriod() {
      const data = {...clone(this.period)}
      const courtIndex = this.listCourts.findIndex(item => item.court_id === data.court_id)
      const dateIndex = this.listCourts[courtIndex].dates.findIndex(item => item.date === data.date)
      this.listCourts[courtIndex].dates[dateIndex].hours.push(data)
      this.resetPeriod()
      this.toggleShowPeriod()
    },
    save(force) {
      force = force || false
      this.isSubmitted = true
      const list = [...this.listCourts]
      const data = {courts: [...list]}
      data.force = force
      if (data.courts.some(court => court.dates.some(date => date.hours.some(hour => !hour.start_hour || !hour.end_hour || !hour.interval || hour.players_class.length === 0)))) {
        warningDialog({
          title: 'Existem períodos sem hora de inicio e fim, intervalo ou categorias selecionadas',
          text: 'Verifique todos os períodos para prosseguir',
        })
        this.isSubmitted = false
        return
      }
      bus.$emit('show-loader')
      restfulService.put('stage', 'courts', this.stageId, data)
          .then(async (response) => {
            if (response.error !== undefined && typeof response.error === 'object') {
              bus.$emit('hide-loader')
              this.isSubmitted = false
              const {value: confirm} = await confirmDialog({
                title: response.error.title,
                html: response.error.message,
              })
              if (confirm) {
                this.save(true)
              }
            } else {
              notifyService.success({hideLoader: true})
              bus.$emit('hide-loader')
              this.isSubmitted = false
            }
          })
          .catch(e => {
            bus.$emit('hide-loader')
            this.isSubmitted = false
            console.log(e)
          })
    }
  }
}

</script>


<template>
  <div class="stage-card card-courts">

    <div class="card-content">
      <div class="card-icon">
        <i class="fas fa-calendar-alt"></i>
      </div>
      <div class="card-information">
        <div class="card-title">Quadras e Horários</div>

        <div class="card-buttons">
          <div class="box">
            <div class="full">
              <el-button @click="openDialog()" type="default" size="small">
                <i class="fas fa-cog"></i> Gerenciar
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <gripo-dialog :model.sync="dialogVisible"
                  width="90vw"
                  top="2vh"
                  :on-close="closeDialog">
      <template v-slot:header>Gerenciar Quadras e Horários</template>
      <template v-slot:content>
        <template v-if="dependencies.courts.length === 0">

          <empty-content>
            <template v-slot:content>
              <div class="text-center">
                <h3 class="title">Você não possui quadras cadastradas</h3>
                <router-link :to="{ name: 'court.index'}"
                             class="el-button el-button--default"
                             title="Ir para o Cadastro de Quadras">
                  <i class="fas fa-square"></i>
                  <span class="ml-1">Ir para o Cadastro de Quadras</span>
                </router-link>
              </div>
            </template>
          </empty-content>
        </template>
        <template v-else>
          <el-form>
            <div class="flex-c pb-3">
              <h3 class="mb-0">Selecione as Quadras</h3>
              <el-button type="success"
                         size="mini"
                         @click="handleAddCourt">
                <i class="fas fa-plus"></i>
                <span>Adicionar Quadra Nova</span>
              </el-button>
            </div>
            <el-form-item>
              <el-checkbox-group v-model="selectedCourts" class="courts-checkbox-item" size="mini">
                <el-checkbox v-for="(item, index) in dependencies.courts"
                             :key="index"
                             @change="selectCourt($event, item)"
                             :label="item.id"
                             :disabled="selectedCourts.some(court => court === item.id)"
                             border>{{ item.name }}
                </el-checkbox>
              </el-checkbox-group>
            </el-form-item>

          </el-form>

          <el-tabs v-model="currentCourtSelected"
                   type="card"
                   closable
                   @tab-remove="removeCourt">
            <el-tab-pane v-for="(court, courtIndex) in listCourts"
                         :key="court.court_id"
                         :name="court.court_id.toString()"
                         :label="court.name">

              <el-tabs v-model="currentCourtDateSelected"
                       type="border-card">
                <el-tab-pane v-for="(date, dateIndex) in court.dates"
                             :key="date.date"
                             :label="date.date | dateEnToBr">
                  <div class="mb-3">
                    <el-button type="primary"
                               size="small"
                               @click="addPeriod(court.court_id, date.date)"
                               :disabled="showSavePeriod">
                      <i class="fas fa-plus"></i>
                      <span>Inserir Período</span>
                    </el-button>
                    <template v-if="showSavePeriod">
                      <v-divider class="mt-3 mb-2"></v-divider>
                      <el-form>
                        <el-row :gutter="20">
                          <el-col :span="4">
                            <el-form-item label="Hora Inicial"
                                          :class="{ 'el-form-item--error': $v.period.start_hour.$error }">
                              <el-input placeholder="Ex.: 08:00"
                                        v-mask="'##:##'"
                                        v-model="period.start_hour"
                                        @input="$v.period.start_hour.$touch()"></el-input>
                            </el-form-item>
                          </el-col>
                          <el-col :span="4">
                            <el-form-item label="Hora Inicial"
                                          :class="{ 'el-form-item--error': $v.period.end_hour.$error }">
                              <el-input placeholder="Ex.: 22:00"
                                        v-mask="'##:##'"
                                        v-model="period.end_hour"
                                        @input="$v.period.end_hour.$touch()"></el-input>
                            </el-form-item>
                          </el-col>
                          <el-col :span="4">
                            <el-form-item label="Intervalo (em minutos)"
                                          :class="{ 'el-form-item--error': $v.period.interval.$error }">
                              <el-input placeholder="Ex.: 45"
                                        type="number"
                                        :min="1"
                                        :step="1"
                                        v-model="period.interval"
                                        @input="$v.period.interval.$touch()"></el-input>
                            </el-form-item>
                          </el-col>
                          <el-col :span="6">
                            <v-select v-model="period.players_class"
                                      :items="dependencies.stage_players_class"
                                      :error-messages="validationMessageField($v.period.players_class)"
                                      @input="$v.period.players_class.$touch()"
                                      @blur="$v.period.players_class.$touch()"
                                      label="Categorias permitidas"
                                      item-text="name_with_sex"
                                      item-value="id"
                                      outline
                                      chips
                                      multiple></v-select>
                          </el-col>
                          <el-col :span="6">
                            <el-button type="primary"
                                       @click="savePeriod"
                                       :disabled="isSubmitted || $v.period.$invalid"
                                       :loading="isSubmitted">
                              Salvar Período
                            </el-button>
                            <el-button type="default"
                                       @click="toggleShowPeriod">
                              Cancelar
                            </el-button>
                          </el-col>
                        </el-row>
                      </el-form>
                    </template>
                  </div>
                  <table class="custom-table-list">
                    <thead>
                    <tr>
                      <th>Hora Inicial</th>
                      <th>Hora Final</th>
                      <th>Intervalo (em minutos)</th>
                      <th>Categorias selecionadas</th>
                      <th class="list-table-nowrap">Ações</th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-for="(hour, hourIndex) in sortListBy(date.hours, 'start_hour')">
                      <tr :key="hourIndex + 1">
                        <td>
                          <el-input placeholder="Hora Inicial"
                                    v-mask="'##:##'"
                                    v-model="hour.start_hour"></el-input>
                        </td>
                        <td>
                          <el-input placeholder="Hora Final"
                                    v-mask="'##:##'"
                                    v-model="hour.end_hour"></el-input>
                        </td>
                        <td>
                          <el-input placeholder="Intervalo (em minutos)"
                                    type="number"
                                    :min="1"
                                    :step="1"
                                    v-model="hour.interval"></el-input>
                        </td>
                        <td class="courts-hours-players-class">
                          <template>
                            <v-select v-model="hour.players_class"
                                      :items="dependencies.stage_players_class"
                                      label="Categorias"
                                      item-text="name_with_sex"
                                      item-value="id"
                                      outline
                                      chips
                                      hide-details
                                      @change="updatePlayersClass($event, hour)"
                                      multiple></v-select>
                          </template>
                          <template v-if="false">
                            <el-tag size="mini" class="mt-1 mr-1"
                                    v-for="(playerClassName, index) in hour.players_class_names" :key="index">
                              {{ playerClassName }}
                            </el-tag>
                          </template>
                        </td>
                        <td class="list-table-nowrap">
                          <el-button @click="removePeriod(hour, courtIndex, dateIndex)"
                                     type="danger"
                                     size="small"
                                     icon="el-icon-delete"
                                     title="Remover Período">
                          </el-button>
                        </td>
                      </tr>
                      <tr :key="(hourIndex + 1) * 50">
                        <td>
                          <div class="el-form-item mb-1">
                            <label class="el-form-item__label">Ao Vivo?</label>
                            <div class="el-form-item__content">
                              <el-switch v-model="hour.live"
                                         :active-value="true"
                                         active-text="SIM"
                                         :inactive-value="false"
                                         inactive-text="NÃO">
                              </el-switch>
                            </div>
                          </div>
                        </td>
                        <td colspan="4">
                          <div class="el-form-item mb-1">
                            <label class="el-form-item__label">Link Transmissão</label>
                            <div class="el-form-item__content">
                              <el-input placeholder="Link"
                                        v-model="hour.live_url"></el-input>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </template>
                    </tbody>
                  </table>
                </el-tab-pane>
              </el-tabs>

            </el-tab-pane>
          </el-tabs>

          <empty-content>
            <template v-slot:content>
              <div class="text-center">
                <h3 class="title">
                  Muita atenção ao alterar os horários
                </h3>
                <p>Os jogos já gerados que não se encaixarem na configuração da grade serão transferidos para a aba "Sem
                  horário definido"</p>
              </div>
            </template>
          </empty-content>

        </template>

      </template>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <el-button type="primary"
                   v-if="listCourts.length > 0"
                   @click="save()"
                   :disabled="isSubmitted"
                   :loading="isSubmitted">
          {{ isSubmitted ? 'Salvando' : 'Salvar' }} Alterações
        </el-button>
      </template>
    </gripo-dialog>

    <gripo-dialog :model.sync="courtModalVisible"
                  width="85vw"
                  :actions-close-btn="false"
                  top="3vh">
      <template v-slot:header>Adicionar Nova Quadra</template>
      <template v-slot:content>
        <CourtForm modal @save="onSaveCourt"/>
      </template>
    </gripo-dialog>

  </div>
</template>
