import Schedule from './components/Schedule.vue'
import List from './components/List.vue'

export default [
    {
        path: '/schedule-daily/:date?/:id?',
        component: Schedule,
        name: 'schedule_daily.index',
        meta: {permission: 'search.schedule'}
    },
    {path: '/schedule', component: List, name: 'schedule.index'}
]
