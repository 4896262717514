<script>
import ListMain from '../../../../components/list/Main'
import ListHeader from '../../../../components/list/Header'
import DeviceOrderPaymentTypeLabel from './labels/PaymentTypeLabel'
import DeviceOrderStatusLabel from './labels/StatusLabel.vue'

import * as restfulService from '../../../../services/restfulService'
import * as notifyService from '../../../../services/notifyService'

import helpers from '@mixins/helpers'
import bus from '@utils/bus'
import {mapGetters} from 'vuex'

const defaultFilters = {
  status: 'created',
  device_sn: 'all',
  orderBy: [{column: 'created_at', direction: 'desc'}]
}

export default {
  components: {
    ListMain,
    ListHeader,
    DeviceOrderPaymentTypeLabel,
    DeviceOrderStatusLabel,
  },
  mixins: [helpers],
  data() {
    return {
      isLoadingData: true,
      isSubmitted: false,
      filters: {...defaultFilters},
      dependencies: {},
    }
  },
  mounted() {
    bus.$emit('list-init', {
      domain: 'device_order',
      data: {...defaultFilters}
    }, () => {
      bus.$emit('hide-loader')
      this.isLoadingData = false
    })
  },
  computed: {
    ...mapGetters(['listStore', 'tenant']),
    posStonePaymentDevices() {
      return this.tenant?.settings?.tenant_stone_payment?.devices || []
    }
  },
  methods: {
    prepareSearchData() {
      const searchData = {}
      if (this.filters.dates && this.filters.dates.length > 0) {
        let startDate = this.dateFormat(this.filters.dates[0], 'en')
        let endDate = this.dateFormat(this.filters.dates[1], 'en')
        if (startDate.length > 0 && endDate.length > 0) {
          searchData.created_at = {between: [startDate + ' 00:00:00', endDate + ' 23:59:59']}
        }
      }
      if (this.filters.status !== 'all') {
        searchData.status = this.filters.status
      }
      if (this.filters.payment_method_type !== 'all') {
        searchData.payment_method_type = this.filters.payment_method_type
      }
      if (this.filters.device_sn !== 'all') {
        searchData.device_sn = this.filters.device_sn
      }
      return searchData
    },
    onFilter() {
      const searchData = this.prepareSearchData()
      bus.$emit('list-filter', searchData)
    },
    onFilterClean() {
      this.filters = {...defaultFilters}
      const data = this.prepareSearchData()
      bus.$emit('list-filter-clean', data)
    },
    cancelOrder(order) {
      bus.$emit('show-loader')
      this.isSubmitted = true
      restfulService.post('device_order', 'cancel', null, {device_order_id: order.id})
          .then(() => {
            notifyService.success()
            this.isSubmitted = false
            this.onFilter()
          })
          .catch(e => {
            this.isSubmitted = false
            bus.$emit('hide-loader')
            console.log(e)
          })
    },
    checkStatus(deviceOrder) {
      bus.$emit('show-loader')
      this.isSubmitted = true
      restfulService.post('device_order', 'check-status', null, {device_order_id: deviceOrder.id})
          .then(({message}) => {
            notifyService.success({message})
            this.isSubmitted = false
            this.onFilter()
          })
          .catch(e => {
            this.isSubmitted = false
            bus.$emit('hide-loader')
            console.log(e)
          })
    },
  }
}
</script>

<template>
  <div>

    <ListHeader title="Notas Fiscais"/>

    <ListMain>

      <div slot="filters">

        <el-card class="el-card__search_list box-card">
          <div slot="header">
            <span>Filtros</span>
            <div>
              <el-button type="primary" @click="onFilter" size="small">
                <i class="fas fa-filter"></i>
                <span>Aplicar Filtros</span>
              </el-button>
              <el-button type="default" @click="onFilterClean" size="small">
                <i class="fas fa-times"></i>
                <span>Limpar</span>
              </el-button>
            </div>
          </div>
          <el-form :inline="true" :model="filters">
            <el-form-item label="Forma de Pagamento">
              <el-select v-model="filters.payment_method_type"
                         clearable
                         placeholder="Filtrar por Tipo">
                <el-option label="Não filtrar" value="all"/>
                <el-option label="Cartão de Débito" value="debit"/>
                <el-option label="Cartão de Crédito" value="credit"/>
                <el-option label="PIX" value="pix"/>
              </el-select>
            </el-form-item>
            <el-form-item label="Status">
              <el-select v-model="filters.status"
                         placeholder="Filtrar por status"
                         empty-text="Nenhum registro encontrado">
                <el-option label="Não filtrar" value="all"/>
                <el-option label="Aguardando Pgto." value="created"/>
                <el-option label="Pago" value="paid"/>
                <el-option label="Cancelado" value="canceled"/>
              </el-select>
            </el-form-item>
            <el-form-item label="Período">
              <el-date-picker
                  v-model="filters.dates"
                  type="daterange"
                  format="dd/MM/yyyy"
                  placeholder="Filtrar por datas">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="Máquina POS">
              <el-select v-model="filters.device_sn"
                         placeholder="Filtrar por máquina"
                         empty-text="Nenhum registro encontrado">
                <el-option label="Não filtrar" value="all"/>
                <el-option v-for="device in posStonePaymentDevices"
                           :label="device.name"
                           :key="device.sn"
                           :value="device.sn" />
              </el-select>
            </el-form-item>
          </el-form>
        </el-card>

      </div>

      <div slot="list" v-if="!isLoadingData">

        <table class="custom-table-list">
          <thead>
          <tr>
            <th>Data/Hora</th>
            <th>Forma de Pagamento</th>
            <th>Status</th>
            <th class="btn-actions">Ações</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in listStore.listData" :key="index">
            <td>{{ item.created_at | dateTimeEnToBrFull }}</td>
            <td>
              <device-order-payment-type-label :payment-method-type="item.payment_method_type"/>
            </td>
            <td>
              <device-order-status-label :status="item.status"/>
            </td>
            <td class="list-table-nowrap">
              <div class="btn-actions">
                <el-button type="info"
                           :disabled="isSubmitted"
                           class="mr-1"
                           v-if="item.status === 'created'"
                           title="Consultar Status do Pedido"
                           @click="checkStatus(item)">
                  <i class="fas fa-sync"></i>
                </el-button>
                <el-popconfirm
                    :title="`Deseja realmente cancelar esse pedido?`"
                    confirm-button-text="Sim!"
                    cancel-button-text="Não"
                    icon=""
                    @confirm="cancelOrder(item)">
                  <el-button type="danger"
                             slot="reference"
                             :disabled="isSubmitted"
                             v-if="item.status === 'created'"
                             title="Cancelar Pedido">
                    <i class="fas fa-times"></i>
                  </el-button>
                </el-popconfirm>
              </div>
            </td>
          </tr>
          </tbody>
        </table>

      </div>

    </ListMain>

  </div>
</template>
