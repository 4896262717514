import {isEmpty} from 'lodash'

export const isLogged = ({token}) => !isEmpty(token)
export const token = ({token}) => token
export const currentUser = ({user}) => user
export const tenant = ({tenant}) => tenant
export const session = ({session}) => session
export const permissions = ({permissions}) => permissions
export const roles = ({roles}) => roles
export const isCoach = ({roles}) => roles.length === 1 && roles.includes('professores')
export const isAdmin = ({roles}) => roles.includes('administrador')
export const isFinancial = ({roles}) => roles.includes('financeiro')
