<script>
import ListMain from '../../../../components/list/Main'
import ListHeader from '../../../../components/list/Header'
import StockTypeLabel from './TypeLabel'

import DataService from '../../../../services/dataService'

import bus from '@utils/bus'
import {mapGetters} from 'vuex'

export default {
  components: {ListMain, ListHeader, StockTypeLabel},
  data() {
    return {
      isLoadingData: true,
      filters: {},
      dependencies: {
        products: []
      }
    }
  },
  mounted() {
    this.getDependencies()
    bus.$emit('list-init', {domain: 'stock', relations: ['product']}, () => {
      bus.$emit('hide-loader')
      this.isLoadingData = false
    })
  },
  computed: {
    ...mapGetters(['listStore'])
  },
  methods: {
    getDependencies() {
      DataService.get([{domain: 'product'}]).then((result) => {
        this.dependencies = {...this.dependencies, ...result}
      })
    },
    onFilter() {
      const searchData = {}
      if (this.filters.product_id) {
        searchData.product_id = this.filters.product_id
      }
      bus.$emit('list-filter', searchData)
    },
    onFilterClean() {
      this.filters = {}
      bus.$emit('list-filter-clean', this.filters)
    },
    onDestroy(item) {
      bus.$emit('list-destroy-item', item)
    }
  }
}
</script>

<template>
  <div>

    <ListHeader title="Movimentação do Estoque"
                route-name="stock.store"
                store-title="Atualizar Estoque Manual"/>

    <ListMain>

      <div slot="filters">

        <el-card class="el-card__search_list box-card">
          <div slot="header">
            <span>Filtros</span>
            <div>
              <el-button type="primary" @click="onFilter" size="small">
                <i class="fas fa-filter"></i>
                <span>Aplicar Filtros</span>
              </el-button>
              <el-button type="default" @click="onFilterClean" size="small">
                <i class="fas fa-times"></i>
                <span>Limpar</span>
              </el-button>
            </div>
          </div>
          <el-form :inline="true" :model="filters">
            <el-form-item label="Produto">
              <el-select v-model="filters.product_id"
                         filterable
                         clearable
                         placeholder="Selecione um produto"
                         empty-text="Nenhum registro encontrado"
                         class="el-select-full"
                         @keydown.enter.native.prevent="onFilter">
                <el-option
                    v-for="item in dependencies.products"
                    :key="item.id"
                    :label="item.description"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-card>

      </div>

      <div slot="list" v-if="!isLoadingData">

        <table class="custom-table-list">
          <thead>
          <tr>
            <th>Produto</th>
            <th>Tipo</th>
            <th>Quantidade</th>
            <th>Data</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in listStore.listData" :key="index">
            <td>{{ item.product.description }}</td>
            <td>
              <stock-type-label :type="item.type"/>
            </td>
            <td>{{ (item.type === 'input' ? '' : '- ') + item.quantity }}</td>
            <td>{{ item.created_at | dateTimeEnToBr }}</td>
          </tr>
          </tbody>
        </table>

      </div>

    </ListMain>
  </div>
</template>
