import * as authService from '../app/services/authService'

import {get} from 'lodash'
import {get as getStorage, set, remove} from '@utils/storage'
import {alertDialog} from '@utils/flash'

const devEnvironment = process.env.NODE_ENV === 'development'

/**
 * Handle API messages. *
 */
export default {
    async success(response) {
        const blocked = await getStorage('blocked')
        if (blocked) {
            const shouldntRemoveBlocked = ['tenant/data', 'tenant/entries', 'tenant/entry-payment', 'auth/login', 'auth/logout']
                .includes(response.config.url)
            if (!shouldntRemoveBlocked) {
                await remove('blocked')
            }
        }
        return response
    },
    async error(error, router) {
        const swalData = {
            icon: 'warning',
            title: 'Atenção',
            message: 'Cod [100] - Falha ao realizar operação'
        }
        let logoutUser = false

        try {
            // API Timeout
            if (error.toString().includes('timeout')) {
                swalData.message = 'A sua solicitação está demorando muito, tente novamente mais tarde'
                if (devEnvironment) {
                    console.error('[API TIMEOUT]')
                }
            } else {
                // Get status code
                const status = get(error, 'response.status')
                // Get error message
                const responseData = get(error, 'response.data') || 'Cod [150] - Falha ao realizar operação'

                /**
                 * Payment Required - Block system
                 */
                if ([402].indexOf(status) > -1) {
                    swalData.message = get(responseData, 'error')
                    const blocked = await getStorage('blocked')
                    if (blocked !== true) {
                        await set('blocked', true)
                        router.push({name: 'tenant_settings.show', params: {tab: 'payment'}})
                    }
                }

                /**
                 * Authorization errors
                 */
                if ([401, 403].indexOf(status) > -1) {
                    const responseMessage = get(responseData, 'message')
                    const responseError = get(responseData, 'error')
                    logoutUser = true

                    // Token expired force logout
                    if (responseMessage && responseMessage === 'Token has expired') {
                        swalData.message = 'Sua sessão expirou, faça login novamente'
                    } else {
                        swalData.message = responseError
                    }
                }

                /**
                 * Not Found
                 */
                if ([404].indexOf(status) > -1) {
                    swalData.title = 'Recurso não encontrado'
                    swalData.message = 'Verifique suas permissões ou página acessada'
                }

                /**
                 * Wrong/Missing data or Validation errors
                 */
                if ([400, 422].indexOf(status) > -1) {
                    const responseError = get(responseData, 'error')
                    if (typeof responseError === 'string') {
                        swalData.message = responseError
                    }
                    if (typeof responseError === 'object') {
                        const responseErrorTitle = get(responseError, 'title')
                        const responseErrorMessage = get(responseError, 'message')
                        if (responseErrorTitle || responseErrorMessage) {
                            if (responseErrorTitle) {
                                swalData.title = responseErrorTitle
                            }
                            if (responseErrorMessage) {
                                swalData.message = responseErrorMessage
                            }
                        } else {
                            let errors = []
                            for (error in responseError) {
                                if (typeof responseError[error] === 'object' && responseError[error].length > 0) {
                                    errors.push(responseError[error][0])
                                }
                            }
                            swalData.message = errors.join('<br>')
                        }
                    }
                }

                /**
                 * WebServer error
                 */
                if ([500].indexOf(status) > -1) {
                    swalData.message = 'Foi encontrado um erro ao processar a sua operação, por favor tente novamente mais tarde'
                    swalData.icon = 'error'
                }

                /**
                 * WebServer in Maintenance
                 */
                if ([503].indexOf(status) > -1) {
                    swalData.message = 'O sistema está em manutenção, acompanhe nossas redes sociais para mais informações'
                    swalData.icon = 'warning'
                }
            }

            // Show alert modal
            alertDialog({title: swalData.title, html: swalData.message, icon: swalData.icon})

            // Logout user
            if (logoutUser) {
                authService.logout().then(() => router.push({name: 'auth.login'}))
            }
        } catch (e) {
            alertDialog({
                title: swalData.title,
                html: 'Aconteceu um erro de comunicação, tente novamente mais tarde',
                icon: swalData.icon
            })
            if (devEnvironment) {
                console.error('[API CONNECTION ERROR]')
            }
        }
        return Promise.reject(error)
    }
}
