<script>
import GripoDialog from '../../../../../../components/common/Dialog'
import GroupsEliminatory from './Eliminatory'
import StageBracketSystem from '../../StageBracketSystem'

import DataService from '../../../../../../services/dataService'
import * as restfulService from '../../../../../../services/restfulService'
import * as notifyService from '../../../../../../services/notifyService'

import helpers from '@mixins/helpers'
import bus from '@utils/bus'

import {orderBy} from 'lodash'
import Draggable from 'vuedraggable'
import {confirmDialog} from '@utils/flash'

export default {
  props: {
    stage: {
      type: Object,
      required: true
    },
    stageId: {
      type: Number,
      required: true
    },
    maxPairPerGroup: {
      type: Number,
      required: true
    },
    groups: {
      type: Number,
      required: true
    },
    matches: {
      type: Number,
      required: true
    }
  },
  components: {GripoDialog, Draggable, GroupsEliminatory, StageBracketSystem},
  mixins: [helpers],
  data() {
    return {
      list: [],
      listWithoutGroup: [],
      listGroups: [],
      listEliminatory: [],
      dialogVisible: false,
      modalSimulatorVisible: false,
      isGroupsGenerated: false,
      isMatchesGenerated: false,
      isLoadingEliminatory: false,
      hideToPrint: false,
      groupsLettersNotMatch: false,
      pairsTopPosition: 0,
      form: {
        player_class_id: '',
        letters: []
      },
      selectedPlayerClass: {
        id: null,
        player_class_id: null,
        type: ''
      },
      formLabelWidth: '120px',
      isSubmitted: false,
      dependencies: {
        stage_players_class: [],
        letters: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'X', 'Y', 'Z']
      },
      simulator: {
        modalSimulatorVisible: false,
        playersClass: [],
        settings: {
          max_pair_per_group: 3,
          bracket_system: 'common',
        },
      }
    }
  },
  computed: {
    dragPairsOptions() {
      if (this.selectedPlayerClass.type === 'eliminatory') {
        return {group: {name: 'pairs', pull: 'clone'}, scroll: false}
      }
      return {group: {name: 'pairs'}, scroll: false}
    },
    orderedListGroups() {
      return orderBy(this.listGroups, ['group'], ['asc'])
    },
    simulatorPairsCount() {
      return this.simulator.playersClass.reduce((total, playerClass) => {
        return total + playerClass.pairs_count
      }, 0)
    },
    simulatorMatchesCount() {
      return this.simulator.playersClass.reduce((total, playerClass) => {
        return total + playerClass.matches.groups_matches_count + playerClass.matches.eliminatories_matches.length
      }, 0)
    }
  },
  watch: {
    listGroups: {
      deep: true,
      handler: function (value) {
        const handleValue = orderBy(value.filter(item => item.pairs.length > 0), ['group'], ['asc'])
        this.groupsLettersNotMatch = handleValue.length > 0
            && handleValue[handleValue.length - 1].group !== this.dependencies.letters[handleValue.length - 1]
      }
    },
    'simulator.settings.bracket_system': function (value) {
      if (value === 'cobrapa') {
        this.simulator.settings.max_pair_per_group = 3
      }
    }
  },
  mounted() {
    this.isGroupsGenerated = this.groups > 0
    this.isMatchesGenerated = this.matches > 0
    this.getDependencies()
    bus.$on('update-brackets', () => {
      this.isLoadingEliminatory = true
      setTimeout(() => {
        this.isLoadingEliminatory = false
      }, 100)
    })
  },
  beforeDestroy() {
    if (this.$refs.groupsContent) {
      this.$refs.groupsContent.removeEventListener('scroll', this.handleScroll)
    }
    bus.$off('update-brackets')
  },
  methods: {
    getDependencies() {
      DataService.get([{
        domain: 'stage_player_class',
        data: {stage_id: this.stageId},
        relations: ['player_class']
      }]).then((result) => {
        this.dependencies = {...this.dependencies, ...result}
      })
    },
    openDialog() {
      bus.$emit('show-loader')
      this.isSubmitted = false
      this.form.player_class_id = ''
      this.selectedPlayerClass = {
        ...{
          id: null,
          player_class_id: null,
          type: ''
        }
      }
      this.listWithoutGroup.splice(0)
      this.listGroups.splice(0)
      restfulService.get('manage/stage', 'groups', this.stageId)
          .then((response) => {
            this.list = [...response.stage.groups]
            this.isGroupsGenerated = this.list.length > 0
            this.dialogVisible = true
            setTimeout(() => {
              this.$refs.groupsContent.addEventListener('scroll', this.handleScroll)
            }, 300)
            bus.$emit('hide-loader')
          })
          .catch((e) => {
            bus.$emit('hide-loader')
            console.log(e)
          })
    },
    closeDialog() {
      if (this.$refs.groupsContent) {
        this.$refs.groupsContent.removeEventListener('scroll', this.handleScroll)
      }
      bus.$emit('hide-loader')
    },
    handleSimulator(data) {
      if (data === null) {
        this.simulator.playersClass.splice(0)
        this.simulator.settings.bracket_system = this.stage.settings?.bracket_system
        this.simulator.settings.max_pair_per_group = this.stage.settings.max_pair_per_group
      }
      bus.$emit('show-loader')
      restfulService.post('manage/stage', 'simulator', this.stageId, {
        ...data || {},
        settings: {
          max_pair_per_group: this.simulator.settings.max_pair_per_group,
          bracket_system: this.simulator.settings.bracket_system,
        }
      })
          .then((response) => {
            this.simulator.playersClass = [...response]
            if (!this.simulator.modalSimulatorVisible) {
              this.simulator.modalSimulatorVisible = !this.simulator.modalSimulatorVisible
            }
            bus.$emit('hide-loader')
          })
          .catch((e) => {
            bus.$emit('hide-loader')
            console.log(e)
          })
    },
    selectPlayerClass() {
      const playerClass = this.list.find(item => item.player_class_id === this.form.player_class_id)
      const playerClassObj = this.dependencies.stage_players_class.find(item => item.player_class_id === this.form.player_class_id)
      if (playerClass) {
        this.selectedPlayerClass = {...playerClassObj}
        this.listWithoutGroup = [...playerClass.without_group]
        this.listGroups = [...playerClass.groups]
      } else {
        this.listWithoutGroup.splice(0)
        this.listGroups.splice(0)
      }
    },
    async saveGroup(list, group) {
      bus.$emit('show-loader')
      const promises = list.map(async (item) => {
        const data = {
          id: this.stageId,
          stage_id: this.stageId,
          player_class_id: this.form.player_class_id,
          pair_id: item.id,
          group: group ? group.group : null
        }
        await restfulService.put('stage', 'group', this.stageId, data)
      })
      await Promise.all(promises)
      notifyService.success({hideLoader: true})
      bus.$emit('hide-loader')
    },
    checkGroup(group) {
      if (group.has_matches) {
        this.$notify({
          title: 'Esse grupo já possui jogos gerados',
          text: 'Remova todos os jogos do grupos para poder realizar alterações',
          type: 'warning',
          duration: 5000
        })
        return false
      }
    },
    addGroup() {
      for (let index in this.dependencies.letters) {
        const letter = this.dependencies.letters[index]
        if (!this.listGroups.some(listGroup => listGroup.group === letter)) {
          this.listGroups.push({
            group: letter,
            pairs: []
          })
          break
        }
      }
    },
    removeGroup(group) {
      this.listGroups = [...this.listGroups.filter(item => item.group !== group)]
    },
    generateBrackets(playerClassId) {
      const data = {player_class_id: playerClassId || null}
      bus.$emit('show-loader')
      restfulService.put('stage', 'groups', this.stageId, data)
          .then(() => {
            this.isGroupsGenerated = true
            notifyService.success({hideLoader: true})
            bus.$emit('hide-loader')
          })
          .catch((e) => {
            console.log(e)
            bus.$emit('hide-loader')
          })
    },
    async destroyGroups(playerClassId) {
      const {value: confirm} = await confirmDialog({
        title: 'Deseja realmente remover os Grupos?',
      })
      if (confirm) {
        const data = {player_class_id: playerClassId || null}
        bus.$emit('show-loader')
        restfulService.put('stage', 'destroy-groups', this.stageId, data)
            .then(() => {
              notifyService.success({hideLoader: true})
              this.isGroupsGenerated = false
              bus.$emit('hide-loader')
            })
            .catch((e) => {
              console.log(e)
              bus.$emit('hide-loader')
            })
      }
    },
    checkMaxPairPerGroup(to) {
      return to.el.children.length < this.maxPairPerGroup
    },
    commandDestroyGroups(command) {
      this.destroyGroups(command ? parseInt(command) : null)
    },
    commandGenerateGroups(command) {
      this.generateBrackets(command ? parseInt(command) : null)
    },
    generateGroups() {
      this.handleSimulator({generate_groups: true, players_class: [...this.simulator.playersClass]})
    },
    generateMatches() {
      this.handleSimulator({
        generate_groups: true,
        generate_matches: true,
        players_class: [...this.simulator.playersClass]
      })
    },
    handleScroll(event) {
      this.pairsTopPosition = event.target.scrollTop
    }
  }
}

</script>


<template>

  <div class="stage-card card-groups">

    <div class="card-content">
      <div class="card-icon">
        <i class="fas fa-users"></i>
      </div>
      <div class="card-information">
        <div class="card-title">Chaves/Eliminatórias</div>

        <div class="card-buttons">
          <div class="box">
            <div class="col">
              <el-button @click="openDialog" size="small">
                <i class="fas fa-cog"></i> <span>Gerenciar</span>
              </el-button>
            </div>
            <div class="col">
              <el-button @click="handleSimulator(null)" type="warning" size="small">
                <i class="fas fa-cubes"></i> Simulador
              </el-button>
            </div>
            <div class="col">
              <el-dropdown trigger="click"
                           key="commandGenerateGroups"
                           @command="commandGenerateGroups"
                           size="small">
                <el-button size="small" type="primary">
                  <i class="fas fa-check"></i>
                  <span>Gerar</span>
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="all">Todas Categorias</el-dropdown-item>
                  <el-dropdown-item v-for="(item, index) in stage.players_class"
                                    :key="index"
                                    :command="item.id">{{ item.name_with_sex }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div class="col">
              <el-dropdown trigger="click"
                           key="commandDestroyGroups"
                           @command="commandDestroyGroups"
                           size="small">
                <el-button type="danger"
                           size="small">
                  <i class="fas fa-times"></i>
                  <span>Remover</span>
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="all">Todas Categorias</el-dropdown-item>
                  <el-dropdown-item v-for="(item, index) in stage.players_class"
                                    :key="index"
                                    :command="item.id">{{ item.name_with_sex }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>

    <gripo-dialog :model.sync="dialogVisible"
                  width="99vw"
                  top="1vh"
                  :on-close="closeDialog">
      <template v-slot:header>
        <span>Gerenciar Chaves | Chaveamento <stage-bracket-system :bracket-system="stage.settings?.bracket_system"/></span>
      </template>
      <template v-slot:content>
        <div class="pb-2"><strong>Categoria</strong></div>
        <v-select :items="dependencies.stage_players_class"
                  v-model="form.player_class_id"
                  @change="selectPlayerClass"
                  item-text="player_class.name_with_sex"
                  item-value="player_class_id"
                  single-line
                  hide-details
                  outline
                  placeholder="Selecione uma Categoria"
                  no-data-text="Nenhum registro encontrado"
                  clearable/>
        <hr class="mt-3"/>
        <div v-if="list.length > 0">
          <v-container fluid grid-list-lg class="groups-container">
            <v-layout ref="groupsContent" wrap
                      style="max-height: calc(100dvh - 250px); overflow-x: hidden; overflow-y: scroll;">
              <v-flex sm3 xs12 class="groups-pairs-list" style="position: relative">
                <el-card class="el-card box-card draggable-list draggable-list-scrollable"
                         :style="{
                              position: 'sticky',
                              top: `${pairsTopPosition}px`,
                              maxHeight: 'calc(100dvh - 300px)',
                              overflowX: 'hidden',
                              overflowY: 'scroll',
                          }">
                  <div slot="header">
                    <span>Duplas</span>
                  </div>
                  <Draggable class="draggable-component"
                             v-model="listWithoutGroup"
                             :options="dragPairsOptions"
                             @add="saveGroup(listWithoutGroup, null)">
                    <div v-for="pair in listWithoutGroup"
                         :key="pair.id">
                      <div class="group-data" :data-id="pair.id">
                        <div class="pair-names" v-html="pair.names"></div>
                        <div class="pair-points"
                             v-if="pair.ranking_points !== null || pair.ranking_points !== undefined">
                          {{ pair.ranking_points }} pontos
                        </div>
                      </div>
                    </div>
                  </Draggable>
                </el-card>
              </v-flex>
              <v-flex sm9 xs12 class="groups-groups-list">
                <v-layout wrap v-if="selectedPlayerClass.type === 'groups'">
                  <el-alert class="mb-2" :closable="false" show-icon type="error" v-if="groupsLettersNotMatch">
                    <div class="fs-16">
                      O número de grupos ou a ordem das letras das chaves não parece
                      estar correto, verifique os grupos para poder continuar
                    </div>
                  </el-alert>
                  <v-flex sm3 xs12 :class="['groups-item']"
                          v-for="(group, index) in orderedListGroups" :key="index">
                    <el-card class="el-card box-card draggable-list">
                      <div slot="header">
                        <span>Chave {{ group.group }} {{ group.has_matches ? ' - Jogos Gerados' : '' }}</span>
                        <el-button @click="removeGroup(group.group)"
                                   v-if="group.pairs.length < 1"
                                   type="danger"
                                   size="small"
                                   icon="el-icon-close"
                                   circle/>
                      </div>
                      <Draggable :class="['draggable-component']"
                                 v-model="group.pairs"
                                 :options="{group:{name:'pairs'}, scroll:false}"
                                 @add="saveGroup(group.pairs, group)">
                        <div v-for="pair in group.pairs"
                             :class="[{'disabled': pair.has_matches}]"
                             :key="pair.id">
                          <div class="group-data" :data-id="pair.id">
                            <div class="pair-names" v-html="pair.names"></div>
                            <div class="pair-points"
                                 v-if="pair.ranking_points !== null || pair.ranking_points !== undefined">
                              {{ pair.ranking_points }} pontos
                            </div>
                          </div>
                        </div>
                        <div class="disabled-helper"></div>
                      </Draggable>
                    </el-card>
                  </v-flex>
                  <v-flex sm3 xs12 :class="['groups-item']"
                          v-if="listWithoutGroup.length > 0 || listGroups.length > 0">
                    <el-card class="el-card box-card draggable-list">
                      <div slot="header">
                        <span class="mr-2">Adicionar Chave</span>
                        <el-button class="add-group-button"
                                   @click="addGroup"
                                   type="primary"
                                   size="small"
                                   icon="el-icon-plus"
                                   circle/>
                      </div>
                    </el-card>
                  </v-flex>
                </v-layout>
                <template v-else>
                  <groups-eliminatory
                      :key="selectedPlayerClass.id"
                      v-if="stageId && selectedPlayerClass && selectedPlayerClass.type === 'eliminatory' && !isLoadingEliminatory"
                      :stage-id="stageId"
                      :player-class-id="selectedPlayerClass.player_class_id"/>
                </template>
              </v-flex>
            </v-layout>
          </v-container>
        </div>
      </template>
    </gripo-dialog>

    <gripo-dialog :model.sync="simulator.modalSimulatorVisible"
                  width="96vw"
                  top="1vh">
      <template v-slot:header>Simulador de Chaves e Jogos</template>
      <template v-slot:content>
        <el-form v-model="simulator" :inline="true">
          <v-container grid-list-md class="pl-0 pr-0 pt-2">
            <v-layout row>
              <v-flex xs3>
                <el-alert type="warning"
                          show-icon
                          :closable="false">
                  <div class="fs-14 pb-1">
                    Preencha com o número duplas de cada categoria para gerar a simulação de chaves e jogos
                  </div>
                </el-alert>
              </v-flex>
              <v-flex xs3>
                <el-form-item label="Sistema de Chaveamento">
                  <el-radio-group v-model="simulator.settings.bracket_system" size="mini">
                    <el-radio-button label="common">Padrão</el-radio-button>
                    <el-radio-button label="cobrapa">Cobrapa</el-radio-button>
                    <el-radio-button label="super16">Super16</el-radio-button>
                  </el-radio-group>
                </el-form-item>
              </v-flex>
              <v-flex xs2>
                <el-form-item label="Nº Máximo de Duplas por Chave">
                  <el-radio-group v-model="simulator.settings.max_pair_per_group"
                                  size="mini"
                                  :disabled="simulator.settings.bracket_system !== 'common'">
                    <el-radio-button :label="3">3</el-radio-button>
                    <el-radio-button :label="4">4</el-radio-button>
                  </el-radio-group>
                </el-form-item>
              </v-flex>
              <v-flex class="fs-18">
                <el-tag class="el-tag--big mt-2 mr-2 mb-2 ml-2" type="warning">
                  <strong>Total de Duplas: {{ simulatorPairsCount }}</strong>
                </el-tag>
                <el-tag class="el-tag--big mt-2 mr-2 mb-2 ml-2" type="success">
                  <strong>Total de Jogos: {{ simulatorMatchesCount }}</strong>
                </el-tag>
              </v-flex>
            </v-layout>
          </v-container>
          <v-container grid-list-md class="pl-0 pr-0 pt-2 groups-simulator-players-class">
            <v-layout row wrap>
              <v-flex xs2 v-for="playerClass in simulator.playersClass" :key="playerClass.id">
                <v-card>
                  <v-card-title class="subheading grey lighten-3">
                    {{ playerClass.name_with_sex }}
                  </v-card-title>
                  <v-card-text class="px-1 text-center">
                    <el-form-item class="mb-0">
                      <el-input-number size="mini" v-model="playerClass.pairs_count"/>
                    </el-form-item>
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
          <div class="pt-2 pb-3 text-center">
            <el-button class="el-button--large"
                       @click="generateGroups"
                       type="primary">
              Gerar Chaves
            </el-button>
            <el-button class="el-button--large ml-2"
                       @click="generateMatches"
                       type="success">
              Gerar Jogos
            </el-button>
          </div>
          <el-tabs type="card">
            <el-tab-pane v-for="playerClass in simulator.playersClass"
                         :key="playerClass.id"
                         :label="playerClass.name_with_sex">
              <div class="fs-16 pb-2 text-center">
                <el-tag class="el-tag--big">
                  <strong>A fase de grupos terá {{ playerClass.matches.groups_matches_count }} jogos</strong>
                </el-tag>
              </div>
              <v-container grid-list-md class="pl-1 pr-1 pt-0 groups-simulator-groups">
                <v-layout row wrap>
                  <v-flex xs3 v-for="group in playerClass.groups" :key="group.letter">
                    <v-card>
                      <v-card-title class="subheading grey lighten-3">
                        Grupo {{ group.letter }}
                      </v-card-title>
                      <v-card-text class="px-0 text-center">
                        <div v-for="pair in group.pairs" :key="pair.number">Dupla {{ pair.number }}</div>
                      </v-card-text>
                    </v-card>
                  </v-flex>
                </v-layout>
                <v-card class="mt-3" v-if="playerClass.matches.eliminatories_matches.length">
                  <v-card-title class="subheading grey lighten-3">
                    Jogos Eliminatórios - {{ playerClass.matches.eliminatories_matches.length }} jogos
                  </v-card-title>
                  <v-card-text class="px-0 text-center">
                    <v-list class="pt-0 pb-0 simulator-matches-eliminatory">
                      <template v-for="(match, index) in playerClass.matches.eliminatories_matches">
                        <v-list-tile :key="index">
                          <v-list-tile-content class="pt-2 pb-2">
                            <v-list-tile-sub-title class="text-center">
                              <el-tag size="mini">
                                {{ match.round_label }}
                              </el-tag>
                            </v-list-tile-sub-title>
                            <v-list-tile-title class="text-center fs-14">
                              {{ match.pair1_label }} x {{ match.pair2_label }}
                            </v-list-tile-title>
                          </v-list-tile-content>
                        </v-list-tile>
                        <v-divider :key="index + 50"
                                   v-if="index + 1 < playerClass.matches.eliminatories_matches.length"/>
                      </template>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-container>
            </el-tab-pane>
          </el-tabs>
        </el-form>
      </template>
    </gripo-dialog>
  </div>
</template>

