import Vue from 'vue'
import helpers from '@mixins/helpers'
import StringMask from 'string-mask'

Vue.filter('dateEnToBr', function (value) {
    if (!value) return ''
    return helpers.methods.dateFormat(value, 'br')
})
Vue.filter('dayMonthEnToBr', function (value) {
    if (!value) return ''
    return helpers.methods.dayMonthFormat(value, 'br')
})
Vue.filter('dateTimeEnToBr', function (value) {
    if (!value) return ''
    return helpers.methods.dateTimeFormat(value, true)
})
Vue.filter('dateTimeEnToBrFull', function (value) {
    if (!value) return ''
    return helpers.methods.dateTimeFormat(value, false)
})
Vue.filter('timeEnToBr', function (value) {
    if (!value) return ''
    return helpers.methods.dateTimeToTimeFormat(value, 'br', true)
})
Vue.filter('currencyFormatter', function (value, config = null) {
    if (!config) {
        config = {symbol: true}
    }
    return helpers.methods.currencyFormatter(value, config)
})
Vue.filter('phone', function (value) {
    if (value === '') return ''
    if (!value) return ''
    const formatter = new StringMask('(##) #####.####')
    return formatter.apply(value)
})
Vue.filter('cpfCnpj', function (value) {
    if (value === '') return ''
    if (!value) return ''
    let formatter = new StringMask('###.###.###-##')
    if (helpers.methods.onlyNumbers(value).length === 14) {
        formatter = new StringMask('##.###.###/####-##')
    }
    return formatter.apply(value)
})
Vue.filter('hourShort', function (value) {
    if (value === '') return ''
    if (!value) return ''
    return value.substr(0, 5)
})
Vue.filter('dateToWeekday', function (value) {
    if (!value) return ''
    return helpers.methods.dateToWeekday(value)
})
Vue.filter('shirtTypeLabel', function (value) {
    if (!value) return ''
    switch (value) {
        case 'tight':
            return 'Baby Look'
        case 'standard_female':
            return 'Feminina'
        default:
            return 'Normal'
    }
})
