<script>
import ListMain from '../../../../components/list/Main'
import ListHeader from '../../../../components/list/Header'
import NotificationFullModal from './NotificationFullModal.vue'

import bus from '@utils/bus'
import helpers from '@mixins/helpers'
import {mapGetters} from 'vuex'

export default {
  components: {ListMain, ListHeader, NotificationFullModal},
  mixins: [helpers],
  data() {
    return {
      isLoadingData: true,
      filters: {
        origin: 'all'
      },
      dependencies: {}
    }
  },
  mounted() {
    bus.$emit('list-init', {
      domain: 'user/notifications/search',
      relations: [],
      searchRoute: false
    }, () => {
      bus.$emit('hide-loader')
      this.isLoadingData = false
    })
  },
  computed: {
    ...mapGetters(['listStore'])
  },
  methods: {
    onFilter() {
      const searchData = {}
      if (this.filters.title) {
        searchData.title = this.filters.title
      }
      if (this.filters.sent_at) {
        searchData.sent_at = this.dateFormat(this.filters.sent_at, 'en')
      }
      if (this.filters.read_at) {
        searchData.read_at = this.dateFormat(this.filters.read_at, 'en')
      }
      if (this.filters.origin !== 'all') {
        searchData.origin = this.filters.origin
      }
      bus.$emit('list-filter', searchData)
    },
    onFilterClean() {
      this.filters = {
        origin: 'all'
      }
      bus.$emit('list-filter-clean', this.filters)
    },
    onDestroy(item) {
      bus.$emit('list-destroy-item', item)
    },
    notificationLink(notification) {
      this.$router.push({name: notification.data.extra.route.name, params: notification.data.extra.route.params})
      if (this.$router.currentRoute.name === notification.data.extra.route.name) {
        setTimeout(() => {
          window.location.reload()
        }, 300)
      }
    },
    checkOrigin(item) {
      return item && item.includes('APP_') ? 'app' : 'tenant'
    }
  }
}
</script>

<template>
  <div>

    <ListHeader title="Notificações"/>

    <ListMain>

      <div slot="filters">
        <el-card class="el-card__search_list box-card">
          <div slot="header">
            <span>Filtros</span>
            <div>
              <el-button type="primary" @click="onFilter" size="small">
                <i class="fas fa-filter"></i>
                <span>Aplicar Filtros</span>
              </el-button>
              <el-button type="default" @click="onFilterClean" size="small">
                <i class="fas fa-times"></i>
                <span>Limpar</span>
              </el-button>
            </div>
          </div>
          <el-form :inline="true" :model="filters">
            <el-form-item label="Título/Mensagem">
              <el-input v-model="filters.title"
                        placeholder="Filtrar por título/mensagem"
                        @keydown.enter.native.prevent="onFilter"/>
            </el-form-item>
            <el-form-item label="Data de Envio">
              <el-date-picker
                  v-model="filters.sent_at"
                  type="date"
                  format="dd/MM/yyyy"
                  placeholder="Filtrar por data de envio">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="Data de Leitura">
              <el-date-picker
                  v-model="filters.read_at"
                  type="date"
                  format="dd/MM/yyyy"
                  value-format="yyyy-MM-dd"
                  placeholder="Filtrar por data de leitura">
              </el-date-picker>
            </el-form-item>
          </el-form>
        </el-card>
      </div>

      <div slot="list" v-if="!isLoadingData">

        <table class="custom-table-list">
          <thead>
          <tr>
            <th class="text-center">Origem</th>
            <th>Título</th>
            <th>Mensagem</th>
            <th>Enviada em</th>
            <th>Lida em</th>
            <th>Arquivada em</th>
            <th class="btn-actions">Ações</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in listStore.listData" :key="index">
            <td class="text-center">
              <span class="notification-origin -app" v-if="checkOrigin(item.key) === 'app'"
                    :key="`${index}_app`">
                  App
              </span>
              <span class="notification-origin -tenant" v-else :key="`${index}_tenant`">
                  Gestão
              </span>
            </td>
            <td>{{ item.title }}</td>
            <td>
              <template v-if="item.message_key !== 'tenant_announcement_update'">
                {{ item.message }}
              </template>
              <template v-else>
                <notification-full-modal :notification="item"/>
              </template>
            </td>
            <td>{{ item.sent_at | dateTimeEnToBr }}</td>
            <td>{{ item.read_at | dateTimeEnToBr }}</td>
            <td>{{ item.filed_at | dateTimeEnToBr }}</td>
            <td class="list-table-nowrap">
              <div class="btn-actions">
                <el-button size="small"
                           v-if="item.data && item.data.extra && item.data.extra.route"
                           @click="notificationLink(item)"
                           title="Ir para o link">
                  <i class="fas fa-external-link-alt"></i>
                </el-button>
              </div>
            </td>
          </tr>
          </tbody>
        </table>

      </div>

    </ListMain>
  </div>
</template>
