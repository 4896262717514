<script>
export default {
  name: 'entry-interval-type',
  props: ['intervalType']
}
</script>

<template>
  <div class="d-inline-block">
    <span v-if="intervalType === 'days'">Dia(s)</span>
    <span v-if="intervalType === 'months'">Mês(es)</span>
    <span v-if="intervalType === 'years'">Ano(s)</span>
  </div>
</template>
