<script>
import * as restfulService from '@app/services/restfulService'

import helpers from '@mixins/helpers'
import {currencyConfig} from '@utils/money'
import {mapGetters} from 'vuex'
import DataService from '@app/services/dataService'

import {cloneDeep} from 'lodash'

export const defaultCoachConfig = {
  entries_by_coach: false,
  enable_app_schedule: false,
  amount_class: 0,
  selected_grid: [],
  sports: [],
  cancel_enabled: true,
  minutes_to_cancel_schedule: 240,
  max_schedule_client: 2,
}

export default {
  name: 'coach-data',
  components: {},
  mixins: [helpers],
  props: {
    enabled: {
      type: Boolean,
      default: false
    },
    clientId: {
      default: null
    },
    hasUser: {
      type: Boolean,
      default: false
    },
    coachConfig: {
      type: Object
    }
  },
  data() {
    return {
      isLoadingConfig: false,
      updateInputMoney: false,
      form: {...defaultCoachConfig},
      grid: [],
      selectedGrid: [],
      dependencies: {
        sports: []
      }
    }
  },
  computed: {
    ...mapGetters(['tenant', 'hasProfessionalPlan', 'isCoach', 'currentUser']),
    moneyMask() {
      return currencyConfig()
    }
  },
  watch: {
    form: {
      deep: true,
      handler() {
        this.$emit('update:coachConfig', this.form)
      }
    },
    selectedGrid: {
      deep: true,
      handler(value) {
        const data = cloneDeep({...this.form})
        data.selected_grid = [...value]
        this.$emit('update:coachConfig', data)
      }
    },
    'form.enable_app_schedule': function (value, oldValue) {
      if (!oldValue && value && !this.isLoadingConfig) {
        this.getCoachConfig(true)
      }
    },
    enabled: function (value) {
      if (!value) {
        this.updateInputMoney = true
        this.$emit('update:coachConfig', defaultCoachConfig)
        setTimeout(() => {
          this.updateInputMoney = false
        }, 100)
      }
    },
    'form.cancel_enabled'(value) {
      this.form.minutes_to_cancel_schedule = !value ? 0 : 240
    },
  },
  async mounted() {
    await this.getDependencies()
    this.getCoachConfig()
  },
  methods: {
    getDependencies() {
      return new Promise(resolve => {
        DataService.get([
          {domain: 'sport'},
        ]).then((result) => {
          this.dependencies = {...this.dependencies, ...result}
          resolve()
        })
      })
    },
    isChecked(key) {
      if (!Array.isArray(this.form.selected_grid) && this.form.selected_grid.length === 0) {
        return false
      }
      return Array.isArray(this.form.selected_grid) && this.form.selected_grid.some(hourKey => hourKey === key)
    },
    selectAll(weekday) {
      if (weekday === null) {
        this.grid.forEach(weekday => {
          weekday.courts.forEach(court => {
            this.selectGroup('all', court.hours)
          })
        })
      } else {
        weekday.courts.forEach(court => {
          this.selectGroup('all', court.hours)
        })
      }
    },
    clearAll(weekday) {
      if (weekday === null) {
        this.selectedGrid.splice(0)
      } else {
        weekday.courts.forEach(court => {
          this.selectGroup('clear', court.hours)
        })
      }
    },
    selectGroup(type, hours) {
      if (type === 'all') {
        this.selectedGrid = [...new Set([...this.selectedGrid, ...hours.map(item => item.key)])]
      } else if (type === 'morning') {
        this.selectedGrid = [...new Set([...this.selectedGrid, ...hours.filter(item => item.start_hour !== '00:00:00' && item.start_hour >= '02:00:00' && item.end_hour <= '12:00:00' && item.end_hour !== '00:00:00').map(item => item.key)])]
      } else if (type === 'evening') {
        this.selectedGrid = [...new Set([...this.selectedGrid, ...hours.filter(item => item.start_hour >= '12:00:00' && item.end_hour <= '18:00:00' && item.end_hour !== '00:00:00').map(item => item.key)])]
      } else if (type === 'night') {
        this.selectedGrid = [...new Set([...this.selectedGrid, ...hours.filter(item => (item.start_hour >= '18:00:00' && item.end_hour <= '23:59:59') || item.end_hour === '00:00:00').map(item => item.key)])]
      } else if (type === 'clear') {
        this.selectedGrid = [...this.selectedGrid.filter(hourKey => !(hours.map(item => item.key).some(item => item === hourKey)))]
      }
    },
    getCoachConfig(updateOnlyGrid) {
      updateOnlyGrid = updateOnlyGrid || false
      this.isLoadingConfig = true
      const data = {client_id: this.clientId || this.currentUser.tenant_client_id, load_grid: updateOnlyGrid}
      restfulService.post('client', 'get-coach-config', null, data)
          .then(response => {
            if (!updateOnlyGrid) {
              this.updateInputMoney = true
              if (response.coach_config.amount_class) {
                response.coach_config.amount_class = this.currencyFormatter(response.coach_config.amount_class)
              }
              this.form = cloneDeep({...this.form, ...response.coach_config})
              setTimeout(() => {
                this.updateInputMoney = false
              }, 100)
            }
            this.grid = [...response.grid]
            this.selectedGrid = [...response.selected_grid]
            this.isLoadingConfig = false
          })
          .catch(e => {
            this.isLoadingConfig = false
            console.log(e)
          })
    }
  }
}
</script>

<template>
  <el-form :model="form"
           label-position="top">
    <el-row :gutter="20">
      <el-col :span="12" v-if="hasProfessionalPlan && !isCoach">
        <el-form-item label="Cobranças das aulas controlado pelo Professor">
          <el-switch v-model="form.entries_by_coach"
                     class="mt-1"
                     active-text="SIM"
                     inactive-text="Não">
          </el-switch>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="Valor da Aula Avulsa">
          <el-input v-model.lazy="form.amount_class"
                    v-if="!updateInputMoney"
                    v-money="moneyMask"/>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">
        <el-form-item label="Habilitar Reservas de aula pelo App">
          <el-switch v-model="form.enable_app_schedule"
                     class="mt-1"
                     active-text="SIM"
                     inactive-text="Não">
          </el-switch>
        </el-form-item>
      </el-col>
      <el-col :span="12" v-if="form.enable_app_schedule">
        <el-form-item label="Esportes">
          <el-select
              v-model="form.sports"
              multiple
              placeholder="Selecione os esportes"
              empty-text="Nenhuma tag encontrada"
              class="el-select-full">
            <el-option
                v-for="item in dependencies.sports"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <template v-if="form.enable_app_schedule">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="Habilitar Cancelamento via App">
            <el-switch v-model="form.cancel_enabled"
                       active-text="Sim"
                       inactive-text="Não"
            ></el-switch>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
              label="Nº de Minutos para Cancelar a Aula"
              class="mb-4">
            <el-input
                type="number"
                :min="0"
                :step="1"
                :disabled="!form.cancel_enabled"
                v-model="form.minutes_to_cancel_schedule"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="Grade de Horários para o App (Selecione os horários que estará disponível para aulas)">
        <div class="mt-1 mb-2 text-center">
          <el-button size="mini" type="primary" plain @click="selectAll(null)">
            Selecionar Todos horários
          </el-button>
          <el-button size="mini" type="warning" plain @click="clearAll(null)">
            Limpar Todos horários
          </el-button>
        </div>
        <el-alert type="info" v-if="isLoadingConfig" class="mt-2 pb-2" :closable="false" show-icon>
          <div class="fs-16">Carregando Grade de Horários</div>
        </el-alert>
        <el-tabs v-else type="card" class="el-tabs--mini mt-2" size="mini">
          <template v-for="weekday in grid">
            <el-tab-pane :label="weekday.weekday_label" :key="weekday.weekday">
              <div class="mt-0 mb-3 text-center">
                <el-button size="mini" type="primary" plain @click="selectAll(weekday)">
                  Selecionar Todos horários de {{ weekday.weekday_label }}
                </el-button>
                <el-button size="mini" type="warning" plain @click="clearAll(weekday)">
                  Limpar Todos horários de {{ weekday.weekday_label }}
                </el-button>
              </div>
              <div class="coach-courts-config">
                <div class="court-item" v-for="court in weekday.courts" :key="`${weekday.weekday}-${court.id}`">
                  <h4 class="text-center pt-2">{{ court.name }}</h4>
                  <div class="actions">
                    <el-button size="mini" type="primary" @click="selectGroup('all', court.hours)">
                      Todos horários
                    </el-button>
                    <el-button size="mini" @click="selectGroup('morning', court.hours)">
                      Toda manhã
                    </el-button>
                    <el-button size="mini" @click="selectGroup('evening', court.hours)">
                      Toda tarde
                    </el-button>
                    <el-button size="mini" @click="selectGroup('night', court.hours)">
                      Toda noite
                    </el-button>
                    <el-button size="mini" type="warning" @click="selectGroup('clear', court.hours)">
                      Limpar
                    </el-button>
                  </div>
                  <el-checkbox-group v-model="selectedGrid" size="mini" class="checkbox-center">
                    <template v-for="hour in court.hours">
                      <el-checkbox-button :key="hour.key"
                                          :label="hour.key"
                                          :checked="isChecked(hour.key)">
                        {{ hour.start_hour | hourShort }} até {{ hour.end_hour | hourShort }}
                      </el-checkbox-button>
                    </template>
                  </el-checkbox-group>
                </div>
              </div>
            </el-tab-pane>
          </template>
        </el-tabs>
      </el-form-item>
    </template>
  </el-form>
</template>
