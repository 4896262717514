<script>
export default {
  name: 'transaction-status-label',
  props: {
    status: String
  },
  computed: {
    statusText() {
      if (this.status === 'created') {
        return 'Aguardando pgto.'
      } else if (this.status === 'paid') {
        return 'Pago'
      } else {
        return 'Cancelada'
      }
    },
    chipColor() {
      if (this.status === 'created') {
        return 'info'
      } else if (this.status === 'paid') {
        return 'success'
      } else {
        return 'danger'
      }
    }
  }
}
</script>

<template>
  <v-chip :value="true"
          :color="chipColor"
          small
          outlined>
    <span class="white--text">{{ statusText }}</span>
  </v-chip>
</template>
