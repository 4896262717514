<script>
export default {
  name: 'totem-checkin',
  props: {
    enabled: {
      type: Boolean,
      default: false,
    },
    module: {
      type: Object,
    }
  }
}
</script>

<template>
  <div :class="{'disabled': !enabled}">
    <el-form-item :label="`Habilitar ${module.name} via Totem`" size="small">
      <el-switch v-model="module.enabled"
                 :active-value="true"
                 :inactive-value="false"
                 active-text="SIM"
                 inactive-text="Não">
      </el-switch>
    </el-form-item>
    <el-divider />
  </div>
</template>
