<script>
export default {
  name: 'stage-type',
  props: ['type', 'sports'],
  computed: {
    sportLabel() {
      return this.sports.find(item => item.id === this.type)?.name || ''
    }
  }
}
</script>

<template>
  <el-tag size="small" class="el-tag--status" type="default">
    {{ sportLabel }}
  </el-tag>
</template>
