<script>
export default {
  name: 'message-pack-sender-type-label',
  props: {
    type: {
      type: String,
      default: 'purchase'
    },
    size: {
      type: String,
      default: 'mini'
    }
  },
  computed: {
    color() {
      if (this.type === 'monthly_free' || this.type === 'initial_pack') {
        return 'info'
      } else if (this.type === 'stage_free' || this.type === 'trial_time') {
        return 'success'
      }
      return 'default'
    }
  }
}
</script>

<template>
  <el-tag :size="size" :type="color">
    <template v-if="type === 'monthly_free'">Mensalidade</template>
    <template v-if="type === 'stage_free'">Torneio</template>
    <template v-if="type === 'purchase'">Compra</template>
    <template v-if="type === 'initial_pack'">Boas Vindas</template>
    <template v-if="type === 'trial_time'">Período de Testes</template>
  </el-tag>
</template>
