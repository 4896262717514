<script>
import ListHeader from '../../../../components/list/Header'
import FormSaveButton from '../../../../components/form/SaveButton'
import FormCancelButton from '../../../../components/form/CancelButton'

import DataService from '../../../../services/dataService'
import * as restfulService from '../../../../services/restfulService'
import * as notifyService from '../../../../services/notifyService'

import {currencyConfig} from '@utils/money'
import helpers from '@mixins/helpers'
import bus from '@utils/bus'

import {required} from 'vuelidate/lib/validators'
import {clone, orderBy, cloneDeep} from 'lodash'
import {mapGetters} from 'vuex'
import {confirmDialog} from '@utils/flash'
import GripoDialog from '../../../../components/common/Dialog'
import {defaultProduct, save as saveProduct} from '../../../financial/product/service'

export default {
  components: {ListHeader, FormSaveButton, FormCancelButton, GripoDialog},
  mixins: [helpers],
  props: {
    modal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isEdit: false,
      isSubmitted: false,
      isLoadingData: true,
      isSearchingCity: false,
      currentWeekdaySelected: '0',
      showAddPeriod: false,
      updateScheduleGrid: true,
      form: {
        name: '',
        initials: '',
        status: true,
        club_id: '',
        order: 0,
        type: '',
        place: '',
        bg_color: 'null',
        tags: []
      },
      periods: [],
      dependencies: {
        clubs: [],
        court_types: [],
        court_places: [],
        court_intervals: [],
        weekdays: [],
        products: [],
        circle_bg_color: [],
        sports: []
      },
      createServiceModalData: {
        visible: false,
        periodIndex: null,
        product: {
          ...cloneDeep(defaultProduct),
          fast_insert: true,
        }
      },
    }
  },
  validations: {
    form: {
      name: {required},
      club_id: {required},
      order: {required}
    }
  },
  computed: {
    ...mapGetters(['tenant', 'hasProfessionalPlan']),
    moneyMask() {
      return currencyConfig()
    },
    backgroundColor() {
      if (this.form.bg_color !== 'null' && this.form.bg_color !== null) {
        return this.form.bg_color
      }
      return 'transparent'
    },
    fontColor() {
      if (this.form.bg_color !== 'null' && this.form.bg_color !== null) {
        return '#FFF'
      }
      return '#333'
    },
    hasAppScheduleConfigEnabled() {
      return this.tenant.settings?.app_schedule?.enabled
    },
    hasAppLessonScheduleConfigEnabled() {
      return this.tenant.settings?.app_schedule?.enable_lesson_schedule
    },
    hasMultipleScheduleConfigEnabled() {
      return this.hasAppScheduleConfigEnabled && this.tenant.settings?.app_schedule?.multiple_scheduled
    }
  },
  async mounted() {
    await this.getDependencies()
    const id = this.$route.params.id || null
    if (id && !this.modal) {
      this.isEdit = true
      restfulService.get('court', null, id, null, ['club', 'schedule_grid', 'tags'])
          .then(response => {
            if (response.tags.length) {
              response.tags = response.tags.map(item => item.tag)
            }
            this.form = {...response}
            this.periods = [...response.schedule_grid.map(item => {
              item.start_hour = this.timeShort(item.start_hour)
              item.end_hour = this.timeShort(item.end_hour)
              item.opened = false
              return item
            })]
            this.reorderPeriods()
            this.updateScheduleGrid = false
            this.isLoadingData = false
            bus.$emit('hide-loader')
          })
          .catch(e => {
            console.log(e)
            this.isLoadingData = false
            bus.$emit('hide-loader')
          })
    } else {
      this.isLoadingData = false
      bus.$emit('hide-loader')
    }
  },
  methods: {
    getDependencies() {
      return new Promise(resolve => {
        DataService.get([
          {
            domain: 'club',
            data: {
              client_club: {
                has: [
                  {
                    client_id: this.tenant.id
                  }
                ]
              }
            }
          },
          {domain: 'court_type'},
          {domain: 'court_place'},
          {domain: 'court_interval'},
          {domain: 'weekday'},
          {domain: 'product', data: {type: 'service'}},
          {domain: 'circle_bg_color'},
          {domain: 'sport'},
          {domain: 'plan'},
        ]).then((result) => {
          this.dependencies = {...this.dependencies, ...result}
          resolve()
        })
      })
    },
    addPeriod() {
      this.periods.push({
        court_id: '',
        product_id: '',
        weekday: '',
        start_hour: '',
        end_hour: '',
        interval: 60,
        members_schedule: false,
        show_client_name: false,
        payment_enabled: false,
        available_in_app: true,
        available_lesson_in_app: true,
        intervals_combination: [],
        plans: [],
      })
      this.reorderPeriods()
    },
    duplicatePeriod(period) {
      this.periods.push({...period})
      this.reorderPeriods()
    },
    reorderPeriods() {
      this.periods = [...orderBy(this.periods, ['weekday', 'start_hour'], ['asc', 'asc'])]
    },
    removePeriod(periodIndex) {
      this.periods.splice(periodIndex, 1)
      this.reorderPeriods()
    },
    convertPrice(price) {
      return this.currencyFormatter(price)
    },
    async onSend() {
      const {invalid} = this.validateFormFocus(this.$v.form, 'form', this.$refs)
      if (!invalid) {
        this.isSubmitted = true
        const data = clone(this.form)
        data.schedule_grid = [...this.periods.filter(item => {
          return item.weekday !== '' && item.start_hour && item.end_hour && item.interval
        })]
        if (data.schedule_grid.length && this.isEdit && this.updateScheduleGrid) {
          const {value: confirm} = await confirmDialog({
            title: 'Deseja realmente salvar essa ação?',
            text: 'Todas as reservas que não estiverem de acordo com a nova configuração dos horários serão canceladas. Mesmo canceladas você ainda poderá acessa-las pelo menu Reservas',
            icon: 'warning',
          })
          if (confirm) {
            bus.$emit('show-loader')
            this.onSave(data)
          } else {
            bus.$emit('hide-loader')
            this.isSubmitted = false
          }
        } else {
          bus.$emit('show-loader')
          this.onSave(data)
        }
      }
    },
    onSave(data) {
      let saveData = {...data}
      saveData.update_schedule_grid = this.updateScheduleGrid
      restfulService.save('court', null, null, saveData)
          .then((response) => {
            notifyService.success({hideLoader: true})
            this.isSubmitted = false
            if (!this.modal) {
              this.$router.push({name: 'court.index'})
            }
            this.$emit('save', response)
          })
          .catch(e => {
            bus.$emit('hide-loader')
            this.isSubmitted = false
            console.log(e)
          })
    },
    showCreateServiceModalData(periodIndex) {
      this.createServiceModalData.visible = true
      this.createServiceModalData.period = periodIndex
      setTimeout(() => this.$refs.createServiceModalDataProductDescription.focus(), 200)
    },
    onSaveCreateServiceModalData() {
      bus.$emit('show-loader')
      this.isSubmitted = true
      saveProduct(this.createServiceModalData.product)
        .then(response => {
          this.dependencies.products.push(response)
          this.periods[this.createServiceModalData.period].product_id = response.id
          this.createServiceModalData.product = {
            ...cloneDeep(defaultProduct),
            fast_insert: true
          }
          notifyService.success()
        })
        .catch(e => {
          console.log(e)
        })
        .finally(() => {
          this.createServiceModalData.visible = false
          bus.$emit('hide-loader')
          this.isSubmitted = false
        })
    }
  }
}
</script>

<template>
  <div>

    <ListHeader title="Quadras" v-if="!modal"/>

    <el-form ref="form"
             :model="form"
             label-position="top"
             @submit.prevent="onSend">

      <el-card class="el-card__save_form box-card">
        <div slot="header">
          <span>Dados da Quadra</span>
        </div>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="Nome"
                          class="is-required"
                          :class="{ 'el-form-item--error': $v.form.name.$error }">
              <el-input v-model="form.name"
                        ref="form.name"
                        @input="$v.form.name.$touch()"/>
              <div class="el-form-item__error" v-if="$v.form.name.$error">Esse campo é obrigatório</div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Sigla">
              <el-input v-model="form.initials"/>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Ordem de Exibição"
                          class="is-required"
                          :class="{ 'el-form-item--error': $v.form.order.$error }">
              <el-input type="number" :min="0" :step="1" v-model="form.order"
                        ref="form.order"
                        @input="$v.form.order.$touch()"/>
              <div class="el-form-item__error" v-if="$v.form.order.$error">Esse campo é obrigatório</div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="Clube"
                          class="is-required"
                          :class="{ 'el-form-item--error': $v.form.club_id.$error }">
              <el-select v-model="form.club_id"
                         ref="form.club_id"
                         clearable
                         filterable
                         placeholder="Selecione uma cidade"
                         empty-text="Nenhum registro encontrado"
                         class="el-select-full"
                         @input="$v.form.club_id.$touch()">
                <el-option v-for="item in dependencies.clubs"
                           :key="item.id"
                           :label="item.name"
                           :value="item.id">
                </el-option>
              </el-select>
              <div class="el-form-item__error" v-if="$v.form.club_id.$error">
                Esse campo é obrigatório
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Tipo de Quadra">
              <el-select v-model="form.type"
                         clearable
                         filterable
                         placeholder="Selecione um tipo de quadra"
                         empty-text="Nenhum registro encontrado"
                         class="el-select-full">
                <el-option v-for="item in dependencies.court_types"
                           :key="item.id"
                           :label="item.name"
                           :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Local da Quadra">
              <el-select v-model="form.place"
                         clearable
                         filterable
                         placeholder="Selecione um local"
                         empty-text="Nenhum registro encontrado"
                         class="el-select-full">
                <el-option v-for="item in dependencies.court_places"
                           :key="item.id"
                           :label="item.name"
                           :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="Esportes">
          <el-select
              v-model="form.tags"
              multiple
              default-first-option
              placeholder="Selecione as tags"
              empty-text="Nenhuma tag encontrada"
              class="el-select-full">
            <el-option
                v-for="item in dependencies.sports"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-row :gutter="20">
          <el-col :span="14">
            <el-form-item label="Cor de Fundo">
              <el-radio-group v-model="form.bg_color" size="small" fill="#CCC">
                <el-radio-button label="null" title="Sem cor de fundo">
                  <div class="color-label -circle -none">&nbsp;</div>
                </el-radio-button>
                <el-radio-button :label="item.id" v-for="item in dependencies.circle_bg_color" :key="item.id">
                  <div class="color-label -circle" :style="`background-color: ${item.id}`"></div>
                </el-radio-button>
              </el-radio-group>
              <div>
                <div class="mt-2 court-color-label"
                     :style="`background-color: ${backgroundColor}; color: ${fontColor}`">
                  {{ form.name ? form.name : 'Exemplo' }}
                </div>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Status">
              <el-switch v-model="form.status"
                         active-text="Ativa"
                         inactive-text="Inativa">
              </el-switch>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>

      <el-card class="el-card__save_form box-card" v-if="!modal">
        <div slot="header">
          <div>
            <span class="mr-4">Configurar Grade de Horários</span>
            <el-switch v-model="updateScheduleGrid"
                       active-text="Sim"
                       inactive-text="Não"/>
          </div>
          <el-button type="primary"
                     size="small"
                     :disabled="!updateScheduleGrid"
                     @click="addPeriod()">
            <i class="fas fa-plus"></i>
            <span>Inserir Período</span>
          </el-button>
        </div>
        <el-form>
          <div class="stage-players-class-list">
            <template v-for="(period, periodIndex) in periods">
              <el-form class="simple-card stage-players-class-list-item px-2 py-2" :inline="true" :key="periodIndex">
                <v-container fluid grid-list-md class="p-0">
                  <v-layout wrap>
                    <v-flex xs6>
                      <el-form-item label="Dia" class="el-select-full mb-0 mr-0">
                        <el-select v-model="period.weekday"
                                   size="mini"
                                   :disabled="!updateScheduleGrid"
                                   placeholder="Dia da Semana"
                                   empty-text="Nenhum registro encontrado"
                                   class="el-select-full">
                          <el-option v-for="item in dependencies.weekdays"
                                     :key="item.id"
                                     :label="stringFirstChars(item.name, 3)"
                                     :value="item.id">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </v-flex>
                    <v-flex xs6>
                      <el-form-item label="Intervalo" class="mb-0 mr-0 el-select-full">
                        <el-select v-model="period.interval"
                                   size="mini"
                                   placeholder="Intervalo"
                                   :disabled="!updateScheduleGrid"
                                   empty-text="Nenhum registro encontrado"
                                   class="el-select-full">
                          <el-option v-for="item in dependencies.court_intervals"
                                     :key="item.id"
                                     :label="item.value"
                                     :value="item.id">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </v-flex>
                    <v-flex xs6>
                      <el-form-item label="Hora Inicial" class="mb-0 mr-0 el-select-full">
                        <el-input placeholder="Ex.: 08:00"
                                  size="mini"
                                  class="mr-1"
                                  v-mask="'##:##'"
                                  :disabled="!updateScheduleGrid"
                                  v-model="period.start_hour"></el-input>
                      </el-form-item>
                    </v-flex>
                    <v-flex xs6>
                      <el-form-item label="Hora Final" class="mb-0 mr-0 el-select-full">
                        <el-input placeholder="Ex.: 22:00"
                                  size="mini"
                                  v-mask="'##:##'"
                                  :disabled="!updateScheduleGrid"
                                  v-model="period.end_hour"></el-input>
                      </el-form-item>
                    </v-flex>

                    <v-flex xs12>
                      <div class="flex-c gap-5 -ai-fs">
                        <el-form-item label="Valor/Serviço" class="mb-0 mr-0 el-select-full">
                          <el-select v-model="period.product_id"
                                     size="mini"
                                     clearable
                                     filterable
                                     placeholder="Valor/Serviço"
                                     :disabled="!updateScheduleGrid"
                                     empty-text="Nenhum registro encontrado"
                                     class="el-select-full">
                            <el-option v-for="item in dependencies.products"
                                       :key="item.id"
                                       :label="`${convertPrice(item.price)} - ${item.description}`"
                                       :value="item.id">
                            </el-option>
                          </el-select>
                        </el-form-item>
                        <div class="el-button-form-align">
                          <el-button
                              type="primary"
                              size="mini"
                              title="Adicionar Serviço"
                              @click="showCreateServiceModalData(periodIndex)">
                            +
                          </el-button>
                        </div>
                      </div>

                    </v-flex>

                    <v-flex xs12 v-if="hasMultipleScheduleConfigEnabled">
                      <el-form-item label="Combinações" class="mb-0 mr-0 el-select-full">
                        <el-select v-model="period.intervals_combination"
                                   size="mini"
                                   multiple
                                   allow-create
                                   default-first-option
                                   filterable
                                   placeholder="Combinações"
                                   :disabled="!updateScheduleGrid"
                                   class="el-select-full"/>
                      </el-form-item>
                    </v-flex>
                    <template v-if="hasAppScheduleConfigEnabled">
                      <v-flex xs6>
                        <el-form-item label="Reservas no App" class="mb-0 mr-0">
                          <el-switch v-model="period.available_in_app"
                                     :disabled="!updateScheduleGrid"
                                     active-text="Sim"
                                     inactive-text="Não"/>
                        </el-form-item>
                      </v-flex>
                      <v-flex xs6 v-if="hasAppLessonScheduleConfigEnabled">
                        <el-form-item label="Aulas no App" class="mb-0 mr-0">
                          <el-switch v-model="period.available_lesson_in_app"
                                     :disabled="!updateScheduleGrid"
                                     active-text="Sim"
                                     inactive-text="Não"/>
                        </el-form-item>
                      </v-flex>
                    </template>
                    <v-flex xs12 v-if="hasProfessionalPlan">
                      <el-form-item label="Planos/Pacotes" class="mb-0 mr-0 el-select-full">
                        <el-select v-model="period.plans"
                                   size="mini"
                                   clearable
                                   multiple
                                   placeholder="Planos/Pacotes"
                                   :disabled="!updateScheduleGrid"
                                   empty-text="Nenhum registro encontrado"
                                   class="el-select-full">
                          <el-option v-for="item in dependencies.plans"
                                     :key="item.id"
                                     :label="`${item.name}`"
                                     :value="item.id">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </v-flex>
                  </v-layout>
                </v-container>
                <div class="text-center mt-2">
                  <el-button type="warning"
                             size="mini"
                             @click="duplicatePeriod(period)"
                             :disabled="!updateScheduleGrid"
                             class="mr-1"
                             title="Duplicar Período">
                    <i class="fas fa-clone"></i>
                    <span class="ml-1">Duplicar Período</span>
                  </el-button>
                  <el-button type="danger"
                             size="mini"
                             @click="removePeriod(periodIndex)"
                             :disabled="!updateScheduleGrid"
                             title="Remover Período">
                    <i class="fas fa-trash"></i>
                    <span class="ml-1">Remover Período</span>
                  </el-button>
                </div>
              </el-form>
            </template>
          </div>
        </el-form>
      </el-card>

      <gripo-dialog :model.sync="createServiceModalData.visible" width="80vw">
        <template v-slot:header>
          Adicionar valor da quadra para o período
        </template>
        <template v-slot:content>
          <el-row>
            <el-form-item label="Descrição" class="is-required">
              <el-input type="textarea" v-model="createServiceModalData.product.description" ref="createServiceModalDataProductDescription"></el-input>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="Valor" class="is-required">
              <el-input
                v-model="createServiceModalData.product.price"
                v-money="moneyMask"
              />
            </el-form-item>
          </el-row>
        </template>

        <template slot="actions">
          <v-spacer></v-spacer>
          <el-button
              type="primary"
              @click="onSaveCreateServiceModalData()"
              :disabled="isSubmitted">Salvar
          </el-button>
        </template>

      </gripo-dialog>

      <el-card class="el-card__btn_form">
        <div class="flex-c">
          <v-spacer v-if="modal"/>
          <form-save-button :event-click="onSend"
                            :is-disabled="isSubmitted"
                            :is-submitted="isSubmitted">
          </form-save-button>
          <form-cancel-button route-name="court.index" v-if="!modal"/>
        </div>
      </el-card>
    </el-form>
  </div>
</template>
