<script>
import FormEntry from './FormEntry'

export default {
  components: {FormEntry},
  name: 'entry-form-entry-expense'
}
</script>

<template>
  <div>
    <form-entry entry-type="expense"
                :simple-entry="$route.params && $route.params.simpleEntry && $route.params.simpleEntry === true"/>
  </div>
</template>
