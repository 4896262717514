<script>
import {mapGetters, mapActions} from 'vuex'

export default {
  name: 'list-thead-column',
  props: {
    field: {
      type: String
    },
    label: {
      type: String
    },
    align: {
      type: String,
      default: 'center'
    }
  },
  computed: {
    ...mapGetters(['listStore']),
    sortIcon() {
      if (!this.field) {
        return null
      }
      const field = this.listStore.listSort.find(item => item.column === this.field)
      if (field) {
        return field.direction || 'asc'
      }
      return null
    },
    alignClass() {
      if (this.align === 'left') {
        return '-align-left'
      } else if (this.align === 'right') {
        return '-align-right'
      }
      return ''
    }
  },
  methods: {
    ...mapActions(['setSortField']),
    async localSetSortField() {
      await this.setSortField(this.field)
      this.$emit('update')
    }
  }
}
</script>

<template>
  <div :class="['list-thead-column', {'-active': sortIcon}, alignClass]">
    <span class="list-thead-column-label" @click="localSetSortField">{{label}}</span>
    <span class="list-thead-column-icon">
            <span key="sort" v-if="!sortIcon"><i class="fas fa-sort"></i></span>
            <span key="sortUp" v-else-if="sortIcon === 'asc'"><i class="fas fa-sort-up"></i></span>
            <span key="sortDown" v-else-if="sortIcon === 'desc'"><i class="fas fa-sort-down"></i></span>
        </span>
  </div>
</template>
