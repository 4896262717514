<script>
import {mapActions, mapGetters} from 'vuex'

import * as restfulService from '@app/services/restfulService'

import bus from '@utils/bus'
import SenderStatusLabel from "../../whatsapp/SenderStatusLabel.vue";

import {cloneDeep} from 'lodash'

export default {
  name: 'whatsapp',
  components: {SenderStatusLabel},
  data() {
    return {
      isComponentLoading: true,
      isFirstLoad: true,
      isSubmitted: false,
      sender: {
        loading: false,
        generating: false,
        type: 'gripo',
        status: 'checking',
        qr_code: '',
        generateTries: 1,
      },
      senderConfigHidden: true,
    }
  },
  computed: {
    ...mapGetters(['tenant']),
    wsInstanceCheck() {
      const key = 'tenantWhatsappSenderInstanceUpdate'
      return {
        channel: `${key}.${this.tenant?.id || ''}`,
        listen: `.${key}`
      }
    },
    sendWhatsappEnabled() {
      return this.tenant.settings?.whatsapp_sender?.enabled || false
    },
    tenantSettingsSenderType() {
      return this.tenant.settings?.whatsapp_sender?.type || 'gripo'
    },
    checkingWhatsappSender() {
      return this.sender.status === 'checking'
    },
    activeWhatsappSender() {
      return this.sender.type === 'gripo'
          || (this.sender.type === 'tenant' && this.sender.status === 'connected')
    },
  },
  watch: {
    'sender.type': function (value, oldValue) {
      if (value === 'tenant') {
        this.instanceGet()
      } else if (value === 'gripo' && oldValue === 'tenant') {
        this.sender.status = 'not_connected'
        this.sender.qr_code = ''
        this.sender.loading = false
        this.sender.generating = false
        this.instanceDelete()
      }
    }
  },
  async mounted() {
    this.isComponentLoading = true
    this.startWebsocket()
    this.sender.type = this.tenantSettingsSenderType
    if (this.sendWhatsappEnabled) {
      this.instanceGet()
    } else {
      this.sender.status = 'not_connected'
    }
    setTimeout(() => {
      this.isComponentLoading = false
    }, 300)
  },
  beforeDestroy() {
    this.sender.generateTries = 0
  },
  methods: {
    ...mapActions(['setTenant']),
    startWebsocket() {
      window.Echo.channel(this.wsInstanceCheck.channel)
          .listen(this.wsInstanceCheck.listen, ({status}) => {
            if (['connected', 'not_connected'].includes(status) && this.sender.type === 'tenant') {
              if (status === 'not_connected') {
                this.instanceGenerate()
                this.senderConfigHidden = false
              } else {
                this.sender.status = status
                this.sender.qr_code = ''
                this.sender.generating = false
              }
            }
          })
    },
    instanceGet() {
      if (this.sender.loading) {
        return
      }
      return new Promise((resolve, reject) => {
        bus.$emit('show-loader')
        this.sender.loading = true
        restfulService.get('tenant', 'whatsapp-sender')
            .then(async (response) => {
              this.instanceGenerateInterval()
              if (response.status) {
                this.sender.status = response.status
                if (response.status === 'connected') {
                  clearInterval(this.instanceInterval)
                  this.instanceInterval = null
                }
                if (response.status !== 'connected' && this.sender.type === 'tenant') {
                  if (response.status === 'not_connected') {
                    this.instanceGenerate()
                  }
                  this.senderConfigHidden = false
                }
              }
              const tenant = cloneDeep(this.tenant)
              tenant.settings.whatsapp_sender = response.whatsapp_sender
              await this.setTenant(tenant)
              bus.$emit('hide-loader')
              this.sender.loading = false
              resolve()
            })
            .catch(e => {
              this.sender.loading = false
              bus.$emit('hide-loader')
              console.log(e)
              reject()
            })
      })
    },
    instanceGenerateInterval() {
      this.instanceInterval = setInterval(() => {
        this.instanceGenerate(false)
      }, 1000 * 10) // 10 seconds
    },
    instanceGenerate(skipInterval) {
      skipInterval = skipInterval || true
      if (!skipInterval) {
        this.instanceGenerateInterval()
      }
      if (!this.sender.generating && this.sender.status !== 'connected') {
        this.sender.generating = true
        restfulService.get('tenant', 'whatsapp-sender/generate')
            .then(async ({status, qr_code}) => {
              if (status) {
                this.sender.status = status
              }
              if (qr_code) {
                this.sender.qr_code = qr_code
                clearInterval(this.instanceInterval)
                this.instanceInterval = null
              }
              this.sender.generating = false
            })
            .catch(e => {
              this.sender.generating = false
              console.log(e)
            })
      }
    },
    async instanceRemoveAndReload() {
      bus.$emit('show-loader')
      try {
        //await restfulService.get('tenant', 'whatsapp-sender/remove')
        await restfulService.get('tenant', 'whatsapp-sender/reload')
        bus.$emit('hide-loader')
      } catch (e) {
        bus.$emit('hide-loader')
        console.log(e)
      }
    },
    instanceReload() {
      bus.$emit('show-loader')
      restfulService.get('tenant', 'whatsapp-sender/reload')
          .then(async () => {
            setTimeout(() => {
              this.instanceGet()
            }, 5000)
          })
          .catch(e => {
            bus.$emit('hide-loader')
            console.log(e)
          })
    },
    instanceRemove() {
      bus.$emit('show-loader')
      restfulService.get('tenant', 'whatsapp-sender/remove')
          .then(async () => {
            setTimeout(() => {
              this.instanceGet()
            }, 5000)
          })
          .catch(e => {
            bus.$emit('hide-loader')
            console.log(e)
          })
    },
    instanceDelete() {
      bus.$emit('show-loader')
      restfulService.get('tenant', 'whatsapp-sender/delete')
          .then(async () => {
            const tenant = cloneDeep(this.tenant)
            tenant.settings.whatsapp_sender = null
            await this.setTenant(tenant)
            bus.$emit('hide-loader')
          })
          .catch(e => {
            bus.$emit('hide-loader')
            console.log(e)
          })
    }
  }
}
</script>

<template>
  <el-card class="el-card__save_form box-car el-loading-mask-light"
           :class="[{'tenant-card-settings-loading' : isComponentLoading}, {'box-card-collapse': sender.type === 'gripo' || senderConfigHidden}]"
           v-loading="isComponentLoading"
           element-loading-text="Carregando dados...">
    <div slot="header">
      <div @click="senderConfigHidden = !senderConfigHidden">
        <el-button size="mini" type="text" class="mr-1" v-if="sender.type === 'tenant'">
          <template v-if="senderConfigHidden">
            <i class="fas fa-chevron-down"></i>
          </template>
          <template v-else>
            <i class="fas fa-chevron-up"></i>
          </template>
        </el-button>
        <span class="mr-2">Envio de Mensagens</span>
        <template v-if="sender.type === 'tenant'">
          <el-tag size="mini" type="success" v-if="activeWhatsappSender && !checkingWhatsappSender">
            Conexão Ativa
          </el-tag>
          <el-tag size="mini" type="warning" v-else-if="!activeWhatsappSender && !checkingWhatsappSender">
            Conexão Pendente
          </el-tag>
          <el-tag size="mini" type="info" v-else-if="checkingWhatsappSender">
            Checando Conexão...
          </el-tag>
        </template>
      </div>
      <div>
        <el-radio-group v-model="sender.type" size="mini">
          <el-radio label="gripo" border class="mr-0 ml-0">Enviar pelo Gripo</el-radio>
          <el-radio label="tenant" border class="mr-0 ml-1">Pelo Clube</el-radio>
        </el-radio-group>
      </div>
    </div>
    <template v-if="sender.type === 'tenant' && !sender.loading && !senderConfigHidden">
      <div class="flex-c -jc-ce gap-10 pt-2 pb-2 relative el-loading-mask-light"
           v-loading="sender.generating"
           element-loading-text="Aguarde, estamos gerando o QRCode...">
        <div class="text-center">
          <h4 class="mb-1">Status</h4>
          <sender-status-label :status="sender.status"/>
          <div class="text-center mt-2">
            <div v-if="sender.status === 'not_found'" class="mb-2">
              <div>Você será notificado quando o QRCode estiver pronto para realizar a conexão!</div>
              <div class="text-danger">
                Case o QRCode não seja gerado em até <strong>5 minutos</strong>, utilize a opção de <strong>Reiniciar
                a sessão</strong>.
              </div>
            </div>
            <div class="flex-c gap-10">
              <el-button type="success"
                         v-if="sender.status !== 'connected'"
                         @click="instanceGenerate"
                         :disabled="isSubmitted || sender.generating || sender.status === 'not_found'"
                         title="Gerar QRCode">
                <i class="fas fa-qrcode"></i>
                <span>Gerar QRCode</span>
              </el-button>
              <el-button type="danger"
                         v-if="sender.status === 'not_found'"
                         @click="instanceRemoveAndReload"
                         :disabled="isSubmitted"
                         title="Reiniciar Sessão">
                <i class="fas fa-times"></i>
                <span>Reiniciar Sessão</span>
              </el-button>
            </div>
            <template v-if="sender.status === 'connected'">
              <el-alert type="warning"
                        class="mb-3"
                        show-icon
                        :closable="false">
                <div class="fs-14">
                  Se você está tendo problemas ao enviar as mensagens, tente recarregar a sessão!
                </div>
              </el-alert>
              <el-alert type="error"
                        class="mb-3"
                        show-icon
                        :closable="false">
                <div class="fs-14">
                  Caso deseje recriar a sessão, reinicie ela e faça a leitura do QRCode novamente!
                </div>
              </el-alert>
              <div class="flex-c gap-10">
                <el-button type="warning"
                           size="small"
                           @click="instanceReload"
                           :disabled="isSubmitted"
                           title="Recarregar Sessão">
                  <i class="fas fa-sync"></i>
                  <span>Recarregar Sessão</span>
                </el-button>
                <el-button type="danger"
                           size="small"
                           @click="instanceRemove"
                           :disabled="isSubmitted"
                           title="Reiniciar Sessão">
                  <i class="fas fa-times"></i>
                  <span>Reiniciar Sessão</span>
                </el-button>
              </div>
            </template>
          </div>
        </div>
        <div v-if="sender.status !== 'connected'">
          <div v-if="!!sender.qr_code" class="mb-3">
            <h3>Instruções para conectar o Whatsapp:</h3>
            <ol>
              <li>Acesse o menu Configurações do seu WhatsApp</li>
              <li>Escolha a opção Aparelhos Conectados</li>
              <li>Escolha Conectar aparelho e aponte a câmera para o QR Code</li>
            </ol>
          </div>
          <div v-if="!!sender.qr_code" class="text-center">
            <div>
              <img :src="sender.qr_code" alt="">
            </div>
            <el-alert type="warning"
                      class="mt-2"
                      show-icon
                      :closable="false">
              <div class="fs-16">
                Se você está tendo problemas ao ler o QRCode, tente gerar ele novamente!
              </div>
            </el-alert>
            <el-alert type="warning"
                      class="mt-2"
                      show-icon
                      :closable="false">
              <div class="fs-16">
                Se você leu o QRCode e a página não atualizou o status da conexão, atualize manualmente para verificar
                a conexão foi realizada corretamente!
              </div>
            </el-alert>
          </div>
        </div>
      </div>
    </template>
  </el-card>
</template>
