import {routes as auth} from './auth'
import {routes as user} from './user'
import {routes as role} from './role'
import {routes as tenant} from './tenant'

export default [
    ...auth,
    ...user,
    ...role,
    ...tenant
]
