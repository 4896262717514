<script>
import ListHeader from '../../../../components/list/Header'
import DashboardClassification from './DashboardClassification'
import DashboardProducts from './DashboardProducts'
import DashboardPaymentMethod from './DashboardPaymentMethod'
import DashboardTopDebtors from './DashboardTopDebtors'
import DashboardTopConsumers from './DashboardTopConsumers'
import bus from '@utils/bus'

export default {
  components: {
    ListHeader,
    DashboardClassification,
    DashboardProducts,
    DashboardPaymentMethod,
    DashboardTopDebtors,
    DashboardTopConsumers,
  },
  data() {
    return {
      tab: 'debtors',
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
    }
  },
  mounted() {
    bus.$emit('hide-loader')
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      this.windowHeight = window.innerHeight
      this.windowWidth = window.innerWidth
    }
  }
}
</script>

<template>
  <div class="dashboard-page">
    <template>
      <ListHeader title="Dashboard"/>
      <template>
        <el-row :gutter="10" class="mb-2">
          <el-col :span="12">
            <dashboard-classification :window-height="windowHeight"
                                      :window-width="windowWidth"></dashboard-classification>
          </el-col>
          <el-col :span="12">
            <dashboard-products :window-height="windowHeight"
                                :window-width="windowWidth"></dashboard-products>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="12">
            <dashboard-payment-method :window-height="windowHeight"
                                      :window-width="windowWidth"></dashboard-payment-method>
          </el-col>
          <el-col :span="12">
            <el-tabs type="border-card"
                     class="dashboard-tabs"
                     v-model="tab">
              <el-tab-pane label="Top Devedores" name="debtors">
                <dashboard-top-debtors :window-height="windowHeight" :tab="tab"
                                       :window-width="windowWidth"></dashboard-top-debtors>
              </el-tab-pane>
              <el-tab-pane label="Top Consumidores" name="consumers">
                <dashboard-top-consumers :window-height="windowHeight" :tab="tab"
                                         :window-width="windowWidth"></dashboard-top-consumers>
              </el-tab-pane>
            </el-tabs>
          </el-col>
        </el-row>
      </template>
    </template>
  </div>
</template>
