<script>
import {currencyConfig} from '@utils/money'

export default {
  name: 'payments-inputs',
  props: {
    paymentMethods: {
      type: Array
    },
    entriesDetails: {
      type: Array
    },
    readonly: {
      type: Boolean,
      default: false
    },
    showUpdateButton: {
      type: Boolean,
      default: false
    },
    amountsConfirmed: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      firstLoad: true,
      localPaymentMethods: [],
      updateInputMoney: false,
    }
  },
  computed: {
    moneyMask() {
      return currencyConfig()
    },
  },
  watch: {
    paymentMethods: {
      deep: true,
      immediate: true,
      handler: function (value) {
        this.localPaymentMethods = [...value].map(item => {
          return {...item, showDetails: false}
        })
        setTimeout(() => {
          this.firstLoad = false
        }, 200)
      }
    }
  },
  methods: {
    updateValues() {
      this.$emit('update:paymentMethods', this.localPaymentMethods)
      this.$emit('updateAmounts')
      this.$emit('update:amountsConfirmed', true)
    },
    entriesByPaymentMethod(paymentMethod) {
      return this.entriesDetails.filter(item => item.payment_method_id === paymentMethod.id)
    }
  }
}
</script>

<template>
  <div>
    <el-form-item v-for="item in localPaymentMethods" :label="`${item.name}`" :key="item.id"
                  class="mb-1">
      <div class="flex-c gap-10">
        <el-input v-model.lazy="item.total"
                  v-if="!updateInputMoney"
                  size="small"
                  :readonly="readonly"
                  v-money="moneyMask"/>
        <div v-if="!showUpdateButton">
          <el-button size="mini" @click="item.showDetails = !item.showDetails">
            <span>Ver Detalhes</span>
          </el-button>
        </div>
      </div>
      <div v-if="item.showDetails" class="mt-2">
        <table class="custom-table-list">
          <thead>
          <tr>
            <th>Quitado em</th>
            <th>Cliente</th>
            <th>Valor</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="entry in entriesByPaymentMethod(item)">
            <td>{{ entry.created_at | dateTimeEnToBr }}h</td>
            <td>{{ entry.client }}</td>
            <td>{{ entry.amount | currencyFormatter }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </el-form-item>
    <el-form-item>
      <el-button type="primary"
                 v-if="showUpdateButton"
                 @click="updateValues">
        <span>Confirmar Valores</span>
      </el-button>
    </el-form-item>
  </div>
</template>