<script>
export default {
  name: 'device-order-status-label',
  props: ['status'],
  computed: {
    label() {
      if (this.status === 'created') {
        return 'Pendente'
      }
      if (this.status === 'paid') {
        return 'Pago'
      }
      return 'Cancelado'
    }
  }
}
</script>

<template>
  <el-tag size="mini" type="info">{{ label }}</el-tag>
</template>
