<script>
import PairImpediments from './PairImpediments'
import PairRankingRegistrations from './PairRankingRegistrations'
import PairStatus from './PairStatus'
import PairNames from './PairNames'
import ClientComponent from '../../../../../../components/common/ClientComponent'
import GripoDialog from '../../../../../../components/common/Dialog'

import * as restfulService from '../../../../../../services/restfulService'
import * as notifyService from '../../../../../../services/notifyService'

import helpers from '@mixins/helpers'
import bus from '@utils/bus'

import {cloneDeep, debounce} from 'lodash'
import {required, requiredIf} from 'vuelidate/lib/validators'
import DataService from '../../../../../../services/dataService'
import * as fileService from '@app/services/fileService'
import {confirmDialog} from '@utils/flash'

export default {
  components: {
    PairImpediments,
    PairRankingRegistrations,
    PairStatus,
    PairNames,
    ClientComponent,
    GripoDialog
  },
  props: {
    stage: {
      type: Object,
      required: true
    },
    stageId: {
      type: Number,
      required: true
    },
    total: {
      type: Number,
      required: true
    },
    totalTwoPlayerClass: {
      type: Number,
      required: true
    },
    totalPairsWithParceiro: {
      type: Number,
      required: true
    }
  },
  mixins: [helpers],
  data() {
    return {
      listData: [],
      tabs: [],
      totalPairs: [],
      pairs: [],
      players_class: [],
      firstLoad: true,
      dialogVisible: false,
      currentTabSelected: null,
      currentPlayerClassId: null,
      form: {
        id: '',
        stage_id: '',
        player_class_id: '',
        player_1_id: '',
        player_1_city_id: '',
        player_1_country_code: 'BRA',
        player_2_id: '',
        player_2_city_id: '',
        player_2_country_code: 'BRA',
        generate_transaction: false,
        notify_users: false
      },
      searchPair: '',
      searchPairText: '',
      player1IsValid: false,
      player2IsValid: false,
      isLoadingPlayers: false,
      isLoadingCities: false,
      filteredCities: [],
      isSubmitted: false,
      dependencies: {
        stage_players_class: [],
        countries: [],
        player1_cities: [],
        player2_cities: []
      },
      currentPair: {
        pair: {},
        tabIndex: null,
        pairIndex: null
      }
    }
  },
  validations: {
    form: {
      player_class_id: {required},
      player_1_id: {required},
      player_1_country_code: {required},
      player_2_id: {
        required: requiredIf(function () {
          return !this.stageSingle
        })
      },
      player_2_country_code: {
        required: requiredIf(function () {
          return !this.stageSingle
        })
      }
    }
  },
  watch: {
    player1SearchCity: debounce(function (value) {
      value && value !== this.form.player1_city_id && this.onSearchCities(value, 'player1')
    }, 300),
    player2SearchCity: debounce(function (value) {
      value && value !== this.form.player2_city_id && this.onSearchCities(value, 'player2')
    }, 300),
    currentTabSelected: function (value) {
      if (!this.firstLoad) {
        const playerClass = this.tabs.find(item => item.id === parseInt(value, 10))
        if (playerClass) {
          if (!playerClass.loaded) {
            this.getPairs(parseInt(value, 10))
          }
        }
      }
      this.form.player_class_id = parseInt(value)
    },
    'form.player_class_id': function (value) {
      if (value != this.currentTabSelected) {
        this.currentTabSelected = value.toString()
      }
    }
  },
  computed: {
    stageSingle() {
      return this.stage.single
    },
    paymentEnabled() {
      return this.stage.settings?.registration_with_payment || false
    },
    paymentUnique() {
      return this.stage.settings?.payment_unique || false
    },
    hasCircuit() {
      return !!this.stage.circuit_id || !!this.stage.external_circuit_reference
    },
    filteredTabs() {
      if (this.searchPairText.length > 0) {
        const searchPairText = this.searchPairText.toLowerCase().trim()
        return this.tabs.map(playerClass => {
          return {
            ...playerClass,
            list: playerClass.list.filter(item => {
              const player1 = (item?.player1?.data?.name?.toLowerCase()?.includes(searchPairText)
                  || item?.player1?.data?.cpf?.toLowerCase()?.includes(searchPairText)
                  || item?.player1?.data?.email?.toLowerCase()?.includes(searchPairText)
                  || item?.player1?.data?.phone?.toLowerCase()?.includes(searchPairText)
                  || item?.player1?.player?.name?.toLowerCase()?.includes(searchPairText)
                  || item?.player1?.player?.cpf_cnpj?.toLowerCase()?.includes(searchPairText)
                  || item?.player1?.player?.email?.toLowerCase()?.includes(searchPairText)
                  || item?.player1?.player?.phone?.toLowerCase()?.includes(searchPairText));
              const player2 = (item?.player2?.data?.name?.toLowerCase()?.includes(searchPairText)
                  || item?.player2?.data?.cpf?.toLowerCase()?.includes(searchPairText)
                  || item?.player2?.data?.email?.toLowerCase()?.includes(searchPairText)
                  || item?.player2?.data?.phone?.toLowerCase()?.includes(searchPairText)
                  || item?.player2?.player?.name?.toLowerCase()?.includes(searchPairText)
                  || item?.player2?.player?.cpf_cnpj?.toLowerCase()?.includes(searchPairText)
                  || item?.player2?.player?.email?.toLowerCase()?.includes(searchPairText)
                  || item?.player2?.player?.phone?.toLowerCase()?.includes(searchPairText));
              return player1 || player2;
            })
          }
        })
      }
      return this.tabs
    }
  },
  async mounted() {
    setTimeout(() => {
      this.totalPairs = this.total
    }, 200)
  },
  methods: {
    async openDialog() {
      bus.$emit('show-loader')
      this.totalPairs = 0
      this.tabs.splice(0)
      this.dialogVisible = true
      this.isSubmitted = false
      setTimeout(async () => {
        this.clearForm()
        await this.getDependencies()
        await this.getPairs()
        this.firstLoad = false
        bus.$emit('hide-loader')
      }, 200)
    },
    closeDialog() {
      bus.$emit('hide-loader')
      this.dialogVisible = false
    },
    getDependencies() {
      return new Promise(resolve => {
        DataService.get([{domain: 'country'}, {
          domain: 'stage_player_class',
          data: {stage_id: this.stageId},
          relations: ['player_class']
        }]).then((result) => {
          this.dependencies = {...this.dependencies, ...result}
          resolve()
        })
      })
    },
    getPairs(playerClassId) {
      bus.$emit('show-loader')
      return new Promise(resolve => {
        playerClassId = playerClassId || null
        const data = {
          player_class_id: playerClassId,
          load_all: !this.firstLoad && !playerClassId
        }
        restfulService.post('stage', 'pairs', this.stageId, data)
            .then((response) => {
              this.totalPairs = response.total
              if (playerClassId) {
                const playerClass = response.items.find(item => item.id === playerClassId)
                const tabPlayerClass = this.tabs.find(item => item.id === playerClassId)
                if (playerClass && tabPlayerClass) {
                  tabPlayerClass.loaded = true
                  tabPlayerClass.list = [...playerClass.list]
                } else {
                  this.tabs = [...response.items]
                }
              } else {
                this.tabs = [...response.items]
              }
              if (this.firstLoad) {
                this.stage.dates = response.dates
              }
              if (this.firstLoad) {
                if (response.selected_player_class) {
                  this.form.player_class_id = response.selected_player_class
                  this.currentTabSelected = response.selected_player_class.toString()
                } else {
                  this.form.player_class_id = response.items[0]?.id || ''
                  this.currentTabSelected = (response.items[0]?.id || '').toString()
                }
              }
              this.isSubmitted = false
              bus.$emit('hide-loader')
              resolve()
            })
            .catch((e) => {
              bus.$emit('hide-loader')
              this.isSubmitted = false
              console.log(e)
            })
      })
    },
    onSearchCitiesPlayer1(query) {
      this.onSearchCities(query, 'player1')
    },
    onSearchCitiesPlayer2(query) {
      this.onSearchCities(query, 'player2')
    },
    onSearchCities(query, player) {
      this.dependencies[`${player}_cities`].splice(0)
      if (query) {
        const data = {country_code: this.form[`${player}_country_code`], query: query}
        restfulService.post('city', 'autocomplete', null, data)
            .then((response) => {
              this.dependencies[`${player}_cities`] = [...response]
            })
            .catch(e => {
              console.log(e)
            })
      }
    },
    onUpdatePair(playerClassId) {
      this.getPairs(playerClassId)
    },
    changePlayerClass(value) {
      if (!value) {
        this.clearForm()
      }
    },
    clearForm() {
      this.$refs.clientComponentPlayer1.reset()
      if (this.$refs.clientComponentPlayer2) {
        this.$refs.clientComponentPlayer2.reset()
      }
      this.form.stage_id = this.stageId
      this.form.id = ''
      this.form.player_class_id = this.currentTabSelected ? parseInt(this.currentTabSelected) : ''
      this.form.player_1_id = ''
      this.form.player_1_city_id = ''
      this.form.player_1_country_code = 'BRA'
      this.form.player_2_id = ''
      this.form.player_2_city_id = ''
      this.form.player_2_country_code = 'BRA'
      this.form.generate_transaction = false
      this.form.notify_users = false
      this.dependencies.player1_cities.splice(0)
      this.dependencies.player2_cities.splice(0)
      setTimeout(() => {
        this.$v.form.$reset()
      }, 300)
    },
    async save() {
      bus.$emit('show-loader')
      this.isSubmitted = true
      let data = {...cloneDeep(this.form)}
      try {
        if (!data.id) {
          await restfulService.post('stage_pair', null, null, data)
        } else {
          await restfulService.put('stage_pair', null, data.id, data)
        }
        this.clearForm()
        this.getPairs(data.player_class_id)
        notifyService.success()
      } catch (e) {
        bus.$emit('hide-loader')
        this.isSubmitted = false
        console.log(e)
      }
    },
    editPair(item) {
      this.isSubmitted = false
      this.dependencies.player1_cities.splice(0)
      this.dependencies.player2_cities.splice(0)
      this.$refs.clientComponentPlayer1.loadClient(item.player1.player)
      this.$refs.clientComponentPlayer2.loadClient(item.player2.player)
      this.form.id = item.id
      this.form.player_class_id = item.player_class_id
      this.form.generate_transaction = false
      this.form.notify_users = false
      this.form.player_1_id = item.player1.player.id
      this.form.player_1_city_id = ''
      this.form.player_1_country_code = 'BRA'
      if (item.player1.player.user) {
        if (item.player1.player.user.city) {
          this.form.player_1_city_id = item.player1.player.user.city_id
          this.dependencies.player1_cities.push(item.player1.player.user.city)
        }
        if (item.player1.player.user.country) {
          this.form.player_1_country_code = item.player1.player.user.country.code
        }
      }
      this.form.player_2_id = 0
      if (!this.stageSingle) {
        this.form.player_2_id = item.player2.player.id
      }
      this.form.player_2_city_id = ''
      this.form.player_2_country_code = 'BRA'
      if (item.player2.player.user) {
        if (item.player2.player.user.city) {
          this.form.player_2_city_id = item.player2.player.user.city_id
          this.dependencies.player2_cities.push(item.player2.player.user.city)
        }
        if (item.player2.player.user.country) {
          this.form.player_2_country_code = item.player2.player.user.country.code
        }
      }
      setTimeout(() => {
        document.querySelector('.pairs-dialog').scrollTop = 0
      }, 100)
    },
    async approvePair(item) {
      const {value: confirm} = await confirmDialog({
        title: 'Deseja realmente aprovar essa dupla da fila de espera?',
        icon: 'warning',
      })
      if (confirm) {
        bus.$emit('show-loader')
        restfulService.put('stage_pair', 'approve', item.id)
            .then(() => {
              this.getPairs(item.player_class_id)
            })
            .catch((e) => {
              console.log(e)
              bus.$emit('hide-loader')
            })
      }
    },
    async queuePair(item) {
      const {value: confirm} = await confirmDialog({
        title: 'Deseja realmente colocar essa dupla na fila de espera?',
        icon: 'warning',
      })
      if (confirm) {
        bus.$emit('show-loader')
        restfulService.put('stage_pair', 'queue', item.id)
            .then(() => {
              this.getPairs(item.player_class_id)
            })
            .catch((e) => {
              console.log(e)
              bus.$emit('hide-loader')
            })
      }
    },
    cancelPair(item) {
      bus.$emit('show-loader')
      restfulService.put('stage_pair', 'cancel', item.id, {
        refund: item.refund,
        approve_next_queue_pair: item.approve_next_queue_pair,
      })
          .then(() => {
            item.cancel_modal = false
            this.getPairs(item.player_class_id)
          })
          .catch((e) => {
            bus.$emit('hide-loader')
            console.log(e)
          })
    },
    exportStagePairs() {
      bus.$emit('show-loader')
      fileService.get('pdf/stage', 'pairs', this.stage.id, 'pdf', null, false, 'Relatório de Duplas')
          .finally(() => {
            bus.$emit('hide-loader')
          })
    },
    exportImpediments() {
      bus.$emit('show-loader')
      fileService.get('pdf/stage', 'impediments', this.stage.id, 'pdf', null, false, 'Relatório de Impedimentos')
          .finally(() => {
            bus.$emit('hide-loader')
          })
    },
    hasQueuePair(pairId, pairs) {
      return pairs.some(item => item.status === 'queued' && item.id !== pairId)
    },
    pairHasTransactionPaid(pair) {
      return (pair.player1?.transaction?.status === 'paid' && !pair.player1?.transaction?.manual_approval)
          || (pair.player2?.transaction?.status === 'paid' && !pair.player2?.transaction?.manual_approval)
    },
    onSearchPair: debounce(function (value) {
      this.searchPairText = value
    }, 300),
  }
}

</script>
<template>
  <div class="stage-card card-pairs">

    <div class="card-content">
      <div class="card-icon">
        <i class="fas fa-user-friends"></i>
      </div>
      <div class="card-information">
        <div class="card-title">Duplas</div>
        <div class="card-subtitle"><strong>{{ totalPairs }}</strong> duplas</div>
        <div class="card-subtitle"><strong>{{ totalTwoPlayerClass }}</strong> atletas em 2 categorias</div>
        <div class="card-subtitle"><strong>{{ totalPairsWithParceiro }}</strong> duplas sem parceiro(a)</div>

        <div class="card-buttons">
          <div class="box">
            <div class="full">
              <el-button @click="openDialog()" type="default" size="small">
                <i class="fas fa-cog"></i> Gerenciar
              </el-button>
            </div>
            <div class="col" v-if="stage.single === false">
              <el-button @click="exportStagePairs" type="info" size="small">
                <i class="fas fa-file-pdf"></i> Rel. Duplas
              </el-button>
            </div>
            <div class="col">
              <el-button @click="exportImpediments" type="info" size="small">
                <i class="fas fa-file-pdf"></i> Rel. Impedimentos
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <gripo-dialog :model.sync="dialogVisible"
                  :content-class="['pairs-dialog']"
                  width="90vw"
                  top="2vh"
                  :on-close="closeDialog">
      <template v-slot:header>Gerenciar {{ stageSingle ? 'Inscrições' : 'Duplas' }}</template>
      <template v-slot:content>
        <el-form :model="form" label-position="top">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item style="margin-bottom: 0;" label="Categoria">
                <v-select :items="dependencies.stage_players_class"
                          v-model="form.player_class_id"
                          @input="$v.form.player_class_id.$touch()"
                          @blur="$v.form.player_class_id.$touch()"
                          :error-messages="validationMessageField($v.form.player_class_id)"
                          @change="changePlayerClass"
                          item-text="player_class.name_with_sex"
                          item-value="player_class_id"
                          single-line
                          outline
                          placeholder="Selecione uma Categoria"
                          no-data-text="Nenhum registro encontrado"/>
              </el-form-item>
            </el-col>
            <el-col :span="6" v-if="paymentEnabled">
              <el-form-item label="Gerar Pagamento">
                <el-switch v-model="form.generate_transaction"
                           class="mt-2"
                           active-text="Sim"
                           inactive-text="Não">
                </el-switch>
                <div class="el-form-item-info text-left mt-1">
                  Somente inscrições novas são afetadas ao ativar essa opção
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="Notificar Atletas">
                <el-switch v-model="form.notify_users"
                           class="mt-2"
                           active-text="Sim"
                           inactive-text="Não">
                </el-switch>
              </el-form-item>
            </el-col>
          </el-row>
          <h4>Categoria</h4>

          <div class="pairs-players">
            <div class="player-box">
              <h4>Atleta {{ stageSingle ? '' : ' 1' }}</h4>
              <el-form-item class="is-required"
                            style="margin-bottom: 0;">
                <client-component :client-id.sync="form.player_1_id"
                                  :validate="$v.form.player_1_id"
                                  ref="clientComponentPlayer1"/>
              </el-form-item>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="País"
                                class="is-required"
                                style="margin-bottom: 0;"
                                :class="{ 'el-form-item--error': $v.form.player_1_country_code.$error }">
                    <v-select v-model="form.player_1_country_code"
                              :items="dependencies.countries"
                              item-value="code"
                              item-text="name"
                              outline
                              single-line
                              :error-messages="validationMessageField($v.form.player_1_country_code)"
                              @input="$v.form.player_1_country_code.$touch()"
                              @blur="$v.form.player_1_country_code.$touch()">
                      <template v-slot:item="data">
                        <v-list-tile-avatar>
                          <span :class="['flag-icon', data.item.flag_icon]"></span>
                        </v-list-tile-avatar>
                        <v-list-tile-content>
                          <v-list-tile-title>
                            {{ data.item.name }}
                          </v-list-tile-title>
                        </v-list-tile-content>
                      </template>
                      <template v-slot:selection="{ item }">
                        <v-list-tile-avatar>
                          <span :class="['flag-icon', item.flag_icon]"></span>
                        </v-list-tile-avatar>
                        <v-list-tile-content>
                          <v-list-tile-title>
                            {{ item.name }}
                          </v-list-tile-title>
                        </v-list-tile-content>
                      </template>
                    </v-select>
                    <div class="el-form-item__error"
                         v-if="$v.form.player_1_country_code.$error">
                      Campo obrigatório
                    </div>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Cidade"
                                style="margin-bottom: 0;">
                    <el-select v-model="form.player_1_city_id"
                               clearable
                               filterable
                               remote
                               :remote-method="onSearchCitiesPlayer1"
                               placeholder="Digite para buscar a cidade..."
                               empty-text="Nenhum registro encontrado"
                               class="el-select-full">
                      <el-option v-for="item in dependencies.player1_cities"
                                 :key="item.id"
                                 :label="(item.name + (item.state ? ' - '+item.state.initials : '') + (item.country ? ' / '+item.country.name : ''))"
                                 :value="item.id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
            <div class="player-box" v-if="!stageSingle">
              <h4>Atleta 2</h4>
              <el-form-item style="margin-bottom: 0;">
                <client-component :client-id.sync="form.player_2_id"
                                  :validate="$v.form.player_2_id"
                                  ref="clientComponentPlayer2"/>
              </el-form-item>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="País"
                                class="is-required"
                                style="margin-bottom: 0;"
                                :class="{ 'el-form-item--error': $v.form.player_2_country_code.$error }">
                    <v-select v-model="form.player_2_country_code"
                              :items="dependencies.countries"
                              item-value="code"
                              item-text="name"
                              outline
                              single-line
                              :error-messages="validationMessageField($v.form.player_2_country_code)"
                              @input="$v.form.player_2_country_code.$touch()"
                              @blur="$v.form.player_2_country_code.$touch()">
                      <template v-slot:item="data">
                        <v-list-tile-avatar>
                          <span :class="['flag-icon', data.item.flag_icon]"></span>
                        </v-list-tile-avatar>
                        <v-list-tile-content>
                          <v-list-tile-title>
                            {{ data.item.name }}
                          </v-list-tile-title>
                        </v-list-tile-content>
                      </template>
                      <template v-slot:selection="{ item }">
                        <v-list-tile-avatar>
                          <span :class="['flag-icon', item.flag_icon]"></span>
                        </v-list-tile-avatar>
                        <v-list-tile-content>
                          <v-list-tile-title>
                            {{ item.name }}
                          </v-list-tile-title>
                        </v-list-tile-content>
                      </template>
                    </v-select>
                    <div class="el-form-item__error"
                         v-if="$v.form.player_2_country_code.$error">
                      Campo obrigatório
                    </div>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Cidade"
                                style="margin-bottom: 0;">
                    <el-select v-model="form.player_2_city_id"
                               clearable
                               filterable
                               remote
                               :remote-method="onSearchCitiesPlayer2"
                               placeholder="Digite para buscar a cidade..."
                               empty-text="Nenhum registro encontrado"
                               class="el-select-full">
                      <el-option v-for="item in dependencies.player2_cities"
                                 :key="item.id"
                                 :label="(item.name + (item.state ? ' - '+item.state.initials : '') + (item.country ? ' / '+item.country.name : ''))"
                                 :value="item.id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-form>

        <div class="pair-save">
          <el-button @click="clearForm()">Limpar</el-button>
          <el-button type="primary"
                     @click="save()"
                     :disabled="isSubmitted || $v.form.$invalid"
                     :loading="isSubmitted">
            {{ isSubmitted ? 'Gravando' : 'Gravar' }} {{ stageSingle ? 'Inscrição' : 'Dupla' }}
          </el-button>
        </div>

        <div class="mb-2">
          <h4 class="mt-0 mb-1">Pesquisar Atletas</h4>
          <el-input v-model="searchPair"
                    @input="onSearchPair"
                    size="small"
                    placeholder="Pesquise por nome, email, telefone, cpf"/>
        </div>
        <el-tabs class="pairs-list-tabs" v-model="currentTabSelected" type="card">
          <el-tab-pane v-for="(item, tabIndex) in filteredTabs"
                       :key="item.id"
                       :label="item.player_class + ' ('+item.count+')'"
                       :name="item.id.toString()">
            <div v-if="item.list.length === 0" class="text-center pt-4 pb-4 subheading">
              Ainda não existem duplas inscritas nessa categoria
            </div>
            <div class="table-responsive" v-else>
              <table class="custom-table-list">
                <thead>
                <tr>
                  <th :class="{'text-right': !stageSingle}">Atleta{{ stageSingle ? '' : ' 1' }}</th>
                  <th v-if="!stageSingle">Atleta 2</th>
                  <th class="list-table-nowrap text-center">Status da {{ stageSingle ? 'Inscrição' : 'Dupla' }}</th>
                  <th class="list-table-nowrap text-center" v-if="hasCircuit">Pontos</th>
                  <th class="list-table-nowrap text-center">Ações</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(pair, pairIndex) in item.list">
                  <td :class="{'text-right': !stageSingle}">
                    <pair-names :pair="pair"
                                :player="pair.player1"
                                :player-id="pair.player1_id"
                                :player-number="1"
                                :payment-enabled="paymentEnabled"
                                :payment-unique="paymentUnique"
                                @updateRegistration="onUpdatePair(pair.player_class_id)"/>
                  </td>
                  <td v-if="!stageSingle">
                    <pair-names :pair="pair"
                                :player="pair.player2"
                                :player-id="pair.player2_id"
                                :player-number="2"
                                :payment-enabled="paymentEnabled"
                                :payment-unique="paymentUnique"
                                @updateRegistration="onUpdatePair(pair.player_class_id)"/>
                  </td>
                  <td>
                    <pair-status :status="pair.status"/>
                  </td>
                  <td class="text-center" v-if="hasCircuit">{{ pair.ranking_points }}</td>
                  <td class="list-table-nowrap pairs-actions">
                    <div class="action">
                      <el-button @click="editPair(pair, tabIndex, pairIndex)"
                                 type="primary"
                                 size="mini"
                                 v-if="!stageSingle"
                                 icon="el-icon-edit"
                                 title="Editar">
                      </el-button>
                    </div>
                    <div class="action" v-if="hasCircuit">
                      <pair-ranking-registrations :stage-id="stageId"
                                                  :stage-single="stageSingle"
                                                  :pair="pair"/>
                    </div>
                    <div class="action">
                      <pair-impediments :stage-id="stageId"
                                        :dates="stage.dates"
                                        :pair="pair"
                                        :stage-single="stageSingle"/>
                    </div>
                    <div class="action" v-if="pair.status === 'queued'">
                      <el-button @click="approvePair(pair)"
                                 type="success"
                                 size="mini"
                                 title="Aprovar da Fila de Espera">
                        <i class="fas fa-check"></i>
                      </el-button>
                    </div>
                    <div class="action" v-if="pair.status !== 'queued'">
                      <el-button @click="queuePair(pair)"
                                 type="default"
                                 size="mini"
                                 title="Colocar na Fila de Espera">
                        <i class="fas fa-list"></i>
                      </el-button>
                    </div>
                    <div class="action" v-if="pair.status !== 'canceled'">
                      <el-popover
                          placement="left"
                          v-model="pair.cancel_modal"
                          width="350"
                          trigger="click">
                        <div class="text-center">
                          <h3 class="mb-0">
                            Cancelamento da dupla:
                          </h3>
                          <div class="mb-2">{{ pair.names }}</div>
                          <div class="highlight-card -payment -small mb-2"
                               v-if="paymentEnabled && pairHasTransactionPaid(pair)">
                            <h4 class="mb-1">Estornar pagamentos?</h4>
                            <el-switch v-model="pair.refund"
                                       active-text="Sim"
                                       inactive-text="Não">
                            </el-switch>
                          </div>
                          <div class="highlight-card -info -small mb-2" v-if="hasQueuePair(pair.id, item.list)">
                            <h4 class="mb-1">Aprovar Próxima Dupla da Fila de Espera?</h4>
                            <el-switch v-model="pair.approve_next_queue_pair"
                                       active-text="Sim"
                                       inactive-text="Não">
                            </el-switch>
                          </div>
                          <div class="flex-c">
                            <el-button type="default"
                                       @click="pair.cancel_modal = false"
                                       size="mini">
                              Cancelar
                            </el-button>
                            <el-button @click="cancelPair(pair)"
                                       type="danger"
                                       size="small">
                              <i class="fas fa-times"></i>
                              <span class="ml-1">Confirmar Cancelamento</span>
                            </el-button>
                          </div>
                        </div>
                        <el-button type="danger"
                                   size="mini"
                                   icon="el-icon-delete"
                                   slot="reference"
                                   title="Cancelar Inscrição">
                        </el-button>
                      </el-popover>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </el-tab-pane>
        </el-tabs>
      </template>
    </gripo-dialog>
  </div>
</template>

<style lang="scss">
.pair-save {
  text-align: right;
  padding-bottom: 20px;
}

.pairs-actions {
  > .action {
    display: inline-block;
    vertical-align: middle;
    margin: 0 1px;
  }
}
</style>
