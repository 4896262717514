<script>
import GripoDialog from '../common/Dialog'
import bus from '@utils/bus'

export default {
  name: 'show-pdf',
  components: {GripoDialog},
  props: {
    base64: {
      type: String
    }
  },
  data() {
    return {
      showDialog: false,
      base64String: ''
    }
  },
  watch: {
    base64(value) {
      if (value) {
        this.showPdf()
      }
    }
  },
  methods: {
    showPdf() {
      bus.$emit('show-loader')
      this.base64String = this.base64
      setTimeout(() => {
        this.showDialog = !this.showDialog
        bus.$emit('hide-loader')
      }, 300)
    },
    handleModalVisible() {
      this.$emit('update:base64', null)
    }
  }
}
</script>

<template>
  <gripo-dialog :model.sync="showDialog"
                width="80vw"
                :hide-header="true"
                :persistent="false"
                content-card-class="modal-pdf"
                :on-close="handleModalVisible">
    <template v-slot:content>
      <iframe ref="content" :src="base64String"></iframe>
    </template>
  </gripo-dialog>
</template>

<style lang="scss">
.modal-pdf {
  overflow: hidden;

  iframe {
    display: block;
    width: 100% !important;
    height: 80vh;
    border: 3px solid #333;
  }
}
</style>