<script>
import ListHeader from '../../../../components/list/Header'
import FormSaveButton from '../../../../components/form/SaveButton'
import FormCancelButton from '../../../../components/form/CancelButton'
import ValidationMessage from '../../../../components/form/ValidationMessage'

import * as restfulService from '../../../../services/restfulService'
import * as notifyService from '../../../../services/notifyService'

import helpers from '@mixins/helpers'
import bus from '@utils/bus'

import {required} from 'vuelidate/lib/validators'
import {clone} from 'lodash'
import DataService from '../../../../services/dataService'

export default {
  components: {ListHeader, FormSaveButton, FormCancelButton, ValidationMessage},
  mixins: [helpers],
  data() {
    return {
      isEdit: false,
      isSubmitted: false,
      isLoadingData: true,
      form: {
        type: 'legal',
        bank_id: '',
        default_payment_method_id: '',
        name: '',
        cpf_cnpj: '',
        responsible_name: '',
        agency: '',
        account_number: '',
        account_digit: '',
        status: 'active'
      },
      dependencies: {
        payment_methods: [],
        banks: []
      }
    }
  },
  validations: {
    form: {
      name: {required}
    }
  },
  async mounted() {
    await this.getDependencies()
    const id = this.$route.params.id || null
    if (id) {
      this.isEdit = true
      restfulService.get('bank_account', null, id, null)
          .then(response => {
            this.form = {...response}
            this.isLoadingData = false
            bus.$emit('hide-loader')
          })
          .catch(e => {
            console.log(e)
            this.isLoadingData = false
            bus.$emit('hide-loader')
          })
    } else {
      this.isLoadingData = false
      bus.$emit('hide-loader')
    }
  },
  methods: {
    getDependencies() {
      return new Promise(resolve => {
        DataService.get([{domain: 'payment_method'}, {domain: 'bank'}]).then((result) => {
          this.dependencies = {...this.dependencies, ...result}
          resolve()
        })
      })
    },
    onSend() {
      this.isSubmitted = true
      const data = clone(this.form)
      data.cpf_cnpj = this.onlyNumbers(data.cpf_cnpj)
      restfulService.save('bank_account', null, null, data)
          .then(() => {
            notifyService.success({hideLoader: true})
            this.isSubmitted = false
            this.$router.push({name: 'bank_account.index'})
          })
          .catch(e => {
            this.isSubmitted = false
            console.log(e)
          })
    }
  }
}
</script>

<template>
  <div>
    <ListHeader title="Contas Bancárias"/>

    <el-card class="el-card__save_form box-card">
      <div slot="header">
        <span>Dados da Conta Bancária</span>
      </div>
      <el-form ref="form"
               :model="form"
               label-position="top">
        <el-form-item label="Nome da Conta"
                      class="is-required"
                      :class="{ 'el-form-item--error': $v.form.name.$error }">
          <el-input v-model="form.name"
                    @input="$v.form.name.$touch()"/>
          <validation-message v-if="$v.form.name.$error"/>
        </el-form-item>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="Banco">
              <el-select v-model="form.bank_id"
                         filterable
                         clearable
                         placeholder="Selecione uma categoria"
                         empty-text="Nenhum registro encontrado"
                         class="el-select-full">
                <el-option
                    v-for="item in dependencies.banks"
                    :key="item.id"
                    :label="`[${item.code}] ${item.name}`"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Forma de Pagamento Padrão">
              <el-select v-model="form.default_payment_method_id"
                         filterable
                         clearable
                         placeholder="Selecione uma forma de pagamento"
                         empty-text="Nenhum registro encontrado"
                         class="el-select-full">
                <el-option
                    v-for="item in dependencies.payment_methods"
                    :key="item.id"
                    :label="item.description"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="Tipo de Conta">
              <el-radio-group v-model="form.type" size="small">
                <el-radio-button label="natural">Física</el-radio-button>
                <el-radio-button label="legal">Jurídica</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="form.type === 'natural' ? 'CPF' : 'CNPJ'">
              <el-input v-model="form.cpf_cnpj"
                        v-mask="form.type === 'natural' ? '###.###.###-##' : '##.###.###/####-##'"/>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Nome do Responsável">
              <el-input v-model="form.responsible_name"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="Agência">
              <el-input v-model="form.agency"/>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Conta">
              <el-input v-model="form.account_number"/>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Dígito">
              <el-input v-model="form.account_digit"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="Status">
          <el-switch v-model="form.status"
                     active-color="#13ce66"
                     active-value="active"
                     active-text="ATIVA"
                     inactive-color="#ff4949"
                     inactive-value="inactive"
                     inactive-text="INATIVA">
          </el-switch>
        </el-form-item>
        <el-form-item>
          <form-save-button :event-click="onSend"
                            :is-disabled="isSubmitted || $v.$invalid"
                            :is-submitted="isSubmitted">
          </form-save-button>
          <form-cancel-button route-name="bank_account.index"/>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>
