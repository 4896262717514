<script>
import GripoDialog from '@components/common/Dialog'
import PaymentsInputs from './PaymentsInputs.vue'

import * as restfulService from '@app/services/restfulService'
import * as notifyService from '@app/services/notifyService'

import helpers from '@mixins/helpers'
import {hasPermissionByName} from "@mixins/helpers";
import bus from '@utils/bus'
import {currencyConfig} from '@utils/money'
import {confirmDialog, warningDialog} from '@utils/flash'

import {clone} from 'lodash'

import {mapGetters} from 'vuex'

const defaultResumeData = {
  movements: {
    input: 0,
    output: 0,
  },
  payment_methods_system: [],
  payment_methods: [],
  initial_amount: 0,
  final_diff: 0,
  final_amount: 0,
  entries_details: [],
}

const defaultEntryResume = {
  entry_teller: {
    id: '',
    name: '',
  },
  ...defaultResumeData
}

export default {
  components: {GripoDialog, PaymentsInputs},
  mixins: [helpers],
  name: 'teller-control',
  data() {
    return {
      showModal: false,
      isSubmitted: false,
      type: 'open',
      tabAction: 'update',
      initialAmount: 0,
      amountsConfirmed: false,
      updateInputMoney: false,
      updateTeller: {
        type: 'output',
        user_id: '',
        amount: 0,
        notes: '',
      },
      resume: {...clone(defaultEntryResume)},
      dependencies: {
        entry_tellers: []
      }
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'tenant']),
    moneyMask() {
      return currencyConfig()
    },
    hasListTellersPermission() {
      return hasPermissionByName('list.entry_teller')
    }
  },
  watch: {
    tabAction: function (value) {
      if (value === 'close' && !!this.resume.entry_teller.id) {
        this.loadTellerResume()
      }
    },
    'resume.entry_teller.id': function (value) {
      if (value && this.tabAction === 'close') {
        this.loadTellerResume()
      }
    }
  },
  mounted() {
    bus.$on('teller-control', this.handleTellerControl)
  },
  beforeDestroy() {
    bus.$off('teller-control', this.handleTellerControl)
  },
  methods: {
    handleTellerControl(value) {
      if (value) {
        this.resume.entry_teller.id = this.tenant?.teller_control?.teller_opened?.id ?? ''
        this.loadTellersList()
      }
      this.tabAction = 'update'
      this.type = value ? 'close' : 'open'
      this.updateInputMoney = true
      if (this.type === 'open') {
        this.initialAmount = 0
      } else {
        this.updateTeller.type = 'output'
        this.updateTeller.user_id = this.currentUser.id
        this.updateTeller.amount = 0
        this.updateTeller.notes = ''
      }
      setTimeout(() => {
        this.updateInputMoney = false
      }, 50)
      this.showModal = true
    },
    onOpenTeller() {
      this.isSubmitted = true
      bus.$emit('show-loader')
      const data = {initial_amount: this.currencyParsed(this.initialAmount)}
      restfulService.post('entry_teller', 'open', null, data)
          .then(({title, entry_teller}) => {
            bus.$emit('reload-session-info')
            bus.$emit('hide-loader')
            this.isSubmitted = false
            this.showModal = false
            notifyService.success({
              title: title,
              message: entry_teller.name
            })
          })
          .catch(e => {
            bus.$emit('hide-loader')
            this.isSubmitted = false
            console.log(e)
          })
    },
    onSaveTellerAction() {
      this.isSubmitted = true
      bus.$emit('show-loader')
      const data = {...this.updateTeller}
      data.amount = this.currencyParsed(data.amount)
      restfulService.post('entry_teller', this.updateTeller.type, this.resume.entry_teller.id, data)
          .then(({message}) => {
            notifyService.success({message: message})
            bus.$emit('hide-loader')
            this.isSubmitted = false
            this.showModal = false
          })
          .catch(e => {
            bus.$emit('hide-loader')
            this.isSubmitted = false
            console.log(e)
          })
    },
    updateResumeAmounts() {
      return new Promise(resolve => {
        bus.$emit('show-loader')
        this.updateInputMoney = true
        const systemPaymentMethods = this.resume.payment_methods_system.reduce((total, item) => total += this.currencyParsed(item.total), 0)
        const paymentMethods = this.resume.payment_methods.reduce((total, item) => total += this.currencyParsed(item.total), 0)
        const initialAmount = this.currencyParsed(this.resume.initial_amount)
        const inputAmount = this.currencyParsed(this.resume.movements.input)
        const outputAmount = this.currencyParsed(this.resume.movements.output)
        const finalAmount = systemPaymentMethods + initialAmount + inputAmount - outputAmount
        const finalDiff = paymentMethods + initialAmount + inputAmount - outputAmount
        this.resume.final_amount = this.currencyFormatter(finalAmount)
        this.resume.final_diff = this.currencyFormatter(finalDiff - finalAmount)
        setTimeout(() => {
          this.updateInputMoney = false
          bus.$emit('hide-loader')
          resolve()
        }, 50)
      })
    },
    loadTellersList() {
      if (!this.hasListTellersPermission) {
        return
      }
      restfulService.post('entry_teller', 'list', null, {show_opened_managed: true})
          .then((response) => {
            this.dependencies.entry_tellers = [...response]
          })
          .catch(e => {
            console.log(e)
          })
    },
    loadTellerResume() {
      this.amountsConfirmed = false
      this.isSubmitted = true
      bus.$emit('show-loader')
      restfulService.post('entry_teller', 'resume', this.resume.entry_teller.id, {})
          .then(async (response) => {
            this.updateInputMoney = true
            this.resume = {...this.resume, ...defaultResumeData, entry_teller: {...response.entry_teller}}
            this.resume.initial_amount = this.currencyFormatter(response.movements.filter(item => item.type === 'open').reduce((total, item) => total += item.amount, 0))
            this.resume.movements.input = this.currencyFormatter(response.movements.filter(item => item.type === 'input').reduce((total, item) => total += item.amount, 0))
            this.resume.movements.output = this.currencyFormatter(response.movements.filter(item => item.type === 'output').reduce((total, item) => total += item.amount, 0))
            if (response.payment_methods.length === 0) {
              this.amountsConfirmed = true
            }
            const paymentMethods = response.payment_methods.map(item => {
              return {
                ...item,
                total: this.currencyFormatter(item.total)
              }
            })
            this.resume.payment_methods_system = paymentMethods.map(item => ({...item}))
            this.resume.payment_methods = paymentMethods.map(item => ({...item}))
            this.resume.entries_details = response.entries_details
            await this.updateResumeAmounts()
            setTimeout(() => {
              this.updateInputMoney = false
            }, 50)
            bus.$emit('hide-loader')
            this.isSubmitted = false
          })
          .catch(e => {
            this.tabAction = 'update'
            bus.$emit('hide-loader')
            this.isSubmitted = false
            console.log(e)
          })
    },
    async onCloseTeller() {
      if (!this.amountsConfirmed) {
        warningDialog({
          title: 'Confirme os valores para poder fechar o caixa'
        })
        return
      }
      const {value: confirm} = await confirmDialog({
        title: 'Deseja realmente fechar o caixa atual?',
        icon: 'warning',
      })
      if (confirm) {
        this.isSubmitted = true
        bus.$emit('show-loader')
        const data = Object.assign({}, this.resume)
        data.initial_amount = this.currencyParsed(data.initial_amount)
        data.movements.input = this.currencyParsed(data.movements.input)
        data.movements.output = this.currencyParsed(data.movements.output)
        data.final_amount = this.currencyParsed(data.final_amount)
        data.final_diff = this.currencyParsed(data.final_diff)
        data.payment_methods_system = data.payment_methods_system.map(item => {
          return {
            ...item,
            total: this.currencyParsed(item.total)
          }
        })
        data.payment_methods = data.payment_methods.map(item => {
          return {
            ...item,
            total: this.currencyParsed(item.total)
          }
        })
        restfulService.post('entry_teller', 'close', this.resume.entry_teller.id, data)
            .then(({title, entry_teller}) => {
              bus.$emit('reload-session-info')
              bus.$emit('hide-loader')
              this.isSubmitted = false
              this.showModal = false
              notifyService.success({
                title: title,
                message: entry_teller.name
              })
            })
            .catch(e => {
              bus.$emit('hide-loader')
              this.isSubmitted = false
              console.log(e)
            })
      }
    },
  }
}
</script>

<template>
  <gripo-dialog :model.sync="showModal"
                width="60vw"
                :persistent="false"
                :actions-close-btn="false">
    <template slot="header">
      {{ type === 'open' ? 'Abertura de Caixa' : 'Gerenciar' }} Caixa
    </template>
    <template slot="content">
      <template v-if="type === 'open'">
        <h3>Valor Inicial no Caixa</h3>
        <el-input v-model.lazy="initialAmount"
                  v-if="!updateInputMoney"
                  v-money="moneyMask"/>
      </template>
      <template v-else>
        <el-form label-position="top">
          <el-form-item label="Caixa" v-if="hasListTellersPermission && dependencies.entry_tellers.length > 1">
            <el-select v-model="resume.entry_teller.id"
                       filterable
                       :disabled="dependencies.entry_tellers.length === 1"
                       class="el-select-full"
                       placeholder="Selecione um caixa"
                       empty-text="Nenhum registro encontrado">
              <el-option v-for="item in dependencies.entry_tellers"
                         :key="item.id"
                         :label="item.name"
                         :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-tabs v-model="tabAction">
            <el-tab-pane label="Alterar Caixa" name="update">
              <el-row :gutter="10">
                <el-col :span="12">
                  <el-form-item>
                    <el-button size="small" :type="updateTeller.type === 'input' ? 'default' : 'danger'"
                               @click="updateTeller.type = 'output'">
                      <i class="fas fa-arrow-down"></i>
                      <span class="ml-2">Sangria</span>
                    </el-button>
                    <el-button size="small" :type="updateTeller.type === 'output' ? 'default' : 'success'"
                               @click="updateTeller.type = 'input'">
                      <i class="fas fa-arrow-up"></i>
                      <span class="ml-2">Suprimento</span>
                    </el-button>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-form-item :label="`${updateTeller.type === 'input' ? 'Valor do Suprimento' : 'Valor da Sangria'}`">
                <el-input v-model.lazy="updateTeller.amount"
                          v-if="!updateInputMoney"
                          v-money="moneyMask"/>
              </el-form-item>
              <el-form-item label="Observações">
                <el-input v-model="updateTeller.notes"
                          type="textarea"
                          :rows="3"/>
              </el-form-item>
            </el-tab-pane>
            <el-tab-pane label="Fechamento do Caixa" name="close">
              <el-row :gutter="20">
                <el-col :span="12" v-if="resume.payment_methods_system?.length">
                  <h3>Valores Apurados (Sistema)</h3>
                  <payments-inputs :readonly="true"
                                   key="payment_methods_system"
                                   :entries-details="resume.entries_details"
                                   :payment-methods.sync="resume.payment_methods_system"/>
                </el-col>
                <el-col :span="12" v-if="resume.payment_methods?.length">
                  <h3>Valores Conferidos (Usuário)</h3>
                  <payments-inputs :show-update-button="true"
                                   key="payment_methods"
                                   :payment-methods.sync="resume.payment_methods"
                                   :amounts-confirmed.sync="amountsConfirmed"
                                   @updateAmounts="updateResumeAmounts"/>
                </el-col>
              </el-row>
              <el-row :gutter="60">
                <el-col :span="8">
                  <el-form-item label="Valor Inicial do Caixa">
                    <el-input v-model.lazy="resume.initial_amount"
                              readonly
                              v-if="!updateInputMoney"
                              v-money="moneyMask"/>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="Suprimentos">
                    <el-input v-model.lazy="resume.movements.input"
                              readonly
                              v-if="!updateInputMoney"
                              v-money="moneyMask"/>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="Sangria">
                    <el-input v-model.lazy="resume.movements.output"
                              readonly
                              v-if="!updateInputMoney"
                              v-money="moneyMask"/>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="60">
                <el-col :span="12">
                  <el-form-item label="Valor Total">
                    <el-input v-model.lazy="resume.final_amount"
                              readonly
                              v-if="!updateInputMoney"
                              v-money="moneyMask"/>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Diferença">
                    <el-input v-model.lazy="resume.final_diff"
                              readonly
                              v-if="!updateInputMoney"
                              v-money="moneyMask"/>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-tab-pane>
          </el-tabs>
        </el-form>
      </template>
    </template>
    <template slot="actions">
      <template v-if="type === 'open'">
        <el-button type="primary" @click="onOpenTeller" :disabled="isSubmitted">
          <i class="fas fa-cash-register"></i>
          <span class="ml-2">Abrir Caixa</span>
        </el-button>
      </template>
      <template v-else>
        <el-button type="primary" @click="onSaveTellerAction" :disabled="isSubmitted" v-if="tabAction === 'update'">
          <i class="fas fa-check"></i>
          <span>Salvar {{ updateTeller.type === 'input' ? 'Suprimento' : 'Sangria' }}</span>
        </el-button>
        <template v-if="tabAction === 'close'">
          <v-spacer/>
          <div class="text-center">
            <el-button type="danger" @click="onCloseTeller"
                       :disabled="isSubmitted || !resume.entry_teller.id || !amountsConfirmed">
              <i class="fas fa-cash-register"></i>
              <span class="ml-2">Fechar o Caixa</span>
            </el-button>
            <div v-if="!amountsConfirmed" class="text-danger mt-2">
              Confirme os valores para Fechar o Caixa
            </div>
          </div>
          <v-spacer/>
        </template>
      </template>
    </template>
  </gripo-dialog>
</template>