<script>
import ListHeader from '../../../../components/list/Header'
import OrderKitchenList from './KitchenList'

import * as restfulService from '../../../../services/restfulService'

import bus from '@utils/bus'
import {orderBy} from 'lodash'

export default {
  components: {ListHeader, OrderKitchenList},
  data() {
    return {
      isLoadingData: true,
      currentDate: '',
      refreshInterval: null,
      list: [],
      canPlay: true,
      context: null,
      player: null
    }
  },
  watch: {
    currentDate: function (newValue) {
      if (newValue) {
        this.getOrderQueue()
      }
    },
    listCreated: function (value) {
      this.player.pause()
      if (value.length) {
        this.playerMusic()
      }
    }
  },
  computed: {
    listCreated() {
      return orderBy(this.list.filter(item => item.status === 'created'), ['created_at'], ['asc'])
    },
    listCooking() {
      return orderBy(this.list.filter(item => item.status === 'cooking'), ['updated_at'], ['desc'])
    },
    listDone() {
      return orderBy(this.list.filter(item => item.status === 'done'), ['updated_at'], ['desc'])
    },
    listDelivered() {
      return orderBy(this.list.filter(item => item.status === 'delivered'), ['updated_at'], ['desc'])
    }
  },
  mounted() {
    this.context = new window.AudioContext()
    this.player = document.querySelector('#notificationPlayer')
    this.player.volume = 1
    this.getCurrentDate()
    this.refreshInterval = setInterval(() => {
      this.getOrderQueue()
    }, 20000)
  },
  beforeDestroy() {
    clearInterval(this.refreshInterval)
  },
  methods: {
    async getCurrentDate() {
      this.currentDate = await restfulService.get('current-date')
    },
    playerMusic() {
      if (this.canPlay) {
        const playPromise = this.player.play()
        if (playPromise !== null) {
          playPromise.then(() => {
            this.player.play()
          })
        }
      }
    },
    toggleMusic() {
      if (this.canPlay) {
        this.player.pause()
      }
      this.canPlay = !this.canPlay
    },
    getOrderQueue() {
      bus.$emit('show-loader')
      const data = {date: this.currentDate}
      this.listCreated.splice(0)
      this.listCooking.splice(0)
      this.listDone.splice(0)
      this.listDelivered.splice(0)
      this.player.pause()
      restfulService.post('order_queue', null, null, data)
          .then(response => {
            this.list = [...response]
            bus.$emit('hide-loader')
          })
          .catch(e => {
            console.log(e)
            bus.$emit('hide-loader')
          })
    },
    updateQueueStatus(queue, status) {
      bus.$emit('show-loader')
      let data = {status}
      restfulService.post('order_queue', 'update-status', queue.id, data)
          .then(() => {
            this.getOrderQueue()
          })
          .catch(e => {
            console.log(e)
            bus.$emit('hide-loader')
          })
    }
  }
}
</script>

<template>
  <div>
    <ListHeader title="Cozinha">
      <el-button type="default" @click="toggleMusic">
        <span key="bell" v-if="canPlay"><i class="fas fa-bell"></i></span>
        <span key="bellSlash" v-if="!canPlay"><i class="fas fa-bell-slash"></i></span>
        <span>Silenciar</span>
      </el-button>
    </ListHeader>
    <audio id="notificationPlayer" loop>
      <source src="http://soundbible.com/mp3/Elevator Ding-SoundBible.com-685385892.mp3" type="audio/mp3">
    </audio>
    <div>
      <v-container grid-list-lg style="padding: 0 0 15px 0; margin: 0;">
        <v-layout row wrap>
          <v-flex xs12 sm6>
            <v-card>
              <v-toolbar color="info" dark height="45">
                <v-toolbar-title>Aguardando</v-toolbar-title>
              </v-toolbar>
              <order-kitchen-list :list="listCreated">
                <div slot="action" slot-scope="data">
                  <v-btn class="pl-2 pr-2" dark color="default"
                         @click="updateQueueStatus(data.slotScope, 'cooking')">
                    <span class="pr-2">Preparação</span>
                    <i class="fas fa-arrow-right"></i>
                  </v-btn>
                </div>
              </order-kitchen-list>
            </v-card>
          </v-flex>
          <v-flex xs12 sm6>
            <v-card>
              <v-toolbar color="default" dark height="45">
                <v-toolbar-title>Em preparação</v-toolbar-title>
              </v-toolbar>
              <order-kitchen-list :list="listCooking">
                <div slot="action" slot-scope="data">
                  <v-btn class="pl-2 pr-2" dark color="warning"
                         @click="updateQueueStatus(data.slotScope, 'done')">
                    <span class="pr-2">Pronto</span>
                    <i class="fas fa-arrow-right"></i>
                  </v-btn>
                </div>
              </order-kitchen-list>
            </v-card>
          </v-flex>
          <v-flex xs12 sm6>
            <v-card>
              <v-toolbar color="warning" dark height="45">
                <v-toolbar-title>Prontos</v-toolbar-title>
              </v-toolbar>
              <order-kitchen-list :list="listDone">
                <div slot="action" slot-scope="data">
                  <v-btn class="pl-2 pr-2" dark color="success"
                         @click="updateQueueStatus(data.slotScope, 'delivered')">
                    <span class="pr-2">Entregue</span>
                    <i class="fas fa-arrow-right"></i>
                  </v-btn>
                </div>
              </order-kitchen-list>
            </v-card>
          </v-flex>
          <v-flex xs12 sm6>
            <v-card>
              <v-toolbar color="success" dark height="45">
                <v-toolbar-title>Entregues</v-toolbar-title>
              </v-toolbar>
              <order-kitchen-list :list="listDelivered"/>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
  </div>
</template>
