<script>
export default {
  name: 'dre-result-amount',
  props: {
    amount: {
      type: Number,
      default: 0
    }
  }
}
</script>

<template>
  <span :class="['font-weight-bold', {'text-danger' : amount < 0}, {'text-success' : amount > 0}]">
    {{ amount | currencyFormatter}}
  </span>
</template>
