import List from './components/List.vue'
import Form from './components/Form.vue'
import Merge from './components/Merge.vue'

export default [
    {path: '/client', component: List, name: 'client.index'},
    {path: '/client/store', component: Form, name: 'client.store'},
    {path: '/client/merge', component: Merge, name: 'client.merge', meta: {permission: 'update.client'}},
    {path: '/client/:id', component: Form, name: 'client.update'}
]
