<script>
export default {
  name: 'schedule-tag-label',
  props: {
    name: String,
    selected: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<template>
  <el-tag size="small"
          class="el-tag--status"
          :effect="selected ? 'dark' : 'plain'">
    {{ name }}
  </el-tag>
</template>
