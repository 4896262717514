import messages from './messages'

/**
 * Request interceptors.
 *
 * @param http
 * @param router
 */
export default (http, router) => {
    http.interceptors.response.use(
        response => messages.success(response),
        error => messages.error(error, router)
    )
}
