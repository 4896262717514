import {Notification} from 'element-ui'
import {merge} from 'lodash'
import bus from '@utils/bus'

function handleObjectData(options) {
    return merge({
        title: 'Sucesso!',
        message: 'A solicitação foi concluída com sucesso',
        offset: 100,
        duration: 3000,
        dangerouslyUseHTMLString: true,
    }, options)
}

function handleLoader(options) {
    if (options.hasOwnProperty('hideLoader') && options.hideLoader === true) {
        bus.$emit('hide-loader')
    }
}

function _common(options) {
    const type = options.type || 'success'
    if (type === 'success') {
        _success(options)
    } else if (type === 'warning') {
        _warning(options)
    }
}

function _success(options) {
    options = handleObjectData({...options, ...{title: options?.title || 'Sucesso!'}})
    handleLoader(options)
    Notification.success(options)
}

function _warning(options) {
    options = handleObjectData({...options, ...{title: options?.title || 'Atenção!'}})
    handleLoader(options)
    Notification.warning(options)
}

export const common = _common
export const success = _success
export const warning = _warning
