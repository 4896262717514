<script>
import GripoDialog from '@components/common/Dialog'
import PlayerImpediments from './PlayerImpediments'

import helpers from '@mixins/helpers'
import * as restfulService from "@app/services/restfulService";
import bus from "../../../../../../../utils/bus";

export default {
  name: 'pair-impediments',
  props: {
    stageId: {
      type: Number,
      required: true
    },
    pair: {
      type: Object,
      required: true
    },
    stageSingle: {
      type: Boolean,
      required: false
    }
  },
  components: {GripoDialog, PlayerImpediments},
  mixins: [helpers],
  data() {
    return {
      impediments: true,
      dialogVisible: false,
      selectedPair: 1,
      dates: []
    }
  },
  methods: {
    async openDialog() {
      bus.$emit('show-loader')
      const result = await restfulService.get('stage', null, this.stageId, ['id'], ['dates'])
      this.dates = [...result.dates]
      setTimeout(() => {
        this.dialogVisible = true
      }, 100)
    },
    closeDialog() {
      this.dialogVisible = false
    },
    save() {
      this.dialogVisible = false
    }
  }
}

</script>


<template>

  <div class="pair-impediments">

    <v-badge color="green darken-2 v-badge--mini" overlap :value="pair.impediments > 0"
             title="Impedimentos Encontrados">
      <template v-slot:badge>&nbsp;</template>
      <el-button @click="openDialog"
                 type="warning"
                 size="mini"
                 title="Impedimentos"
                 icon="el-icon-date">
      </el-button>
    </v-badge>

    <gripo-dialog :model.sync="dialogVisible"
                  v-if="dialogVisible && pair.id"
                  width="85vw"
                  top="3vh">
      <template slot="header">Impedimentos</template>
      <template slot="content">
        <div class="text-center mb-3">
          <h4 class="mb-1">Selecione o atleta</h4>
          <el-button-group>
            <el-button @click="selectedPair = 1" :type="selectedPair === 1 ? 'primary' : ''">
              {{ pair.player1.player.name }}
            </el-button>
            <el-button @click="selectedPair = 2" :type="selectedPair === 2 ? 'primary' : ''" v-if="!stageSingle">
              {{ pair.player2.player.name }}
            </el-button>
          </el-button-group>
        </div>
        <div v-show="selectedPair === 1">
          <player-impediments :stage-id="stageId"
                              :dates="dates"
                              :pair-id="pair.id"
                              :player-id="pair.player1_id"
                              :player-name="pair.player1.player.name"></player-impediments>
        </div>
        <div v-show="selectedPair === 2">
          <player-impediments :stage-id="stageId"
                              :dates="dates"
                              :pair-id="pair.id"
                              :player-id="pair.player2_id"
                              :player-name="pair.player2.player.name"></player-impediments>
        </div>
        <div v-show="![1, 2].includes(selectedPair)">
          Selecione um atleta
        </div>
      </template>
    </gripo-dialog>
  </div>
</template>

<style lang="scss">
.pair-impediments {
  display: inline-block;
}
</style>
