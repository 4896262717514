import List from './components/List.vue'
import Form from './components/Form.vue'
import Notifications from './components/Notifications.vue'

export default [
    {path: '/user', component: List, name: 'user.index'},
    {path: '/user/store', component: Form, name: 'user.store'},
    {path: '/user/notifications', component: Notifications, name: 'user.notifications', meta: {permission: false}},
    {path: '/user/:id', component: Form, name: 'user.update'}
]
