<script>
import bus from '@utils/bus'
import {mapGetters} from 'vuex'

export default {
  name: 'list-delete-button',
  props: ['item', 'domain'],
  computed: {
    ...mapGetters(['permissions']),
    canDestroy() {
      return !this.item.blocked === true && this.permissions.some((item) => item.name === 'destroy.' + this.domain)
    }
  },
  methods: {
    onDestroy(item) {
      bus.$emit('list-destroy-item', item)
    }
  }
}
</script>

<template>
  <el-button type="danger"
             v-if="canDestroy"
             @click="onDestroy(item)"
             title="Remover Item">
    <i class="fas fa-trash"></i>
  </el-button>
</template>
