<script>
export default {
  name: 'user-info-actions',
  props: {
    isAdmin: {
      type: Boolean,
      default: false
    },
    isSchedules: {
      type: Boolean,
      default: false
    },
    isFinancial: {
      type: Boolean,
      default: false
    },
    isTorneios: {
      type: Boolean,
      default: false
    },
    enabled: {
      type: Boolean,
      default: false
    },
    actions: {
      type: Object
    }
  },
  computed: {
    channelEnabled: {
      get: function () {
        return this.enabled
      },
      set: function (value) {
        this.$emit('update:enabled', value)
      }
    },
    actionsSettings: {
      get: function () {
        return this.actions
      },
      set: function (value) {
        this.$emit('update:actions', value)
      }
    }
  },
}
</script>

<template>
  <v-container fluid grid-list-lg
               :class="[{'disabled': !channelEnabled}, 'pl-0 pr-0 pt-0']">
    <v-layout row wrap>
      <v-flex md4 sm6 xs12 v-if="isAdmin || isSchedules">
        <v-card :elevation="1">
          <v-card-title class="subheading grey lighten-3">
            <v-icon left>fa-calendar-alt</v-icon>
            <span>Reservas</span>
          </v-card-title>
          <v-list dense>
            <v-list-tile>
              <v-list-tile-content>
                <v-checkbox
                    v-model="actionsSettings.schedule_store.created"
                    key="user_notifications.push.actions.schedule.created"
                    color="primary"
                    @change="$emit('updateNotifications')"
                    label="Aguardando Confirmação"></v-checkbox>
              </v-list-tile-content>
            </v-list-tile>
            <v-divider></v-divider>
            <v-list-tile>
              <v-list-tile-content>
                <v-checkbox
                    v-model="actionsSettings.schedule_store.scheduled"
                    key="schedule.scheduled"
                    color="primary"
                    label="Confirmada"></v-checkbox>
              </v-list-tile-content>
            </v-list-tile>
            <v-divider></v-divider>
            <v-list-tile>
              <v-list-tile-content>
                <v-checkbox
                    v-model="actionsSettings.schedule_store.canceled"
                    key="schedule.canceled"
                    color="primary"
                    label="Cancelada"></v-checkbox>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
        </v-card>
      </v-flex>
      <v-flex md4 sm6 xs12 v-if="isAdmin || isFinancial">
        <v-card :elevation="1">
          <v-card-title class="subheading grey lighten-3">
            <v-icon left>fa-box</v-icon>
            <span>Produtos</span>
          </v-card-title>
          <v-list dense>
            <v-list-tile>
              <v-list-tile-content>
                <v-checkbox
                    v-model="actionsSettings.product_minimum_stock"
                    key="product.minimum_stock"
                    color="primary"
                    label="Alerta de Estoque Mínimo"></v-checkbox>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
        </v-card>
      </v-flex>
      <v-flex md4 sm6 xs12 v-if="isAdmin || isTorneios">
        <v-card :elevation="1">
          <v-card-title class="subheading grey lighten-3">
            <v-icon left>fa-user-plus</v-icon>
            <span>Inscrições em Torneios</span>
          </v-card-title>
          <v-list dense>
            <v-list-tile>
              <v-list-tile-content>
                <v-checkbox
                    v-model="actionsSettings.stage_pair_store.created"
                    key="stage_pair_store.created"
                    color="primary"
                    label="Dupla/Atleta Inscritos"></v-checkbox>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
        </v-card>
      </v-flex>
      <v-flex md4 sm6 xs12>
      </v-flex>
    </v-layout>
  </v-container>
</template>