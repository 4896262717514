<script>
import ListHeader from '../../../../components/list/Header'
import FormSaveButton from '../../../../components/form/SaveButton'
import FormCancelButton from '../../../../components/form/CancelButton'

import * as restfulService from '../../../../services/restfulService'
import * as notifyService from '../../../../services/notifyService'

import bus from '@utils/bus'

import {required} from 'vuelidate/lib/validators'
import {clone} from 'lodash'

export default {
  components: {ListHeader, FormSaveButton, FormCancelButton},
  data() {
    return {
      isEdit: false,
      isSubmitted: false,
      isLoadingData: true,
      form: {
        description: '',
        order: 100,
        blocked: false,
      },
      dependencies: {}
    }
  },
  validations: {
    form: {
      description: {required}
    }
  },
  mounted() {
    const id = this.$route.params.id || null
    if (id) {
      this.isEdit = true
      restfulService.get('entry_payment_method', null, id)
          .then(response => {
            this.form = {...response}
            this.isLoadingData = false
            bus.$emit('hide-loader')
          })
          .catch(e => {
            console.log(e)
            this.isLoadingData = false
            bus.$emit('hide-loader')
          })
    } else {
      this.isLoadingData = false
      bus.$emit('hide-loader')
    }
  },
  methods: {
    onSend() {
      this.isSubmitted = true
      const data = clone(this.form)
      restfulService.save('entry_payment_method', null, null, data)
          .then(() => {
            notifyService.success({hideLoader: true})
            this.isSubmitted = false
            this.$router.push({name: 'entry_payment_method.index'})
          })
          .catch(e => {
            this.isSubmitted = false
            console.log(e)
          })
    }
  }
}
</script>

<template>
  <div>
    <ListHeader title="Formas de Pagamento"/>
    <el-card class="el-card__save_form box-card">
      <div slot="header">
        <span>Dados da Forma de Pagamento</span>
      </div>
      <el-form ref="form"
               :model="form"
               label-position="top"
               @submit.prevent="onSend">
        <el-row :gutter="20">
          <el-col :span="14">
            <el-form-item label="Descrição"
                          class="is-required"
                          :class="{ 'el-form-item--error': $v.form.description.$error }">
              <el-input v-model="form.description"
                        :disabled="form.blocked"
                        @input="$v.form.description.$touch()"/>
              <div class="el-form-item__error" v-if="$v.form.description.$error">Esse campo é obrigatório</div>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="Ordem de Exibição">
              <el-input type="number"
                        :min="0"
                        :step="1"
                        v-model="form.order"/>
              <el-alert type="warning"
                        class="mt-2 pt-1 pr-1 pb-2 pl-1"
                        :closable="false">
                <div>Campo usado na ordenação das formas de pagamento</div>
              </el-alert>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item>
          <form-save-button :event-click="onSend"
                            :is-disabled="isSubmitted || $v.$invalid"
                            :is-submitted="isSubmitted">
          </form-save-button>
          <form-cancel-button route-name="entry_payment_method.index"/>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>
