<script>
export default {
  name: 'product-type-label',
  props: ['type']
}
</script>

<template>
  <div class="d-inline-block">
    <el-tag size="mini" type="default" v-if="type === 'product'">Produto</el-tag>
    <el-tag size="mini" type="warning" v-if="type === 'service'">Serviço</el-tag>
  </div>
</template>
