<script>
import ListMain from '../../../../components/list/Main'
import ListHeader from '../../../../components/list/Header'

import bus from '@utils/bus'
import {mapGetters} from 'vuex'

import {isEmpty} from 'lodash'

export default {
  components: {ListMain, ListHeader},
  data() {
    return {
      isLoadingData: true,
      filters: {},
      dependencies: {}
    }
  },
  mounted() {
    bus.$emit('list-init', {domain: 'player_class'}, () => {
      bus.$emit('hide-loader')
      this.isLoadingData = false
    })
  },
  computed: {
    ...mapGetters(['listStore'])
  },
  methods: {
    onFilter() {
      const searchData = {}
      if (!isEmpty(this.filters.name_with_sex)) {
        searchData.name = {
          'like': '%' + this.filters.name_with_sex + '%'
        }
      }
      bus.$emit('list-filter', searchData)
    },
    onFilterClean() {
      this.filters = {}
      bus.$emit('list-filter-clean', this.filters)
    },
    onDestroy(item) {
      bus.$emit('list-destroy-item', item)
    }
  }
}
</script>

<template>
  <div>

    <ListHeader title="Categorias"/>

    <ListMain>

      <div slot="filters">

        <el-card class="el-card__search_list box-card">
          <div slot="header">
            <span>Filtros</span>
            <div>
              <el-button type="primary" @click="onFilter" size="small">
                <i class="fas fa-filter"></i>
                <span>Aplicar Filtros</span>
              </el-button>
              <el-button type="default" @click="onFilterClean" size="small">
                <i class="fas fa-times"></i>
                <span>Limpar</span>
              </el-button>
            </div>
          </div>
          <el-form :inline="true" :model="filters">
            <el-form-item label="Nome">
              <el-input v-model="filters.name_with_sex"
                        placeholder="Filtrar por nome"
                        @keydown.enter.native.prevent="onFilter"/>
            </el-form-item>
          </el-form>
        </el-card>

      </div>

      <div slot="list" v-if="!isLoadingData">

        <table class="custom-table-list">
          <thead>
          <tr>
            <th class="list-table-nowrap">ID</th>
            <th>Nome</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in listStore.listData" :key="index">
            <td>{{ item.id }}</td>
            <td>{{ item.name_with_sex }}</td>
          </tr>
          </tbody>
        </table>

      </div>

    </ListMain>
  </div>
</template>
