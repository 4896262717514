<script>
import ListHeader from '../../../../components/list/Header'
import ListTheadColumn from '../../../../components/list/TheadColumn'
import ListExport from '../../../../components/list/Export'

import DataService from '../../../../services/dataService'
import * as restfulService from '../../../../services/restfulService'
import * as fileService from '../../../../services/fileService'

import helpers from '@mixins/helpers'
import bus from '@utils/bus'

import {mapGetters, mapActions} from 'vuex'

const defaultFilters = {
  start_date: helpers.methods.dateNow('en') + ' 00:00:00',
  end_date: helpers.methods.dateNow('en') + ' 23:59:59',
  client: '',
  category_id: '',
  product_id: '',
  payment_methods: [],
  created_by_user_id: [],
  teller_ids: [],
  bank_account_id: '',
  group_products: true,
  created_products: true,
  group_by_category: false,
  product_created_date: false,
  include_partial_settled: false,
  tags: [],
  has_nfce: 'all',
}

export default {
  components: {ListTheadColumn, ListHeader, ListExport},
  mixins: [helpers],
  data() {
    return {
      isLoadingData: true,
      isLoadingTellers: false,
      filters: {...defaultFilters},
      list: {
        items: [],
        total: 0,
        total_cash: 0,
        total_profit: 0,
        partial_settled_products: {
          items: [],
          total_settled: 0,
          total_created: 0,
        }
      },
      dependencies: {
        payment_methods: [],
        product_categories: [],
        bank_accounts: [],
        products: [],
        product_tags: [],
        tenant_users: [],
        entry_tellers: [],
      },
      exportFields: [],
      defaultFieldsToExport: [
        {field: 'product', label: 'Produto'},
        {field: 'category_name', label: 'Categoria'},
        {field: 'quantity', label: 'Qtd. Vendida'},
        {field: 'stock', label: 'Estoque Atual'},
        {field: 'purchase_price', label: 'Preço de Compra'},
        {field: 'price', label: 'Preço de Venda'},
        {field: 'discount', label: 'Desconto'},
        {field: 'total', label: 'Valor Total'},
        {field: 'total_profit', label: 'Lucro'},
        {field: 'payment_methods', label: 'Forma de Pagamento'},
      ],
      extraFieldsToExport: [
        {field: 'date', label: 'Data'},
        {field: 'client', label: 'Cliente'},
      ],
      pdfBase64: '',
    }
  },
  computed: {
    ...mapGetters(['listStore', 'tenant']),
    hasTellerControlEnabled() {
      return !!this.tenant?.teller_control || false
    },
    listByCategory() {
      const categories = []
      this.list.items.map(product => {
        const categoryIndex = categories.findIndex(item => item.id === product.category_id)
        if (categoryIndex < 0) {
          categories.push({
            id: product.category_id,
            name: product.category_name,
            products: [product],
            total: product.total,
            total_profit: product.total_profit,
            total_discount: product.discount,
          })
        } else {
          categories[categoryIndex].products.push(product)
          categories[categoryIndex].total += product.total
          categories[categoryIndex].total_profit += product.total_profit
          categories[categoryIndex].total_discount += product.discount
        }
      })
      return categories
    }
  },
  watch: {
    'filters.group_products': function (value) {
      this.setListSort(value ? [{column: 'total', direction: 'desc'}] : [{column: 'date', direction: 'asc'}])
      this.setExportFields()
    },
    'filters.created_products': function (value) {
      this.filters.product_created_date = !value
    },
    'filters.start_date': function () {
      this.getEntryTellers()
    },
    'filters.end_date': function () {
      this.getEntryTellers()
    },
  },
  mounted() {
    this.setListMode('single')
    this.setListSort([{column: 'total', direction: 'desc'}])
    this.getDependencies()
    this.getTenantUsers()
    this.getEntryTellers()
    this.setExportFields()
    bus.$emit('hide-loader')
  },
  methods: {
    ...mapActions(['setListMode', 'setListSort']),
    setExportFields() {
      if (this.filters.group_products) {
        this.exportFields = [...this.defaultFieldsToExport]
      } else {
        this.exportFields = [...this.extraFieldsToExport, ...this.defaultFieldsToExport]
      }
    },
    getDependencies() {
      DataService.get([
        {domain: 'payment_method'},
        {domain: 'bank_account'},
        {domain: 'product_category'},
        {domain: 'product_tags'},
        {
          domain: 'product',
          data: {active: true}
        }]).then((result) => {
        this.dependencies = {...this.dependencies, ...result}
      })
    },
    async getTenantUsers() {
      const result = await restfulService.post('user/search', null, null, {
        status: 'active',
        orderBy: [
          {
            column: 'name',
            direction: 'ASC'
          }
        ]
      })
      this.dependencies.tenant_users = [...result.data]
    },
    async getEntryTellers() {
      if (!this.hasTellerControlEnabled) {
        return
      }
      this.isLoadingTellers = true
      this.dependencies.entry_tellers.splice(0)
      if (!!this.filters.start_date && !!this.filters.end_date) {
        const result = await restfulService.post('entry_teller', 'list', null, {
          type: 'managed',
          start_date: this.filters.start_date,
          end_date: this.filters.end_date,
        })
        this.dependencies.entry_tellers = [...result]
      }
      this.isLoadingTellers = false
    },
    getRequestData() {
      const data = {
        start_date: this.filters.start_date,
        teller_ids: this.filters.teller_ids,
        teller_all: this.filters.teller_ids.length === 0
      }
      if (this.filters.end_date) {
        data.end_date = this.filters.end_date
      }
      if (this.filters.client) {
        data.client = this.filters.client
      }
      if (this.filters.category_id) {
        data.category_id = this.filters.category_id
      }
      if (this.filters.product_id) {
        data.product_id = this.filters.product_id
      }
      if (this.filters.payment_methods.length) {
        data.payment_methods = this.filters.payment_methods
      }
      if (this.filters.bank_account_id) {
        data.bank_account_id = this.filters.bank_account_id
      }
      if (this.filters.group_products !== undefined) {
        data.group_products = this.filters.group_products
      }
      if (this.filters.created_products !== undefined) {
        data.created_products = this.filters.created_products
      }
      if (this.filters.product_created_date !== undefined) {
        data.product_created_date = this.filters.product_created_date
      }
      if (this.filters.include_partial_settled !== undefined) {
        data.include_partial_settled = this.filters.include_partial_settled
      }
      if (this.filters.tags.length > 0) {
        data.tags = this.filters.tags
      }
      if (this.filters.created_by_user_id.length > 0) {
        data.created_by_user_id = this.filters.created_by_user_id
      }
      if (this.filters.has_nfce !== 'all') {
        data.has_nfce = true
      }
      return data
    },
    onFilter() {
      bus.$emit('show-loader')
      this.isSubmitted = true
      const data = this.getRequestData()
      data.orderBy = [...this.listStore.listSort]
      restfulService.post('report', 'order/products', null, data)
          .then(response => {
            this.list = {...response}
            this.isSubmitted = false
            bus.$emit('hide-loader')
          })
          .catch(e => {
            this.isSubmitted = false
            console.log(e)
            bus.$emit('hide-loader')
          })
    },
    onExport({format, fields}) {
      if ('pdf' === format) {
        this.generatePDF(fields)
      } else if ('csv' === format) {
        this.generateCsv(fields)
      }
    },
    generateCsv(fields) {
      bus.$emit('show-loader')
      const data = this.getRequestData()
      data.export = 'csv'
      data.fields = fields
      data.orderBy = [...this.listStore.listSort]
      fileService.get('report', 'order/products', null, 'csv', data, false, 'Relatório de Produtos')
          .finally(() => {
            bus.$emit('hide-loader')
          })
    },
    generatePDF(fields) {
      bus.$emit('show-loader')
      const data = this.getRequestData()
      data.export = 'pdf'
      data.fields = fields
      data.orderBy = [...this.listStore.listSort]
      fileService.get('report', 'order/products', null, 'pdf', data, false, 'Relatório de Produtos', true)
          .then((response) => {
            this.pdfBase64 = response
          })
          .finally(() => {
            bus.$emit('hide-loader')
          })
    },
    onFilterClean() {
      this.filters = {...defaultFilters}
    },
  }
}
</script>

<template>
  <div>

    <ListHeader title="Relatório de Produtos"/>

    <el-card class="el-card__search_list box-card">
      <div slot="header">
        <span>Filtros</span>
        <div>
          <el-button type="primary" @click="onFilter" size="small">
            <i class="fas fa-filter"></i>
            <span>Aplicar Filtros</span>
          </el-button>
          <el-button type="default" @click="onFilterClean" size="small">
            <i class="fas fa-times"></i>
            <span>Limpar</span>
          </el-button>
          <list-export
              @export="onExport"
              :fields="exportFields"
              :base64-pdf.sync="pdfBase64">
          </list-export>
        </div>
      </div>
      <el-form :inline="true" :model="filters">
        <el-form-item label="Data Inicial">
          <el-date-picker v-model="filters.start_date"
                          placeholder="Data Inicial"
                          type="datetime"
                          format="dd/MM/yyyy HH:mm"
                          value-format="yyyy-MM-dd HH:mm:ss">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="Data Final">
          <el-date-picker v-model="filters.end_date"
                          placeholder="Data Final"
                          type="datetime"
                          format="dd/MM/yyyy HH:mm"
                          value-format="yyyy-MM-dd HH:mm:ss">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="Caixas"
                      v-if="hasTellerControlEnabled">
          <el-select v-model="filters.teller_ids"
                     clearable
                     multiple
                     :placeholder="isLoadingTellers ? 'Buscando caixas...' : 'Todos os caixas'"
                     empty-text="Nenhum registro encontrado"
                     class="el-select-full"
                     @keydown.enter.native.prevent="onFilter">
            <el-option
                v-for="item in dependencies.entry_tellers"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Cliente">
          <el-input v-model="filters.client"
                    placeholder="Filtrar por Cliente"
                    @keydown.enter.native.prevent="onFilter"/>
        </el-form-item>
        <el-form-item label="Categoria dos Produtos">
          <el-select v-model="filters.category_id"
                     filterable
                     clearable
                     placeholder="Selecione uma Categoria dos Produtos"
                     empty-text="Nenhum registro encontrado"
                     class="el-select-full"
                     @keydown.enter.native.prevent="onFilter">
            <el-option
                v-for="item in dependencies.product_categories"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Produto">
          <el-select v-model="filters.product_id"
                     filterable
                     clearable
                     placeholder="Selecione um Produtos"
                     empty-text="Nenhum registro encontrado"
                     class="el-select-full"
                     @keydown.enter.native.prevent="onFilter">
            <el-option
                v-for="item in dependencies.products"
                :key="item.id"
                :label="item.description"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Agrupar por Produto" class="el-form-item-switch">
          <el-switch v-model="filters.group_products"
                     :active-value="true"
                     :inactive-value="false"
                     active-text="SIM"
                     inactive-text="NÃO">
          </el-switch>
        </el-form-item>
        <el-form-item label="Agrupar por Categoria" class="el-form-item-switch">
          <el-switch v-model="filters.group_by_category"
                     :active-value="true"
                     :inactive-value="false"
                     active-text="SIM"
                     inactive-text="NÃO">
          </el-switch>
        </el-form-item>
        <el-form-item label="Incluir Produtos não pagos" class="el-form-item-switch">
          <el-switch v-model="filters.created_products"
                     :active-value="true"
                     :inactive-value="false"
                     active-text="SIM"
                     inactive-text="NÃO">
          </el-switch>
        </el-form-item>
        <el-form-item label="Considerar Dt. de Pgto." class="el-form-item-switch">
          <el-switch v-model="filters.product_created_date"
                     :active-value="true"
                     :inactive-value="false"
                     active-text="SIM"
                     inactive-text="NÃO">
          </el-switch>
        </el-form-item>
        <el-form-item label="Incluir Pagamento Parcial" class="el-form-item-switch">
          <el-switch v-model="filters.include_partial_settled"
                     :disabled="!filters.product_created_date"
                     :active-value="true"
                     :inactive-value="false"
                     active-text="SIM"
                     inactive-text="NÃO">
          </el-switch>
        </el-form-item>
        <el-form-item label="Forma de Pagamento">
          <el-select v-model="filters.payment_methods"
                     filterable
                     clearable
                     multiple
                     placeholder="Selecione uma forma de pagamento"
                     empty-text="Nenhum registro encontrado"
                     class="el-select-full"
                     @keydown.enter.native.prevent="onFilter">
            <el-option
                v-for="item in dependencies.payment_methods"
                :key="item.id"
                :label="item.description"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Conta Bancária">
          <el-select v-model="filters.bank_account_id"
                     filterable
                     clearable
                     placeholder="Selecione uma conta bancária"
                     empty-text="Nenhum registro encontrado"
                     class="el-select-full"
                     @keydown.enter.native.prevent="onFilter">
            <el-option
                v-for="item in dependencies.bank_accounts"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Tags">
          <el-select
              v-model="filters.tags"
              multiple
              filterable
              placeholder="Digite as tags"
              empty-text="Nenhuma tag encontrada"
              class="el-select-full">
            <el-option
                v-for="item in dependencies.product_tags"
                :key="item"
                :label="item"
                :value="item">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="NFCe Gerada">
          <el-select
              v-model="filters.has_nfce"
              placeholder="NFCe Gerada"
              empty-text="Nenhuma tag encontrada"
              class="el-select-full">
            <el-option label="Não filtrar" value="all"/>
            <el-option label="Sim" :value="true"/>
          </el-select>
        </el-form-item>
        <el-form-item label="Usuário">
          <el-select
              v-model="filters.created_by_user_id"
              multiple
              filterable
              placeholder="Selecione os usuários"
              empty-text="Nenhum usuário encontrado"
              class="el-select-full">
            <el-option
                v-for="item in dependencies.tenant_users"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="font-weight-bold">
        <div class="el-form-item-description text-danger"
             v-if="filters.teller_ids.length > 0">
          * Os campos data inicial e final não serão considerados ao selecionar um ou mais caixas
        </div>
      </div>
    </el-card>

    <div class="main-list responsive-list space-top">
      <div class="el-card el-card__search_list box-card">
        <div class="el-card__header">
          <div>
            <div class="flex-c list-header"><span>Listagem</span></div>
          </div>
        </div>
        <div class="el-card__body">
          <div class="table-list" v-if="!filters.group_by_category">
            <div>
              <table class="custom-table-list">
                <thead>
                <tr>
                  <th v-if="!filters.group_products">
                    <list-thead-column field="date"
                                       label="Data"
                                       @update="onFilter"/>
                  </th>
                  <th v-if="!filters.group_products">Cliente</th>
                  <th>Produto</th>
                  <th>Categoria</th>
                  <th align="center">
                    <list-thead-column field="quantity"
                                       label="Qtd. Vendida"
                                       @update="onFilter"/>
                  </th>
                  <th align="center">
                    <list-thead-column field="stock"
                                       label="Estoque Atual"
                                       @update="onFilter"/>
                  </th>
                  <th align="right">
                    <list-thead-column field="purchase_price"
                                       label="Preço de Compra"
                                       @update="onFilter"/>
                  </th>
                  <th align="right">
                    <list-thead-column field="price"
                                       label="Preço de Venda"
                                       @update="onFilter"/>
                  </th>
                  <th align="right">Desconto</th>
                  <th align="right">
                    <list-thead-column field="total"
                                       label="Valor Total"
                                       @update="onFilter"/>
                  </th>
                  <th align="right">
                    <list-thead-column field="total_profit"
                                       label="Lucro"
                                       @update="onFilter"/>
                  </th>
                  <th>Forma de Pagamento</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in list.items">
                  <td v-if="!filters.group_products">{{ item.date | dateTimeEnToBr }}</td>
                  <td v-if="!filters.group_products">{{ item.client }}</td>
                  <td>{{ item.product }}</td>
                  <td>{{ item.category_name }}</td>
                  <td align="center">{{ item.quantity }} ({{ item.unit }})</td>
                  <td align="center">{{ item.stock }}</td>
                  <td align="right">{{ item.purchase_price | currencyFormatter }}</td>
                  <td align="right">{{ item.price | currencyFormatter }}</td>
                  <td align="right">{{ item.discount | currencyFormatter }}</td>
                  <td align="right">{{ item.total | currencyFormatter }}</td>
                  <td align="right">{{ item.total_profit | currencyFormatter }}</td>
                  <td>{{ item.payment_methods }}</td>
                </tr>
                </tbody>
                <tfoot>
                <tr>
                  <th :colspan="filters.group_products ? 6 : 8" align="right">Total Geral:</th>
                  <th align="right">{{ list.total_discount | currencyFormatter }}</th>
                  <th align="right">{{ list.total | currencyFormatter }}</th>
                  <th align="right">{{ list.total_profit | currencyFormatter }}</th>
                  <th colspan="1"></th>
                </tr>
                </tfoot>
              </table>
            </div>
          </div>
          <div class="table-list" v-else>
            <div v-for="category in listByCategory" class="mb-4">
              <h3>{{ category.name }}</h3>
              <table class="custom-table-list">
                <thead>
                <tr>
                  <th v-if="!filters.group_products">
                    <list-thead-column field="date"
                                       label="Data"
                                       @update="onFilter"/>
                  </th>
                  <th v-if="!filters.group_products">Cliente</th>
                  <th>Produto</th>
                  <th align="center">
                    <list-thead-column field="quantity"
                                       label="Qtd. Vendida"
                                       @update="onFilter"/>
                  </th>
                  <th align="center">
                    <list-thead-column field="stock"
                                       label="Estoque Atual"
                                       @update="onFilter"/>
                  </th>
                  <th align="right">
                    <list-thead-column field="purchase_price"
                                       label="Preço de Compra"
                                       @update="onFilter"/>
                  </th>
                  <th align="right">
                    <list-thead-column field="price"
                                       label="Preço de Venda"
                                       @update="onFilter"/>
                  </th>
                  <th align="right">Desconto</th>
                  <th align="right">
                    <list-thead-column field="total"
                                       label="Valor Total"
                                       @update="onFilter"/>
                  </th>
                  <th align="right">
                    <list-thead-column field="total_profit"
                                       label="Lucro"
                                       @update="onFilter"/>
                  </th>
                  <th>Forma de Pagamento</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in category.products">
                  <td v-if="!filters.group_products">{{ item.date | dateEnToBr }}</td>
                  <td v-if="!filters.group_products">{{ item.client }}</td>
                  <td>{{ item.product }}</td>
                  <td align="center">{{ item.quantity }}</td>
                  <td align="center">{{ item.stock }}</td>
                  <td align="right">{{ item.purchase_price | currencyFormatter }}</td>
                  <td align="right">{{ item.price | currencyFormatter }}</td>
                  <td align="right">{{ item.discount | currencyFormatter }}</td>
                  <td align="right">{{ item.total | currencyFormatter }}</td>
                  <td align="right">{{ item.total_profit | currencyFormatter }}</td>
                  <td>{{ item.payment_methods }}</td>
                </tr>
                </tbody>
                <tfoot>
                <tr>
                  <th :colspan="filters.group_products ? 5 : 7" align="right">Total Geral:</th>
                  <th align="right">{{ category.total_discount | currencyFormatter }}</th>
                  <th align="right">{{ category.total | currencyFormatter }}</th>
                  <th align="right">{{ category.total_profit | currencyFormatter }}</th>
                  <th colspan="1"></th>
                </tr>
                </tfoot>
              </table>
            </div>
          </div>
          <div v-if="filters.include_partial_settled" class="mt-5">
            <h4>Produtos com Pagamento Parcial</h4>
            <div class="table-list">
              <div>
                <table class="custom-table-list">
                  <thead>
                  <tr>
                    <th>
                      <list-thead-column field="date"
                                         label="Data"
                                         @update="onFilter"/>
                    </th>
                    <th>Cliente</th>
                    <th>Produto</th>
                    <th>Categoria</th>
                    <th align="center">
                      <list-thead-column field="quantity"
                                         label="Qtd. Vendida"
                                         @update="onFilter"/>
                    </th>
                    <th align="center">
                      <list-thead-column field="stock"
                                         label="Estoque Atual"
                                         @update="onFilter"/>
                    </th>
                    <th align="right">
                      <list-thead-column field="purchase_price"
                                         label="Preço de Compra"
                                         @update="onFilter"/>
                    </th>
                    <th align="right">
                      <list-thead-column field="price"
                                         label="Preço de Venda"
                                         @update="onFilter"/>
                    </th>
                    <th align="right">Desconto</th>
                    <th align="right">
                      <list-thead-column field="total"
                                         label="Valor Total"
                                         @update="onFilter"/>
                    </th>
                    <th align="right">
                      <list-thead-column field="total_profit"
                                         label="Lucro"
                                         @update="onFilter"/>
                    </th>
                    <th>Forma de Pagamento</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="item in list.partial_settled_products.items">
                    <td>{{ item.date | dateTimeEnToBr }}</td>
                    <td>{{ item.client }}</td>
                    <td>{{ item.product }}</td>
                    <td>{{ item.category_name }}</td>
                    <td align="center">{{ item.quantity }} ({{ item.unit }})</td>
                    <td align="center">{{ item.stock }}</td>
                    <td align="right">{{ item.purchase_price | currencyFormatter }}</td>
                    <td align="right">{{ item.price | currencyFormatter }}</td>
                    <td align="right">{{ item.discount | currencyFormatter }}</td>
                    <td align="right">{{ item.total | currencyFormatter }}</td>
                    <td align="right">{{ item.total_profit | currencyFormatter }}</td>
                    <td>{{ item.payment_methods }}</td>
                  </tr>
                  </tbody>
                  <tfoot>
                  <tr>
                    <th :colspan="9" align="right">Total Pago:</th>
                    <th align="right">{{ list.partial_settled_products.total_settled | currencyFormatter }}</th>
                    <th colspan="2"></th>
                  </tr>
                  <tr>
                    <th :colspan="9" align="right">Total Em aberto:</th>
                    <th align="right">{{ list.partial_settled_products.total_created | currencyFormatter }}</th>
                    <th colspan="2"></th>
                  </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
