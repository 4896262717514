<script>
export default {
  name: 'user-type-label',
  props: ['ownerType'],
  computed: {
    ownerTypeLabel() {
      if (this.ownerType === 'Domain\\Admin\\System\\User\\User') {
        return 'App'
      } else if (this.ownerType === 'Domain\\Admin\\System\\AdminUser\\AdminUser') {
        return 'Painel'
      } else {
        return 'Gestão'
      }
    },
    ownerTypeTagType() {
      if (this.ownerType === 'Domain\\Admin\\System\\User\\User') {
        return 'default'
      } else {
        return 'info'
      }
    }
  }
}
</script>

<template>
  <div>
    <el-tag size="mini" :type="ownerTypeTagType">{{ ownerTypeLabel }}</el-tag>
  </div>
</template>
