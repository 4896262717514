import {routes as dashboard} from './dashboard'
import {routes as client} from './client'
import {routes as court} from './court'
import {routes as playerClass} from './player_class'
import {routes as plan} from './plan'

export default [
    ...dashboard,
    ...client,
    ...court,
    ...playerClass,
    ...plan,
]
