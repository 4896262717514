import axios from 'axios'
import interceptors from './interceptors'
import baseConfig from '../config'

/**
 * Create axios instance
 * @type {AxiosInstance}
 */
const http = axios.create({
    baseURL: baseConfig.fullAPIPath,
    headers: {'content-type': 'application/json'},
    timeout: 150000
})

const setToken = token => {
    http.defaults.headers.common.Authorization = `Bearer ${token}`
}

const setSession = session => {
    http.defaults.headers.common.Session = session
}

export {http, setToken, setSession}
export default function install(Vue, {router}) {
    interceptors(http, router)
}
