<script>
export default {
  name: 'message-pack-status-label',
  props: {
    status: {
      type: String,
      default: 'created'
    },
    size: {
      type: String,
      default: 'mini'
    }
  },
  computed: {
    color() {
      if (this.status === 'active') {
        return 'success'
      } else if (this.status === 'expired') {
        return 'danger'
      }
      return 'default'
    }
  }
}
</script>

<template>
  <el-tag :size="size" :type="color">
    <template v-if="status === 'active'">Ativo</template>
    <template v-if="status === 'expired'">Expirado</template>
    <template v-if="status === 'created'">Aguardando Pagamento</template>
  </el-tag>
</template>
