<script>
import {TOTEM_VALIDATION_OPTION_LABELS} from '../../../../service'

export default {
  name: 'totem-order',
  props: {
    enabled: {
      type: Boolean,
      default: false,
    },
    module: {
      type: Object,
    },
    validationOptions: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    optionsLabels() {
      return TOTEM_VALIDATION_OPTION_LABELS
    }
  }
}
</script>

<template>
  <div :class="{'disabled': !enabled}">
    <el-row :gutter="10">
      <el-col :span="10">
        <el-form-item :label="`Habilitar ${module.name} via Totem`" size="small">
          <el-switch v-model="module.enabled"
                     :active-value="true"
                     :inactive-value="false"
                     active-text="SIM"
                     inactive-text="Não">
          </el-switch>
        </el-form-item>
      </el-col>
      <el-col :span="14">
        <el-form-item v-if="module.enabled" label="Opções de Validação">
          <el-checkbox-group v-model="module.validation_options" size="small">
            <template v-for="option in validationOptions">
              <el-checkbox-button :key="option"
                                  :label="option">
                {{ optionsLabels[option] }}
              </el-checkbox-button>
            </template>
          </el-checkbox-group>
        </el-form-item>
      </el-col>
    </el-row>
    <el-form-item v-if="module.enabled" label="Quantidade Máxima de Produtos">
      <el-input-number v-model="module.settings.max_product_quantity" :min="1" :step="1"/>
    </el-form-item>
    <el-divider/>
  </div>
</template>
