import List from './components/List.vue'
import Form from './components/Form.vue'
import Show from './components/Show.vue'
import UpdateRegistration from './components/manage/registration/Form.vue'

export default [
    {path: '/stage', component: List, name: 'stage.index'},
    {path: '/stage/store', component: Form, name: 'stage.store'},
    {path: '/stage/:id', component: Form, name: 'stage.update'},
    {path: '/stage/:id/show', component: Show, name: 'stage.show'},
    {path: '/registration/:id', component: UpdateRegistration, name: 'registration.update'}
]
