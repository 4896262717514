<script>
import ListMain from '../../../../components/list/Main'
import ListHeader from '../../../../components/list/Header'
import ListEditButton from '../../../../components/list/EditButton'
import ListDeleteButton from '../../../../components/list/DeleteButton'

import * as fileService from '@app/services/fileService'

import bus from '@utils/bus'
import {mapGetters} from 'vuex'

import {isEmpty} from 'lodash'

const defaultOrderSearch = {orderBy: [{column: 'created_at', direction: 'DESC'}]}

export default {
  components: {ListMain, ListHeader, ListEditButton, ListDeleteButton},
  data() {
    return {
      isLoadingData: true,
      isSubmitted: false,
      filters: {},
      dependencies: {}
    }
  },
  mounted() {
    const data = {...defaultOrderSearch}
    bus.$emit('list-init', {domain: 'circuit', data}, () => {
      bus.$emit('hide-loader')
      this.isLoadingData = false
    })
  },
  computed: {
    ...mapGetters(['listStore'])
  },
  methods: {
    onFilter() {
      const searchData = {}
      if (!isEmpty(this.filters.name)) {
        searchData.name = {
          'like': '%' + this.filters.name + '%'
        }
      }
      bus.$emit('list-filter', searchData)
    },
    onFilterClean() {
      this.filters = {}
      bus.$emit('list-filter-clean', this.filters, {...defaultOrderSearch})
    },
    onDestroy(item) {
      bus.$emit('list-destroy-item', item)
    },
    exportRanking(item) {
      bus.$emit('show-loader')
      const data = {ranking: 'all'}
      fileService.get('pdf/circuit', 'ranking', item.id, 'pdf', data, false, 'Ranking')
          .finally(() => {
            bus.$emit('hide-loader')
          })
    }
  }
}
</script>

<template>
  <div>

    <ListHeader title="Circuitos" route-name="circuit.store"/>

    <ListMain>

      <div slot="filters">

        <el-card class="el-card__search_list box-card">
          <div slot="header">
            <span>Filtros</span>
            <div>
              <el-button type="primary" @click="onFilter" size="small">
                <i class="fas fa-filter"></i>
                <span>Aplicar Filtros</span>
              </el-button>
              <el-button type="default" @click="onFilterClean" size="small">
                <i class="fas fa-times"></i>
                <span>Limpar</span>
              </el-button>
            </div>
          </div>
          <el-form :inline="true" :model="filters">
            <el-form-item label="Nome">
              <el-input v-model="filters.name"
                        placeholder="Filtrar por nome"
                        @keydown.enter.native.prevent="onFilter"/>
            </el-form-item>
          </el-form>
        </el-card>

      </div>

      <div slot="list" v-if="!isLoadingData">

        <table class="custom-table-list">
          <thead>
          <tr>
            <th class="list-table-nowrap">ID</th>
            <th>Código de Importação</th>
            <th>Nome</th>
            <th>Externo</th>
            <th>Período</th>
            <th class="btn-actions">Ações</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in listStore.listData" :key="index">
            <td>{{ item.id }}</td>
            <td>{{ item.reference_hash }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.is_external ? 'Sim' : 'Não' }}</td>
            <td>
              <template v-if="item.annual">
                Anual (365)
              </template>
              <template v-else>
                <div>Dt. de Início: <strong>{{ item.start_date | dateEnToBr }}</strong></div>
                <div>Dt. de Fim: <strong>{{ item.end_date | dateEnToBr }}</strong></div>
              </template>
            </td>
            <td class="list-table-nowrap">
              <div class="btn-actions">
                <el-button type="primary"
                           size="small"
                           @click="exportRanking(item)"
                           :disabled="isSubmitted"
                           title="Gerar Ranking">
                  <i class="fas fa-file-pdf"></i>
                </el-button>
                <ListEditButton route-name="circuit.update" :item="item"/>
                <ListDeleteButton :item="item" domain="circuit"/>
              </div>
            </td>
          </tr>
          </tbody>
        </table>

      </div>

    </ListMain>
  </div>
</template>
