import Login from './components/Login.vue'
import Register from './components/Register.vue'
import Verify from './components/Verify.vue'
import RecoveryAccess from './components/RecoveryAccess.vue'

export default [
    {path: '/auth/login', component: Login, name: 'auth.login', meta: {auth: false}},
    {path: '/auth/login/:hash', component: Login, name: 'auth.login', meta: {auth: false}},
    {path: '/auth/register/:hash/:email', component: Register, name: 'auth.register', meta: {auth: false}},
    {path: '/auth/verify/:hash/:email', component: Verify, name: 'auth.verify', meta: {auth: false}},
    {path: '/auth/recovery-access', component: RecoveryAccess, name: 'auth.recovery-access', meta: {auth: false}},
]
