import { render, staticRenderFns } from "./DrawBracketsGenerator.vue?vue&type=template&id=4132fb64"
import script from "./DrawBracketsGenerator.vue?vue&type=script&lang=js"
export * from "./DrawBracketsGenerator.vue?vue&type=script&lang=js"
import style0 from "./DrawBracketsGenerator.vue?vue&type=style&index=0&id=4132fb64&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports