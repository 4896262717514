<script>
export default {
  name: 'transaction-status-label',
  props: {
    status: String,
    transaction: Object
  },
  computed: {
    statusText() {
      if (this.status === 'created') {
        return 'Aguardando'
      } else if (this.status === 'paid') {
        return 'Pago'
      } else if (this.status === 'refunded') {
        return 'Estornado'
      } else {
        return 'Cancelada'
      }
    },
    chipColor() {
      if (this.status === 'created') {
        return 'info'
      } else if (this.status === 'paid') {
        return 'success'
      } else if (this.status === 'refunded') {
        return 'warning'
      } else {
        return 'error'
      }
    }
  }
}
</script>

<template>
  <div>
    <v-chip :value="true"
            :color="chipColor"
            small
            outlined>
      <span class="white--text">{{ statusText }}</span>
    </v-chip>
    <div v-if="transaction.status === 'refunded'">
      Em: <strong>{{ transaction.updated_at | dateTimeEnToBr }}h</strong>
    </div>
  </div>
</template>
