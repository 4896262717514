<script>
import GripoDialog from '../common/Dialog'
import {required} from 'vuelidate/lib/validators'
import ShowPdf from '../common/ShowPdf'

export default {
  name: 'list-export',
  components: {ShowPdf, GripoDialog},
  props: {
    extraFormats: {
      type: Array,
      default: () => []
    },
    fields: {
      type: Array,
      default: () => []
    },
    sortFields: {
      type: Array,
      default: () => []
    },
    base64Pdf: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isSubmitted: false,
      showDialog: false,
      options: {
        format: 'pdf',
        fields: [],
        sort: [],
      },
      selectSortField: '',
      selectedSortFields: []
    }
  },
  validations: {
    options: {
      fields: {required}
    }
  },
  computed: {
    sortFieldsList() {
      return this.sortFields.filter(item => !this.selectedSortFields.some(field => field.field === item.field)
          && this.options.fields.includes(item.field))
    },
    extraFormatSelected() {
      return this.extraFormats.find(item => item.value === this.options.format)
    }
  },
  watch: {
    showDialog(value) {
      this.options.format = 'pdf'
      this.options.fields.splice(0)
      if (value && this.fields.length) {
        this.options.fields = [...this.fields.map(item => item.field)]
      }
    },
    'options.fields': {
      deep: true,
      handler: function (fields) {
        this.selectedSortFields = [...this.selectedSortFields.filter(item => fields.includes(item.field))]
      }
    }
  },
  methods: {
    onShowDialog() {
      this.showDialog = !this.showDialog
    },
    generateExport() {
      if (this.extraFormatSelected) {
        this.extraFormatSelected.exec()
      } else {
        this.isSubmitted = true
        this.$emit('export', {
          format: this.options.format,
          fields: [...this.fields.filter(item => this.options.fields.some(field => field === item.field))],
          sort: [...this.selectedSortFields],
        })
        setTimeout(() => {
          this.isSubmitted = false
        }, 500)
      }
    },
    onSelectSortField(field) {
      this.selectedSortFields.push({...this.sortFields.find(item => item.field === field), direction: 'asc'})
      this.selectSortField = ''
    },
    onRemoveSortField(field) {
      this.selectedSortFields = [...this.selectedSortFields.filter(item => item.field !== field.field)]
    }
  }
}
</script>

<template>
  <div class="d-inline-block ml-2">
    <el-button type="info" @click="onShowDialog" size="small">
      <i class="fas fa-file-pdf"></i>
      <span>Exportar</span>
    </el-button>
    <gripo-dialog :model.sync="showDialog"
                  width="60vw"
                  top="3vh">
      <template v-slot:header>Exportar Dados da Listagem</template>
      <template v-slot:content>
        <el-form ref="options"
                 :model="options"
                 label-position="top">
          <el-form-item label="Formato" class="is-required">
            <el-radio-group v-model="options.format">
              <el-radio-button label="pdf">PDF</el-radio-button>
              <el-radio-button label="csv">Excel/CSV</el-radio-button>
              <el-radio-button v-for="format in extraFormats"
                               :key="format.value"
                               :label="format.value">
                {{ format.label }}
              </el-radio-button>
            </el-radio-group>
          </el-form-item>
          <template v-if="!extraFormatSelected">
            <el-form-item label="Campos"
                          :class="['is-required', { 'el-form-item--error': $v.options.fields.$error }]">
              <el-transfer v-model="options.fields"
                           @input="$v.options.fields.$touch()"
                           :data="fields"
                           :titles="['Disponíveis', 'Selecionados']"
                           target-order="push"
                           :props="{
                                      key: 'field',
                                      label: 'label'
                                    }">
              </el-transfer>
            </el-form-item>

            <el-row :gutter="20">
              <el-col :span="8">
                <el-form-item label="Ordenação">
                  <el-select v-model="selectSortField"
                             filterable
                             placeholder="Selecione a ordenação"
                             empty-text="Nenhum registro encontrado"
                             @change="onSelectSortField"
                             class="el-select-full">
                    <el-option v-for="item in sortFieldsList"
                               :key="item.field"
                               :label="item.label"
                               :value="item.field">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="16">
                <h4 class="mb-0">Campos Selecionados</h4>
                <div>
                  <div class="mt-2" v-if="!selectedSortFields.length">Nenhum campo de ordenação selecionado</div>
                  <template v-for="field in selectedSortFields">
                    <el-tag :key="field.field" size="large"
                            class="export-list-sort-field"
                            type="info"
                            @close="onRemoveSortField(field)"
                            disable-transitions
                            closable>
                      <strong>{{ field.label }}</strong>
                      <el-select size="mini" v-model="field.direction" style="width: 80px;">
                        <el-option label="ASC" value="asc"></el-option>
                        <el-option label="DESC" value="desc"></el-option>
                      </el-select>
                    </el-tag>
                  </template>
                </div>
              </el-col>
            </el-row>
          </template>
          <el-alert v-else-if="!!extraFormatSelected?.description" :closable="false">
            <div class="fs-16 text-center">{{extraFormatSelected?.description}}</div>
          </el-alert>
        </el-form>
      </template>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <el-button type="primary"
                   :disabled="isSubmitted || $v.options.$invalid"
                   @click="generateExport">
          <i class="fas fa-file-pdf"></i>
          <span class="ml-2">Exportar</span>
        </el-button>
      </template>
    </gripo-dialog>

    <show-pdf :base64="base64Pdf"/>
  </div>
</template>
