<script>
export default {
  name: 'list-edit-button',
  props: ['routeName', 'item']
}
</script>

<template>
  <router-link :to="{ name: routeName, params: { id: item.id } }"
               class="el-button el-button--info"
               title="Editar Dados">
    <i class="fas fa-pencil-alt"></i>
  </router-link>
</template>
