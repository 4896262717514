import * as restfulService from './restfulService'

function _get(domain, route, id, format, data, blank, filename, returnBase64 = false) {
    return new Promise((resolve, reject) => {
        format = format || 'pdf'
        blank = blank === true
        filename = filename || ''
        if (format.length > 0) {
            let request
            if (data !== undefined && data !== null && data !== false) {
                request = restfulService.post(domain, route, id, data)
            } else {
                request = restfulService.get(domain, route, id)
            }
            request
                .then(function (response) {

                    if (returnBase64) {
                        return resolve(response.file)
                    }

                    let pom = document.createElement('a')
                    if (blank) {
                        pom.setAttribute('target', '_blank')
                    } else {
                        pom.setAttribute('download', format + '_' + filename + '.' + format)
                    }
                    pom.setAttribute('href', response.file)
                    pom.style.display = 'none'
                    document.body.appendChild(pom)
                    pom.click()
                    document.body.removeChild(pom)
                    resolve()
                })
                .catch((e) => {
                    console.log(e)
                    reject()
                })
        }
    })
}

function _openPdf(file) {
    return new Promise(async (resolve) => {
        const win = window.open('', '_blank')
        let html = ''
        html += '<html>'
        html += '<body style="margin:0!important">'
        html += '<embed width="100%" height="100%" src="' + file + '" type="application/pdf" />'
        html += '</body>'
        html += '</html>'
        setTimeout(() => {
            win.document.write(html)
        }, 0)
        resolve()
    })
}

function _downloadFileLink(link, filename) {
    return new Promise(async (resolve) => {
        let pom = document.createElement('a')
        pom.setAttribute('target', '_blank')
        pom.setAttribute('download', filename)
        pom.setAttribute('href', link)
        pom.style.display = 'none'
        document.body.appendChild(pom)
        pom.click()
        document.body.removeChild(pom)
        resolve()
    })
}

export const get = _get
export const openPdf = _openPdf
export const downloadFileLink = _downloadFileLink
