<script>
import GripoDialog from '@components/common/Dialog'

import * as restfulService from "@app/services/restfulService";

import helpers from '@mixins/helpers'
import bus from '@utils/bus'

export default {
  name: 'pair-ranking-registrations',
  props: {
    stageId: {
      type: Number,
      required: true
    },
    pair: {
      type: Object,
      required: true
    },
    stageSingle: {
      type: Boolean,
      required: false
    },
  },
  components: {GripoDialog},
  mixins: [helpers],
  data() {
    return {
      dialogVisible: false,
      player1Stages: [],
      player2Stages: [],
    }
  },
  methods: {
    openDialog() {
      this.getPairInfo()
    },
    getPairInfo() {
      bus.$emit('show-loader')
      restfulService.get('stage_pair', 'ranking-registrations', this.pair.id)
          .then((response) => {
            this.player1Stages = [...response.player1Stages]
            this.player2Stages = [...response.player2Stages]
            this.dialogVisible = true
            bus.$emit('hide-loader')
          })
          .catch((e) => {
            bus.$emit('hide-loader')
            console.log(e)
          })
    },
    closeDialog() {
      this.dialogVisible = false
    },
    save() {
      this.dialogVisible = false
    }
  }
}

</script>


<template>

  <div class="d-inline-block">

    <el-button @click="openDialog"
               type="info"
               size="mini"
               title="Verificar Cadastros">
      <i class="fas fa-user"></i>
    </el-button>

    <gripo-dialog :model.sync="dialogVisible"
                  width="85vw"
                  top="3vh">
      <template slot="header">Verificação de Cadastros</template>
      <template slot="content">
        <el-row :gutter="10">
          <el-col :span="12">
            <h3>Atleta{{stageSingle ? '' : ' 1'}}</h3>
            <div class="table-responsive">
              <table class="custom-table-list">
                <thead>
                <tr>
                  <th>Torneio</th>
                  <th>Nome</th>
                  <th>Categoria</th>
                  <th>Dados</th>
                  <th class="text-center">Pontos</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="stage in player1Stages">
                  <td width="30%">{{stage.stage}}</td>
                  <template v-if="stage.found">
                    <td>{{stage.name}}</td>
                    <td>{{stage.player_class}}</td>
                    <td>
                      {{[stage.cpf, stage.email, stage.phone].filter(item => !!item).join(' - ')}}
                    </td>
                    <td class="text-center">{{stage.points}}</td>
                  </template>
                  <td v-else colspan="4" class="text-danger">
                    Inscrição não encontrada
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </el-col>
          <el-col :span="12" v-if="!stageSingle">
            <h3>Atleta 2</h3>
            <div class="table-responsive">
              <table class="custom-table-list">
                <thead>
                <tr>
                  <th>Torneio</th>
                  <th>Nome</th>
                  <th>Categoria</th>
                  <th>Dados</th>
                  <th class="text-center">Pontos</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="stage in player2Stages">
                  <td width="30%">{{stage.stage}}</td>
                  <template v-if="stage.found">
                    <td>{{stage.name}}</td>
                    <td>{{stage.player_class}}</td>
                    <td>
                      {{[stage.cpf, stage.email, stage.phone].filter(item => !!item).join(' - ')}}
                    </td>
                    <td class="text-center">{{stage.points}}</td>
                  </template>
                  <td v-else colspan="4" class="text-danger">
                    Inscrição não encontrada
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </el-col>
        </el-row>
      </template>
    </gripo-dialog>
  </div>
</template>
