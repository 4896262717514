<script>
import * as restfulService from '../../../../../../services/restfulService'

import bus from '@utils/bus'

import Draggable from 'vuedraggable'

export default {
  name: 'draw-match',
  components: {Draggable},
  props: {
    stageId: {
      type: Number
    },
    playerClassId: {
      type: Number
    },
    matches: {
      type: Array,
      default: () => []
    },
    round: {
      type: Number
    },
    game: {
      type: Number
    },
    pairNumber: {
      type: Number
    },
    hideToPrint: {
      type: Boolean
    }
  },
  data() {
    return {
      isLoading: false
    }
  },
  computed: {
    isByeMatch() {
      return this.match.datetime === null && this.match.winner_pair !== null && this.match.pair_id === null
    },
    match() {
      const match = this.matches.find(item => {
        return item.round === this.round & item.number === this.game
      })
      if (match) {
        return {
          id: match.id,
          pair_id: match['pair' + this.pairNumber + '_id'],
          pair_names: match['pair' + this.pairNumber + '_id'] ? match['pair' + this.pairNumber].names : null,
          pair_number: this.pairNumber,
          datetime: match.datetime,
          winner_pair: match.winner_pair,
          list: []
        }
      }
      return {
        id: null,
        pair_id: null,
        pair_names: '',
        pair_number: this.pairNumber,
        list: []
      }
    }
  },
  methods: {
    saveMatch() {
      this.isLoading = true
      const pair = this.match.list[0]
      let data = {
        pair_number: this.match.pair_number,
        pair_id: pair.id,
        round: this.round,
        number: this.game,
        player_class_id: this.playerClassId,
        stage_id: this.stageId,
        match_id: this.match.id,
      }
      restfulService.post('match', 'pair-update', null, data)
          .then(response => {
            this.match.id = response.id
            this.match.pair_id = pair.id
            this.match.pair_names = pair.names
            setTimeout(() => {
              this.isLoading = false
            }, 200)
          })
          .catch(e => {
            console.log(e)
          })
    },
    deleteMatch() {
      this.isLoading = true
      let data = {pair_number: this.match.pair_number, pair_id: null, match_id: this.match.id}
      restfulService.post('match', 'pair-update', null, data)
          .then(() => {
            this.match.pair_id = null
            this.match.pair_names = null
            this.match.list.splice(0)
            setTimeout(() => {
              this.isLoading = false
            }, 200)
          })
          .catch(e => {
            console.log(e)
          })
    },
    processNextRound() {
      let data = {pair_id: this.match.pair_id}
      restfulService.put('match', 'process-next-round', this.match.id, data)
          .then(() => {
            bus.$emit('update-brackets')
          })
          .catch(e => {
            console.log(e)
          })
    }
  }
}
</script>

<template>
  <div class="match-content">
    <div class="match-box">
      <template v-if="isByeMatch">
        <span>BYE</span>
      </template>
      <template v-else-if="!isLoading">
        <template v-if="match.pair_id">
          <span class="d-inline-block mr-2" style="vertical-align: top;" v-html="match.pair_names"></span>
          <el-button @click="deleteMatch"
                     v-if="!hideToPrint"
                     size="mini"
                     type="danger"
                     class="el-button--ultra-small-circle"
                     icon="el-icon-close"
                     circle
                     plain>
          </el-button>
          <el-button @click="processNextRound"
                     v-if="!hideToPrint"
                     size="mini"
                     type="warning"
                     title="Passar de Fase"
                     class="el-button--ultra-small-circle"
                     circle
                     plain>
            <i class="fas fa-arrow-right"></i>
          </el-button>
        </template>
        <template v-else>
          <Draggable class="draggable-component"
                     v-model="match.list"
                     :options="{group:{name:'pairs'}, scroll:false}"
                     @add="saveMatch">
          </Draggable>
        </template>
      </template>
    </div>
  </div>
</template>
