<script>
export default {
  name: 'balance-status-label',
  props: {
    status: String
  },
  computed: {
    statusText() {
      if (this.status === 'failed') {
        return 'Falhou'
      } else if (this.status === 'transferred') {
        return 'Transferido'
      } else {
        return 'Processando'
      }
    },
    chipColor() {
      if (this.status === 'failed') {
        return 'error'
      } else if (this.status === 'transferred') {
        return 'success'
      } else {
        return 'info'
      }
    }
  }
}
</script>

<template>
  <v-chip :value="true"
          :color="chipColor"
          small
          outlined>
    <span class="white--text">{{ statusText }}</span>
  </v-chip>
</template>
