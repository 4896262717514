<script>
import ListHeader from '../../../../components/list/Header'
import FormSaveButton from '../../../../components/form/SaveButton'
import FormCancelButton from '../../../../components/form/CancelButton'

import DataService from '../../../../services/dataService'
import * as restfulService from '../../../../services/restfulService'
import * as notifyService from '../../../../services/notifyService'

import helpers from '@mixins/helpers'
import bus from '@utils/bus'

import {clone} from 'lodash'
import {required, requiredIf} from 'vuelidate/lib/validators'
import StageStatus from "../../stage/components/StageStatus.vue";

export default {
  components: {StageStatus, ListHeader, FormSaveButton, FormCancelButton},
  mixins: [helpers],
  data() {
    return {
      isEdit: false,
      isSubmitted: false,
      isLoadingData: true,
      showParentCircuit: false,
      form: {
        circuit_id: '',
        is_external: false,
        name: '',
        start_date: '',
        end_date: '',
        stages_number: 0,
        match_types: [],
        stages_connected: [],
        annual: false,
        year_in_name: false
      },
      dependencies: {
        circuits: [],
        match_types: []
      },
      pointsPlaceholder: {
        0: '5',
        64: '7',
        32: '10',
        16: '20',
        8: '30',
        4: '50',
        2: {
          2: '60',
          1: '80',
        },
      }
    }
  },
  computed: {
    circuitsFiltered() {
      if (this.isEdit && this.form.id) {
        return this.dependencies.circuits.filter(item => item.id !== this.form.id)
      }
      return this.dependencies.circuits
    }
  },
  validations: {
    form: {
      name: {required},
      start_date: {
        required: requiredIf(function (obj) {
          return !obj.circuit_id && !obj.annual
        })
      },
      end_date: {
        required: requiredIf(function (obj) {
          return !obj.circuit_id && !obj.annual
        })
      }
    }
  },
  async mounted() {
    await this.getDependencies()
    const id = this.$route.params.id || null
    if (id) {
      this.isEdit = true
      this.showParentCircuit = true
      restfulService.get('circuit', null, id, null, ['match_types'])
          .then(response => {
            this.form = {...response}
            this.dependencies.match_types.forEach(item => {
              const matchType = this.form.match_types.find(matchType => matchType.round === item.round)
              if (matchType) {
                item.points = matchType.points
                item.winner_points = matchType.winner_points
              }
            })
            this.isLoadingData = false
            bus.$emit('hide-loader')
          })
          .catch(e => {
            console.log(e)
            this.isLoadingData = false
            bus.$emit('hide-loader')
          })
    } else {
      this.isLoadingData = false
      bus.$emit('hide-loader')
    }
  },
  methods: {
    getDependencies() {
      return new Promise(resolve => {
        DataService.get([{domain: 'circuit'}, {domain: 'match_type'}]).then((result) => {
          this.dependencies = {...result}
          resolve()
        })
      })
    },
    onSend() {
      this.isSubmitted = true
      const data = clone(this.form)
      data.match_types = clone(this.dependencies.match_types).filter(item => item.points)
      restfulService.save('circuit', null, null, data)
          .then(() => {
            notifyService.success({hideLoader: true})
            this.isSubmitted = false
            this.$router.push({name: 'circuit.index'})
          })
          .catch(e => {
            this.isSubmitted = false
            console.log(e)
          })
    }
  }
}
</script>

<template>
  <div>

    <ListHeader title="Circuito"/>

    <el-form ref="form"
             :model="form"
             label-position="top"
             @submit.prevent="onSend">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-card class="el-card__save_form box-card">
            <div slot="header">
              <span>Dados do Circuito</span>
            </div>

            <el-form-item label="Circuito Pai" v-if="showParentCircuit">
              <el-select v-model="form.circuit_id"
                         filterable
                         clearable
                         placeholder="Selecione um circuito pai"
                         empty-text="Nenhum registro encontrado"
                         class="el-select-full">
                <el-option v-for="item in circuitsFiltered"
                           :key="item.id"
                           :label="item.name"
                           :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Nome"
                          class="is-required"
                          :class="{ 'el-form-item--error': $v.form.name.$error }">
              <el-input v-model="form.name"
                        @input="$v.form.name.$touch()"/>
              <div class="el-form-item__error" v-if="$v.form.name.$error">Esse campo é obrigatório</div>
            </el-form-item>
            <el-row :gutter="20">
              <el-col :span="10">
                <el-form-item label="Circuito Externo">
                  <el-switch v-model="form.is_external"
                             :active-value="true"
                             :inactive-value="false"
                             active-text="SIM"
                             inactive-text="NÃO">
                  </el-switch>
                </el-form-item>
              </el-col>
              <el-col :span="14">
                <el-alert type="info" show-icon :closable="false">
                  <div class="fs-14">Utilize essa opção para criar um circuito com raking entre clubes do Gripo</div>
                </el-alert>
              </el-col>
            </el-row>
            <el-row :gutter="20" class="mt-2">
              <el-col :span="10">
                <el-form-item label="Circuito 365 (Anual)">
                  <el-switch v-model="form.annual"
                             :active-value="true"
                             :inactive-value="false"
                             active-text="SIM"
                             inactive-text="NÃO">
                  </el-switch>
                </el-form-item>
              </el-col>
              <el-col :span="14">
                <el-alert type="info" show-icon :closable="false">
                  <div class="fs-14">No circuito anual a pontuação das etapas vinculadas vence após 1 anos da sua
                    realização
                  </div>
                </el-alert>
              </el-col>
            </el-row>
            <div class="mt-2" v-if="form.annual">
              <el-form-item label="Número de Etapas">
                <el-input-number v-model="form.stages_number"
                                 :step="1"
                                 :min="0">
                </el-input-number>
              </el-form-item>
              <el-alert type="info" show-icon :closable="false">
                <div class="fs-14">
                  <template v-if="form.stages_number === 0">
                    0 (Zero) significa que todos os torneios de 1 ano para trás serão considerados para o ranking
                  </template>
                  <template v-else>
                    As últimos {{ form.stages_number }} etapas/torneios serão consideradas para o ranking
                  </template>
                </div>
              </el-alert>
            </div>
            <el-row :gutter="20" v-if="!form.annual">
              <el-col :span="12">
                <el-form-item label="Data de Inicio"
                              class="is-required"
                              :class="{ 'el-form-item--error': $v.form.start_date.$error }">
                  <el-date-picker type="date"
                                  v-model="form.start_date"
                                  placeholder="Data de Inicio"
                                  format="dd/MM/yyyy"
                                  value-format="yyyy-MM-dd"
                                  v-mask="'##/##/####'"
                                  @input="$v.form.start_date.$touch()">
                  </el-date-picker>
                  <div class="el-form-item__error" v-if="$v.form.start_date.$error">
                    Esse campo é obrigatório
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Data Final"
                              class="is-required"
                              :class="{ 'el-form-item--error': $v.form.end_date.$error }">
                  <el-date-picker type="date"
                                  v-model="form.end_date"
                                  placeholder="Data Final"
                                  format="dd/MM/yyyy"
                                  value-format="yyyy-MM-dd"
                                  v-mask="'##/##/####'"
                                  @input="$v.form.end_date.$touch()">
                  </el-date-picker>
                  <div class="el-form-item__error" v-if="$v.form.end_date.$error">
                    Esse campo é obrigatório
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-card>
          <el-card class="el-card__save_form box-card mt-4" v-if="form.is_external && Array.isArray(form.stages_connected) && form.stages_connected.length > 0">
            <div slot="header">
              <span>Etapas Vinculadas ao Circuito Externo</span>
            </div>
            <table class="custom-table-list">
              <thead>
              <tr>
                <th>Nome</th>
                <th>Datas</th>
                <th>Cidade</th>
                <th>Status</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, index) in form.stages_connected" :key="index">
                <td>{{item.name}}</td>
                <td>Entre {{item.first_date | dateEnToBr}} e {{item.last_date | dateEnToBr}}</td>
                <td>{{item.city}}</td>
                <td>
                  <stage-status :status="item.status"/>
                </td>
              </tr>
              </tbody>
            </table>
          </el-card>
        </el-col>
        <el-col :span="12">
          <el-card class="el-card__save_form box-card">
            <div slot="header">
              <span>Pontuações</span>
            </div>
            <el-form-item v-for="(item, index) in dependencies.match_types"
                          :key="index"
                          :label="item.value">
              <div v-if="item.round !== 2">
                <el-input v-model="item.points" :placeholder="`Ex.: ${pointsPlaceholder[item.round]}`"></el-input>
              </div>
              <div v-if="item.round === 2" class="mt-2">
                <el-row :gutter="20">
                  <el-col :span="12">
                    <strong>Vice</strong><br>
                    <div class="mt-2">
                      <el-input v-model="item.points" :placeholder="`Ex.: ${pointsPlaceholder[2][2]}`"></el-input>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <strong>Campeão</strong>
                    <div class="mt-2">
                      <el-input v-model="item.winner_points"
                                :placeholder="`Ex.: ${pointsPlaceholder[2][1]}`"></el-input>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </el-form-item>
          </el-card>
        </el-col>
      </el-row>
      <el-card class="el-card__btn_form">
        <form-save-button :event-click="onSend"
                          :is-disabled="isSubmitted || $v.form.$invalid"
                          :is-submitted="isSubmitted">
        </form-save-button>
        <form-cancel-button route-name="circuit.index"/>
      </el-card>
    </el-form>
  </div>
</template>
