<script>
import GripoDialog from "../../../../components/common/Dialog.vue";
import * as restfulService from "../../../../services/restfulService";

export default {
  name: 'notification-full-modal',
  components: {GripoDialog},
  props: {
    notification: Object,
    show: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      showModal: false
    }
  },
  watch: {
    showModal: async function (value) {
      if (!value) {
        const data = {
          notifications_id: [this.notification.id],
          status: 'read'
        }
        await restfulService.post('user', 'notification/update', null, data)
        this.$emit('reloadNotifications')
      }
    }
  },
  mounted() {
    this.showModal = this.show
  }
}
</script>

<template>
  <div>
    <el-button size="small"
               type="info"
               @click="showModal = true"
               title="Visualizar Mensagem Completa">
      <i class="far fa-folder-open"></i>
      Visualizar Mensagem Completa
    </el-button>
    <gripo-dialog :model.sync="showModal"
                  :actions-close-btn="false"
                  persistent
                  width="80vw">
      <template slot="header">{{ notification?.title }}</template>
      <template slot="content">
        <div v-html="notification?.message"/>
      </template>
    </gripo-dialog>
  </div>
</template>
