<script>
export default {
  name: 'user-type',
  props: {
    type: String
  },
  computed: {
    text() {
      if (this.type === 'totem') {
        return 'Totem'
      } else if (this.type === 'invite') {
        return 'Convite'
      } else {
        return 'Usuário'
      }
    },
    tagType() {
      if (this.type === 'totem') {
        return 'warning'
      } else if (this.type === 'invite') {
        return 'info'
      } else {
        return 'default'
      }
    }
  }
}
</script>

<template>
  <el-tag size="mini" :type="tagType">{{ text }}</el-tag>
</template>
