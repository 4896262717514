<script>
import ListMain from '../../../../components/list/Main'
import ListHeader from '../../../../components/list/Header'
import ListEditButton from '../../../../components/list/EditButton'
import ListDeleteButton from '../../../../components/list/DeleteButton'
import GripoDialog from '../../../../components/common/Dialog'

import * as notifyService from '../../../../services/notifyService'
import * as restfulService from '../../../../services/restfulService'

import bus from '@utils/bus'
import {mapGetters} from 'vuex'

import {debounce} from 'lodash'

export default {
  components: {GripoDialog, ListMain, ListHeader, ListEditButton, ListDeleteButton},
  data() {
    return {
      isLoadingData: true,
      isSearchingClient: false,
      isModalAddPlayerVisible: false,
      searchClient: '',
      selectedPlayer: '',
      filters: {},
      dependencies: {
        clients: []
      },
      currentGroup: {
        id: null,
        name: '',
        max_players: 0,
        players: []
      }
    }
  },
  watch: {
    'searchClient': debounce(function (value) {
      value && value !== this.selectedPlayer && this.onSearchClient(value)
    }, 300)
  },
  mounted() {
    bus.$emit('list-init', {domain: 'player_group', relations: ['players.client.user']}, () => {
      bus.$emit('hide-loader')
      this.isLoadingData = false
    })
  },
  computed: {
    ...mapGetters(['listStore'])
  },
  methods: {
    onFilter() {
      const searchData = {}
      if (this.filters.name) {
        searchData.name = {
          'like': '%' + this.filters.name + '%'
        }
      }
      bus.$emit('list-filter', searchData)
    },
    onFilterClean() {
      this.filters = {}
      bus.$emit('list-filter-clean', this.filters)
    },
    onDestroy(item) {
      bus.$emit('list-destroy-item', item)
    },
    onSelectGroup(group) {
      this.currentGroup = {...group}
      this.handleAddPlayerModalVisible()
    },
    handleAddPlayerModalVisible() {
      this.isModalAddPlayerVisible = !this.isModalAddPlayerVisible
    },
    onSearchClient(query) {
      this.dependencies.clients.splice(0)
      this.isSearchingClient = true
      let data = {query}
      restfulService.post('client', 'autocomplete', null, data)
          .then((response) => {
            if (response.length > 0) {
              this.dependencies.clients = [...this.dependencies.clients, ...response]
            }
            this.isSearchingClient = false
          })
          .catch(e => {
            console.log(e)
          })
    },
    async onSelectClient() {
      if (this.selectedPlayer) {
        let clientId = null
        const clientData = this.dependencies.clients.find(item => item.id === this.selectedPlayer)
        if (clientData.type === 'user') {
          const data = {
            user: {
              id: clientData.id,
              name: clientData.name
            },
            name: clientData.name
          }
          const storeClient = await restfulService.post('client', 'save', null, data)
          clientId = storeClient.id
        } else {
          clientId = clientData.id
        }
        this.searchClient = ''
        this.selectedPlayer = ''
        this.dependencies.clients.splice(0)
        let data = {client_id: clientId}
        restfulService.post('player_group', 'store-player', this.currentGroup.id, data)
            .then((response) => {
              this.currentGroup.players = [...response.players]
              notifyService.success({hideLoader: true})
              this.onFilter()
            })
            .catch(e => {
              console.log(e)
            })
      }
    },
    onDeletePlayer(player) {
      let data = {client_id: player.client_id}
      restfulService.post('player_group', 'delete-player', this.currentGroup.id, data)
          .then(() => {
            const playerFoundIndex = this.currentGroup.players.findIndex(item => item.id === player.id)
            this.currentGroup.players.splice(playerFoundIndex, 1)
            notifyService.success({hideLoader: true})
            this.onFilter()
          })
          .catch(e => {
            console.log(e)
          })
    }
  }
}
</script>

<template>
  <div>

    <ListHeader title="Grupos" route-name="player_group.store"/>

    <ListMain>

      <div slot="filters">
        <el-card class="el-card__search_list box-card">
          <div slot="header">
            <span>Filtros</span>
            <div>
              <el-button type="primary" @click="onFilter" size="small">
                <i class="fas fa-filter"></i>
                <span>Aplicar Filtros</span>
              </el-button>
              <el-button type="default" @click="onFilterClean" size="small">
                <i class="fas fa-times"></i>
                <span>Limpar</span>
              </el-button>
            </div>
          </div>
          <el-form :inline="true" :model="filters">
            <el-form-item label="Nome">
              <el-input v-model="filters.name"
                        placeholder="Filtrar por nome"
                        @keydown.enter.native.prevent="onFilter"/>
            </el-form-item>
          </el-form>
        </el-card>
      </div>

      <div slot="list" v-if="!isLoadingData">
        <table class="custom-table-list">
          <thead>
          <tr>
            <th class="list-table-nowrap">ID</th>
            <th>Nome</th>
            <th>Jogadores</th>
            <th class="btn-actions">Ações</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in listStore.listData" :key="index">
            <td>{{item.id}}</td>
            <td>{{item.name}}</td>
            <td>
              {{item.players.length}} / {{item.max_players}}
              <el-button type="primary" size="small" class="ml-3" @click="onSelectGroup(item)">
                <i class="fas fa-users"></i>
                <span>Jogadores</span>
              </el-button>
            </td>
            <td class="list-table-nowrap">
              <div class="btn-actions">
                <ListEditButton route-name="player_group.update" :item="item"/>
                <ListDeleteButton :item="item" domain="player_group"/>
              </div>
            </td>
          </tr>
          </tbody>
        </table>

      </div>

    </ListMain>

    <gripo-dialog :model.sync="isModalAddPlayerVisible"
                  width="60vw"
                  top="1vh"
                  :actions-close-btn="false">
      <template slot="header">Grupo: {{currentGroup.name}}</template>
      <template slot="content">
        <el-alert v-if="currentGroup && currentGroup.max_players === currentGroup.players.length"
                  :closable="false"
                  show-icon
                  :title="`Esse grupo atingiu o número limite de jogadores: ${currentGroup.max_players}`"
                  type="warning"
                  class="mb-3">
        </el-alert>

        <h3>Buscar jogadores</h3>
        <v-autocomplete v-model="selectedPlayer"
                        :items="dependencies.clients"
                        :search-input.sync="searchClient"
                        clearable
                        @change="onSelectClient"
                        no-data-text="Digite os dados do jogador..."
                        color="blue-grey lighten-2"
                        label="Nome/Celular/Email/CPF"
                        item-text="name"
                        item-value="id"
                        hide-details
                        hide-selected
                        :disabled="currentGroup && currentGroup.max_players === currentGroup.players.length"
                        no-filter>
          <template slot="item" slot-scope="data">
            <v-list-tile-content>
              <v-list-tile-title>
                {{data.item.name}}
              </v-list-tile-title>
              <v-list-tile-sub-title v-if="data.item.phone">
                {{data.item.phone | phone}}
              </v-list-tile-sub-title>
            </v-list-tile-content>
          </template>
        </v-autocomplete>
        <div class="mt-3">
          <h3>Jogadores do Grupo ({{currentGroup.players.length}})</h3>
          <v-list class="players-group-list">
            <template v-for="(player, index) in currentGroup.players">
              <v-divider :key="index + 5000"/>
              <v-list-tile :key="index">
                <v-list-tile-avatar v-if="player.client.user && player.client.user.avatar_path">
                  <img :src="player.client.user.avatar_path">
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>
                    {{player.client.name}}
                  </v-list-tile-title>
                  <v-list-tile-sub-title v-if="player.client.user && player.client.user.phone">
                    {{player.client.user.phone | phone}}
                  </v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile-action>
                  <v-btn small color="error" @click="onDeletePlayer(player)">
                    Remover
                  </v-btn>
                </v-list-tile-action>
              </v-list-tile>
            </template>
          </v-list>
        </div>
      </template>
    </gripo-dialog>
  </div>
</template>
