<script>
export default {
  name: 'empty-content',
  data() {
    return {
      message: 'Conteúdo não disponível'
    }
  }
}
</script>

<template>
  <div class="empty-content">
    <v-alert class="empty-content-alert" :value="true" type="info" outline>
      <slot name="content">{{ message }}</slot>
    </v-alert>
  </div>
</template>

<style lang="scss">
.empty-content {
  width: 60%;
  margin: 30px auto;

  .empty-content-alert {
    border-radius: 3px;

    h3, .title {
      font-size: 18px;
      font-weight: bold;
    }
  }
}

@media all and (max-width: 768px) {
  .empty-content {
    width: 80%;
    margin: 15px auto;
  }
}
</style>