<script>
import * as restfulService from '@app/services/restfulService'
import * as notifyService from '@app/services/notifyService'

import bus from '@utils/bus'
import {confirmDialog} from '@utils/flash'

export default {
  name: 'registration-payment',
  props: {
    size: {
      type: String,
      default: 'mini'
    },
    paymentEnabled: {
      type: Boolean,
      default: false
    },
    paymentUnique: {
      type: Boolean,
      default: false
    },
    playerNumber: {
      type: Number,
      default: 1
    },
    registration: {
      type: Object
    },
    transaction: {
      type: Object
    },
    showActions: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      transactionStatus: '',
      transactionPayDate: null
    }
  },
  computed: {
    canHandlePayment() {
      return !this.paymentUnique
          || (this.paymentUnique && this.playerNumber === 1)
    },
    hasTransaction() {
      return this.transaction !== null
    },
    paymentExpired() {
      return this.hasTransaction && this.registration.payment_expired
    },
    paymentCreated() {
      return (this.transactionStatusCreated || !this.registration.payment_datetime)
          && this.registration.status !== 'canceled'
    },
    transactionStatusCreated() {
      return this.transactionStatus === 'created'
    },
    transactionStatusPaid() {
      return this.transactionStatus === 'paid'
    },
    transactionStatusCancelled() {
      return this.transactionStatus === 'canceled'
    },
    transactionStatusRefunded() {
      return this.transactionStatus === 'refunded'
    },
    paymentPaid() {
      return (this.hasTransaction && this.transactionStatusPaid)
          || (!this.hasTransaction && this.registration.payment_datetime)
    },
    paymentPayDatetime() {
      if (this.hasTransaction) {
        return this.transactionPayDate
      }
      return this.registration.payment_datetime
    },
    statusLabel() {
      if (this.transactionStatusCreated) {
        return 'Aguardando Pagto.'
      } else if (this.transactionStatusCancelled) {
        return 'Cancelado'
      } else if (this.transactionStatusRefunded) {
        return 'Estornado'
      } else {
        return ''
      }
    },
    tagType() {
      if (this.transactionStatusCreated) {
        return 'default'
      } else if (this.transactionStatusCancelled) {
        return 'danger'
      } else if (this.transactionStatusRefunded) {
        return 'warning'
      } else {
        return ''
      }
    }
  },
  watch: {
    transaction: {
      deep: true,
      immediate: true,
      handler: function (value) {
        if (value && value.id) {
          this.transactionStatus = value.status
          this.transactionPayDate = value.pay_date
        }
      }
    }
  },
  mounted() {
    if (this.hasTransaction) {
      this.transactionStatus = this.transaction.status
      this.transactionPayDate = this.transaction.pay_date
    }
  },
  methods: {
    async approveTransaction() {
      const {value: confirm} = await confirmDialog({
        title: 'Deseja realmente confirmar o pagamento dessa inscrição?',
        icon: 'warning',
      })
      if (confirm) {
        bus.$emit('show-loader')
        const data = {approve_payment: true}
        restfulService.put('registration', 'approve', this.registration.id, data)
            .then(response => {
              notifyService.success({hideLoader: true})
              this.transactionStatus = response.status
              this.transactionPayDate = response.pay_date
              this.$emit('updateRegistration')
              bus.$emit('hide-loader')
            })
            .catch(e => {
              console.log(e)
              bus.$emit('hide-loader')
            })
      }
    },
    async generateTransaction() {
      const {value: confirm} = await confirmDialog({
        title: 'Deseja realmente gerar um pagamento dessa inscrição?',
        icon: 'warning',
      })
      if (confirm) {
        bus.$emit('show-loader')
        const data = {}
        restfulService.post('registration', 'create-transaction', this.registration.id, data)
            .then(() => {
              notifyService.success({hideLoader: true})
              this.$emit('updateRegistration')
              bus.$emit('hide-loader')
            })
            .catch(e => {
              console.log(e)
              bus.$emit('hide-loader')
            })
      }
    }
  }
}
</script>

<template>
  <div :class="['d-inline-block']">
    <div>
      <template v-if="hasTransaction && tagType">
        <el-tooltip effect="dark"
                    placement="bottom"
                    :disabled="transaction.paid && !(transaction?.gateway_data?.pix_expiration_date)">
          <el-tag :size="size" class="el-tag--status" :type="tagType"
                  v-if="!(paymentExpired && registration.status === 'created')">
            {{ statusLabel }}
          </el-tag>
          <div slot="content">
            <strong>Expira em: {{ transaction?.gateway_data?.pix_expiration_date | dateTimeEnToBr }}h</strong>
          </div>
        </el-tooltip>
        <el-tag :size="size" class="el-tag--status mt-1" type="warning"
                v-if="paymentExpired && registration.status === 'created'">
          Código PIX Expirado
        </el-tag>
        <div v-if="transactionStatusRefunded" class="mt-1">
          <strong>Atualizado em: {{ transaction.updated_at | dateTimeEnToBr }}h</strong>
        </div>
      </template>
      <template v-if="paymentPaid">
        <el-tag :size="size" class="el-tag--status" type="success">
          Pago
        </el-tag>
        <div v-if="paymentPayDatetime" class="mt-1">
          <strong>Em: {{ paymentPayDatetime | dateTimeEnToBr }}h</strong>
        </div>
      </template>
    </div>
    <div class="mt-1" v-if="showActions">
      <div class="text-center">
        <el-button type="success"
                   class="el-button--extra-small"
                   v-if="paymentCreated && canHandlePayment"
                   @click="approveTransaction"
                   :size="size"
                   title="Confirmar Pagamento Manualmente">
          <span class="mr-1">Confirmar</span>
          <i class="fas fa-check"></i>
        </el-button>
      </div>
      <div class="text-center mt-1"
           v-if="((!hasTransaction && paymentEnabled) || (hasTransaction && paymentExpired)) && canHandlePayment">
        <el-button type="warning"
                   class="el-button--extra-small"
                   v-if="registration.status === 'created'"
                   @click="generateTransaction"
                   :size="size"
                   title="Gerar Pagamento Online">
          <span class="mr-1">Gerar Pgto.</span>
          <i class="fas fa-money-bill"></i>
        </el-button>
      </div>
    </div>
  </div>
</template>
