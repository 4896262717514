<script>
import ApiService from '../../services/common/api/Api'

import {mapActions, mapGetters} from 'vuex'
import bus from '@utils/bus'

import {cloneDeep, isEmpty} from 'lodash'
import {confirmDialog} from '@utils/flash'

const listService = new ApiService()

export default {
  name: 'list-main',
  props: {
    isPaginationVisible: {type: Boolean, default: true},
    showTotal: {type: Boolean, default: true},
    title: {type: String, default: 'Listagem'},
    loadingMessage: {type: String, default: 'Carregando lista...'},
    emptyListMessage: {type: String, default: 'Nenhum registro foi encontrado'},
    showPagination: {type: Boolean, default: true},
    showHeader: {type: Boolean, default: true},
  },
  data() {
    return {
      isLoading: true,
      requestPending: false,
      pages: 1,
      currentPage: 1,
      perPage: 50,
      total: 0,
      defaultData: {},
      customHidePagination: false
    }
  },
  computed: {
    ...mapGetters(['listStore']),
    perPagesList() {
      let pages = [50, 100, 250, 500, this.total]
      return pages.filter(item => item <= this.total)
    },
    emptyList() {
      return this.total === 0
    }
  },
  methods: {
    ...mapActions([
      'setListOptions',
      'setListData',
      'setListFilters',
      'setListSort',
      'setCurrentPage'
    ]),

    /**
     * Método que inicializa a lista de componente
     * @param initData
     * @param callback
     */
    async onListInit(initData, callback) {
      bus.$emit('show-loader')
      await listService.setDomain(initData.domain)
      await this.handleSearchFilters(initData)
      const initOptions = {
        ref: initData.ref,
        domain: initData.domain,
        page: initData.page || this.currentPage,
        perPage: initData.page || this.perPage,
        columns: initData.columns || null,
        relations: initData.relations || null,
        data: initData.data || this.defaultData,
        processResponse: initData.processResponse || null,
        searchRoute: typeof initData.searchRoute !== 'undefined' ? initData.searchRoute : true,
        customSearchRoute: initData?.customSearchRoute || false,
      }
      await this.setListOptions(initOptions)
      if (initOptions.data.orderBy && initOptions.data.orderBy.length > 0) {
        await this.setListSort(initOptions.data.orderBy)
      }
      await this.setListFilters(initOptions.data)
      const response = await this.onSearchRequest(false)
      if (typeof callback === 'function') {
        callback(response)
      }
    },

    async onSearchRequest(hideLoaderCheck) {
      this.isLoading = true
      this.customHidePagination = false
      return new Promise(async (resolve, reject) => {
        bus.$emit('show-loader')
        const options = cloneDeep(this.listStore.options)
        options.data = {...this.listStore.listFilters}
        if (this.listStore.listSort) {
          options.data.orderBy = {...this.listStore.listSort}
        }
        this.setListData([])
        this.requestPending = true
        /*
        window.history.pushState(
            {},
            null,
            this.$route.path +
            '?' +
            Object.keys(options.data)
                .map(key => {
                  return (
                      encodeURIComponent(key) + '=' + encodeURIComponent(options.data[key])
                  )
                })
                .join('&')
        )
         */
        listService.search(options)
            .then(async (response) => {
              this.requestPending = false
              const responseData = await this.processListResponse(options, response)
              this.setListData(responseData)
              this.isLoading = false
              this.total = response.total
              this.pages = response.last_page
              this.customHidePagination = response?.hide_pagination || false
              const hideLoader = !!hideLoaderCheck
              if (hideLoader) {
                bus.$emit('hide-loader')
              }
              resolve(responseData)
            })
            .catch((e) => {
              this.requestPending = false
              reject(e)
              bus.$emit('hide-loader')
            })
      })
    },

    /**
     * @param data
     */
    async handleSearchFilters(data) {
      return new Promise(async (resolve) => {
        if (this.listStore.options.ref && data.ref &&
            data.ref === this.listStore.options.ref &&
            !isEmpty(this.listStore.listFilters)) {
          data.data = this.listStore.listFilters
        } else {
          this.setListFilters({})
        }
        resolve()
      })
    },

    /**
     * @param options
     * @param response
     */
    async processListResponse(options, response) {
      return new Promise(async (resolve) => {
        if (response && response.data && response.data.length > 0) {
          // Processa a resposta caso seja passado um callback no atributo processResponse
          if (typeof options.processResponse === 'function') {
            response.data = [...options.processResponse(response.data)]
          }
        } else {
          response.data = []
        }
        resolve(response.data)
      })
    },

    /**
     * Filtra os resultados da listagem
     *
     * @param data
     */
    async onFilter(data) {
      await this.setListFilters(data)
      await this.onSearchRequest(true)
    },

    /**
     * Limpa os filtros da listagem e atualiza a mesma
     *
     * @param {Object} data
     */
    async onFilterClean(data) {
      this.currentPage = 1
      await this.setCurrentPage(1)
      await this.setListFilters(data)
      await this.onSearchRequest(true)
    },

    /**
     * Ordena os resultados da listagem
     */
    async onSortChange() {
      await this.onSearchRequest(true)
    },

    /**
     * Remove um item da listagem
     *
     * @param item
     * @param callback
     */
    async onDestroyItem(item, callback) {
      const {value: confirm} = await confirmDialog({
        title: 'Deseja realmente remover esse registro?',
      })
      if (confirm) {
        const {id} = item
        listService.destroy({domain: this.listStore.options.domain, id: id})
            .then(() => {
              this.onPaginatorChangePage(this.currentPage)
              bus.$emit('hide-loader')
              if (typeof callback === 'function') {
                callback()
              }
            })
            .catch(e => {
              console.log(e)
              bus.$emit('hide-loader')
              if (typeof callback === 'function') {
                callback()
              }
            })
      } else {
        bus.$emit('hide-loader')
        if (typeof callback === 'function') {
          callback()
        }
      }
    },

    /**
     * Recarrega a lista quando a quantidade de registros é alterada
     * @param val
     */
    async onPaginatorChangeSize(val) {
      this.perPage = val
      this.onPaginatorChangePage(1, val)
    },

    /**
     * Recarrega a lista quando a paginação é alterada
     * @param page
     * @param perPage
     */
    async onPaginatorChangePage(page, perPage) {
      this.currentPage = page
      let searchData = this.listStore.options
      if (!!page && page > 0) {
        searchData.page = page
      }
      if (!!perPage && perPage > 0) {
        searchData.perPage = perPage
      }
      await this.setCurrentPage(page)
      await this.setListOptions(searchData)
      await this.onSearchRequest(true)
    },
    resetList() {
      this.setCurrentPage(1)
      this.setListData([])
      this.setListFilters({})
      this.setListOptions({})
      this.setListSort([])
    }
  },
  mounted() {
    this.resetList()
    bus.$on('list-init', (options, callback) => this.onListInit(options, callback))
    bus.$on('list-filter', async (data, resolve) => {
      await this.onFilter(data)
      if (typeof resolve === 'function') {
        resolve()
      }
    })
    bus.$on('list-filter-clean', (data) => this.onFilterClean(data))
    bus.$on('list-destroy-item', (item) => this.onDestroyItem(item))
    bus.$on('list-sort-change', (item) => this.onSortChange(item))
    if (!this.requestPending && this.isLoading) {
      this.isLoading = false
    }
  },
  destroyed() {
    this.resetList()
    bus.$off('list-init')
    bus.$off('list-filter')
    bus.$off('list-filter-clean')
    bus.$off('list-destroy-item')
    bus.$off('list-sort-change')
  }
}
</script>

<template>
  <div class="list-component">

    <slot name="filters"></slot>

    <slot name="afterFilters"></slot>

    <slot name="beforeMainList"></slot>

    <div class="main-list" v-if="!!$slots.list">

      <el-card class="el-card__search_list box-card">

        <div slot="header">
          <div class="flex-c list-header">
            <slot name="headerTitle">
              <span>{{ title }}</span>
            </slot>
            <slot name="headerList"></slot>
          </div>
        </div>

        <div class="table-list" v-if="!isLoading">
          <div class="gradient-hide" v-if="false"/>
          <slot name="list" class="table-responsive"/>
        </div>

        <div class="table-empty-list" :key="'emptyList'" v-if="emptyList && !isLoading">
          <div class="empty-icon">
            <i class="far fa-file-alt"></i>
          </div>
          <h3 class="empty-title">{{ emptyListMessage }}</h3>
        </div>

        <div class="table-empty-list table-loading-list" :key="'loadingList'" v-if="emptyList && isLoading">
          <h3 class="empty-title">{{ loadingMessage }}</h3>
        </div>

        <template v-if="showPagination">
          <div class="main-footer" v-if="!emptyList && !isLoading">
            <el-pagination v-if="isPaginationVisible && !customHidePagination"
                           :page-sizes="perPagesList"
                           :page-size="perPage"
                           background
                           :small="$vuetify.breakpoint.smAndDown"
                           @size-change="onPaginatorChangeSize"
                           @current-change="onPaginatorChangePage"
                           :current-page="currentPage"
                           :pager-count="($vuetify.breakpoint.smAndDown ? 5 : 9)"
                           layout="sizes, prev, pager, next"
                           :total="total">
            </el-pagination>
            <div class="total-records">
              <span>Total de Registros:</span> <strong>{{ total }}</strong>
            </div>
          </div>
        </template>

      </el-card>
    </div>

  </div>
</template>

<style lang="scss">
.list-component {
  > div {

    &:first-child {
      margin-bottom: 20px;
    }
  }
}

.main-list {
  margin: 20px 0 0 0;

  &.space-top {
    margin-top: 20px;
  }

  .table-list {
    padding-top: 5px;
    position: relative;

    .gradient-hide {
      position: absolute;
      right: 0;
      top: 0;
      width: 80px;
      height: 100%;
      background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.89) 89%, rgba(255, 255, 255, 1) 100%);
      background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.89) 89%, rgba(255, 255, 255, 1) 100%);
      background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.89) 89%, rgba(255, 255, 255, 1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=1);
    }

    > div {
      width: 100%;
      overflow-x: auto;
      overflow-y: hidden;
    }
  }
}

.main-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .el-pagination {
    text-align: center;
    padding: 15px 0 !important;
  }

  .total-records {
    text-transform: uppercase;
    font-size: 13px;

    span {
      color: #6c6c6c;
    }

    strong {
      font-size: 16px;
    }
  }
}

.table-empty-list {
  padding: 20px;

  .empty-icon,
  .empty-title,
  p {
    text-align: center;
  }

  .empty-icon {
    font-size: 80px;
    color: #c8c8c8;
  }

  .empty-title {
    margin: 0;
    padding: 0;
    font-weight: 500;
    font-size: 18px;
    color: #838383;
  }

  p {
    padding: 15px 0 0;
    font-size: 16px;
    color: #919191;
  }
}

.table-loading-list {
  .empty-icon {
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: loadingSpinner 1.1s infinite linear;
    animation: loadingSpinner 1.1s infinite linear;
  }
}

@media all and (max-width: 768px) {
  .empty-list {
    .empty-icon {
      font-size: 60px;
    }

    h3 {
      font-size: 15px;
    }
  }
  .main-footer {
    flex-direction: column;
  }
}

@-webkit-keyframes loadingSpinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loadingSpinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
