const CEP_API_URL = 'https://viacep.com.br/ws'

export async function get(cep) {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await fetch(`${CEP_API_URL}/${cep}/json`).then(response => response.json())
            if (false === result.hasOwnProperty('erro')) {
                resolve(result)
            }
            reject(result)
        } catch (e) {
            console.log(e)
            reject(e)
        }
    })
}
