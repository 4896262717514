import {hasRoutePermission} from '@mixins/helpers'
import {warningDialog} from "@utils/flash";

export const loginRedirect = async (response, router, hasBasicPlan, hasProfessionalPlan) => {
    return new Promise((resolve) => {
        if (Array.isArray(response.clubs) && response.clubs.length > 0) {
            resolve({routeName: false, routeParams: null})
        } else {
            let routeName = ''
            let routeParams = {}
            if (response.blocked) {
                routeName = 'tenant_settings.show'
                routeParams['tab'] = 'payment'
            } else {
                const isCoach = response.roles.length === 1 && response.roles[0] === 'professores'
                const isTournaments = response.roles.length === 1 && response.roles[0] === 'torneios'
                if (isTournaments) {
                    routeName = 'stage.index'
                } else if (isCoach || (hasBasicPlan && !hasProfessionalPlan)) {
                    routeName = 'schedule_daily.index'
                } else if (hasProfessionalPlan) {
                    routeName = 'order.daily.index'
                } else {
                    routeName = 'client.index'
                }
                if (!hasProfessionalPlan && !hasRoutePermission(router.resolve({name: routeName}).resolved)) {
                    if (hasRoutePermission(router.resolve({name: 'client.index'}).resolved)) {
                        routeName = 'client.index'
                    } else {
                        warningDialog({title: 'Permissão mínima não encontrada, entre em contato com o gestor do clube'})
                        throw new Error('Permissão mínima não encontrada')
                    }
                }
            }
            resolve({routeName, routeParams})
        }
    })
}